import { useState, useEffect } from 'react';

/**
 * Hook that acts like useState, but also updates state when a prop changes. It also uses the prop's
 * initial value as initital value for the state
 *
 * @param {any} propValue
 * @returns {[any, import('react').Dispatch<any>]}
 */
export const useStateUpdatedByProp = (propValue) => {
    const [value, setValue] = useState(propValue);
    useEffect(() => {
        setValue(propValue);
    }, [propValue]);

    return [value, setValue];
};
