import * as actionTypes from './action-types';

/**
 * @type {CollectionTypeEditorState}
 */
const COLLECTION_TYPE_EDITOR_INITIAL_STATE = {
    originalCollectionTypeId: null,
    addingCollectionType: false,
    currentCollectionType: null,
};

/**
 * collectionTypeEditor reducer
 *
 * @param {CollectionTypeEditorState} state
 * @param {*} action
 * @returns {CollectionTypeEditorState}
 */
export const collectionTypeEditor = (
    state = COLLECTION_TYPE_EDITOR_INITIAL_STATE,
    action = {},
) => {
    switch (action.type) {
        case actionTypes.START_ADDING_COLLECTION_TYPE:
            return {
                ...state,
                originalCollectionTypeId: null,
                addingCollectionType: true,
                currentCollectionType: getNewEmptyCollectionType(),
            };

        case actionTypes.START_EDITING_COLLECTION_TYPE:
            return {
                ...state,
                originalCollectionTypeId: action.collectionType.id,
                addingCollectionType: false,
                currentCollectionType: { ...action.collectionType },
            };

        case actionTypes.PATCH_COLLECTION_TYPE_FIELD:
            if (!state.currentCollectionType) {
                return state;
            }
            return {
                ...state,
                currentCollectionType: {
                    ...state.currentCollectionType,
                    [action.fieldName]: action.newValue,
                },
            };
        default:
            return state;
    }
};

/**
 * Gets an empty guide to start adding it
 *
 * @returns {CollectionType}
 */
function getNewEmptyCollectionType() {
    return {
        id: null,
        name: '',
        collection_class: getDefaultCollectionClass(),
        collection_class_id: getDefaultCollectionClass().id,
        ps_category: null,
        ps_subcategory: null,
        is_archived: false,
    };
}

function getDefaultCollectionClass() {
    return {
        id: 1,
        name: '',
    };
}
