import { notification } from 'antd';
import {
    addNumberOfDaysSpot as addNumberOfDaysApi,
    addNumberOfDaysItem,
    deleteNumberOfDaysItem,
    deleteNumberOfDaysSpot as deleteNumberOfDaysSpotApi,
    getNumberOfDaysItemsForGuide as getNumberOfDaysItemsForGuideFromDB,
    patchNumberOfDaysItem,
    patchNumberOfDaysSpot,
} from 'api/number-of-days-items.api';
import { AnyAction, Dispatch } from 'redux';
import { notifyGuideNumberOfDaysItemsChange } from 'store/guide-editor/actions';
import { parse } from 'utils/error-parser';
import {
    ItemFieldChange,
    applyPatchToItem,
    findOriginal,
    getChangesBetweenItemSets,
} from 'utils/sortable-item-utils';
import {
    FAILED_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE,
    FAILED_SAVING_NUMBER_OF_DAYS_ITEMS,
    FAILED_SAVING_NUMBER_OF_DAYS_SPOTS,
    STARTED_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE,
    STARTED_SAVING_NUMBER_OF_DAYS_ITEMS,
    STARTED_SAVING_NUMBER_OF_DAYS_SPOTS,
    SUCCESS_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE,
    SUCCESS_SAVING_NUMBER_OF_DAYS_ITEMS,
    SUCCESS_SAVING_NUMBER_OF_DAYS_SPOTS,
} from './action-types';
import { getNumberOfDaysItemsForGuide } from './selectors';
import { ThunkDispatch } from 'redux-thunk';

export const loadNumberOfDaysItemsForGuide = (guideId: Id) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch({
            type: STARTED_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE,
            guideId,
        });
        try {
            const payload = await getNumberOfDaysItemsForGuideFromDB(guideId);
            dispatch({
                type: SUCCESS_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE,
                guideId,
                payload,
            });
            return getNumberOfDaysItemsForGuide(getState(), guideId);
        } catch (error) {
            const errorText = parse(error);
            dispatch({
                type: FAILED_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE,
                guideId,
                error: errorText,
            });
        }
    };
};

/**
 * Saves the number of days spot that have changed
 */
const saveChangedNumberOfDaysSpot = (
    numberOfDaysId: Id,
    current: NumberOfDaysSpot[],
    original: NumberOfDaysSpot[],
) => {
    return async (dispatch: Dispatch) => {
        const {
            newItems,
            deletedItems,
            editedItems,
        } = getChangesBetweenItemSets(current, original);

        dispatch({ type: STARTED_SAVING_NUMBER_OF_DAYS_SPOTS });

        const itemsToAdd = newItems.map((newItem) =>
            addNumberOfDaysApi(numberOfDaysId, newItem),
        );
        const itemsToDelete = deletedItems.map((deletedItem) =>
            deleteNumberOfDaysSpotApi(deletedItem),
        );
        const itemsToPatch = editedItems.map((editedItem) =>
            patchNumberOfDaysSpot(editedItem),
        );

        try {
            await Promise.all([
                ...itemsToAdd,
                ...itemsToDelete,
                ...itemsToPatch,
            ]);
            dispatch({ type: SUCCESS_SAVING_NUMBER_OF_DAYS_SPOTS });
        } catch (error) {
            const errorText = parse(error);
            dispatch({
                type: FAILED_SAVING_NUMBER_OF_DAYS_SPOTS,
                error: errorText,
            });
            notification.error({
                message: 'Error saving number of days spots',
                description: errorText,
            });
            throw error;
        }
    };
};

/**
 * Saves the number of days spots that have changed
 */
const saveChangedNumberOfDaysSpots = (
    current: NumberOfDaysItem[],
    original: NumberOfDaysItem[],
) => {
    return async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        return current.forEach(async (currentItem) => {
            const originalItem = findOriginal(currentItem, original);

            return dispatch(
                saveChangedNumberOfDaysSpot(
                    currentItem.id,
                    currentItem.spots,
                    originalItem?.spots ?? [],
                ),
            );
        });
    };
};

/**
 * Saves the number of days items that have changed
 */
export const saveChangedNumberOfDaysItems = (
    guideId: Id,
    current: NumberOfDaysItem[],
    original: NumberOfDaysItem[],
) => {
    return async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        const {
            newItems,
            deletedItems,
            editedItems,
        } = getChangesBetweenItemSets(current, original);

        dispatch({ type: STARTED_SAVING_NUMBER_OF_DAYS_ITEMS });
        const itemsToAdd = newItems.map(async (newItem) => {
            const addedItem = await addNumberOfDaysItem(newItem);
            return { ...newItem, id: addedItem.id };
        });
        const itemsToDelete = deletedItems.map((deletedItem) =>
            deleteNumberOfDaysItem(deletedItem),
        );
        const itemsToPatch = editedItems.map(async (editedItem) => {
            await patchNumberOfDaysItem(editedItem);

            return editedItem;
        });

        try {
            const changedItems = (
                await Promise.all([
                    ...itemsToAdd,
                    ...itemsToDelete,
                    ...itemsToPatch,
                ])
            ).filter((item): item is NumberOfDaysItem => 'id' in item);

            await dispatch(
                saveChangedNumberOfDaysSpots(changedItems, original),
            );
            await dispatch(loadNumberOfDaysItemsForGuide(guideId));
            dispatch({ type: SUCCESS_SAVING_NUMBER_OF_DAYS_ITEMS });
        } catch (error) {
            const errorText = parse(error);
            dispatch({
                type: FAILED_SAVING_NUMBER_OF_DAYS_ITEMS,
                error: errorText,
            });
            notification.error({
                message: 'Error saving number of days items',
                description: errorText,
            });
            throw error;
        }
    };
};

export const addNumberOfDaysSpot = (
    numberOfDaysId: Id,
    spot: NumberOfDaysSpot,
) => (dispatch: Dispatch, getState: () => RootState) => {
    const items = getState().guideEditor.currentNumberOfDaysItems.map((item) =>
        item.id === numberOfDaysId
            ? {
                  ...item,
                  spots: [...item.spots, spot],
              }
            : item,
    );

    dispatch(notifyGuideNumberOfDaysItemsChange(items));
};

export const deleteNumberOfDaysSpot = (numberOfDaysId: Id, spotId: Id) => (
    dispatch: Dispatch,
    getState: () => RootState,
) => {
    const items = getState().guideEditor.currentNumberOfDaysItems.map((item) =>
        item.id === numberOfDaysId
            ? {
                  ...item,
                  spots: item.spots.filter(({ id }) => id !== spotId),
              }
            : item,
    );

    dispatch(notifyGuideNumberOfDaysItemsChange(items));
};

const applyPatchToSpot = (
    spots: NumberOfDaysSpot[],
    changedSpotFields: ItemFieldChange[],
) => {
    let items = spots;

    changedSpotFields.forEach((changedFields) => {
        items = applyPatchToItem(items, changedFields);
    });

    return items;
};

export const changeNumberOfDaysSpot = (
    numberOfDaysId: Id,
    changedSpotFields: ItemFieldChange[],
) => (dispatch: Dispatch, getState: () => RootState) => {
    const items = getState().guideEditor.currentNumberOfDaysItems.map((item) =>
        item.id === numberOfDaysId
            ? {
                  ...item,
                  spots: applyPatchToSpot(item.spots, changedSpotFields),
              }
            : item,
    );

    dispatch(notifyGuideNumberOfDaysItemsChange(items));
};
