import React from 'react';
import { getContainedSize } from 'utils/image/image-size-utils';
import { getPhotoWithClosestSize } from 'utils/ps-spot-utils';
import { MEDIA_SELECTOR_POPOVER_IMG_SIZE } from 'constants/ux';

import tooltipStyles from '../../MediaItemTooltipWrapper/style.module.css';
import './SpotMediaItemPopover.css';

/**
 * @param {Object} props
 * @param {PSSpot_v15_Photo} props.media
 */
function SpotMediaItemPopover({ media }) {
    const photo = getPhotoWithClosestSize(media, 800);
    const photoSize = media.exact_size === false ? null : getPhotoSize(photo);
    const containedSize = getContainedSize(
        photoSize,
        MEDIA_SELECTOR_POPOVER_IMG_SIZE,
    );

    return (
        <div className={tooltipStyles.root}>
            <img
                style={containedSize}
                className={tooltipStyles.image}
                src={photo.path}
                alt=""
            />
        </div>
    );
}

/**
 *
 * @param {CMSMediaPathInfo} photo
 */
function getPhotoSize(photo) {
    if (!photo.height || !photo.width) {
        return null;
    }
    return {
        height: photo.height,
        width: photo.width,
    };
}

export default SpotMediaItemPopover;
