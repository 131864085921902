/**
 * Gets all the loaded stories associated with a guide
 *
 * @param {RootState} state
 * @param {Id} guideId
 * @returns {StoryItem[] | null}
 */
export const getStoriesForGuide = (state, guideId) => {
    const allStoriesHash = getGuideStoriesInfo(state, guideId).byId;
    if (!allStoriesHash) {
        return null;
    }
    const allStories = Object.values(allStoriesHash);
    return allStories.sort((a, b) => {
        return (b.order || 0) - (a.order || 0);
    });
};

/**
 * Checks if stories associated with a guide are being loaded
 *
 * @param {RootState} state
 * @param {Id} guideId
 * @returns {boolean}
 */
export const isLoadingStoriesForGuide = (state, guideId) => {
    return getGuideStoriesInfo(state, guideId).loading;
};

/**
 * Gets error (if any) loading stories associated with a guide
 *
 * @param {RootState} state
 * @param {Id} guideId
 * @returns {string}
 */
export const getErrorLoadingStoriesForGuide = (state, guideId) => {
    return getGuideStoriesInfo(state, guideId).error;
};

/**
 * Checks if we are saving story items at the moment
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const isSavingStoryItems = (state) => {
    return state.storyItems.isSavingStoryItems;
};

/**
 * Gets the state of stories associated to a guide. If non existing, will return a default value
 *
 * @param {RootState} state
 * @param {Id} guideId
 * @returns {StoryItemsPerGuideState}
 */
function getGuideStoriesInfo(state, guideId) {
    return (
        state.storyItems.byGuideId[guideId] || {
            loading: false,
            error: null,
            byId: null,
        }
    );
}
