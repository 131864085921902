import React from 'react';
import MediaItemTooltipWrapper from '../../MediaItemTooltipWrapper';
import ShutterstockMediaItemPopover from './ShutterstockMediaItemPopover';
import MediaItemContent from '../MediaItemContent';

/**
 *
 * @param {Object} props
 * @param {ShutterstockPhotoFromSearch} props.media
 * @param {Id} props.guideId
 * @param {(media: ShutterstockPhotoFromSearch) => void} props.onSelect
 * @param {boolean} props.isSelected
 * @param {boolean} [props.isLoading=false]
 * @param {boolean} [props.canBeFavorite=true]
 */
function ShutterstockMediaItem({
    media,
    guideId,
    onSelect,
    isSelected,
    isLoading = false,
    canBeFavorite = true,
}) {
    const assetToUse =
        media.assets['preview'] ||
        media.assets['huge_thumb'] ||
        media.assets['large_thumb'];

    return (
        <MediaItemTooltipWrapper
            content={<ShutterstockMediaItemPopover media={media} />}
            overlayClassName="shutterstock-media-item-popover__antd-popover"
        >
            <MediaItemContent
                media={media}
                guideId={guideId}
                onSelect={() => onSelect(media)}
                isSelected={isSelected}
                mediaType="Shutterstock"
                isLoading={isLoading}
                canBeFavorite={canBeFavorite}
                imgSrc={assetToUse.url}
            />
        </MediaItemTooltipWrapper>
    );
}

export default ShutterstockMediaItem;
