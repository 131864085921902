import React, { MouseEventHandler, ReactNode } from 'react';

import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Badge, Button, Card, Modal, Spin } from 'antd';
import emptyStateImage from '../../../assets/images/empty-state.svg';
import styles from './style.module.css';
import { ListData, ListItem } from './use-sort';
import classNames from 'classnames';
import { BaseButtonProps } from 'antd/es/button/button';

type SortableListProps<T> = {
    data: ListData<T>[];
    onMoveUp: (data: T) => void;
    onMoveDown: (data: T) => void;
    onDelete: (data: T) => void;
    onAdd: () => void;
    children(options: {
        data: ListData<T> | null;
        index: number;
    }): React.ReactNode;
    title?: string | ReactNode;
    header?: ReactNode;
    disableFirstItem?: boolean;
    isLoading?: boolean;
    secondary?: boolean;
};

type SortableListEmptyState = {
    message: string;
    action: ReactNode;
};

type SortableListAddButtonProps = BaseButtonProps & {
    onClick?: MouseEventHandler<HTMLElement> | undefined;
    disabled?: boolean;
    children?: ReactNode;
};

export const SortableList = <T extends ListItem>({
    title,
    data,
    onAdd,
    onMoveUp,
    onMoveDown,
    onDelete,
    children,
    header,
    disableFirstItem = false,
    isLoading = false,
    secondary = false,
}: SortableListProps<T>) => {
    const isEmpty = !isLoading && data.length === 0;

    const showConfirm = (item: T) => {
        Modal.confirm({
            title: 'Delete item?',
            content:
                'Are you sure you want to remove this item? All data will be lost.',
            onOk() {
                onDelete(item);
            },
            okText: 'Delete',
            okButtonProps: {
                danger: true,
            },
            cancelText: 'Cancel',
        });
    };

    return (
        <Spin spinning={isLoading}>
            <Card
                title={
                    title ? (
                        <>
                            {title}
                            <Badge
                                count={data.length}
                                color="#8a99ad"
                                className={styles.badge}
                                showZero
                            />
                        </>
                    ) : null
                }
                style={
                    secondary
                        ? {
                              boxShadow: 'none',
                          }
                        : undefined
                }
                bodyStyle={{ padding: 0 }}
                extra={
                    title ? (
                        <Button
                            type="primary"
                            size="middle"
                            icon={<PlusOutlined />}
                            aria-label="Add new item"
                            onClick={onAdd}
                            disabled={isLoading}
                        />
                    ) : null
                }
                bordered={!secondary}
            >
                <>
                    {header ? (
                        <div className={styles.header}>{header}</div>
                    ) : null}

                    {isEmpty ? (
                        children({ data: null, index: 0 })
                    ) : (
                        <>
                            <ol
                                className={classNames({
                                    [styles.rightDivider]: secondary,
                                })}
                            >
                                {data
                                    .sort((a, b) => a.order - b.order)
                                    .map((item, index) => (
                                        <li
                                            key={item.id}
                                            className={classNames(styles.item, {
                                                [styles.secondary]: secondary,
                                            })}
                                        >
                                            <div className={styles.children}>
                                                {children({
                                                    data: item,
                                                    index,
                                                })}
                                            </div>
                                            {!disableFirstItem || index > 0 ? (
                                                <div className="story-item-editor__buttons">
                                                    <Button
                                                        disabled={
                                                            item.order <
                                                            (disableFirstItem
                                                                ? 2
                                                                : 1)
                                                        }
                                                        icon={
                                                            <ArrowUpOutlined />
                                                        }
                                                        onClick={() =>
                                                            onMoveUp(item)
                                                        }
                                                    />
                                                    <Button
                                                        icon={
                                                            <DeleteOutlined
                                                                style={{
                                                                    color:
                                                                        '#F5222D',
                                                                }}
                                                            />
                                                        }
                                                        onClick={() =>
                                                            showConfirm(item)
                                                        }
                                                    />
                                                    <Button
                                                        icon={
                                                            <ArrowDownOutlined />
                                                        }
                                                        disabled={
                                                            item.order >=
                                                            data.length - 1
                                                        }
                                                        onClick={() =>
                                                            onMoveDown(item)
                                                        }
                                                    />
                                                </div>
                                            ) : null}
                                        </li>
                                    ))}
                            </ol>
                            {!isLoading ? (
                                <div
                                    className={classNames(styles.footer, {
                                        [styles.secondary]: secondary,
                                    })}
                                >
                                    {
                                        <AddButton
                                            onClick={onAdd}
                                            disabled={isLoading}
                                            type={
                                                secondary
                                                    ? 'default'
                                                    : 'primary'
                                            }
                                        >
                                            {'Add new item'}
                                        </AddButton>
                                    }
                                </div>
                            ) : null}
                        </>
                    )}
                </>
            </Card>
        </Spin>
    );
};

function EmptyState({ message, action }: SortableListEmptyState) {
    return (
        <div role="status" aria-label={message} className={styles.emptyState}>
            <img src={emptyStateImage} alt="" />
            <p className={styles.emptyStateText}>{message}</p>
            {action}
        </div>
    );
}

function AddButton({
    onClick,
    children,
    disabled,
    type = 'primary',
}: SortableListAddButtonProps) {
    return (
        <Button
            type={type}
            size="large"
            icon={<PlusOutlined />}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </Button>
    );
}

SortableList.EmptyState = EmptyState;
SortableList.AddButton = AddButton;
