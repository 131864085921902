import React from 'react';
import classNames from 'classnames';
import './Title.css';

/** @typedef {"page"|"modal"|"section"|"subsection"} TitleType */

/**
 * @param {Object} props
 * @param {TitleType} props.type
 * @param {string} [props.className]
 * @param {React.ReactNode} props.children
 */
function Title(props) {
    const Tag = getTagFromType(props.type);

    return (
        <Tag
            className={classNames(
                'title',
                `title-${props.type}`,
                props.className,
            )}
        >
            {props.children}
        </Tag>
    );
}

/**
 * @param {TitleType} type
 */
function getTagFromType(type) {
    switch (type) {
        case 'page':
            return 'h1';
        case 'subsection':
            return 'h3';
        default:
            return 'h2';
    }
}

export default Title;
