import React, { useEffect, useState } from 'react';
import {
    Typography,
    Button,
    Radio,
    notification,
    Popover,
    Alert,
    Spin,
    Tooltip,
    Modal,
} from 'antd';
import Linkify from 'react-linkify';
import { InfoCircleOutlined } from '@ant-design/icons';
import { migrateSpotSource, useSpotDetails } from 'api/spots.api';
import FormField from 'components/FormField';
import SpotSearch from 'components/SpotSearch';
import { refreshCollectionSpots } from 'api/collection-spots.api';
import { parse } from 'utils/error-parser';
import { Buffer } from 'buffer';

const { Title, Text } = Typography;

/** @type {SpotMigrationReason} */
const SPOT_SOURCE_MIGRATION_REASON_FIX = 1;
/** @type {SpotMigrationReason} */
const SPOT_SOURCE_MIGRATION_REASON_ADD = 2;

/**
 * @param {Object} props
 * @param {CollectionSpot} props.collectionSpot
 * @param {Guide} props.guide
 * @param {(newSource: PSSpot_v15_Minimal) => void} props.onSourceUpdate
 */
function CollectionSpotEditorSource({ collectionSpot, guide, onSourceUpdate }) {
    /** @type {[PSSpot_v15_Minimal, React.Dispatch<PSSpot_v15_Minimal>]} */
    const [newSource, setNewSource] = useState(null);
    /** @type {[SpotMigrationReason, React.Dispatch<SpotMigrationReason>]} */
    const [reason, setReason] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const { isLoading, fullSpot, errorLoading, mutate } = useSpotDetails(
        collectionSpot.spot,
    );
    useEffect(() => {
        if (!newSource) {
            setReason(null);
        }
    }, [newSource]);

    const isReplaceButtonDisabled = reason === null || !newSource || isUpdating;

    const updateSource = async () => {
        setIsUpdating(true);
        try {
            const newSpotSource = await migrateSpotSource(
                collectionSpot.spot.id,
                newSource,
                reason,
            );
            refreshCollectionSpots(collectionSpot.collection_id, guide.id);
            mutate();
            onSourceUpdate(newSpotSource);
            notification.success({
                message: 'Source is replaced',
                placement: 'bottomLeft',
            });
            setNewSource(null);
        } catch (error) {
            if (error.status === 400) {
                const errorDetail = await error.json();
                Modal.error({
                    title: 'Error replacing source',
                    content: (
                        <Linkify>
                            {errorDetail.message || 'Unknown error'}
                        </Linkify>
                    ),
                });
                return;
            }
            notification.error({
                message: 'Error replacing source',
                description: parse(error),
                placement: 'bottomLeft',
            });
        } finally {
            setIsUpdating(false);
        }
    };

    if (isLoading && !errorLoading) {
        return <Spin>Loading source...</Spin>;
    }

    const link = getLinkForSpot(fullSpot, collectionSpot);

    return (
        <>
            {errorLoading && (
                <>
                    <Alert
                        message="Error loading spot source"
                        description={`There as an error loading the spot source, maybe it is no longer available. Original error: "${errorLoading}"`}
                        showIcon
                        type="warning"
                    />
                    <div className="mb-m" />
                </>
            )}
            {!errorLoading && (
                <FormField labelWidth={105} label="Current source">
                    <div className="collection-spot-editor__source-link">
                        {link && (
                            <Tooltip title="Click to open source URL">
                                <a
                                    href={link}
                                    target="_BLANK"
                                    rel="noopener noreferrer"
                                >
                                    {collectionSpot.spot.source}
                                </a>
                            </Tooltip>
                        )}
                        {!link && (
                            <Tooltip title="No source URL for this spot">
                                <>{collectionSpot.spot.source}</>
                            </Tooltip>
                        )}
                        &nbsp;
                        <Popover
                            overlayStyle={{
                                maxWidth: 300,
                            }}
                            content={
                                <SourceInfoPopover spot={collectionSpot.spot} />
                            }
                            placement="right"
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    </div>
                </FormField>
            )}
            <FormField labelWidth={105} label="New source">
                <SpotSearch
                    placeholder="Search for a spot or paste an URL"
                    value={newSource}
                    location={guide.location}
                    guide={guide}
                    onSelect={(tempSpot) => setNewSource(tempSpot)}
                />
            </FormField>
            <FormField labelWidth={105} label="Reason" alignLabelTop={true}>
                <Radio.Group
                    onChange={(ev) => setReason(ev.target.value)}
                    value={reason}
                    disabled={!newSource}
                >
                    <Radio
                        value={SPOT_SOURCE_MIGRATION_REASON_FIX}
                        className="collection-spot-editor__source-radio"
                    >
                        Spot was wrong
                    </Radio>
                    <Radio
                        value={SPOT_SOURCE_MIGRATION_REASON_ADD}
                        className="collection-spot-editor__source-radio"
                    >
                        Improved source
                    </Radio>
                </Radio.Group>
            </FormField>
            <FormField labelWidth={105} label=" " showSemicolon={false}>
                <Popover
                    overlayStyle={{
                        maxWidth: 300,
                    }}
                    title={<>Replace source</>}
                    content={
                        <>
                            By replacing the source of this spot, you will
                            update the spot data for all Polarsteps users that
                            added this spot to their past trips.
                        </>
                    }
                    placement="right"
                    trigger={'hover'}
                >
                    <Button
                        type="primary"
                        loading={isUpdating}
                        onClick={() => {
                            updateSource();
                        }}
                        disabled={isReplaceButtonDisabled}
                    >
                        Replace source
                    </Button>
                </Popover>
            </FormField>
        </>
    );
}

/**
 * @param {Object} props
 * @param {PSSpot_v15_Minimal} props.spot
 */
function SourceInfoPopover({ spot }) {
    return (
        <>
            <Title level={4}>Current source info</Title>
            <div>
                <Text strong>Name:</Text>{' '}
                <Text type="secondary">{spot.name}</Text>
            </div>
            <div>
                <Text strong>Category:</Text>{' '}
                <Text type="secondary">{spot.category_label}</Text>
            </div>
            <div>
                <Text strong>Sub category:</Text>{' '}
                <Text type="secondary">{spot.sub_category}</Text>
            </div>
            <div>
                <Text strong>Location:</Text>{' '}
                <Text type="secondary">{spot.location?.single_line}</Text>
            </div>
        </>
    );
}

/**
 * @param {PSSpot_v15} spot
 * @param {CollectionSpot} collectionSpot
 */
function getLinkForSpot(spot, collectionSpot) {
    if (!spot) {
        return null;
    }
    if (spot.source === 'polarsteps_spots') {
        const decoded = Buffer.from(
            collectionSpot.spot_id + '',
            'base64',
        ).toString('binary');
        const id = decoded.split('|')[1];
        return `/polarsteps-spot/${id}`;
    }
    return spot?.deeplink || spot?.booking_url || spot?.website;
}

export default CollectionSpotEditorSource;
