import { AnyAction } from 'redux';
import * as actionTypes from './action-types';
import { getItemsMappedById } from 'utils/sortable-item-utils';

const HIGHLIGHT_ITEMS_INITIAL_STATE: HighlightItemsState = {
    byGuideId: {},
    isSavingHighlightItems: false,
};

export const highlightItems = (
    state = HIGHLIGHT_ITEMS_INITIAL_STATE,
    action: AnyAction,
): HighlightItemsState => {
    switch (action.type) {
        case actionTypes.STARTED_LOADING_HIGHLIGHT_ITEMS_FOR_GUIDE:
            return {
                ...state,
                byGuideId: {
                    ...state.byGuideId,
                    [action.guideId]: {
                        loading: true,
                        error: null,
                        byId: null,
                    },
                },
            };
        case actionTypes.FAILED_LOADING_HIGHLIGHT_ITEMS_FOR_GUIDE:
            return {
                ...state,
                byGuideId: {
                    ...state.byGuideId,
                    [action.guideId]: {
                        loading: false,
                        error: action.error,
                        byId: null,
                    },
                },
            };

        case actionTypes.SUCCESS_LOADING_HIGHLIGHT_ITEMS_FOR_GUIDE:
            return {
                ...state,
                byGuideId: {
                    ...state.byGuideId,
                    [action.guideId]: {
                        loading: false,
                        error: null,
                        byId: getItemsMappedById(action.payload),
                    },
                },
            };

        case actionTypes.STARTED_SAVING_HIGHLIGHT_ITEMS:
            return {
                ...state,
                isSavingHighlightItems: true,
            };
        case actionTypes.SUCCESS_SAVING_HIGHLIGHT_ITEMS:
        case actionTypes.FAILED_SAVING_HIGHLIGHT_ITEMS:
            return {
                ...state,
                isSavingHighlightItems: false,
            };

        default:
            return state;
    }
};
