import { Alert, Button, Input, Select, Typography, notification } from 'antd';
import classNames from 'classnames';
import FormField from 'components/FormField';
import { GUIDE_GOOD_TO_KNOW_DESCRIPTION_MAX_LENGTH } from 'constants/fields-limits';
import { camelCase } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getErrorLoadingGoodToKnowItemsForGuide,
    isLoadingGoodToKnowItemsForGuide,
    isSavingGoodToKnowItems,
} from 'store/good-to-know-items/selectors';
import {
    initGuideGoodToKnowItems,
    notifyGuideGoodToKnowItemsChange,
    resetGuideGoodToKnowItems,
    saveGoodToKnowItems,
} from 'store/guide-editor/actions';
import {
    doGoodToKnowItemsHaveErrors,
    getCurrentGoodToKnowItemsBeingEdited,
    getCurrentGuide,
    haveGoodToKnowItemsChanged,
} from 'store/guide-editor/selectors';
import { ItemFieldChange, applyPatchToItem } from 'utils/sortable-item-utils';
import { v4 as uuid } from 'uuid';
import { SortableList } from '../SortableList';
import { useSort } from '../SortableList/use-sort';
import styles from './style.module.css';

type GuideEditorGoodToKnowProps = {
    onClickDiscard: () => void;
};

type GuideEditorGoodToKnowItemProps = {
    index: number;
    goodToKnowItem: GoodToKnowItem;
    onChangeField: (change: ItemFieldChange) => void;
    errors?: FieldError;
    isSubmitted?: boolean;
};

type GoodToKnowColor = {
    [key: string]: string;
};

const colors: GoodToKnowColor = {
    '#4FBBC4': 'Vibe',
    '#775BC8': 'Stay',
    '#E08B28': 'Insider tip',
    '#CC586F': 'Best time to visit',
    '#4B997C': 'How to get there',
    '#807577': 'Need to know',
    '#BF8060': 'Extra 1',
    '#F2835B': 'Extra 2',
};

const defaultItems: Pick<GoodToKnowItem, 'title' | 'color_code'>[] = [
    {
        title: "What's the **vibe** like in",
        color_code: getColorByLabel('vibe'),
    },
    {
        title: 'Where should I **stay** in',
        color_code: getColorByLabel('stay'),
    },
    {
        title: 'Best **insider tip** for',
        color_code: getColorByLabel('insider tip'),
    },
    {
        title: 'When’s the **best time to visit**',
        color_code: getColorByLabel('best time to visit'),
    },
    {
        title: 'How do I **get to**',
        color_code: getColorByLabel('how to get there'),
    },
    {
        title: 'What else do I **need to know** about',
        color_code: getColorByLabel('need to know'),
    },
];

export const GuideEditorGoodToKnow = ({
    onClickDiscard,
}: GuideEditorGoodToKnowProps) => {
    const dispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const guide = useSelector(getCurrentGuide);
    const guideId = useMemo(() => guide?.id, [guide]);
    const currentGoodToKnowItems = useSelector(
        getCurrentGoodToKnowItemsBeingEdited,
    );
    const isLoading = useSelector((state: RootState) =>
        isLoadingGoodToKnowItemsForGuide(state, guideId),
    );
    const errorLoading = useSelector((state: RootState) =>
        getErrorLoadingGoodToKnowItemsForGuide(state, guideId),
    );
    const isSaving = useSelector(isSavingGoodToKnowItems);
    const hasChanged = useSelector(haveGoodToKnowItemsChanged);
    const { hasErrors, errors } = useSelector(doGoodToKnowItemsHaveErrors);

    /**
     * Patches many good to know item fields at the same time and dispatches them to the store
     */
    const patchGoodToKnowItemFields = (changes: ItemFieldChange[]) => {
        let items = currentGoodToKnowItems;
        for (const change of changes) {
            items = applyPatchToItem(items, change);
        }

        dispatch(notifyGuideGoodToKnowItemsChange(items));
    };

    const patchGoodToKnowItemField = (changes: ItemFieldChange) => {
        patchGoodToKnowItemFields([changes]);
    };

    const dispatchSavedGoodToKnowItems = async () => {
        if (!guideId) return;
        dispatch(saveGoodToKnowItems(guideId, currentGoodToKnowItems));
    };

    const save = async () => {
        setIsSubmitted(true);

        if (!hasErrors) {
            dispatchSavedGoodToKnowItems();
        } else {
            notification.error({
                message: 'Form validation failed',
                description: 'Please check the fields marked in red',
            });
        }
    };

    useEffect(() => {
        if (guideId) {
            dispatch(initGuideGoodToKnowItems(guideId));
        }
        return () => {
            dispatch(resetGuideGoodToKnowItems());
        };
    }, [guideId, dispatch]);

    const { onMoveUp, onMoveDown } = useSort(currentGoodToKnowItems);

    const preloadDefaultItems = () => {
        dispatch(
            notifyGuideGoodToKnowItemsChange(
                currentGoodToKnowItems.concat(generateDefaultItems(guide)),
            ),
        );
    };

    const addGoodToKnowItem = () => {
        if (!guideId) return;
        const newItem = getEmptyGoodToKnow(
            guideId,
            currentGoodToKnowItems.length,
        );
        const newList = currentGoodToKnowItems.concat([newItem]);
        dispatch(notifyGuideGoodToKnowItemsChange(newList));
    };

    const deleteGoodToKnowItem = ({ id }: GoodToKnowItem) => {
        const newItems = currentGoodToKnowItems.filter(
            (item) => item.id !== id,
        );
        dispatch(notifyGuideGoodToKnowItemsChange(newItems));
    };

    const moveGoodToKnowItemUp = (item: GoodToKnowItem) => {
        const sortedItems = onMoveUp(item);

        if (sortedItems) {
            patchGoodToKnowItemFields(
                sortedItems.map((item) => ({
                    id: item.id,
                    fieldName: 'order',
                    newValue: item.newOrder,
                })),
            );
        }
    };

    const moveGoodToKnowItemDown = (item: GoodToKnowItem) => {
        const sortedItems = onMoveDown(item);

        if (sortedItems) {
            patchGoodToKnowItemFields(
                sortedItems.map((item) => ({
                    id: item.id,
                    fieldName: 'order',
                    newValue: item.newOrder,
                })),
            );
        }
    };

    if (!guide) {
        return null;
    }

    return (
        <>
            {errorLoading && (
                <div className="full-page-tabs-error">
                    <Alert
                        className="grow-full-flex"
                        message="There was an error loading good to know items"
                        description={errorLoading}
                        type="error"
                        showIcon
                    />
                </div>
            )}
            <SortableList
                title="Good to know"
                data={currentGoodToKnowItems}
                onAdd={addGoodToKnowItem}
                onDelete={deleteGoodToKnowItem}
                onMoveDown={moveGoodToKnowItemDown}
                onMoveUp={moveGoodToKnowItemUp}
                isLoading={isLoading || isSaving}
            >
                {({ data, index }) =>
                    !data ? (
                        <SortableList.EmptyState
                            message="No good to know items yet"
                            action={
                                <SortableList.AddButton
                                    onClick={preloadDefaultItems}
                                    disabled={isLoading}
                                >
                                    Preload default items
                                </SortableList.AddButton>
                            }
                        />
                    ) : (
                        <GuideEditorGoodToKnowItem
                            key={data.id}
                            index={index}
                            goodToKnowItem={data}
                            onChangeField={patchGoodToKnowItemField}
                            isSubmitted={isSubmitted}
                            errors={errors[data.id]}
                        />
                    )
                }
            </SortableList>

            <div className="full-page-tabs-footer">
                <div className="max-content-width">
                    <div className="grow-full-flex"></div>

                    <Button size="large" onClick={onClickDiscard}>
                        Cancel
                    </Button>

                    <Button
                        size="large"
                        type="primary"
                        loading={isSaving}
                        onClick={save}
                        disabled={!hasChanged}
                    >
                        Save changes
                    </Button>
                </div>
            </div>
        </>
    );
};

function GuideEditorGoodToKnowItem({
    index,
    goodToKnowItem,
    onChangeField,
    errors = {},
    isSubmitted = false,
}: GuideEditorGoodToKnowItemProps) {
    const handleChangeField = useCallback(
        <T,>(fieldName: string, newValue: T) => {
            onChangeField({
                id: goodToKnowItem.id,
                fieldName: fieldName,
                newValue: newValue,
            });
        },
        [goodToKnowItem.id, onChangeField],
    );

    return (
        <div className={styles.item}>
            <Typography.Title level={5}>{`Item ${index + 1}`}</Typography.Title>
            <div className={styles.itemContent}>
                <div className={styles.form}>
                    <div className={styles.doubleFieldRow}>
                        <FormField
                            label="Title"
                            length={goodToKnowItem.title.length}
                            labelWidth={100}
                            className={styles.noMargin}
                            error={isSubmitted ? errors.title : undefined}
                        >
                            <Input
                                size="large"
                                value={goodToKnowItem.title}
                                status={
                                    isSubmitted && errors.title ? 'error' : ''
                                }
                                onChange={(e) =>
                                    handleChangeField('title', e.target.value)
                                }
                            />
                        </FormField>

                        <Select
                            size="large"
                            className={classNames(
                                styles.colorSelect,
                                styles[
                                    camelCase(colors[goodToKnowItem.color_code])
                                ],
                            )}
                            defaultValue={goodToKnowItem.color_code}
                            onChange={(value) =>
                                handleChangeField('color_code', value)
                            }
                            options={Object.entries(colors).map(
                                ([color, label]) => ({
                                    value: color,
                                    label,
                                    className: classNames(
                                        styles.colorOption,
                                        styles[camelCase(colors[color])],
                                    ),
                                }),
                            )}
                        />
                    </div>
                    <FormField
                        label="Description"
                        length={goodToKnowItem?.description?.length ?? 0}
                        maxLength={GUIDE_GOOD_TO_KNOW_DESCRIPTION_MAX_LENGTH}
                        labelWidth={100}
                        error={isSubmitted ? errors.description : undefined}
                        className={styles.noMargin}
                    >
                        <Input.TextArea
                            size="large"
                            value={goodToKnowItem.description}
                            autoSize={{
                                minRows: 1,
                                maxRows: 4,
                            }}
                            status={
                                isSubmitted && errors.description ? 'error' : ''
                            }
                            onChange={(e) =>
                                handleChangeField('description', e.target.value)
                            }
                        />
                    </FormField>
                    <FormField
                        label="Notes"
                        labelWidth={100}
                        className={styles.noMargin}
                    >
                        <Input.TextArea
                            size="large"
                            className="form-field__textarea"
                            autoSize={{
                                minRows: 1,
                                maxRows: 12,
                            }}
                            value={goodToKnowItem.editor_notes}
                            onChange={(e) =>
                                handleChangeField(
                                    'editor_notes',
                                    e.target.value,
                                )
                            }
                            placeholder="Add your thoughts..."
                        />
                    </FormField>
                </div>
            </div>
        </div>
    );
}

function getEmptyGoodToKnow(guideId: Id, order: number): GoodToKnowItem {
    return {
        id: uuid(),
        guide_id: guideId,
        title: ``,
        description: '',
        editor_notes: '',
        color_code: getColorByLabel('extra 1'),
        order,
    };
}

function generateDefaultItems(guide: Guide): GoodToKnowItem[] {
    return guide?.id
        ? defaultItems.map(({ title, color_code }, index) => ({
              id: uuid(),
              guide_id: guide.id as Id,
              title: `${title} ${
                  guide.location_name_custom || guide.location.name
              }?`,
              description: '',
              editor_notes: '',
              color_code,
              order: index,
          }))
        : [];
}

function getColorByLabel(value: string): string {
    return (
        Object.entries(colors).find(
            ([_, label]) => value.toLowerCase() === label.toLowerCase(),
        )?.[0] || '#000'
    );
}
