import React from 'react';
import { Typography } from 'antd';
import GenericSelectorSWR from 'components/GenericSelectorSWR';
import {
    getSecondLevel,
    SPOT_CATEGORY_SECOND_LEVEL_SWR_KEY,
} from 'api/spot-categories.api';

const { Text } = Typography;

/**
 * @param {Object} props
 * @param {string} props.parentCategoryId
 * @param {Id} props.value
 * @param {(newVal: Id) => void} props.onChange
 */
function SecondLevelSpotCategoriesSelector({
    parentCategoryId,
    value,
    onChange,
}) {
    if (!parentCategoryId) {
        return <Text type="secondary">Please select a parent category</Text>;
    }
    const swrKey = `${SPOT_CATEGORY_SECOND_LEVEL_SWR_KEY}_${parentCategoryId}`;
    return (
        <GenericSelectorSWR
            value={value}
            onChange={onChange}
            key={swrKey}
            swrKey={swrKey}
            fetcher={() => getSecondLevel(parentCategoryId)}
            placeholder="Select second level category"
            useStringIds={true}
        ></GenericSelectorSWR>
    );
}

export default SecondLevelSpotCategoriesSelector;
