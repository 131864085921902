import { get, post, postDelete, patch } from 'utils/http';
import { BASE_URL } from 'constants/env';
import { getStringFromSingle } from 'utils/flask-restless-filters';
import { deleteOldCMSMediaIfChanged } from './cms-media.api';

/**
 * Gets all the story items associated with a guide
 *
 * @param {Id} guideId
 * @returns {Promise<StoryItem[]>}
 */
export const getStoriesForGuide = (guideId) => {
    const filter = getStringFromSingle({
        name: 'guide_id',
        op: '==',
        val: guideId + '',
    });
    return get(`${BASE_URL}/cms/story_items?q={"filters":[${filter}]}`).then(
        (result) => result.objects,
    );
};

/**
 * Adds a story item to the database
 *
 * @param {StoryItem} story
 * @returns {Promise<StoryItem>}
 */
export const addStory = async (story) => {
    return post(`${BASE_URL}/cms/story_items`, toDBFormat(story));
};

/**
 * Patches a story in the database
 *
 * @param {StoryItem} newStory
 * @param {StoryItem} oldStory
 * @returns {Promise<StoryItem>}
 */
export const patchStory = async (newStory, oldStory) => {
    const patchedStoryItem = await patch(
        `${BASE_URL}/cms/story_items/${newStory.id}`,
        toDBFormat(newStory),
    );
    deleteOldCMSMediaIfChanged(patchedStoryItem.cms_media, oldStory.cms_media);
    return patchedStoryItem;
};

/**
 * Removes a story item to the database
 *
 * @param {StoryItem} story
 */
export const deleteStory = async (story) => {
    const deletionResult = await postDelete(
        `${BASE_URL}/cms/story_items/${story.id}`,
        {
            returnPlainResponse: true,
        },
    );
    deleteOldCMSMediaIfChanged(null, story.cms_media);
    return deletionResult;
};

/**
 * Converts a story item to the format needed for the DB
 *
 * @param {StoryItem} story
 * @returns {DBStoryItem}
 */
function toDBFormat(story) {
    return {
        guide_id: story.guide_id,
        spot_id: story.spot?.id || null,
        cms_media_id: story.cms_media?.id || null,
        tagline: story.tagline,
        order: story.order,
        editor_notes: story.editor_notes,
    };
}
