import {
    getCurrentGuide,
    hasGuideChanged,
    isShowingSpotCollectionEditor,
} from 'store/guide-editor/selectors';
import { haveStoriesChanged } from 'store/guide-editor/selectors';

/**
 * @param {RootState} state
 * @returns {boolean}
 */
export const hasUnsavedChanges = (state) => {
    // Guide editor -> basic info (both adding and editing)
    if (getCurrentGuide(state) && hasGuideChanged(state)) {
        return true;
    }
    // Guide editor -> spot collection editor
    if (isShowingSpotCollectionEditor(state)) {
        return true;
    }

    // Guide editor -> Story items
    if (haveStoriesChanged(state)) {
        return true;
    }

    return false;
};
