import { get } from 'utils/http';
import { BASE_URL } from 'constants/env';
import { parse } from 'utils/error-parser';

/**
 * @param {UploadableImage} image
 * @returns {Promise<SignedUploadableImage>}
 */
export function signImageToUpload(image) {
    return get(
        `${BASE_URL}/cms/cms_media/signed_url?object_name=${encodeURI(
            image.name,
        )}&object_type=${image.type}`,
    )
        .then((result) => {
            return {
                image,
                resourceUrl: result.resource_url,
                uniqueObjectName: result.unique_object_name,
                uploadUrl: result.upload_url,
            };
        })
        .catch((error) => {
            const errorText = parse(error);
            return Promise.reject(errorText);
        });
}

const DEFAULT_HEADERS = {
    Accept: 'text/plain',
    'x-amz-acl': 'public-read',
    'Cache-Control': 'max-age=2628000',
};

/**
 * @param {SignedUploadableImage} image
 * @returns {Promise<SignedUploadableImage>}
 */
export function uploadImage(image) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', image.uploadUrl);

        const headers = {
            ...DEFAULT_HEADERS,
            'Content-Disposition':
                'attachment; filename="' +
                encodeURI(image.uniqueObjectName) +
                '"',
            'Content-Type': image.image.type,
        };
        Object.entries(headers).forEach(([name, value]) => {
            xhr.setRequestHeader(name, value);
        });

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                resolve(image);
            }
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status !== 200) {
                reject('Error uploading');
            }
        };
        xhr.addEventListener('error', reject);
        xhr.send(image.image.blob);
    });
}
