import { EditOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Tabs } from 'components/Tabs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
    hasGuideChanged,
    haveGoodToKnowItemsChanged,
    haveGuideNumberOfDaysHeaderChanged,
    haveHighlightsChanged,
    haveNumberOfDaysItemsChanged,
    haveStoriesChanged,
    isAddingGuide,
    isOrientationContentOnly,
} from 'store/guide-editor/selectors';
import { hasUnsavedChanges } from 'store/misc-selectors';
import GuideEditorBasicInfo from './GuideEditorBasicInfo';
import GuideEditorCollections from './GuideEditorCollections';
import GuideEditorMap from './GuideEditorMap';
import GuideEditorStory from './GuideEditorStory';
import GuideEditorTips from './GuideEditorTips';

import { TabDefinition } from 'components/Tabs/Tabs';
import { GuideEditorGoodToKnow } from './GuideEditorGoodToKnow';
import { GuideEditorHighlights } from './GuideEditorHighlights';
import { GuideEditorNumberOfDays } from './GuideEditorNumberOfDays';
import './style.css';

const { confirm } = Modal;

function GuideEditor() {
    const history = useHistory();
    const addingGuide = useSelector(isAddingGuide);
    const hasBasicInfoChanged = useSelector(hasGuideChanged);
    const hasStoryTabChanged = useSelector(haveStoriesChanged);
    const hasHighlightedTabChanged = useSelector(haveHighlightsChanged);
    const hasGoodToKnowTabChanged = useSelector(haveGoodToKnowItemsChanged);
    const hasNumberOfDaysTabChanged = useSelector(haveNumberOfDaysItemsChanged);
    const hasNumberOfDaysHeaderChanged = useSelector(
        haveGuideNumberOfDaysHeaderChanged,
    );
    const unsavedChanges = useSelector(hasUnsavedChanges);
    const showOnlyOrientationContent = useSelector(isOrientationContentOnly);

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const tabField = query.get('tab');

    const onClickDiscard = () => {
        if (!unsavedChanges) {
            history.push('/guides');
            return;
        }
        confirm({
            title: 'Discard changes',
            content:
                'Are you sure you want to discard the changes you made in the guide? All changes will be lost.',
            onOk() {
                history.push('/guides');
            },
            okText: 'Discard',
            okButtonProps: {
                danger: true,
            },
            cancelText: 'Cancel',
        });
    };

    const tabs: TabDefinition[] = [
        {
            name: <>Collections</>,
            key: 'collections',
            content: <GuideEditorCollections />,
            hasFooter: false,
            isHidden: addingGuide || showOnlyOrientationContent,
        },
        {
            name: (
                <span>
                    Basic info{' '}
                    {hasBasicInfoChanged && (
                        <EditOutlined className="tab-pane-icon" />
                    )}
                </span>
            ),
            key: 'basicInfo',
            content: <GuideEditorBasicInfo onClickDiscard={onClickDiscard} />,
            hasFooter: true,
        },
        {
            name: (
                <span>
                    Story{' '}
                    {hasStoryTabChanged && (
                        <EditOutlined className="tab-pane-icon" />
                    )}
                </span>
            ),
            key: 'story',
            content: <GuideEditorStory onClickDiscard={onClickDiscard} />,
            hasFooter: true,
            isHidden: addingGuide || showOnlyOrientationContent,
        },
        {
            name: (
                <span>
                    Highlights{' '}
                    {hasHighlightedTabChanged && (
                        <EditOutlined className="tab-pane-icon" />
                    )}
                </span>
            ),
            key: 'highlights',
            content: <GuideEditorHighlights onClickDiscard={onClickDiscard} />,
            hasFooter: true,
            isHidden: addingGuide,
        },
        {
            name: (
                <span>
                    Good to know{' '}
                    {hasGoodToKnowTabChanged && (
                        <EditOutlined className="tab-pane-icon" />
                    )}
                </span>
            ),
            key: 'goodToKnow',
            content: <GuideEditorGoodToKnow onClickDiscard={onClickDiscard} />,
            hasFooter: true,
            isHidden: addingGuide,
        },
        {
            name: (
                <span>
                    Number of days{' '}
                    {(hasNumberOfDaysTabChanged ||
                        hasNumberOfDaysHeaderChanged) && (
                        <EditOutlined className="tab-pane-icon" />
                    )}
                </span>
            ),
            key: 'numberOfDays',
            content: (
                <GuideEditorNumberOfDays onClickDiscard={onClickDiscard} />
            ),
            hasFooter: true,
            isHidden: addingGuide,
        },

        {
            name: <>Map</>,
            key: 'map',
            content: <GuideEditorMap />,
            hasFooter: false,
            isHidden: addingGuide,
        },
        {
            name: <>Tips</>,
            key: 'tips',
            content: <GuideEditorTips />,
            hasFooter: false,
            isHidden: addingGuide || showOnlyOrientationContent,
        },
    ];

    return (
        <Tabs
            defaultActiveKey={addingGuide ? 'basicInfo' : 'collections'}
            type="full-page"
            tabs={tabs}
            activeKey={tabField || undefined}
            onChange={(newActiveKey) => {
                history.push({
                    pathname: history.location.pathname,
                    search: `?tab=${newActiveKey}`,
                });
            }}
        ></Tabs>
    );
}

export default GuideEditor;
