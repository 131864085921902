import React from 'react';
import { Tag } from 'antd';

/**
 *
 * @param {Object} props
 * @param {PublishStatus} props.publishStatus
 */
export default function PublishStatusTag({ publishStatus }) {
    const color = getColorFromStatus(publishStatus);

    return <Tag color={color}>{publishStatus.name}</Tag>;
}

/**
 * @param {PublishStatus} status
 */
function getColorFromStatus(status) {
    switch ((status?.name || '').toLowerCase()) {
        case 'published':
            return 'green';
        case 'ready_to_publish':
            return 'blue';
        case 'draft':
            return 'orange';
        case 'pending_approval':
            return 'red';
        default:
            return undefined;
    }
}
