import React, { useEffect, useMemo, useState, Suspense } from 'react';
import { Card, Divider, Input, Tooltip, Typography } from 'antd';
import {
    CheckCircleOutlined,
    ExclamationCircleFilled,
    WarningFilled,
} from '@ant-design/icons';
import useSWR from 'swr';
import { searchSpot } from 'api/location-iq.api';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import Loading from 'components/Loading';
import FormField2 from 'components/FormField2';
import { useStateUpdatedByProp } from 'utils/use-state-updated-by-prop';
import AllSubcategoriesSelector from 'components/SpotCategoriesSelector/AllSubcategories';
import { parse } from 'utils/error-parser';
import './style.css';

const SpotEditorMap = React.lazy(() => import('./SpotEditorMap'));

const { Search, TextArea } = Input;
const { Text } = Typography;

/**
 *
 * @param {Object} props
 * @param {PolarstepsSpot} props.initialSpot
 * @param {PSLocation} [props.guideLocation]
 * @param {(newSpot: PolarstepsSpot) => void} props.onSpotChange
 */
function PolarstepsSpotEditor({ initialSpot, onSpotChange, guideLocation }) {
    /** @type {[PolarstepsSpot, any]} */
    const [spot, setSpot] = useStateUpdatedByProp(initialSpot);
    const [lastLocationSearch, setLastLocationSearch] = useState('');

    /** @type {[[number, number], [number, number]]} */
    const guideBounds = useMemo(() => {
        if (!guideLocation) {
            return null;
        }
        return [
            [guideLocation.lon - 1, guideLocation.lat - 1],
            [guideLocation.lon + 1, guideLocation.lat + 1],
        ];
    }, [guideLocation]);

    const {
        data: lastLocation,
        isValidating: isSearchingLocation,
        error: errorSearchingLocation,
    } = useSWR(
        lastLocationSearch
            ? `'search-spot-location-${lastLocationSearch}-${
                  guideBounds ? JSON.stringify(guideBounds) : ''
              }`
            : null,
        () => searchSpot(lastLocationSearch, guideBounds),
        { revalidateOnFocus: false },
    );

    useEffect(() => {
        if (!lastLocation) {
            return;
        }
        setSpot((/** @type {PolarstepsSpot} */ oldSpot) => {
            return {
                ...oldSpot,
                lat: lastLocation.lat,
                lon: lastLocation.lon,
                locality: lastLocation.locality,
                administrative_area: lastLocation.administrative_area,
                country: lastLocation.country,
                country_code: lastLocation.country_code,
                single_line: lastLocation.single_line,
            };
        });
    }, [lastLocation]);

    /**
     * @param {string} fieldName
     * @param {any} value
     */
    const patchField = (fieldName, value) => {
        setSpot((/** @type {PolarstepsSpot} */ oldSpot) => {
            return {
                ...oldSpot,
                [fieldName]: value,
            };
        });
    };

    /**
     * @param {[number, number]} newLngLat
     */
    const onUpdateLngLat = (newLngLat) => {
        if (!newLngLat) {
            return;
        }
        setSpot((/** @type {PolarstepsSpot} */ oldSpot) => {
            return {
                ...oldSpot,
                lat: newLngLat[1],
                lon: newLngLat[0],
                // Setting rest of location as null since we are not sure if these are accurate anymore
                locality: null,
                administrative_area: null,
                country: null,
                country_code: null,
            };
        });
    };

    useEffect(() => {
        onSpotChange(spot);
    }, [spot]);

    const getPhoneError = () => {
        if (!spot.phone_number) {
            return null;
        }
        if (isValidPhoneNumber(spot.phone_number)) {
            return null;
        }
        return true;
    };

    return (
        <Card title="Spot content" className="ps-spot-editor">
            <Divider orientation="left">Basic info</Divider>
            <FormField2
                label="Spot name"
                mandatory={true}
                length={spot.name.length}
                maxLength={50}
            >
                <Input
                    value={spot.name}
                    onChange={(e) => patchField('name', e.target.value)}
                    size="large"
                    maxLength={50}
                    placeholder="Name for the spot"
                />
            </FormField2>
            <FormField2 label="Category" mandatory={true}>
                <AllSubcategoriesSelector
                    value={spot.sub_category}
                    onChange={(newCategory) => {
                        patchField('sub_category', newCategory.id);
                        patchField('category_label', newCategory.name);
                    }}
                />
            </FormField2>
            <FormField2 label="Location" mandatory={true}>
                <Search
                    size="large"
                    onSearch={async (query) => setLastLocationSearch(query)}
                    loading={isSearchingLocation}
                    enterButton
                    placeholder="Search for a spot, place, or city"
                />
                {errorSearchingLocation && (
                    <Text type="danger">{parse(errorSearchingLocation)}</Text>
                )}
                <div className="ps-spot-editor__secondary-text mb-s">
                    You can also drag the map around for a more accurate
                    location
                </div>

                <div className="ps-spot-editor__map">
                    <Suspense fallback={<Loading />}>
                        <SpotEditorMap
                            spot={spot}
                            initialCenter={
                                guideLocation
                                    ? [guideLocation.lon, guideLocation.lat]
                                    : null
                            }
                            onUpdateLngLat={(newLngLat) =>
                                onUpdateLngLat(newLngLat)
                            }
                        />
                    </Suspense>
                </div>
            </FormField2>
            <Divider orientation="left">Details</Divider>
            <FormField2 label="Address">
                <TextArea
                    value={spot.single_line}
                    autoSize={{ minRows: 1, maxRows: 6 }}
                    onChange={(e) => patchField('single_line', e.target.value)}
                    size="large"
                    maxLength={2000}
                    placeholder=""
                />
            </FormField2>
            <FormField2 label="Website">
                <Input
                    value={spot.website}
                    onChange={(e) => patchField('website', e.target.value)}
                    size="large"
                    maxLength={255}
                    placeholder="https://"
                    suffix={getWebsiteIcon(spot.website)}
                />
            </FormField2>
            <FormField2 label="Phone number">
                <div className="ps-spot-editor__phone-container">
                    <PhoneInput
                        placeholder="+ 31 123 45 67 89 (international format)"
                        value={spot.phone_number}
                        onChange={(newPhone) =>
                            patchField('phone_number', newPhone)
                        }
                    />
                    {getPhoneError() && (
                        <div className="ps-spot-editor__phone-warning">
                            <Tooltip title="Phone number does not look correct">
                                <ExclamationCircleFilled
                                    style={{ color: '#D48806' }}
                                />
                            </Tooltip>
                        </div>
                    )}
                </div>
            </FormField2>
        </Card>
    );
}

export default PolarstepsSpotEditor;

/**
 * @param {string} website
 */
function getWebsiteIcon(website) {
    if (!website) {
        return null;
    }
    if (!isValidHttpUrl(website)) {
        return (
            <Tooltip title="URL is not valid">
                <WarningFilled style={{ color: 'var(--polar-red)' }} />
            </Tooltip>
        );
    }
    return <CheckCircleOutlined style={{ color: 'var(--polar-green-6)' }} />;
}

/**
 * @param {string} string
 */
function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
}
