import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Alert, Spin, notification } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { isEqual } from 'lodash';
import Loading from 'components/Loading';
import {
    usePolarstepsSpot,
    patchPolarstepsSpot,
} from 'api/polarsteps-spots.api';
import PolarstepsSpotEditor from 'components/PolarstepsSpotEditor';
import { isPolarstepsSpotValid } from 'utils/polarsteps-spot-utils';
import { parse } from 'utils/error-parser';

function EditPolarstepsSpot() {
    // @ts-ignore
    const { id } = useParams();
    const { spot, isLoading, error: errorLoading, mutate } = usePolarstepsSpot(
        id,
    );
    /** @type {[PolarstepsSpot, React.Dispatch<PolarstepsSpot>]} */
    const [editedSpot, setEditedSpot] = useState(null);
    const [isPatching, setIsPatching] = useState(false);

    const patchSpot = () => {
        setIsPatching(true);
        patchPolarstepsSpot(editedSpot)
            .then((result) => {
                notification.success({
                    message: 'Spot saved correctly!',
                });
                return mutate(result);
            })
            .catch((error) => {
                notification.error({
                    message: 'Error patching Polarsteps spot',
                    description: parse(error),
                });
            })
            .finally(() => {
                setIsPatching(false);
            });
    };

    return (
        <div className="full-page">
            <PageHeader
                title={
                    isLoading ? <Spin size="small" /> : 'Edit Polarsteps spot'
                }
                ghost={false}
                // onBack={() => history.push('/polarsteps-spots')}
            />
            {isLoading && !errorLoading && (
                <div className="full-page-content">
                    <div className="full-page-content-center">
                        <Loading tip="Loading..." />
                    </div>
                </div>
            )}

            {errorLoading && (
                <div className="full-page-content">
                    <div className="full-page-content-center full-page-error">
                        <Alert
                            message="Error loading spot"
                            description={errorLoading}
                            type="error"
                            showIcon
                        />
                        <div className="full-page-error-button">
                            <Button
                                type="primary"
                                onClick={() => mutate()}
                                loading={isLoading}
                            >
                                Try loading again
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {spot && (
                <>
                    <div className="full-page-content">
                        <div className="line-center mb-l">
                            <PolarstepsSpotEditor
                                initialSpot={spot}
                                onSpotChange={(newSpot) =>
                                    setEditedSpot(newSpot)
                                }
                            />
                        </div>
                    </div>
                    <div className="full-page-footer full-page-footer___floating">
                        <div className="grow-full-flex"></div>
                        {/* <Link to="/guides/collection-types">
                            <Button size="large">Cancel</Button>
                        </Link> */}
                        <Button
                            size="large"
                            type="primary"
                            disabled={
                                isPatching ||
                                !isPolarstepsSpotValid(editedSpot) ||
                                isEqual(spot, editedSpot)
                            }
                            onClick={patchSpot}
                            loading={isPatching}
                        >
                            Save changes
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}

export default EditPolarstepsSpot;
