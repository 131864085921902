import React, { useMemo } from 'react';
import { Input, Typography } from 'antd';
import FormField from 'components/FormField';
import {
    GUIDE_QUICK_FACT_BODY_MAX_LENGTH,
    GUIDE_QUICK_FACT_TITLE_MAX_LENGTH,
    QUICK_FACTS_PER_GUIDE,
} from 'constants/fields-limits';
import { areQuickFactsCorrect } from 'utils/guide-utils';
import './GuideQuickFacts.css';

const { Title } = Typography;

/**
 * @param {Object} props
 * @param {QuickFact[] | null} props.value
 * @param {(newValue: [QuickFact, QuickFact, QuickFact]) => void} props.onChange
 */
export default function GuideQuickFacts({ value, onChange }) {
    const normalizedValue = useMemo(() => getNormalizedQuickFact(value), [
        value,
    ]);

    /**
     * @param {QuickFact} newFact
     * @param {number} index
     */
    const onFactChanged = (newFact, index) => {
        const newFacts = normalizedValue.slice();
        newFacts[index] = { ...newFact };
        // @ts-ignore
        onChange(newFacts);
    };

    return (
        <div>
            <QuickFact
                title="Fact 1"
                value={normalizedValue[0]}
                onChange={(newValue) => onFactChanged(newValue, 0)}
            />
            <QuickFact
                title="Fact 2"
                value={normalizedValue[1]}
                onChange={(newValue) => onFactChanged(newValue, 1)}
            />
            <QuickFact
                title="Fact 3"
                value={normalizedValue[2]}
                onChange={(newValue) => onFactChanged(newValue, 2)}
            />
            {!areQuickFactsCorrect(normalizedValue) && (
                <div className="line-center mt-m">
                    <Typography.Text type="danger">
                        Quick Look items need to be either all complete or all
                        empty
                    </Typography.Text>
                </div>
            )}
        </div>
    );
}

/**
 *
 * @param {QuickFact[] | null} rawQuickFact
 * @returns {[QuickFact, QuickFact, QuickFact]}
 */
function getNormalizedQuickFact(rawQuickFact) {
    if (rawQuickFact?.length === QUICK_FACTS_PER_GUIDE) {
        // @ts-ignore
        return rawQuickFact;
    }
    /** @type {QuickFact} */
    const emptyQuickFact = { title: '', body: '' };
    return [
        (rawQuickFact || [])[0] || { ...emptyQuickFact },
        (rawQuickFact || [])[1] || { ...emptyQuickFact },
        (rawQuickFact || [])[2] || { ...emptyQuickFact },
    ];
}

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {QuickFact} props.value
 * @param {(newValue: QuickFact) => void} props.onChange
 */
function QuickFact({ title, value, onChange }) {
    return (
        <div className="quick-fact line">
            <Title level={5}>{title}</Title>
            <div className="grow-full-flex">
                <FormField
                    label="Title"
                    labelWidth={70}
                    length={(value.title || '').length}
                    maxLength={GUIDE_QUICK_FACT_TITLE_MAX_LENGTH}
                >
                    <Input
                        value={value.title || ''}
                        onChange={(e) =>
                            onChange({ ...value, title: e.target.value })
                        }
                        size="large"
                        placeholder=""
                    />
                </FormField>
                <FormField
                    label="Body"
                    labelWidth={70}
                    length={(value.body || '').length}
                    maxLength={GUIDE_QUICK_FACT_BODY_MAX_LENGTH}
                >
                    <Input
                        value={value.body || ''}
                        onChange={(e) =>
                            onChange({ ...value, body: e.target.value })
                        }
                        size="large"
                        placeholder=""
                    />
                </FormField>
            </div>
        </div>
    );
}
