import { post, postDelete, patch } from 'utils/http';
import { BASE_URL } from 'constants/env';

/**
 *
 * @param {CollectionSpotTip} collectionSpotTip
 * @returns {Promise<CollectionSpotTip>}
 */
export const addTip = (collectionSpotTip) => {
    return post(
        `${BASE_URL}/cms/collection_spot_tips`,
        toDBFormat(collectionSpotTip),
    );
};

/**
 *
 * @param {CollectionSpotTip} collectionSpotTip
 * @returns {Promise<CollectionSpotTip>}
 */
export const deleteTip = async (collectionSpotTip) => {
    postDelete(`${BASE_URL}/cms/collection_spot_tips/${collectionSpotTip.id}`, {
        returnPlainResponse: true,
    });
    return collectionSpotTip;
};

/**
 *
 * @param {CollectionSpotTip} collectionSpotTip
 * @returns {Promise<CollectionSpotTip>}
 */
export const patchTip = (collectionSpotTip) => {
    return patch(
        `${BASE_URL}/cms/collection_spot_tips/${collectionSpotTip.id}`,
        toDBFormat(collectionSpotTip),
    );
};

/**
 * Converts a tip to the format needed for the DB
 *
 * @param {CollectionSpotTip} tip
 * @returns {DBCollectionSpotTip}
 */
function toDBFormat(tip) {
    return {
        collection_spot_id: tip.collection_spot_id,
        step_spot_id: tip.step_spot_id,
        status_id: tip.status_id,
        translation: tip.translation || null,
        language: tip.language,
    };
}
