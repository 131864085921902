import React, { useState } from 'react';
import { notification } from 'antd';
import { add as addSpot } from 'api/spots.api';
import SpotSearch from 'components/SpotSearch';
import { parse } from 'utils/error-parser';
import { toGenericSpot } from 'utils/polarsteps-spot-utils';
import AddPolarstepsSpotModal from './AddPolarstepsSpotModal';

/**
 * This component will find spots (using SpotSearch component), and add them to our DB when selecting them.
 * It will allow adding Polartseps spots (aka custom spots) by showing a Modal.
 *
 * @param {Object} props
 * @param {PSSpot_v15_Minimal | null} props.value
 * @param {string} [props.placeholder='Search spot']
 * @param {string} [props.selectingPlaceholder='Selecting spot...']
 * @param {PSLocation} props.location
 * @param {Guide} props.guide
 * @param {(spot: PSSpot_v15_Minimal) => void} props.onSelect
 * @param {boolean} [props.disabled=false]
 * @param {React.ReactNode} [props.customSearchIcon]
 * @param {boolean} [props.allowAddPolarstepsSpot=false] If true, there will be an option to add a Polarsteps custom Spot
 */
function SpotSelector({
    value,
    placeholder = 'Search spot',
    selectingPlaceholder = 'Selecting spot...',
    location,
    guide,
    onSelect,
    disabled = false,
    customSearchIcon = null,
    allowAddPolarstepsSpot = false,
}) {
    const [isAddingSpot, setIsAddingSpot] = useState(false);
    const [errorAdding, setErrorAdding] = useState(null);
    const [
        isShowingAddPolarstepsSpotModal,
        setIsShowingAddPolarstepsSpotModal,
    ] = useState(false);
    const [initialPolarstepsSpotName, setInitialPolarstepsSpotName] = useState(
        '',
    );

    /**
     * @param {string} spotName
     */
    const showAddPolarstepsSpotModal = (spotName) => {
        setInitialPolarstepsSpotName(spotName);
        setIsShowingAddPolarstepsSpotModal(true);
    };

    /**
     * @param {PSSpot_v15_Minimal} tempSpot
     * @returns {Promise<PSSpot_v15>}
     */
    const addSpotAndNotify = async (tempSpot) => {
        if (!tempSpot) {
            onSelect(null);
            return;
        }
        setIsAddingSpot(true);
        setErrorAdding(null);
        return addSpot(tempSpot)
            .then((addedSpot) => {
                onSelect(addedSpot);
                return addedSpot;
            })
            .catch((error) => {
                const errorText = parse(error);
                setErrorAdding(errorText);
                notification.error({
                    message: 'Error adding spot',
                    description: errorText,
                });
                return null;
            })
            .finally(() => setIsAddingSpot(false));
    };

    return (
        <>
            <SpotSearch
                className="spot-selector"
                value={value}
                placeholder={placeholder}
                selectingPlaceholder={selectingPlaceholder}
                location={location}
                guide={guide}
                onSelect={addSpotAndNotify}
                disabled={disabled}
                isAddingSpot={isAddingSpot}
                errorAdding={errorAdding}
                customSearchIcon={customSearchIcon}
                allowAddPolarstepsSpot={allowAddPolarstepsSpot}
                onAddPolarstepsSpot={(newSpotName) => {
                    showAddPolarstepsSpotModal(newSpotName);
                }}
            />
            {allowAddPolarstepsSpot && (
                <AddPolarstepsSpotModal
                    location={location}
                    isShown={isShowingAddPolarstepsSpotModal}
                    initialName={initialPolarstepsSpotName}
                    onClose={() => setIsShowingAddPolarstepsSpotModal(false)}
                    onAddPolarstepsSpot={async (newPSSpot) => {
                        await addSpotAndNotify(toGenericSpot(newPSSpot));
                        setIsShowingAddPolarstepsSpotModal(false);
                    }}
                />
            )}
        </>
    );
}

export default SpotSelector;
