import React from 'react';
import { Typography, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Text } = Typography;

/**
 *
 * @param {Object} props
 * @param {() => void} props.onShowCustomTip
 */
function EmptyTips({ onShowCustomTip }) {
    return (
        <div className="collection-spot-editor__tips-empty">
            <div className="mb-m">
                <Text strong>No tips added</Text>
            </div>

            <Text type="secondary">
                Add a available user tip from the right column or create a
                custom tip
            </Text>
            <br />
            <Button
                className="mt-m"
                type="link"
                icon={<PlusOutlined />}
                onClick={onShowCustomTip}
            >
                Create custom tip
            </Button>
        </div>
    );
}

export default EmptyTips;
