import { BASE_URL } from 'constants/env';
import { capitalize } from 'lodash';
import { get } from 'utils/http';
import useSWR, { trigger } from 'swr';

const ENDPOINT = `${BASE_URL}/cms/collection_spot_label_types`;

export function labelName(name) {
    if (!name) return;

    switch (name) {
        case 'eco_friendly':
            return 'Eco-conscious';
        default:
            return capitalize(name.split('_').join(' '));
    }
}

/** @returns {{ labelTypes: CollectionSpotLabelType[], error?: Error, isLoading: boolean }} */
export function useLabelTypes() {
    const { data, error } = useSWR(ENDPOINT, get, {
        revalidateOnFocus: false,
    });
    const isLoading = !data && !error;
    const labelTypes = /** @type {{objects: any[]}} */ (data)?.objects || [];

    return { labelTypes, error, isLoading };
}

export function refetchLabels() {
    trigger(ENDPOINT);
}
