import { isSameObject } from 'utils/compare';
import { orderBy, every } from 'lodash';
import {
    COLLECTION_SPOT_NAME_MAX_LENGTH,
    CUSTOM_TIP_MAX_LENGTH,
} from 'constants/fields-limits';
import { canTipBeSaved } from './collection-spot-tip-utils';

/**
 *
 * @typedef TipUser
 * @property {string} firstName
 * @property {string} avatarUrl
 */

/**
 * Gets the tip to show for a collection spot
 *
 * @param {CollectionSpot} collectionSpot
 * @returns {{tip: string, user: TipUser}}
 */
export const getTip = (collectionSpot) => {
    if (collectionSpot.custom_tip) {
        return {
            tip: collectionSpot.custom_tip,
            user: {
                firstName: 'Editor',
                avatarUrl: collectionSpot.main_editor?.google_profile_image,
            },
        };
    }
    const availableTips = (collectionSpot.tips || []).filter(
        (tip) => !!tip.step_spot?.user,
    );
    const firstEnglishTip = getFirstEnglishTip(availableTips);
    if (firstEnglishTip) {
        return {
            tip: getTipText(firstEnglishTip),
            user: {
                firstName: firstEnglishTip.step_spot.user?.first_name,
                avatarUrl:
                    firstEnglishTip.step_spot.user?.profile_image_thumb_path,
            },
        };
    }
    const firstTip = availableTips[0];
    if (firstTip) {
        return {
            tip: getTipText(firstTip),
            user: {
                firstName: firstTip.step_spot.user?.first_name,
                avatarUrl: firstTip.step_spot.user?.profile_image_thumb_path,
            },
        };
    }

    return null;
};

/**
 * Gets the text to show for a tip
 *
 * @param {CollectionSpotTip} collectionSpotTip
 * @returns {string}
 */
function getTipText(collectionSpotTip) {
    if (!collectionSpotTip) {
        return null;
    }
    return collectionSpotTip.translation || collectionSpotTip.step_spot?.tip;
}

/**
 * Finds the first english tip in a list. A comment will be considered English if:
 * it has a translation or
 * it has language set to en
 *
 * @param {CollectionSpotTip[]} tips
 * @returns {CollectionSpotTip}
 */
function getFirstEnglishTip(tips) {
    for (const tip of tips) {
        if ((tip.language || '').toLowerCase() === 'en' || !!tip.translation) {
            return tip;
        }
    }
    return null;
}

/**
 * @typedef {object} CollectionSpotTipChanges
 * @property {CollectionSpotTip[]} newTips Tips that have been added
 * @property {CollectionSpotTip[]} deletedTips Tips that have been deleted
 * @property {CollectionSpotTip[]} editedTips Tips that have been changed
 */

/**
 * Gets the changed collection spot tips between the current and new collection spot
 *
 * @param {CollectionSpot} current
 * @param {CollectionSpot} original
 * @returns {CollectionSpotTipChanges}
 */
export const getChangedTips = (current, original) => {
    const newTips = current.tips.filter(
        (tip) => !isTipContained(tip, original.tips),
    );
    const deletedTips = original.tips.filter(
        (tip) => !isTipContained(tip, current.tips),
    );
    const editedTips = current.tips.filter((tip) =>
        hasTipChanged(tip, original.tips),
    );

    return { newTips, deletedTips, editedTips };
};

/**
 * Checks if a tips is contained in an array of tips (just checking its id)
 *
 * @param {CollectionSpotTip} tip
 * @param {CollectionSpotTip[]} tips
 * @returns {boolean}
 */
function isTipContained(tip, tips) {
    return !!findOriginal(tip, tips);
}

/**
 * Checks if a tip has changed compared to the original (also checks if there was an original one)
 *
 * @param {CollectionSpotTip} tip
 * @param {CollectionSpotTip[]} originals list of original tip items, if the tip is not tehre it means that it is new, not changed
 * @returns {boolean}
 */
function hasTipChanged(tip, originals) {
    const originalTip = originals.find((eachTip) => tip.id === eachTip.id);
    if (!originalTip) {
        return false;
    }
    return (
        tip.language !== originalTip.language ||
        tip.translation !== originalTip.translation ||
        !isSameObject(tip.status, originalTip.status)
    );
}

/**
 * Gets the original tip related (same id) to a tip from a list
 *
 * @param {CollectionSpotTip} tip
 * @param {CollectionSpotTip[]} originals
 * @returns {CollectionSpotTip}
 */
function findOriginal(tip, originals) {
    return originals.find((eachTip) => tip.id === eachTip.id);
}

/**
 * @param {CollectionSpot[]} collectionSpots
 * @returns {CollectionSpot[]}
 */
export const orderCollectionSpots = (collectionSpots) => {
    return orderBy(collectionSpots, ['order', 'id'], ['asc', 'asc']);
};

/**
 * @param {CollectionSpot[]} topCollectionSpots
 * @returns {CollectionSpot[]}
 */
export const orderTopCollectionSpots = (topCollectionSpots) => {
    return orderBy(
        topCollectionSpots,
        ['top_spot_order', 'id'],
        ['asc', 'asc'],
    );
};

/**
 * Checks if a collection spot can be saved, meaning all of its fields are filled, also for the tips it contains
 *
 * @param {CollectionSpot} collectionSpot
 * @returns {boolean}
 */
export const canCollectionSpotBeSaved = (collectionSpot) => {
    return !getReasonForCollectionSpotDisallowedSave(collectionSpot);
};

/**
 * Checks if a collection spot can be saved, meaning all of its fields are filled, also for the tips it contains
 *
 * @param {CollectionSpot} collectionSpot
 */
export function getReasonForCollectionSpotDisallowedSave(collectionSpot) {
    if (!collectionSpot) {
        return 'No collection spot';
    }
    if ((collectionSpot.name || '').length > COLLECTION_SPOT_NAME_MAX_LENGTH) {
        return 'Name too long';
    }
    if ((collectionSpot.custom_tip || '').length > CUSTOM_TIP_MAX_LENGTH) {
        return 'Custom tip too long';
    }

    const customMediaMissingAttribution = (
        collectionSpot.collection_spot_media || []
    ).find((mediaRelation) => !mediaRelation.cms_media.author_name);

    if (customMediaMissingAttribution) {
        return 'Custom media missing attribution';
    }
    const allTipsCanBeSaved = every(
        (collectionSpot.tips || []).map(canTipBeSaved),
    );
    if (!allTipsCanBeSaved) {
        return 'Some tips have problems';
    }
    return null;
}

/**
 * Checks if a collection spot has source of any of the ones in the list
 *
 * @param {CollectionSpot} collectionSpot
 * @param {Source[]} sources
 */
export const isSpotFromSources = (collectionSpot, sources) => {
    if (!sources?.length) {
        return true;
    }
    return !!sources.find(
        (source) => source.name === collectionSpot.spot.source,
    );
};
