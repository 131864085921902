import React, { useState } from 'react';
import { Input, Card } from 'antd';
import EmptyTips from './Empty';
import AllTips from './AllTips';
import SelectedTips from './SelectedTips';
import { getCollectionSpotTipFromStepSpot } from 'utils/collection-spot-tip-utils';

const { TextArea } = Input;

/**
 * @param {Object} props
 * @param {CollectionSpot} props.collectionSpot
 * @param {(...args: any[]) => void} props.onPatchField
 */
export default function CollectionSpotEditorTips({
    collectionSpot,
    onPatchField,
}) {
    const [showCustomTip, setShowCustomTip] = useState(
        !!collectionSpot?.custom_tip,
    );

    /**
     * @param {StepSpot} stepSpot
     */
    const onSelectStepSpot = async (stepSpot) => {
        const newCollectionSpotTip = await getCollectionSpotTipFromStepSpot(
            stepSpot,
            collectionSpot,
        );
        const newTips = [...collectionSpot.tips, newCollectionSpotTip];
        onPatchField({
            tips: newTips,
        });
    };

    const showEmpty =
        !collectionSpot.tips?.length &&
        !collectionSpot.custom_tip &&
        !showCustomTip;

    return (
        <div className="collection-spot-editor__tab-content collection-spot-editor__tips">
            {showEmpty && (
                <div className="collection-spot-editor__tips-left-empty">
                    <EmptyTips onShowCustomTip={() => setShowCustomTip(true)} />
                </div>
            )}
            {!showEmpty && (
                <div className="collection-spot-editor__tips-left">
                    <SelectedTips
                        showCustomTip={showCustomTip}
                        setShowCustomTip={(newValue) =>
                            setShowCustomTip(newValue)
                        }
                        collectionSpot={collectionSpot}
                        onPatchField={onPatchField}
                    />
                    <Card title="Editor's notes">
                        <TextArea
                            className="form-field__textarea"
                            autoSize={{ minRows: 1, maxRows: 20 }}
                            value={collectionSpot.editor_notes?.tips || ''}
                            onChange={(e) => {
                                const prevNotes =
                                    collectionSpot.editor_notes || {};
                                onPatchField({
                                    editor_notes: {
                                        ...prevNotes,
                                        tips: e.target.value,
                                    },
                                });
                            }}
                            size="large"
                            placeholder="Share your thoughts"
                        />
                    </Card>
                </div>
            )}

            <div className="collection-spot-editor__tips-right">
                <AllTips
                    collectionSpot={collectionSpot}
                    onSelectStepSpot={onSelectStepSpot}
                    selectedTips={collectionSpot.tips}
                />
            </div>
        </div>
    );
}
