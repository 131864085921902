import { useState, useEffect } from 'react';

/**
 * @typedef UseInfiniteScrollGetResult
 * @property {boolean} isLoading True while the get is being done
 * @property {(page: number) => void} getNewPage Callback to get new pages for the current list
 * @property {boolean} hasMoreResults When false, we have reached the end of the "infinite" list
 * @property {any[]} results List of all the results so far in the current list
 * @property {string?} errorLoadingMessage Error message from last get, if any
 */
/**
 * Hook that automatically gets infinite scroll data, using a provided getter. When the apiGetter function changes, all
 * the state will be reset
 *
 * @param {(page: number) => Promise<{ hasMore: boolean, currentPage: number, results: any[]}>} apiGetter
 * @returns {UseInfiniteScrollGetResult}
 */
export const useInfiniteScrollGet = (apiGetter) => {
    const [hasMoreResults, setHasMoreResults] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    /** @type {[string, import('react').Dispatch<string>]} */
    const [errorLoadingMessage, setErrorLoadingMessage] = useState(null);
    const [results, setResults] = useState([]);
    const [isMounted, setIsMounted] = useState(true);

    useEffect(() => {
        setErrorLoadingMessage(null);
        setIsLoading(false);
        setHasMoreResults(true);
        setResults([]);
    }, [apiGetter]);

    useEffect(() => {
        return () => setIsMounted(false);
    }, []);

    const getNewPage = (newPage) => {
        if (hasMoreResults) {
            setErrorLoadingMessage(null);
            setIsLoading(true);
            apiGetter(newPage)
                .then((result) => {
                    if (result.currentPage === newPage && isMounted) {
                        setResults((results) => [
                            ...results,
                            ...result.results,
                        ]);
                        setHasMoreResults(result.hasMore);
                    }
                })
                .catch((e) => {
                    if (isMounted) {
                        setErrorLoadingMessage(e.toString());
                    }
                })
                .finally(() => {
                    if (isMounted) {
                        setIsLoading(false);
                    }
                });
        }
    };

    return {
        isLoading,
        getNewPage,
        hasMoreResults,
        results,
        errorLoadingMessage,
    };
};
