import React from 'react';
import { Typography, Alert, Button, Table, Pagination, Card } from 'antd';
import Loading from 'components/Loading';
import { getDataSourceFromArray } from 'utils/data-source-utils';
import { usePaginatedGet } from 'utils/use-paginated-get';
import './style.css';

const { Text } = Typography;

/** @typedef {Object} GenericOverviewTableColumn
 * @property {string} title
 * @property {string} dataIndex
 * @property {string} key
 * @property {any} [defaultSortOrder]
 * @property {((any) => any)| boolean} [sorter]
 * @property {import('antd/lib/table/interface').SortOrder} [sortOrder]
 * @property {(any) => any} [render]
 */

/**
 * @param {Object} props
 * @param {(any) => any} props.apiGetter Function to call to call the api, accepts a page and returns a Promise that
 * will resolve with an object like: { page: 1, results: [...], total_pages }
 * @param {GenericOverviewTableColumn[]} props.columns
 * @param {string} [props.emptyTableMessage]
 * @param {(column: string, order: string)=>any} [props.sortCallback]
 */
function GenericOverviewTable({
    apiGetter,
    columns,
    emptyTableMessage = 'No values yet.',
    sortCallback,
}) {
    const {
        isLoading,
        errorLoadingMessage,
        currentPageValues,
        currentPage,
        setCurrentPage,
        lastPaginationInfo,
        refresh,
    } = usePaginatedGet(apiGetter);

    const handleTableChange = (pagination, filters, sorter) => {
        sortCallback(sorter.columnKey, sorter.order);
        columns.map((c) => {
            if (c.key === sorter.columnKey) {
                c.sortOrder = sorter.order;
            } else {
                c.sortOrder = null;
            }
        });
    };

    return (
        <>
            {isLoading && (
                <div className="full-page-content-center">
                    <Loading tip="Loading..." />
                </div>
            )}
            {errorLoadingMessage && (
                <div className="full-page-content-center full-page-error">
                    <Alert
                        message={errorLoadingMessage}
                        description={errorLoadingMessage}
                        type="error"
                        showIcon
                    />
                    <div className="full-page-error-button">
                        <Button type="primary" onClick={() => refresh()}>
                            Try loading again
                        </Button>
                    </div>
                </div>
            )}

            {currentPageValues?.length === 0 && (
                <div className="full-page-content-center">
                    <Text type="secondary">{emptyTableMessage}</Text>
                </div>
            )}

            {currentPageValues &&
                currentPageValues?.length > 0 &&
                lastPaginationInfo && (
                    <Card
                        bodyStyle={{ padding: '24px 0' }}
                        bordered={false}
                        className="mb-m"
                    >
                        {getPagination(
                            {
                                ...lastPaginationInfo,
                                page: currentPage,
                            },
                            setCurrentPage,
                        )}
                        <Table
                            showSorterTooltip
                            className="mt-m mb-m"
                            dataSource={getDataSourceFromArray(
                                currentPageValues,
                            )}
                            columns={columns}
                            pagination={false}
                            onChange={handleTableChange}
                        />
                        {getPagination(
                            {
                                ...lastPaginationInfo,
                                page: currentPage,
                            },
                            setCurrentPage,
                        )}
                    </Card>
                )}
        </>
    );
}

/**
 * @param {PaginationInfo} paginationInfo
 * @param {(newPage: number) => void} setCurrentPage
 */
function getPagination(paginationInfo, setCurrentPage) {
    if (!paginationInfo?.totalPages || paginationInfo.totalPages < 2) {
        return null;
    }

    return (
        <div className="line-center mr-m">
            <div className="grow-full-flex" />
            <Pagination
                current={paginationInfo.page}
                onChange={setCurrentPage}
                total={paginationInfo.totalResults}
                pageSize={paginationInfo.resultsPerPage}
                showSizeChanger={false}
            />
        </div>
    );
}

export default GenericOverviewTable;
