import { AnyAction } from 'redux';
import * as actionTypes from './action-types';
import { getItemsMappedById } from 'utils/sortable-item-utils';

const NUMBER_OF_DAYS_ITEMS_INITIAL_STATE: NumberOfDaysState = {
    byGuideId: {},
    isSavingNumberOfDaysItems: false,
    isSavingNumberOfDaysSpots: false,
};

export const numberOfDaysItems = (
    state = NUMBER_OF_DAYS_ITEMS_INITIAL_STATE,
    action: AnyAction,
): NumberOfDaysState => {
    switch (action.type) {
        case actionTypes.STARTED_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE:
            return {
                ...state,
                byGuideId: {
                    ...state.byGuideId,
                    [action.guideId]: {
                        loading: true,
                        error: null,
                        byId: null,
                    },
                },
            };
        case actionTypes.FAILED_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE:
            return {
                ...state,
                byGuideId: {
                    ...state.byGuideId,
                    [action.guideId]: {
                        loading: false,
                        error: action.error,
                        byId: null,
                    },
                },
            };

        case actionTypes.SUCCESS_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE:
            return {
                ...state,
                byGuideId: {
                    ...state.byGuideId,
                    [action.guideId]: {
                        loading: false,
                        error: null,
                        byId: getItemsMappedById(action.payload),
                    },
                },
            };

        case actionTypes.STARTED_SAVING_NUMBER_OF_DAYS_ITEMS:
            return {
                ...state,
                isSavingNumberOfDaysItems: true,
            };
        case actionTypes.SUCCESS_SAVING_NUMBER_OF_DAYS_ITEMS:
        case actionTypes.FAILED_SAVING_NUMBER_OF_DAYS_ITEMS:
            return {
                ...state,
                isSavingNumberOfDaysItems: false,
            };

        case actionTypes.STARTED_SAVING_NUMBER_OF_DAYS_SPOTS:
            return {
                ...state,
                isSavingNumberOfDaysSpots: true,
            };
        case actionTypes.SUCCESS_SAVING_NUMBER_OF_DAYS_SPOTS:
        case actionTypes.FAILED_SAVING_NUMBER_OF_DAYS_SPOTS:
            return {
                ...state,
                isSavingNumberOfDaysSpots: false,
            };

        default:
            return state;
    }
};
