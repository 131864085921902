// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../typings/svg.d.ts" />

import React from 'react';
import ecoFriendlyIcon from './icons/label_eco_friendly.svg';
import hiddenGemIcon from './icons/label_hidden_gem.svg';
import inTheAreaIcon from './icons/label_in_the_area.svg';
import locallyOwnedIcon from './icons/label_locally_owned.svg';
import famousIcon from './icons/label_famous.svg';
import { labelName } from 'utils/collection-spot-label-utils';

/**
 *
 * @param {Object} props
 * @param {string} props.name
 */
export function SpotLabel({ name }) {
    const icon = labelIcon(name);
    const title = labelName(name);

    return (
        <div className="spot-label bg-white" title={title}>
            <div className="spot-label-content">
                {icon ? (
                    <div className="spot-label-icon">
                        <img src={icon} alt={`${name} icon`} />
                    </div>
                ) : null}
                <span>{title}</span>
            </div>
        </div>
    );
}

export function labelIcon(labelName) {
    switch (labelName) {
        case 'eco_friendly':
            return ecoFriendlyIcon;
        case 'hidden_gem':
            return hiddenGemIcon;
        case 'in_the_area':
            return inTheAreaIcon;
        case 'locally_owned':
            return locallyOwnedIcon;
        case 'famous':
            return famousIcon;
        default:
            return null;
    }
}
