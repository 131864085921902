import React, { useState } from 'react';
import { Card, Tag, Button, Spin, Alert, notification } from 'antd';
import { parse } from 'utils/error-parser';
import { LikeFilled, LikeOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';
import SpotMediaItem from 'components/MediaSelector/MediaSelectorModal/Spot/SpotMediaItem';
import { useInfiniteSpotMedia } from 'utils/use-infinite-spot-media';
import { useSpotDetails } from 'api/spots.api';
import {
    useSpotCustomGallery,
    updateGallery,
} from 'api/spot-custom-gallery.api';

/**
 *
 * @param {Object} props
 * @param {React.MutableRefObject<HTMLElement>} props.parentRef Ref to the parent element for infinite scrolling purposes
 * @param {CollectionSpot} props.collectionSpot
 * @param {Guide} props.guide
 */
export default function SourcePhotos({ parentRef, collectionSpot, guide }) {
    const { fullSpot, isLoading: isLoadingSpotDetails } = useSpotDetails(
        collectionSpot.spot,
    );
    const {
        allMedia,
        isLoading,
        getNewPage,
        hasMoreResults,
    } = useInfiniteSpotMedia(fullSpot);

    const {
        spotGalleryHash,
        spotGalleryList,
        isValidating,
        isLoading: isLoadingCustomGallery,
        error: errorLoadingCustomGallery,
        mutate,
    } = useSpotCustomGallery(collectionSpot.spot);

    const [isToggling, setIsToggling] = useState(false);

    /**
     *
     * @param {PSSpot_v15_Photo} photo
     */
    const togglePhotoInGallery = async (photo) => {
        let photos;
        if (spotGalleryHash[photo.external_id]) {
            photos = spotGalleryList.filter(
                (otherPhoto) => otherPhoto.external_id !== photo.external_id,
            );
        } else {
            photos = [...spotGalleryList, photo];
        }
        setIsToggling(true);
        try {
            await updateGallery(collectionSpot.spot, photos);
        } catch (e) {
            notification.error({
                message: 'Error saving changes.',
                description: parse(e),
            });
        } finally {
            setIsToggling(false);
        }
    };

    if (isLoadingSpotDetails) {
        return (
            <div className="collection-spot-editor__photos--loading">
                <Spin tip="Loading spot photos..." />
            </div>
        );
    }

    if (errorLoadingCustomGallery) {
        return (
            <div className="collection-spot-editor__photos--loading">
                <Alert
                    message="Error loading custom gallery"
                    description={errorLoadingCustomGallery.toString()}
                    showIcon
                    type="error"
                    action={
                        <Button size="small" onClick={() => mutate()}>
                            Try again
                        </Button>
                    }
                />
            </div>
        );
    }

    return (
        <Card
            title={
                <div className="collection-spot-editor__photos-title">
                    <div className="mr-s">Spot photos from source</div>
                    {!isLoadingCustomGallery && (
                        <Tag>{spotGalleryList?.length || 0} in Allowlist</Tag>
                    )}
                </div>
            }
            bodyStyle={{ padding: 0 }}
        >
            <div className="collection-spot-editor__photos-body">
                <p className="collection-spot-editor__photos-text">
                    If you would like to customize what will be shown in the
                    gallery for this spot, you can allow specific photos from
                    the media manager below by clicking the “Like” button. Only
                    the photos you have allowed will be shown in the app.
                </p>

                {allMedia.length === 0 && !isLoading && (
                    <div className="collection-spot-editor__photos--no-photos">
                        No photos for this spot
                    </div>
                )}

                <InfiniteScroll
                    className="media-selector-image-list"
                    pageStart={0}
                    loadMore={getNewPage}
                    threshold={400}
                    hasMore={!isLoading && hasMoreResults}
                    getScrollParent={() => parentRef.current}
                    useWindow={false}
                >
                    {allMedia.map((photo) => (
                        <SpotMediaItem
                            key={photo.external_id}
                            media={photo}
                            spot={fullSpot}
                            guideId={guide.id}
                            canBeFavorite={false}
                            popoverMouseEnterDelay={4000}
                            extraContent={
                                <Button
                                    disabled={isValidating || isToggling}
                                    icon={
                                        spotGalleryHash[photo.external_id] ? (
                                            <LikeFilled
                                                style={{
                                                    color: '#1890FF',
                                                }}
                                            />
                                        ) : (
                                            <LikeOutlined />
                                        )
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        togglePhotoInGallery(photo);
                                    }}
                                />
                            }
                        />
                    ))}
                </InfiniteScroll>
                {isLoading && (
                    <div className="media-selector__loading">
                        <Spin />
                    </div>
                )}
            </div>
        </Card>
    );
}
