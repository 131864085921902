import { v4 as uuid } from 'uuid';
import { getAllTipStatus } from 'api/tip-status.api';
import { TRANSLATION_TIP_MAX_LENGTH } from 'constants/fields-limits';

/**
 * Creates a collection spot trip from a step spot, using default initial values and a random temp id
 *
 * @param {StepSpot} stepSpot
 * @param {CollectionSpot} collectionSpot
 * @returns {Promise<CollectionSpotTip>}
 */
export const getCollectionSpotTipFromStepSpot = async (
    stepSpot,
    collectionSpot,
) => {
    const allTipStatus = await getAllTipStatus();
    const approved = allTipStatus.find((status) => status.name === 'approved');
    return {
        id: uuid(),
        collection_spot_id: collectionSpot.id,
        step_spot_id: stepSpot.id,
        step_spot: { ...stepSpot },
        status_id: approved.id,
        status: { ...approved },
        translation: '',
        language: stepSpot.language || 'en', // When a step spot does not have an auto detected language, we set english as default
        // to avoid having spot collection trips without language
    };
};

/**
 *
 * @param {CollectionSpotTip} tip
 * @returns {boolean}
 */
export const canTipBeSaved = (tip) => {
    return (tip.translation || '').length <= TRANSLATION_TIP_MAX_LENGTH;
};
