import { Input, Typography } from 'antd';
import classNames from 'classnames';
import FormField from 'components/FormField';
import InGuideSpotSelector from 'components/InGuideSpotSelector';
import MediaSelector from 'components/MediaSelector';
import Title from 'components/Typography/Title';
import { STORY_COVER_RATIO } from 'constants/media-sizes.constants';
import React from 'react';
import './style.css';

const { Text } = Typography;
const { TextArea } = Input;

type StoryItemEditor = {
    story: StoryItem;
    guide: Guide;
    onFieldChanged: (storyId: Id, fieldName: string, newValue: unknown) => void;
    index: number;
};

function StoryItemEditor({
    story,
    guide,
    onFieldChanged,
    index,
}: StoryItemEditor) {
    return (
        <div
            className={classNames(
                'js-story-item-editor',
                'story-item-editor',
                'line-center',
                {
                    'is-first-story': index === 0,
                },
            )}
        >
            <div className="story-item-editor__media">
                <MediaSelector
                    location={guide.location}
                    guideId={story.guide_id}
                    spot={story.spot}
                    includeVideos={true}
                    onSelect={(media) =>
                        onFieldChanged(story.id, 'cms_media', media)
                    }
                    onDelete={() => onFieldChanged(story.id, 'cms_media', null)}
                    media={story.cms_media}
                    ratio={STORY_COVER_RATIO}
                ></MediaSelector>
            </div>
            <div className="grow-full-flex story-item-editor__form">
                <Title type="subsection">Story item {index + 1}</Title>
                {index === 0 && !story.tagline && !story.spot && (
                    <>
                        <div className="mt-s"></div>
                        <Text type="secondary">
                            The first story item doesn’t contain tagline or a
                            spot
                        </Text>
                    </>
                )}
                {index === 0 && (story.tagline || story.spot) && (
                    <Text className="ml-xs" type="danger">
                        The first story item should not contain tagline or a
                        spot, please remove it. This tagline or spot WILL NOT be
                        shown in the guide.
                    </Text>
                )}
                {(index !== 0 || story.tagline || story.spot) && (
                    <div className="story-item-editor__form-inner">
                        <FormField
                            label="Tagline"
                            length={story.tagline.length}
                            maxLength={100}
                            labelWidth={50}
                        >
                            <TextArea
                                className="form-field__textarea"
                                autoSize={{ minRows: 1, maxRows: 4 }}
                                value={story.tagline}
                                onChange={(e) =>
                                    onFieldChanged(
                                        story.id,
                                        'tagline',
                                        e.target.value,
                                    )
                                }
                                placeholder="Add tagline"
                            />
                        </FormField>

                        <FormField label="Spot" labelWidth={50}>
                            <InGuideSpotSelector
                                value={story.spot}
                                guide={guide}
                                onSelect={(spot) =>
                                    onFieldChanged(story.id, 'spot', spot)
                                }
                            />
                        </FormField>
                        <FormField label="Notes" labelWidth={50}>
                            <TextArea
                                className="form-field__textarea"
                                autoSize={{ minRows: 1, maxRows: 12 }}
                                value={story.editor_notes}
                                onChange={(e) =>
                                    onFieldChanged(
                                        story.id,
                                        'editor_notes',
                                        e.target.value,
                                    )
                                }
                                placeholder="Add your thoughts..."
                            />
                        </FormField>
                    </div>
                )}
            </div>
        </div>
    );
}

export default StoryItemEditor;
