import React from 'react';
import MediaItemContent from '../MediaItemContent';
import MediaItemTooltipWrapper from '../../MediaItemTooltipWrapper';
import UnsplashMediaItemPopover from './UnsplashMediaItemPopover';

/**
 *
 * @param {Object} props
 * @param {UnsplashPhotoFromSearch} props.media
 * @param {Id} props.guideId
 * @param {(media: UnsplashPhotoFromSearch) => void} props.onSelect
 * @param {boolean} props.isSelected
 * @param {boolean} [props.isLoading=false]
 * @param {boolean} [props.canBeFavorite=true]
 */
function UnsplashMediaItem({
    media,
    guideId,
    onSelect,
    isSelected,
    isLoading = false,
    canBeFavorite = true,
}) {
    return (
        <MediaItemTooltipWrapper
            content={<UnsplashMediaItemPopover media={media} />}
            overlayClassName="unsplash-media-item-popover__antd-popover"
        >
            <MediaItemContent
                media={media}
                guideId={guideId}
                onSelect={() => onSelect(media)}
                isSelected={isSelected}
                mediaType="Unsplash"
                isLoading={isLoading}
                canBeFavorite={canBeFavorite}
                imgSrc={media.urls.small}
            />
        </MediaItemTooltipWrapper>
    );
}

export default UnsplashMediaItem;
