export const STARTED_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE =
    'STARTED_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE';
export const FAILED_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE =
    'FAILED_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE';
export const SUCCESS_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE =
    'SUCCESS_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE';
export const STARTED_SAVING_GOOD_TO_KNOW_ITEMS =
    'STARTED_SAVING_GOOD_TO_KNOW_ITEMS';
export const FAILED_SAVING_GOOD_TO_KNOW_ITEMS =
    'FAILED_SAVING_GOOD_TO_KNOW_ITEMS';
export const SUCCESS_SAVING_GOOD_TO_KNOW_ITEMS =
    'SUCCESS_SAVING_GOOD_TO_KNOW_ITEMS';
