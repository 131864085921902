import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Button, Card, Input } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import React from 'react';
import FactDropdown from './Facts';
import './style.css';

const { TextArea } = Input;
function arrayMove(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
}

/**
 * @param {Object} props
 * @param {CollectionSpot} props.collectionSpot
 * @param {(newValues: Object.<string, any>)=> void} props.onPatchField
 */
export default function CollectionSpotEditorContent({
    collectionSpot,
    onPatchField,
}) {
    /**
     * @param {number} index
     * @param {CollectionSpotQuickFact} fact
     */

    const updateField = (index, fact) => {
        const newFacts = collectionSpot.quick_facts.slice();
        newFacts[index] = fact;
        onPatchField({ quick_facts: newFacts });
    };

    const addFact = () => {
        onPatchField({
            quick_facts: [
                ...collectionSpot.quick_facts,
                { emoji: '', text: '' },
            ],
        });
    };

    /**
     * @param {number} index
     */
    const removeFact = (index) => {
        const newFacts = collectionSpot.quick_facts.slice();
        newFacts.splice(index, 1);
        onPatchField({
            quick_facts: newFacts,
        });
    };

    return (
        <div className="collection-spot-editor__tab-content collection-spot-editor__spot">
            <div className="collection-spot-editor__spot-content">
                <Card
                    title="Spot Facts"
                    className="collection-spot-editor__spot-content-card"
                >
                    {collectionSpot.quick_facts.map((item, index) => (
                        <div
                            key={index}
                            className="collection-spot-editor__spot-content-item"
                        >
                            <FactDropdown
                                updateFact={(fact) => updateField(index, fact)}
                                value={(item.emoji + ' ' + item.text).trim()}
                            />
                            <ButtonGroup>
                                <Button
                                    disabled={index === 0}
                                    icon={<ArrowUpOutlined />}
                                    onClick={() => {
                                        onPatchField({
                                            quick_facts: arrayMove(
                                                collectionSpot.quick_facts,
                                                index,
                                                index - 1,
                                            ),
                                        });
                                    }}
                                ></Button>
                                <Button
                                    icon={<DeleteOutlined />}
                                    onClick={() => removeFact(index)}
                                    disabled={
                                        collectionSpot.quick_facts.length <= 1
                                    }
                                ></Button>
                                <Button
                                    disabled={
                                        index ===
                                        collectionSpot.quick_facts.length - 1
                                    }
                                    onClick={() => {
                                        onPatchField({
                                            quick_facts: arrayMove(
                                                collectionSpot.quick_facts,
                                                index,
                                                index + 1,
                                            ),
                                        });
                                    }}
                                    icon={<ArrowDownOutlined />}
                                ></Button>
                            </ButtonGroup>
                        </div>
                    ))}
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        onClick={addFact}
                    >
                        Add a fact
                    </Button>
                </Card>
                <div className="mb-ml"></div>
                <Card
                    title="Editor's notes"
                    className="collection-spot-editor__spot-content-card"
                >
                    <TextArea
                        className="form-field__textarea"
                        autoSize={{ minRows: 1, maxRows: 20 }}
                        value={collectionSpot.editor_notes?.content || ''}
                        onChange={(e) => {
                            const prevNotes = collectionSpot.editor_notes || {};
                            onPatchField({
                                editor_notes: {
                                    ...prevNotes,
                                    content: e.target.value,
                                },
                            });
                        }}
                        size="large"
                        placeholder="Share your thoughts"
                    />
                </Card>
            </div>
        </div>
    );
}
