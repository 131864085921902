import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Avatar, Typography, Button } from 'antd';
import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';
import LanguageFlag from 'components/LanguageFlag';
import './SelectableTip.css';

const { Text } = Typography;

/**
 *
 * @param {Object} props
 * @param {function} props.onSelect
 * @param {boolean} props.isSelected
 * @param {StepSpot} props.stepSpot
 */
export default function SelectableTip({ onSelect, isSelected, stepSpot }) {
    const select = () => {
        if (!isSelected) {
            onSelect(stepSpot);
        }
    };

    const date = useMemo(() => {
        if (!stepSpot?.creation_time) {
            return null;
        }
        const date = new Date(stepSpot?.creation_time);
        return new Intl.DateTimeFormat('en-UK', { dateStyle: 'medium' }).format(
            date,
        );
    }, [stepSpot?.creation_time]);

    return (
        <div
            className={classNames('selectable-tip line-center', {
                'is-selected': isSelected,
                'is-selectable': !isSelected,
            })}
            onClick={select}
        >
            <Avatar size={24} src={stepSpot.user.profile_image_thumb_path} />
            <div className="grow-full-flex ml-sm mr-sm">
                <div>
                    <Text type="secondary">
                        {date} | {stepSpot.user.first_name}{' '}
                        {stepSpot.user.last_name}
                    </Text>
                </div>
                <div>
                    <Text>{stepSpot.tip}</Text>
                </div>
            </div>
            <LanguageFlag language={stepSpot.language} />
            {!isSelected && (
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    size={'small'}
                    className="ml-sm"
                />
            )}
            {isSelected && (
                <CheckCircleOutlined
                    style={{ fontSize: 22, color: '#00A776' }}
                    className="ml-sm"
                />
            )}
        </div>
    );
}
