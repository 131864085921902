import { QUICK_FACTS_PER_GUIDE } from 'constants/fields-limits';

/**
 * Gets the name of a guide
 *
 * @param {Guide} guide
 * @returns {string}
 */
export const getName = (guide) => {
    return guide?.location_name_custom || guide?.location?.name;
};

/**
 * Gets the name of a guide
 *
 * @param {Guide} guide
 * @returns {string}
 */
export const getCountry = (guide) => {
    return guide?.location?.detail;
};

/**
 * @param {QuickFact[]} quickFacts
 */
export const areQuickFactsCorrect = (quickFacts = []) => {
    const nonEmptyQuickFacts = (quickFacts || []).filter(
        ({ body, title }) => !!body || !!title,
    );
    const completeEmptyQuickFacts = (quickFacts || []).filter(
        ({ body, title }) => !!body && !!title,
    );
    return (
        (nonEmptyQuickFacts.length === 0 ||
            nonEmptyQuickFacts.length === QUICK_FACTS_PER_GUIDE) &&
        completeEmptyQuickFacts.length === nonEmptyQuickFacts.length
    );
};
