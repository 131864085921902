/**
 * Augments the objects of an array by adding a key equal to their id and a lastEdited field containing last_edited_by and last_edited_date info
 *
 * @param {Object[]} array
 * @returns {Object[]}
 */
export const getDataSourceFromArray = (array) => {
    return array.map((rawItem) => {
        const lastEdited = getLastEditedText(rawItem);
        return { ...rawItem, key: rawItem.id, lastEdited };
    });
};

function getLastEditedText(rawItem) {
    if (!rawItem.last_edited_date || !rawItem.last_edited_by) {
        return '';
    }
    return `${getDateString(rawItem.last_edited_date)} by ${
        rawItem.last_edited_by.first_name
    }`;
}

function getDateString(date) {
    return `${date.toLocaleString(undefined, {
        dateStyle: 'short',
    })} at ${date.toLocaleTimeString(undefined, {
        timeStyle: 'short',
    })}`;
}

/**
 * Checks if certain fields of an object contain the desired text. Case insensitive.
 *
 * @param {Object} obj The object to check
 * @param {string} text The text to look for
 * @param {string[]} fieldsToCheck name of fields we should check
 * @param {Function} filterFn function to use to filter, if not specified we will use fieldsToCheck param
 * @returns {boolean}
 */
export const objectContainsText = (obj, text, fieldsToCheck, filterFn) => {
    if (!text) {
        return true;
    }

    // If provided, we use filterFn
    if (filterFn) {
        return filterFn(obj, text);
    }

    // If no filterFn we check fieldsToCheck
    const lowerCaseFilter = text.toLowerCase();
    for (const fieldName of fieldsToCheck) {
        const text = (obj[fieldName] || '').toLowerCase();
        if (text.includes(lowerCaseFilter)) {
            return true;
        }
    }
    return false;
};
