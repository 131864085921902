import {
    login as loginApi,
    logout as logoutApi,
    getCurrentUser as getCurrentUserApi,
} from 'api/auth.api';
import { parse } from 'utils/error-parser';
import { clearSentryUser, setSentryUser } from 'utils/sentry';
import {
    ERROR_LOADING,
    ERROR_LOGGING_OUT,
    START_LOADING,
    START_LOGGING_OUT,
    SUCCESS_LOADING,
    SUCCESS_LOGGING_OUT,
} from './action-types';

const clearUserSession = async () => {
    await logoutApi();
    clearSentryUser();
};

/**
 * Action that checks if the user is logged in
 *
 * @returns {(dispatch: any, getState: any) => Promise<void>} The action to be dispatched
 */
export const authenticate = () => {
    return async (dispatch, getState) => {
        // If already loading, do nothing
        /** @type {RootState} */
        const state = getState();
        if (state.auth.initted || state.auth.loading) {
            return Promise.resolve();
        }
        dispatch({ type: START_LOADING });
        try {
            const currentUser = await getCurrentUserApi();
            if (!currentUser.id) {
                await clearUserSession();
                dispatch({ type: SUCCESS_LOADING });
                return;
            }
            setSentryUser({
                username: currentUser.email,
                email: currentUser.email,
            });
            dispatch({
                type: SUCCESS_LOADING,
                payload: currentUser,
            });
        } catch (error) {
            await clearUserSession();
            dispatch({
                type: ERROR_LOADING,
                error: parse(error),
            });
            return;
        }
        return Promise.resolve();
    };
};

/**
 * Notifies that a Google sign on has happened. Will update state accordingly.
 *
 * @param {google.accounts.id.CredentialResponse} googleUser
 * @returns {(dispatch: any) => Promise<void>}
 */
export const notifyGoogleSignOn = (googleUser) => {
    return (dispatch) => {
        const idToken = googleUser.credential;
        return loginApi(idToken).then((user) => {
            setSentryUser({ username: user.email, email: user.email });
            dispatch({
                type: SUCCESS_LOADING,
                payload: user,
            });
        });
    };
};

export const logout = () => {
    return async (dispatch, getState) => {
        // If already loading, do nothing
        /** @type {RootState} */
        const state = getState();
        if (state.auth.loggingOut) {
            return Promise.resolve();
        }

        dispatch({ type: START_LOGGING_OUT });

        try {
            clearUserSession().then(() => {
                dispatch({
                    type: SUCCESS_LOGGING_OUT,
                });
            });
        } catch (error) {
            dispatch({ type: ERROR_LOGGING_OUT, error: parse(error) });
        }
    };
};
