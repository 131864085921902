import { useState, useEffect } from 'react';
import { parse } from './error-parser';

/**
 *
 * @typedef UsePaginatedGetResult
 * @property {number} currentPage Current page number being shown, will be one at first
 * @property {import('react').Dispatch<number>} setCurrentPage Dispatcher to change the current page
 * @property {boolean} isLoading True while the get is being done
 * @property {string} errorLoadingMessage If an error occurred in the last get, it will be here
 * @property {any[]} currentPageValues Values for the currently shown page
 * @property {PaginationInfo} lastPaginationInfo Last received pagination info
 * @property {()=> void} refresh Load again the last page that was loaded
 */

/**
 * Hook that automatically gets paginated data, using a provided getter
 *
 * @param {(page: number) => Promise<{pagination: PaginationInfo, results: any[]}>} apiGetter
 * @returns {UsePaginatedGetResult}
 */
export const usePaginatedGet = (apiGetter) => {
    const [currentPage, setCurrentPage] = useState(1);
    /** @type {[PaginationInfo, import('react').Dispatch<PaginationInfo>]} */
    const [lastPaginationInfo, setLastPaginationInfo] = useState(null);
    const [currentPageValues, setCurrentPageValues] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorLoadingMessage, setErrorLoadingMessage] = useState(null);
    const [tryAgainUnique, setTryAgainUnique] = useState(1);

    useEffect(() => {
        setErrorLoadingMessage(null);
        setIsLoading(true);
        setCurrentPageValues(null);
        apiGetter(currentPage)
            .then((result) => {
                if (result.pagination.page === currentPage) {
                    setCurrentPageValues(result.results);
                }
                setLastPaginationInfo(result.pagination);
            })
            .catch((e) => {
                setErrorLoadingMessage(parse(e));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [currentPage, apiGetter, tryAgainUnique]);

    return {
        isLoading,
        errorLoadingMessage,
        currentPageValues,
        currentPage,
        setCurrentPage,
        lastPaginationInfo,
        refresh: () => setTryAgainUnique((old) => old + 1),
    };
};
