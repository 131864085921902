import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Button, Alert } from 'antd';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Loading from 'components/Loading';
import {
    orderCollectionSpots,
    isSpotFromSources,
} from 'utils/collection-spot-utils';
import CollectionSpot from '../CollectionSpot';
import {
    useCollectionSpots,
    patchCollectionSpot,
} from 'api/collection-spots.api';
import './style.css';
import { parse } from 'utils/error-parser';

const { Text } = Typography;

const SortableItem = SortableElement(
    /**
     * @param {Object} props
     * @param {CollectionSpot} props.spot
     * @param {Source[]} props.filterBySources
     */
    ({ spot, filterBySources }) => (
        <CollectionSpot
            collectionSpot={spot}
            collection={spot.collection}
            isDimmed={!isSpotFromSources(spot, filterBySources)}
        />
    ),
);

const SortableList = SortableContainer(
    ({ items, filterBySources, isLoading }) => {
        return (
            <div className="collection__content-spots">
                {isLoading && (
                    <div className="collection__content-spots-loading">
                        <Loading />
                    </div>
                )}

                {items.map((spot, index) => (
                    // @ts-ignore
                    <SortableItem
                        key={`item-${spot.id}`}
                        index={index}
                        spot={spot}
                        filterBySources={filterBySources}
                    />
                ))}
            </div>
        );
    },
);

/**
 * @param {Object} props
 * @param {Collection} props.collection
 * @param {Source[]} props.filterBySources
 */
function CollectionSpots({ collection, filterBySources }) {
    const {
        collectionSpots,
        isValidating,
        error: errorLoadingCollectionSpots,
        refresh: refreshCollectionSpots,
    } = useCollectionSpots(collection.id);

    const isLoadingCollectionSpots = !collectionSpots && isValidating;

    const orderedSpots = useMemo(() => orderCollectionSpots(collectionSpots), [
        collectionSpots,
    ]);

    const [spotsToShow, setSpotsToShow] = useState(orderedSpots);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setSpotsToShow(orderedSpots);
    }, [orderedSpots]);

    /**
     * @param {number} oldIndex
     * @param {number} newIndex
     */
    const reorderSpots = async (oldIndex, newIndex) => {
        /** @type {Object.<Id, CollectionSpot>} */
        const spotsHash = orderedSpots.reduce(
            (acc, spot) => ({ ...acc, [spot.id]: spot }),
            {},
        );

        /** @type {CollectionSpot[]} */
        const reorderedSpots = arrayMove(
            orderedSpots,
            oldIndex,
            newIndex,
        ).map((spot, index) => ({ ...spot, order: index }));

        // Update right away to give fast feedback
        setSpotsToShow(reorderedSpots);

        const spotsToUpdate = reorderedSpots.filter(
            (spot) => spotsHash[spot.id].order !== spot.order,
        );
        setIsLoading(true);
        await Promise.all(
            spotsToUpdate.map((correctOrderSpot) =>
                patchCollectionSpot(correctOrderSpot, undefined),
            ),
        );
        if (spotsToUpdate.length) {
            await refreshCollectionSpots();
        }
        setIsLoading(false);
    };

    if (isLoadingCollectionSpots) {
        return (
            <div className="collection__content-loading">
                <Loading />
            </div>
        );
    }

    if (errorLoadingCollectionSpots) {
        return (
            <div className="column-center">
                <Alert
                    message="Error loading spots for this collection"
                    description={parse(errorLoadingCollectionSpots)}
                    showIcon
                    type="error"
                />
                <div className="mt-sm"></div>
                <Button size="large" onClick={() => refreshCollectionSpots()}>
                    Try again
                </Button>
            </div>
        );
    }

    if (!spotsToShow?.length) {
        return (
            <div className="collection__content-spots-empty">
                <Text type="secondary">No spots yet</Text>
            </div>
        );
    }

    return (
        // @ts-ignore
        <SortableList
            isLoading={isLoading}
            items={spotsToShow}
            filterBySources={filterBySources}
            distance={10}
            axis="xy"
            helperClass="collection-spot__dragging"
            onSortEnd={({ oldIndex, newIndex }) =>
                reorderSpots(oldIndex, newIndex)
            }
        />
    );
}

export default CollectionSpots;
