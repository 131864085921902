import { Alert, Button, Input, Typography, notification } from 'antd';
import { addCMSMedia } from 'api/cms-media.api';
import classNames from 'classnames';
import FormField from 'components/FormField';
import InGuideSpotSelector from 'components/InGuideSpotSelector';
import MediaSelector from 'components/MediaSelector';
import { StockPhotoWarningModal } from 'components/StockPhotoWarning';
import {
    GUIDE_GOOD_TO_KNOW_DESCRIPTION_MAX_LENGTH,
    GUIDE_HIGHLIGHT_TITLE_MAX_LENGTH,
} from 'constants/fields-limits';
import {
    HIGHLIGHT_COVER,
    HIGHLIGHT_COVER_RATIO,
} from 'constants/media-sizes.constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    initGuideHighlights,
    notifyGuideHighlightsChange,
    resetGuideHighlights,
    saveGuideHighlights,
} from 'store/guide-editor/actions';
import {
    doHighlightsHaveErrors,
    getCurrentGuide,
    getCurrentHighlightsBeingEdited,
    haveHighlightsChanged,
} from 'store/guide-editor/selectors';
import {
    getErrorLoadingHighlightsForGuide,
    getHighlightsForGuide,
    isLoadingHighlightsForGuide,
    isSavingHighlightItems,
} from 'store/highlight-items/selectors';
import { isSameExternalMedia } from 'utils/media-utils';
import { ItemFieldChange, applyPatchToItem } from 'utils/sortable-item-utils';
import { getNonBoughtMediaFromList } from 'utils/stock-images-utils';
import { v4 as uuid } from 'uuid';
import { SortableList } from '../SortableList';
import { useSort } from '../SortableList/use-sort';
import styles from './style.module.css';

type GuideEditorHighlightsProps = {
    onClickDiscard: () => void;
};

type GuideEditorHighlightItemProps = {
    index: number;
    guide: Guide;
    highlight: HighlightItem;
    onChangeField: (change: ItemFieldChange) => void;
    errors?: FieldError;
    isSubmitted?: boolean;
};

export const GuideEditorHighlights = ({
    onClickDiscard,
}: GuideEditorHighlightsProps) => {
    const dispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const guide = useSelector(getCurrentGuide);
    const guideId = useMemo(() => guide?.id, [guide]);
    const currentHighlights = useSelector(getCurrentHighlightsBeingEdited);
    const oldHighlights = useSelector((state: RootState) =>
        getHighlightsForGuide(state, guideId),
    );
    const isLoading = useSelector((state: RootState) =>
        isLoadingHighlightsForGuide(state, guideId),
    );
    const errorLoading = useSelector((state: RootState) =>
        getErrorLoadingHighlightsForGuide(state, guideId),
    );
    const isSaving = useSelector(isSavingHighlightItems);
    const hasChanged = useSelector(haveHighlightsChanged);
    const { hasErrors, errors } = useSelector(doHighlightsHaveErrors);

    const [
        isAddingStockPhotoWarningShown,
        setIsAddingStockPhotoWarningShown,
    ] = useState(false);
    const [photosToPurchase, setPhotosToPurchase] = useState<CMSMedia[]>([]);
    const [isSavingMedia, setIsSavingMedia] = useState(false);
    const { onMoveUp, onMoveDown } = useSort(currentHighlights);

    /**
     * Patches many highlight fields at the same time and dispatches them to the store
     */
    const patchHighlightFields = (changes: ItemFieldChange[]) => {
        let highlights = currentHighlights;
        for (const change of changes) {
            highlights = applyPatchToItem(highlights, change);
        }

        dispatch(notifyGuideHighlightsChange(highlights));
    };

    const patchHighlightField = (changes: ItemFieldChange) => {
        patchHighlightFields([changes]);
    };

    const addHighlight = () => {
        if (!guideId) return;
        const newHighlight = getEmptyHighlight(
            guideId,
            currentHighlights.length,
        );
        const newHighlights = currentHighlights.concat([newHighlight]);
        dispatch(notifyGuideHighlightsChange(newHighlights));
    };

    const deleteHighlight = ({ id: highlightId }: HighlightItem) => {
        const newHighlights = currentHighlights.filter(
            (highlight) => highlight.id !== highlightId,
        );
        dispatch(notifyGuideHighlightsChange(newHighlights));
    };

    const moveHighlightUp = (highlight: HighlightItem) => {
        const sortedItems = onMoveUp(highlight);

        if (sortedItems) {
            patchHighlightFields(
                sortedItems.map((item) => ({
                    id: item.id,
                    fieldName: 'order',
                    newValue: item.newOrder,
                })),
            );
        }
    };

    const moveHighlightDown = (highlight: HighlightItem) => {
        const sortedItems = onMoveDown(highlight);

        if (sortedItems) {
            patchHighlightFields(
                sortedItems.map((item) => ({
                    id: item.id,
                    fieldName: 'order',
                    newValue: item.newOrder,
                })),
            );
        }
    };

    /**
     * Checks if the guide is published, and the editor is adding highlights with stock photos that will be bought.
     *
     */
    const shouldShowWarningAddingStockPhotos = async (): Promise<{
        shouldShow: boolean;
        highlights: HighlightItem[];
    }> => {
        if (guide.publish_status.name !== 'published') {
            return { shouldShow: false, highlights: [] };
        }

        const highlightsWithChangedMedia = currentHighlights.filter(
            (highlight) => {
                if (!highlight.cms_media) {
                    return false;
                }
                const oldHighlight = oldHighlights?.find(
                    ({ id }) => id === highlight.id,
                );
                if (!oldHighlight) {
                    return true;
                }
                return !isSameExternalMedia(
                    highlight.cms_media,
                    oldHighlight.cms_media ?? null,
                );
            },
        );

        const allNonBoughtStockImages = await getNonBoughtMediaFromList(
            getMediaFromHighlights(highlightsWithChangedMedia),
        );
        const highlightsWithNonBoughtStockImages = highlightsWithChangedMedia.filter(
            (highlight) => {
                return !!allNonBoughtStockImages.find((image) =>
                    isSameExternalMedia(image, highlight.cms_media ?? null),
                );
            },
        );
        return {
            shouldShow: !!allNonBoughtStockImages.length,
            highlights: highlightsWithNonBoughtStockImages,
        };
    };

    const dispatchSavedHighlights = async () => {
        if (!guideId) return;

        try {
            setIsSavingMedia(true);
            for (const highlight of currentHighlights) {
                const oldHighlight = oldHighlights?.find(
                    (oldHighlight) => oldHighlight.id === highlight.id,
                );
                if (
                    !isSameExternalMedia(
                        oldHighlight?.cms_media ?? null,
                        highlight.cms_media,
                    )
                ) {
                    const addedMedia = await addCMSMedia(
                        highlight.cms_media,
                        HIGHLIGHT_COVER,
                        !!highlight.cms_media &&
                            photosToPurchase?.includes(highlight.cms_media),
                    );
                    highlight.cms_media = addedMedia;
                }
            }

            dispatch(saveGuideHighlights(guideId, currentHighlights));
        } catch (error) {
            notification.error({
                message: 'Something went wrong. Please, try again later',
            });
        } finally {
            setIsSavingMedia(false);
        }
    };

    const save = async () => {
        setIsSubmitted(true);
        if (!hasErrors) {
            const {
                shouldShow,
                highlights,
            } = await shouldShowWarningAddingStockPhotos();
            if (shouldShow) {
                setPhotosToPurchase(getMediaFromHighlights(highlights));
                setIsAddingStockPhotoWarningShown(true);
                return;
            }
            dispatchSavedHighlights();
        } else {
            notification.error({
                message: 'Form validation failed',
                description: 'Please check the fields marked in red',
            });
        }
    };

    useEffect(() => {
        if (guideId) {
            dispatch(initGuideHighlights(guideId));
        }
        return () => {
            dispatch(resetGuideHighlights());
        };
    }, [guideId, dispatch]);

    if (!guide) {
        return null;
    }

    return (
        <>
            {errorLoading && (
                <div className="full-page-tabs-error">
                    <Alert
                        className="grow-full-flex"
                        message="There was an error loading highlight items"
                        description={errorLoading}
                        type="error"
                        showIcon
                    />
                </div>
            )}
            <SortableList
                title="Highlights"
                data={currentHighlights}
                onAdd={addHighlight}
                onDelete={deleteHighlight}
                onMoveDown={moveHighlightDown}
                onMoveUp={moveHighlightUp}
                isLoading={isLoading || isSaving}
            >
                {({ data, index }) =>
                    !data ? (
                        <SortableList.EmptyState
                            message="No highlights yet"
                            action={
                                <SortableList.AddButton
                                    onClick={addHighlight}
                                    disabled={isLoading}
                                >
                                    Add the first highlight
                                </SortableList.AddButton>
                            }
                        />
                    ) : (
                        <GuideEditorHighlightItem
                            key={data.id}
                            index={index}
                            highlight={data}
                            guide={guide}
                            onChangeField={patchHighlightField}
                            isSubmitted={isSubmitted}
                            errors={errors[data.id]}
                        />
                    )
                }
            </SortableList>

            {isAddingStockPhotoWarningShown && (
                <StockPhotoWarningModal
                    numberOfPhotos={photosToPurchase.length}
                    onConfirm={() => {
                        setIsAddingStockPhotoWarningShown(false);
                        dispatchSavedHighlights();
                    }}
                    onCancel={() => {
                        setIsAddingStockPhotoWarningShown(false);
                    }}
                />
            )}
            <div className="full-page-tabs-footer">
                <div className="max-content-width">
                    <div className="grow-full-flex"></div>

                    <Button size="large" onClick={onClickDiscard}>
                        Cancel
                    </Button>

                    <Button
                        size="large"
                        type="primary"
                        loading={isSaving || isSavingMedia}
                        onClick={save}
                        disabled={!hasChanged}
                    >
                        Save changes
                    </Button>
                </div>
            </div>
        </>
    );
};

function GuideEditorHighlightItem({
    index,
    guide,
    highlight,
    onChangeField,
    errors = {},
    isSubmitted = false,
}: GuideEditorHighlightItemProps) {
    const handleChangeField = useCallback(
        <T,>(fieldName: string, newValue: T) => {
            onChangeField({
                id: highlight.id,
                fieldName: fieldName,
                newValue: newValue,
            });
        },
        [highlight.id, onChangeField],
    );

    return (
        <div className={classNames(styles.item, 'js-highlight-item-editor')}>
            <Typography.Title level={4}>{`Item ${index + 1}`}</Typography.Title>
            <div className={styles.itemContent}>
                <div className={styles.mediaSelector}>
                    <MediaSelector
                        location={guide.location}
                        guideId={highlight.guide_id}
                        includeVideos={true}
                        onSelect={(media: CMSMedia) =>
                            handleChangeField('cms_media', media)
                        }
                        onDelete={() => handleChangeField('cms_media', null)}
                        media={highlight.cms_media}
                        ratio={HIGHLIGHT_COVER_RATIO}
                        spot={highlight.spot ?? undefined}
                    />
                </div>
                <div className={styles.form}>
                    <FormField
                        label="Title"
                        length={highlight.title.length}
                        maxLength={GUIDE_HIGHLIGHT_TITLE_MAX_LENGTH}
                        error={isSubmitted ? errors.title : undefined}
                        labelWidth={100}
                    >
                        <Input
                            className="form-field__textarea"
                            value={highlight.title}
                            onChange={(e) =>
                                handleChangeField('title', e.target.value)
                            }
                            status={isSubmitted && errors.title ? 'error' : ''}
                        />
                    </FormField>
                    <FormField
                        label="Description"
                        length={highlight.description.length}
                        maxLength={GUIDE_GOOD_TO_KNOW_DESCRIPTION_MAX_LENGTH}
                        error={isSubmitted ? errors.description : undefined}
                        labelWidth={100}
                    >
                        <Input.TextArea
                            className="form-field__textarea"
                            value={highlight.description}
                            autoSize={{
                                minRows: 1,
                                maxRows: 4,
                            }}
                            status={
                                isSubmitted && errors.description ? 'error' : ''
                            }
                            onChange={(e) =>
                                handleChangeField('description', e.target.value)
                            }
                        />
                    </FormField>
                    <FormField
                        label="Spot"
                        labelWidth={100}
                        error={isSubmitted ? errors.spot : undefined}
                    >
                        <InGuideSpotSelector
                            value={highlight.spot}
                            guide={guide}
                            onSelect={(spot: PSSpot_v15_Minimal | null) =>
                                handleChangeField('spot', spot)
                            }
                            hasError={!!errors.spot && isSubmitted}
                        />
                    </FormField>
                    <FormField label="Notes" labelWidth={100}>
                        <Input.TextArea
                            className="form-field__textarea"
                            autoSize={{
                                minRows: 1,
                                maxRows: 12,
                            }}
                            value={highlight.editor_notes}
                            onChange={(e) =>
                                handleChangeField(
                                    'editor_notes',
                                    e.target.value,
                                )
                            }
                            placeholder="Add your thoughts..."
                        />
                    </FormField>
                </div>
            </div>
        </div>
    );
}

function getEmptyHighlight(guideId: Id, order: number): HighlightItem {
    return {
        id: uuid(),
        guide_id: guideId,
        title: '',
        description: '',
        editor_notes: '',
        cms_media: null,
        spot: null,
        order: order,
    };
}

function getMediaFromHighlights(highlights: HighlightItem[]) {
    return highlights.reduce((acc: CMSMedia[], highlight) => {
        if (!highlight.cms_media) return acc;

        return [...acc, highlight.cms_media];
    }, []);
}
