import { flatMap } from 'lodash';
import { useEffect, useState } from 'react';
import { parse } from 'utils/error-parser';
import { withCache } from 'utils/fetch-cache';
import { get } from 'utils/http';
import { loadSharedCodebase } from 'utils/use-shared-codebase';

export const SPOT_CATEGORY_FIRST_LEVEL_SWR_KEY =
    'SPOT_CATEGORY_FIRST_LEVEL_SWR_KEY';
export const SPOT_CATEGORY_SECOND_LEVEL_SWR_KEY =
    'SPOT_CATEGORY_SECOND_LEVEL_SWR_KEY';

async function getCategoryTree() {
    const sharedCodebase = await loadSharedCodebase();
    const categoryTreeJson = await withCache('CATEGORY_TREE_URL_json', () =>
        get(sharedCodebase.com.polarsteps.shared.core.CATEGORY_TREE_URL, {
            credentials: 'omit',
        }),
    );
    let core = sharedCodebase.polarstepsCore();
    return core.createCategoryTreeWithData(JSON.stringify(categoryTreeJson));
}

/**
 * Gets all the first level (category) ids of categories
 *
 * @returns {Promise<SpotCategory[]>}
 */
export const getAllFirstLevel = async () => {
    const categoryTree = await getCategoryTree();
    return getFirstLevelCategoriesFromTree(categoryTree)
        .map((category) => category.id)
        .map(expandToObjectWithId);
};

/**
 * @param {CoreCategoryTree} categoryTree
 */
function getFirstLevelCategoriesFromTree(categoryTree) {
    const allFirstLevel = flatMap(
        categoryTree.mainCategories(),
        /**
         * @param {Category} mainCategory
         */
        (mainCategory) => mainCategory.children(),
    );

    return allFirstLevel.filter(
        (category) => category.categoryLevel() === 'ps_category',
    );
}

/**
 * Gets all the second level (sub_category) of the tree
 *
 * @returns {Promise<SpotCategory[]>}
 */
export const getAllSecondLevel = async () => {
    const categoryTree = await getCategoryTree();
    const allFirstLevel = getFirstLevelCategoriesFromTree(categoryTree);
    const allSecondLevel = flatMap(allFirstLevel, (firstLevel) =>
        firstLevel.children(),
    );

    return allSecondLevel.filter(
        (category) => category.categoryLevel() === 'ps_sub_category',
    );
};
export const useAllSecondLevel = () => {
    const [reloadCounter, setReloadCounter] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    /** @type {[string, React.Dispatch<string>]} */
    const [error, setError] = useState(null);
    /** @type {[SpotCategory[], React.Dispatch<SpotCategory[]>]} */
    const [allCategories, setAllCategories] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setError(null);
        getAllSecondLevel()
            .then((categories) => {
                setAllCategories(categories);
            })
            .catch((e) => {
                setError(parse(e));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [reloadCounter]);

    const reload = () => {
        setReloadCounter((old) => old + 1);
    };

    return {
        isLoading,
        error,
        allCategories: (allCategories ?? []).sort((a, b) =>
            a.name.localeCompare(b.name),
        ),
        reload,
    };
};

/**
 * Gets all the second level (sub_category) ids of categories for first level parent
 *
 * @param {string} firstLevelParent
 * @returns {Promise<SpotCategory[]>}
 */
export const getSecondLevel = async (firstLevelParent) => {
    const categoryTree = await getCategoryTree();
    const allFirstLevel = getFirstLevelCategoriesFromTree(categoryTree);

    const firstLevelCategory = allFirstLevel.find(
        (category) => category.id === firstLevelParent,
    );
    return firstLevelCategory
        .children()
        .filter((category) => category.categoryLevel() === 'ps_sub_category')
        .map((category) => category.id)
        .map(expandToObjectWithId);
};

function expandToObjectWithId(property) {
    return {
        id: property,
        name: property,
    };
}
