import React from 'react';
import { getContainedSize } from 'utils/image/image-size-utils';
import { MEDIA_SELECTOR_POPOVER_IMG_SIZE } from 'constants/ux';
import classNames from 'classnames';

import tooltipStyles from '../../MediaItemTooltipWrapper/style.module.css';
import './UnsplashMediaItemPopover.css';

/**
 * @param {Object} props
 * @param {UnsplashPhotoFromSearch} props.media
 */
function UnsplashMediaItemPopover({ media }) {
    const size = getContainedSize(media, MEDIA_SELECTOR_POPOVER_IMG_SIZE);

    return (
        <div
            className={classNames(
                tooltipStyles.root,
                'unsplash-media-item-popover',
            )}
        >
            <img
                style={size}
                className={tooltipStyles.image}
                src={media.urls.regular}
                alt=""
            />
            <div className={tooltipStyles.text}>
                <div>{media.description}</div>
                <div>{media.alt_description}</div>
                <div>
                    Photo by{' '}
                    <a
                        href={media.user.links.html}
                        target="_BLANK"
                        rel="noopener noreferrer"
                    >
                        {media.user.first_name} {media.user.last_name}
                    </a>
                </div>
            </div>
        </div>
    );
}

export default UnsplashMediaItemPopover;
