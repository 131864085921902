import { get, post } from 'utils/http';
import { BASE_URL } from 'constants/env';
import { withCache } from 'utils/fetch-cache';

/**
 * Saves a location in the database
 *
 * @param {PSLocation} location
 * @returns {Promise<PSLocation>}
 */
export const addLocation = (location) => {
    const dbLocation = toDBFormat(location);
    return post(`${BASE_URL}/cms/locations`, dbLocation).then((newLocation) => {
        return fromDBFormat(newLocation);
    });
};

/**
 * Removes a location from the database
 *
 * @param {PSLocation} _location
 * @returns {Promise}
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const removeLocation = (_location) => {
    // Right now this is not allowed in the database
    return Promise.resolve();
    // return postDelete(`${BASE_URL}/cms/locations/${location.id}`, {
    //     returnPlainResponse: true,
    // });
};

/**
 * Gets a location from the database
 *
 * @param {Id} locationId
 * @returns {Promise<PSLocation>}
 */
export const getLocation = (locationId) => {
    return withCache(`${BASE_URL}/cms/locations/${locationId}`, get);
};

/**
 * Converts a location to the format needed for the DB
 *
 * @param {PSLocation} location
 * @returns {DBPSLocation}
 */
function toDBFormat(location) {
    return {
        name: location.name,
        lat: location.lat,
        lon: location.lon,
        detail: location.detail,
        full_detail: location.fullDetail,
        country_code: location.countryCode,
    };
}

/**
 * Converts a location to the format needed for the DB
 *
 * @param {DBPSLocation} location
 * @returns {PSLocation}
 */
function fromDBFormat(location) {
    return {
        id: location.id,
        name: location.name,
        lat: location.lat,
        lon: location.lon,
        detail: location.detail,
        fullDetail: location.full_detail,
        countryCode: location.country_code,
    };
}
