import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

export const initSentry = () => {
    Sentry.init({
        dsn:
            'https://8f5caaa775804b0c9b97ff937aa3301c@o557138.ingest.sentry.io/5944334',
        // integrations: [new Integrations.BrowserTracing()],  // No need for perf monitoring yet
        release: `meraki@${process.env.REACT_APP_VERSION}`,
        environment: process.env.NODE_ENV,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0, // No need for perf monitoring yet
        ignoreErrors: [
            'ResizeObserver loop limit exceeded', // Can safely ignore https://stackoverflow.com/a/50387233
        ],
    });
};

export const setSentryUser = ({ username = '', email = '' }) => {
    Sentry.setUser({ username, email });
};

export const clearSentryUser = () => {
    Sentry.configureScope((scope) => scope.setUser(null));
};
