import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Popover, Button } from 'antd';
import {
    HomeOutlined,
    UserOutlined,
    RocketOutlined,
    BookOutlined,
    GlobalOutlined,
    EnvironmentOutlined,
    ToolOutlined,
} from '@ant-design/icons';
import { routes, getActiveRoute } from 'routes';
import Logo from './polarsteps-logo.svg';
import SmallLogo from './polarsteps-compass.svg';
import SidebarUser from './SidebarUser';
import FeatureFlags from 'components/FeatureFlags';
import './style.css';

const { Sider } = Layout;

function Sidebar() {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const logo = collapsed ? SmallLogo : Logo;
    const activeRoute = getActiveRoute(location.pathname, routes);
    const selectedKeys = activeRoute ? [activeRoute.id] : [];
    // @ts-ignore
    const version = window.cms_version;

    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={280}
            onMouseEnter={() => setCollapsed(false)}
            onMouseLeave={() => setCollapsed(true)}
        >
            <div className="sidebar">
                <div className="sidebar__logo">
                    <img src={logo} alt="Polarsteps logo" />
                </div>
                <Menu theme="dark" mode="inline" selectedKeys={selectedKeys}>
                    {getMenuItemForRoute('home')}
                    {getMenuItemForRoute('users')}
                    {getMenuItemForRoute('trips')}
                    {getMenuItemForRoute('travel-books')}
                    {getMenuItemForRoute('explore')}
                    <Menu.SubMenu
                        title={
                            <span>
                                {getIconForRoute('guides')}
                                <span>Guides</span>
                            </span>
                        }
                        key="guides"
                    >
                        {getMenuItemForRoute('guides-overview')}
                        {getMenuItemForRoute('guides-collections')}
                        {getMenuItemForRoute('guides-collection-types')}
                    </Menu.SubMenu>

                    {getMenuItemForRoute('advanced')}
                </Menu>
                <SidebarUser />
                <div className="sidebar__version-container">
                    <Popover
                        trigger="click"
                        content={<FeatureFlags />}
                        placement="topRight"
                    >
                        <Button
                            type="link"
                            style={{
                                height: 24,
                                fontSize: 10,
                                color: 'white',
                            }}
                        >
                            {version}
                        </Button>
                    </Popover>
                </div>
            </div>
        </Sider>
    );
}

function getMenuItemForRoute(routeId) {
    const routeInfo = routes[routeId];

    return (
        <Menu.Item key={routeInfo.id} disabled={routeInfo.disabled}>
            {getLinkPartForRoute(routeInfo)}
        </Menu.Item>
    );
}

function getLinkPartForRoute(routeInfo) {
    if (routeInfo.disabled) {
        return (
            <>
                {getIconForRoute(routeInfo.id)}
                <span>{routeInfo.title}</span>
            </>
        );
    }

    return (
        <Link to={routeInfo.route}>
            {getIconForRoute(routeInfo.id)}
            <span>{routeInfo.title}</span>
        </Link>
    );
}

export default Sidebar;

function getIconForRoute(routeId) {
    switch (routeId) {
        case 'home':
            return <HomeOutlined />;
        case 'users':
            return <UserOutlined />;
        case 'trips':
            return <RocketOutlined />;
        case 'travel-books':
            return <BookOutlined />;
        case 'explore':
            return <GlobalOutlined />;
        case 'guides':
            return <EnvironmentOutlined />;
        case 'advanced':
            return <ToolOutlined />;
        default:
            return null;
    }
}
