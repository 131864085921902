import React from 'react';
import Flag from 'components/Flag';
import { useCountryCodeFromLanguage } from 'utils/country-language-converter';

/**
 *
 * @param {Object} props
 * @param {?string} props.language
 */
function LanguageFlag({ language }) {
    const { countryCode } = useCountryCodeFromLanguage(language);
    if (!countryCode) {
        return null;
    }
    return <Flag countryCode={countryCode} />;
}

export default LanguageFlag;
