import { getSourceId } from 'api/sources.api';

/**
 * @param {CMSMedia} media
 * @param {Id[]} stockSourceIds IDs for the sources considered stock
 */
const isMediaNonBoughtStock = (media, stockSourceIds) => {
    if (!stockSourceIds.includes(media.source_id)) {
        return false; // Not a stock source
    }
    // TODO: we need a way of knowing if an image has already been purchased
    // return media.is_preview;
    return true;
};

/**
 * @param {CMSMedia[]} mediaList
 */
export const getNonBoughtMediaFromList = async (mediaList) => {
    const stockSources = [await getSourceId('shutterstock')];
    return mediaList
        .filter((photo) => !!photo)
        .filter((photo) => isMediaNonBoughtStock(photo, stockSources));
};
