/**
 * Gets the currently being edited collection type
 *
 * @param {RootState} state
 * @returns {CollectionType}
 */
export const getCurrentCollectionType = (state) => {
    return state.collectionTypeEditor.currentCollectionType;
};

/**
 * Checks if the currently edited collection type is complete enought to be saved in the DB
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const isCollectionTypeCorrect = (state) => {
    const current = getCurrentCollectionType(state);
    return !!(
        current &&
        current.name &&
        current.ps_category &&
        current.collection_class
    );
};
