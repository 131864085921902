import React from 'react';
import classNames from 'classnames';
import { Tabs as AntdTabs } from 'antd';
import './Tabs.css';
import StickyBox from 'react-sticky-box';
/**
 * TabDefinition
 *
 * @typedef {object} TabDefinition
 * @property {string} key
 * @property {React.ReactNode | string} name Title of the tab
 * @property {React.ReactNode} [content]
 * @property {boolean} [hasFooter=false]
 * @property {boolean} [isHidden=false]
 */

/**
 * @param {Object} props
 * @param {string} [props.defaultActiveKey]
 * @param {TabDefinition[]} [props.tabs]
 * @param {"full-page"|"normal"} [props.type="normal"]
 * @param {string} [props.activeKey]
 * @param {(activeKey: string) => void} [props.onChange]
 */
function Tabs({
    defaultActiveKey,
    tabs = [],
    type = 'normal',
    activeKey,
    onChange = () => undefined,
}) {
    // We add activeKey only if something is present. Otherwise AntD thinks tabs are controlled,
    // and nothing is shown when active key is not present
    const extraProps = activeKey ? { activeKey } : {};

    const renderTabBar = (props, DefaultTabBar) => (
        <StickyBox offsetTop={0} offsetBottom={20} style={{ zIndex: 10 }}>
            <DefaultTabBar {...props} />
        </StickyBox>
    );

    return (
        <AntdTabs
            size="small"
            defaultActiveKey={defaultActiveKey}
            tabBarStyle={{
                backgroundColor: 'white',
                padding: '0 24px',
                marginBottom: 0,
            }}
            renderTabBar={renderTabBar}
            className={classNames('tabs', `${type}-tabs`)}
            onChange={onChange}
            items={tabs
                .filter((tabDef) => !tabDef.isHidden)
                .map((tabDef) => ({
                    key: tabDef.key,
                    label: tabDef.name,
                    children: (
                        <div
                            className={classNames('tab-pane', {
                                'tab-pane-with-footer': tabDef.hasFooter,
                            })}
                        >
                            <div
                                className={classNames(
                                    'tab-content',
                                    `${type}-tab-content`,
                                    {
                                        'tab-content-with-footer':
                                            tabDef.hasFooter,
                                    },
                                )}
                            >
                                <div
                                    className={classNames(
                                        'tab-content-limit-width',
                                        `${type}-tab-content-limit-width`,
                                    )}
                                >
                                    {tabDef.content}
                                </div>
                            </div>
                        </div>
                    ),
                }))}
            {...extraProps}
        />
    );
}

export default Tabs;
