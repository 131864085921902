import React from 'react';
import PolarstepsMediaItem from './Polarsteps/PolarstepsMediaItem';
import SpotMediaItem from './Spot/SpotMediaItem';
import UnsplashMediaItem from './Unsplash/UnsplashMediaItem';
import ShutterstockMediaItem from './Shutterstock/ShutterstockMediaItem';

/**
 *
 * @param {Object} props
 * @param {MediaSelectorMediaType} props.type
 * @param {FavoriteImage} props.media
 * @param {Id} props.guideId
 * @param {() => void} props.onSelect
 * @param {boolean} props.isSelected
 * @param {boolean} props.isLoading
 * @param {boolean} [props.canBeFavorite=true]
 */
export default function FavoriteMediaItem({
    type,
    media,
    guideId,
    onSelect,
    isSelected,
    isLoading,
    canBeFavorite = true,
}) {
    const commonProps = {
        guideId,
        isSelected,
        isLoading,
        canBeFavorite,
    };

    switch (type) {
        case 'PSMedia':
            return (
                <PolarstepsMediaItem
                    {...commonProps}
                    // @ts-ignore
                    media={media.data}
                    onSelect={() => onSelect()}
                />
            );
        case 'Spot':
            return (
                <SpotMediaItem
                    {...commonProps}
                    // @ts-ignore
                    media={media.data.photo}
                    // @ts-ignore
                    spot={media.data.spot}
                    onSelect={() => onSelect()}
                />
            );
        case 'Unsplash':
            return (
                <UnsplashMediaItem
                    {...commonProps}
                    // @ts-ignore
                    media={media.data}
                    onSelect={() => onSelect()}
                />
            );
        case 'Shutterstock':
            return (
                <ShutterstockMediaItem
                    {...commonProps}
                    // @ts-ignore
                    media={media.data}
                    onSelect={() => onSelect()}
                />
            );
        default:
            return null;
    }
}
