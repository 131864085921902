import { cloneDeep } from 'lodash';
import * as actionTypes from './action-types';

/**
 * @type {GuideEditorState}
 */
const GUIDE_EDITOR_INITIAL_STATE = {
    originalGuideId: null,
    addingGuide: false,
    currentGuide: null,
    removingCollection: null,
    editingSpot: null,
    savingNewGuide: false,
    errorSavingNewGuide: null,
    patchingGuide: false,
    errorPatchingGuide: null,
    showingSpotCollectionEditor: false,
    currentStories: null,
    currentHighlights: null,
    currentGoodToKnowItems: null,
    currentNumberOfDaysItems: null,
};

/**
 * guideEditor reducer
 *
 * @param {GuideEditorState} state
 * @param {*} action
 * @returns {GuideEditorState}
 */
export const guideEditor = (
    state = GUIDE_EDITOR_INITIAL_STATE,
    action = {},
) => {
    switch (action.type) {
        case actionTypes.START_ADDING_GUIDE:
            return {
                ...state,
                originalGuideId: null,
                addingGuide: true,
                currentGuide: getNewEmptyGuide(action.author),
                removingCollection: null,
                editingSpot: null,
            };

        case actionTypes.STOP_ADDING_GUIDE:
            return {
                ...state,
                originalGuideId: null,
                addingGuide: false,
                currentGuide: null,
                removingCollection: null,
                editingSpot: null,
            };

        case actionTypes.INIT_EDITED_GUIDE:
            return {
                ...state,
                originalGuideId: action.guide.id,
                addingGuide: false,
                currentGuide: cloneDeep(action.guide),
                removingCollection: null,
                editingSpot: null,
            };

        case actionTypes.STOP_EDITING_GUIDE:
            return {
                ...state,
                originalGuideId: null,
                addingGuide: false,
                currentGuide: null,
                removingCollection: null,
                editingSpot: null,
            };

        case actionTypes.PATCH_GUIDE_FIELD:
            if (!state.currentGuide) {
                return state;
            }
            return {
                ...state,
                currentGuide: {
                    ...state.currentGuide,
                    [action.fieldName]: action.newValue,
                },
            };
        case actionTypes.START_SAVING_NEW_GUIDE:
            return {
                ...state,
                savingNewGuide: true,
                errorSavingNewGuide: null,
            };
        case actionTypes.SUCCESS_SAVING_NEW_GUIDE:
            return {
                ...state,
                savingNewGuide: false,
                errorSavingNewGuide: null,
            };
        case actionTypes.FAILED_SAVING_NEW_GUIDE:
            return {
                ...state,
                savingNewGuide: false,
                errorSavingNewGuide: action.error,
            };
        case actionTypes.START_PATCHING_GUIDE:
            return {
                ...state,
                patchingGuide: true,
                errorPatchingGuide: null,
            };
        case actionTypes.SUCCESS_PATCHING_GUIDE:
            return {
                ...state,
                patchingGuide: false,
                errorPatchingGuide: null,
                currentGuide: action.patchedGuide,
            };
        case actionTypes.FAILED_PATCHING_GUIDE:
            return {
                ...state,
                patchingGuide: false,
                errorPatchingGuide: action.error,
            };
        case actionTypes.NOTIFY_SPOT_COLLECTION_EDITOR_SHOWN:
            return {
                ...state,
                showingSpotCollectionEditor: action.isShown,
            };
        case actionTypes.SET_CURRENT_GUIDE_STORIES:
            return {
                ...state,
                currentStories: action.currentStories,
            };
        case actionTypes.SET_CURRENT_GUIDE_HIGHLIGHTS:
            return {
                ...state,
                currentHighlights: action.currentHighlights,
            };
        case actionTypes.SET_CURRENT_GUIDE_GOOD_TO_KNOW_ITEMS:
            return {
                ...state,
                currentGoodToKnowItems: action.currentGoodToKnowItems,
            };
        case actionTypes.SET_CURRENT_GUIDE_NUMBER_OF_DAYS_ITEMS:
            return {
                ...state,
                currentNumberOfDaysItems: action.currentNumberOfDaysItems,
            };
        case actionTypes.UPDATE_CURRENT_GUIDE_FIELD:
            return {
                ...state,
                currentGuide: {
                    ...state.currentGuide,
                    [action.fieldName]: action.value,
                },
            };
        default:
            return state;
    }
};

/**
 * Gets an empty guide to start adding it
 *
 * @param {CMSUser} editor
 * @returns {Guide}
 */
function getNewEmptyGuide(editor) {
    return {
        id: null,
        publish_status: getDraftPublishStatus(),
        last_edited_by: null,
        location: null,
        location_name_custom: '',
        guide_type: getInitialGuideType(),
        guide_ranking: getInitialGuideRanking(),
        tagline: '',
        cover_image: null,
        main_editor: editor,
        main_editor_id: editor?.id,
        meta_description: '',
    };
}

function getDraftPublishStatus() {
    return {
        id: 1,
        name: 'Draft',
    };
}

function getInitialGuideType() {
    return {
        id: 2,
        name: 'city',
    };
}

function getInitialGuideRanking() {
    return {
        id: 1,
        name: 'Default',
        level: 3,
    };
}
