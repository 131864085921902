import { get, post } from 'utils/http';
import { BASE_URL } from 'constants/env';
import { parse } from 'utils/error-parser';

/**
 * @param {string} query
 * @param {MediaOrientationSelectorValue} [orientation = 'all']
 * @param {number} [page=1]
 * @returns {Promise<{ hasMore: boolean, currentPage: number, results: ShutterstockPhotoFromSearch[]}>}
 */
export const search = async (query, orientation = 'all', page = 1) => {
    const resultsPerPage = 30;

    const url = new URL(`${BASE_URL}/cms/shutterstock/search`);

    url.searchParams.append('query', query);
    url.searchParams.append('view', 'full'); // Request more info for each image
    url.searchParams.append('sort', 'relevance');
    url.searchParams.append('page', `${page}`);
    url.searchParams.append('per_page', `${resultsPerPage}`);
    url.searchParams.append('license', 'commercial');
    switch (orientation) {
        case 'portrait':
            url.searchParams.append('aspect_ratio_max', `${0.9}`);
            break;
        case 'landscape':
            url.searchParams.append('aspect_ratio_min', `${1.1}`);
            break;
        case 'squarish':
            url.searchParams.append('aspect_ratio_min', `${0.8}`);
            url.searchParams.append('aspect_ratio_max', `${1.2}`);
            break;
        default:
            break;
    }
    const result = await get(url.toString());

    if (!result) {
        throw new Error('Error searching shutterstock');
    }

    const currentResults = (page - 1) * resultsPerPage + result.data.length;
    // Even though is_editorial images shouldn't be received because of license=commercial, we make sure we don't show them
    const results = (result.data || []).filter((image) => !image.is_editorial);
    return {
        results,
        currentPage: page,
        hasMore: currentResults < result.total_count,
    };
};

/**
 *
 * @param {string} contributorId
 * @returns {Promise<ShutterstockContributor>}
 */
export const getContributor = async (contributorId) => {
    const result = await get(
        `${BASE_URL}/cms/shutterstock/contributor/${contributorId}`,
    );
    if (result.data && result.data[0]) {
        return result.data[0];
    }
    throw new Error(`Shutterstock contributor not found: ${contributorId}`);
};

/**
 * Gets the number of photos in a guide that have not been bought yet (and need to be).
 *
 * @param {Guide} guide
 * @returns {Promise<number>}
 */
export const getNonBoughtStockImages = async (guide) => {
    const result = await get(
        `${BASE_URL}/cms/guides/${guide.id}/shutterstock/license`,
    );
    return result.total_unlicensed_images;
};

const SHUTTERSTOCK_LICENSING_TASK_STATUS_COMPLETE = 2;
const SHUTTERSTOCK_LICENSING_TASK_STATUS_FAILED = 3;

/**
 * Licenses all the images from a guide that have not been licensed yet
 *
 * @param {Guide} guide
 * @returns {Promise<void>}
 */
export const buyStockImagesForGuide = async (guide) => {
    const checkEvery = 2000; //ms
    let taskId;
    try {
        const result = await post(
            `${BASE_URL}/cms/guides/${guide.id}/shutterstock/license`,
        );
        taskId = result.taskId;
    } catch (e) {
        throw new Error(`Error creating task to license images: ${parse(e)}`);
    }

    let status = null;
    let failedChecks = 0;
    while (status !== SHUTTERSTOCK_LICENSING_TASK_STATUS_COMPLETE) {
        try {
            await sleep(checkEvery).promise;
            const statusResult = await get(
                `${BASE_URL}/cms/shutterstock/license/${taskId}`,
            );
            status = statusResult.status;
            failedChecks = 0;
        } catch (e) {
            failedChecks += 1;
            if (failedChecks > 3) {
                throw new Error(
                    `Error checking shutterstock/license taskId:${taskId}`,
                );
            }
        }
        if (status === SHUTTERSTOCK_LICENSING_TASK_STATUS_FAILED) {
            throw new Error(`Error licensing, taskId:${taskId}`);
        }
    }
};

/**
 * Promise that resolves after a few ms, and can be cancelled
 *
 * @param {number} ms
 * @returns {{promise: Promise<void>, cancel: () => void}}
 */
function sleep(ms = 1000) {
    let timeout;
    const promise = new Promise((resolve) => {
        timeout = setTimeout(resolve, ms);
    });

    return {
        promise,
        cancel: () => {
            clearTimeout(timeout);
        },
    };
}
