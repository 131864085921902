import useSWR from 'swr';
import { getSourceId } from 'api/sources.api';
import { useEffect, useMemo, useState } from 'react';
import { isMediaRelatedToFav } from 'utils/favorite-utils';
import {
    addFavoriteImage,
    getFavoriteImagesForGuide,
    removeFavoriteImage,
} from 'api/guide-favorite-image.api';
import { useCurrentUser } from 'utils/use-current-user';

/**
 * @param {Id} guideId
 */
export const getSWRKeyMediaFavoritesPerGuide = (guideId) => {
    return `/cms/guide/${guideId}/favorite_images`;
};

/**
 * @param {Id} guideId
 */
export const useMediaFavorites = (guideId) => {
    const key = getSWRKeyMediaFavoritesPerGuide(guideId);
    const { data, isValidating, error, mutate } = useSWR(key, () =>
        getFavoriteImagesForGuide(guideId),
    );
    return { favorites: data, isValidating, error, mutate };
};

/**
 * @param {FavoriteImageData} media
 * @param {MediaSelectorMediaType} mediaType
 * @param {Id} guideId
 */
export const useMediaFavorite = (media, mediaType, guideId) => {
    const currentUser = useCurrentUser();
    const { favorites: allFavorites, mutate } = useMediaFavorites(guideId);
    const mediaInfo = useMediaExternalIdAndSource(media, mediaType);
    const relatedFavorite = useMemo(() => {
        if (!mediaInfo || !allFavorites) {
            return null;
        }
        return allFavorites.find((favorite) =>
            isMediaRelatedToFav(
                mediaInfo.externalId,
                mediaInfo.sourceId,
                favorite,
            ),
        );
    }, [allFavorites, mediaInfo]);

    const addFavorite = async () => {
        if (!mediaInfo) {
            return;
        }
        const source_id = await getSourceId(
            mapMediaTypeToSource(mediaType, media),
        );
        const external_id = getExternalId(media, mediaType);
        await addFavoriteImage(guideId, {
            creator_id: currentUser?.id,
            source_id,
            external_id,
            data: media,
        });
        await mutate();
    };

    const removeFavorite = async () => {
        if (!mediaInfo) {
            return;
        }
        const fav = allFavorites.find((favorite) =>
            isMediaRelatedToFav(
                mediaInfo.externalId,
                mediaInfo.sourceId,
                favorite,
            ),
        );
        await removeFavoriteImage(guideId, fav.id);
        await mutate();
    };

    return {
        allFavorites,
        relatedFavorite,
        isFavorite: !!relatedFavorite,
        addFavorite,
        removeFavorite,
    };
};

/**
 * @param {FavoriteImageData} media
 * @param {MediaSelectorMediaType} mediaType
 */
function useMediaExternalIdAndSource(media, mediaType) {
    const [sourceId, setSourceId] = useState(null);
    useEffect(() => {
        if (!mediaType) {
            return;
        }
        getSourceId(mapMediaTypeToSource(mediaType, media)).then((id) =>
            setSourceId(id),
        );
    }, [mediaType, media]);

    if (sourceId === null) {
        return null;
    }
    return {
        sourceId,
        externalId: getExternalId(media, mediaType),
    };
}

/**
 * @param {MediaSelectorMediaType} mediaType
 * @param {FavoriteImageData} media
 */
function mapMediaTypeToSource(mediaType, media) {
    switch (mediaType) {
        case 'PSMedia':
            return 'polarsteps';
        case 'Shutterstock':
            return 'shutterstock';
        case 'Unsplash':
            return 'unsplash';
        case 'Spot':
            // @ts-ignore
            return media.spot.source;
    }
}

/**
 * @param {FavoriteImageData} media
 * @param {MediaSelectorMediaType} mediaType
 * @returns {Id}
 */
function getExternalId(media, mediaType) {
    switch (mediaType) {
        case 'PSMedia':
        case 'Shutterstock':
        case 'Unsplash':
            // @ts-ignore
            return media.id;
        case 'Spot':
            // @ts-ignore
            return media.photo.external_id;
    }
}
