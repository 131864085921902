import { combineReducers } from 'redux';
import { auth } from './auth/reducer.js';
import { guides } from './guides/reducer.js';
import { collectionTypes } from './collection-types/reducer.js';
import { guideEditor } from './guide-editor/reducer.js';
import { collectionTypeEditor } from './collection-type-editor/reducer.js';
import { storyItems } from './story-items/reducer.js';
import { highlightItems } from './highlight-items/reducer';
import { goodToKnowItems } from './good-to-know-items/reducer';
import { numberOfDaysItems } from './number-of-days-items/reducer';

export default combineReducers({
    auth,
    guides,
    collectionTypes,
    guideEditor,
    collectionTypeEditor,
    storyItems,
    highlightItems,
    goodToKnowItems,
    numberOfDaysItems,
});
