import * as actionTypes from './action-types';

/**
 * @type {CollectionTypesState}
 */
const COLLECTION_TYPES_INITIAL_STATE = {
    loadingById: {},
    errorsById: {},
    byId: {},
    addingCollectionType: false,
    errorAddingCollectionType: null,
    patchingById: {},
    errorsPatchingById: {},
};

/**
 * collectionTypes reducer
 *
 * @param {CollectionTypesState} state
 * @param {*} action
 * @returns {CollectionTypesState}
 */
export const collectionTypes = (
    state = COLLECTION_TYPES_INITIAL_STATE,
    action = {},
) => {
    switch (action.type) {
        case actionTypes.STARTED_LOADING_COLLECTION_TYPE:
            return {
                ...state,
                loadingById: {
                    [action.id]: true,
                },
                errorsById: {
                    [action.id]: null,
                },
                byId: {
                    [action.id]: null,
                },
            };
        case actionTypes.FAILED_LOADING_COLLECTION_TYPE:
            return {
                ...state,
                loadingById: {
                    [action.id]: false,
                },
                errorsById: {
                    [action.id]: action.error,
                },
                byId: {
                    [action.id]: null,
                },
            };
        case actionTypes.SUCCESS_LOADING_COLLECTION_TYPE:
            return {
                ...state,
                loadingById: {
                    [action.id]: false,
                },
                errorsById: {
                    [action.id]: null,
                },
                byId: {
                    [action.id]: action.payload,
                },
            };
        case actionTypes.STARTED_ADDING_COLLECTION_TYPE:
            return {
                ...state,
                addingCollectionType: true,
                errorAddingCollectionType: null,
            };
        case actionTypes.FAILED_ADDING_COLLECTION_TYPE:
            return {
                ...state,
                addingCollectionType: false,
                errorAddingCollectionType: action.error,
            };
        case actionTypes.SUCCESS_ADDING_COLLECTION_TYPE:
            return {
                ...state,
                addingCollectionType: false,
                errorAddingCollectionType: null,
                byId: {
                    [action.payload.id]: action.payload,
                },
            };
        case actionTypes.STARTED_PATCHING_COLLECTION_TYPE:
            return {
                ...state,
                patchingById: {
                    [action.id]: true,
                },
                errorsPatchingById: {
                    [action.id]: null,
                },
            };
        case actionTypes.FAILED_PATCHING_COLLECTION_TYPE:
            return {
                ...state,
                patchingById: {
                    [action.id]: false,
                },
                errorsPatchingById: {
                    [action.id]: action.error,
                },
            };
        case actionTypes.SUCCESS_PATCHING_COLLECTION_TYPE:
            return {
                ...state,
                patchingById: {
                    [action.id]: false,
                },
                errorsPatchingById: {
                    [action.id]: null,
                },
                byId: {
                    [action.id]: action.payload,
                },
            };
        default:
            return state;
    }
};
