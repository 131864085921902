import React from 'react';
import GenericSelectorSWR from 'components/GenericSelectorSWR';
import { AUTHORS_SWR_KEY, getAuthors } from 'api/users.api';

/**
 *
 * @param {Object} props
 * @param {Id} props.value
 * @param {(newId: Id, newAuthor: CMSUser) => void} props.onChange
 */
function AuthorSelector({ value, onChange }) {
    return (
        <GenericSelectorSWR
            value={value}
            onChange={onChange}
            fetcher={getAuthors}
            swrKey={AUTHORS_SWR_KEY}
            placeholder="Select user"
            textGetter={(author) => `${author.first_name} ${author.last_name}`}
        ></GenericSelectorSWR>
    );
}

export default AuthorSelector;
