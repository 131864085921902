import { get, post, postLikeForm } from 'utils/http';
import { BASE_URL } from 'constants/env';

/**
 * Logs in the CMS, providing the id_token of a Google Suite user already logged in there.
 *
 * @param {string} id_token
 * @returns {Promise<CMSUser>}
 */
export const login = (id_token) => {
    return postLikeForm(`${BASE_URL}/cms/login`, {
        id_token,
    });
};

export const logout = () => {
    return post(`${BASE_URL}/cms/logout`);
};

export const getCurrentUser = () => {
    return get(`${BASE_URL}/cms/currentuser`);
};
