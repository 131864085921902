import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from '@react-hook/debounce';
import { getAllCollectionTypesWithPagination } from 'api/collection-types.api';
import GenericOverviewTable from 'components/GenericOverviewTable';
import { Button, Input } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { PlusOutlined } from '@ant-design/icons';
import './style.css';

const { Search } = Input;

const columns = [
    {
        title: 'Order',
        dataIndex: 'id',
        key: 'id',
        // eslint-disable-next-line react/display-name
        render: (text, record) => (
            <Link to={`/guides/collection-type/${record.id}`}>{text}</Link>
        ),
        sorter: (a, b) => a.id > b.id,
        width: '100px',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        // eslint-disable-next-line react/display-name
        render: (text, record) => (
            <Link to={`/guides/collection-type/${record.id}`}>{text}</Link>
        ),
        sorter: (a, b) => a.name > b.name,
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'collection_class',
        sorter: (a, b) => a.collection_class.name > b.collection_class.name,
        render: (text, record) => record.collection_class.name,
    },
];

function CollectionTypes() {
    const [filter, setFilter] = useDebounce('', 300);
    const apiGetter = useCallback(
        (...args) => getAllCollectionTypesWithPagination(filter, ...args),
        [filter],
    );

    return (
        <div className="full-page">
            <PageHeader
                ghost={false}
                title="Collection types"
                extra={
                    <Link
                        to="/guides/collection-types/add"
                        className="full-page-header-tools-add-button"
                    >
                        <Button
                            type="primary"
                            size="large"
                            icon={<PlusOutlined />}
                        >
                            Add new type
                        </Button>
                    </Link>
                }
            >
                <Search
                    className="full-page-header-tools-filter"
                    placeholder="Search for collection types"
                    size="large"
                    onChange={(e) => setFilter(e.target.value)}
                />
            </PageHeader>

            <div className="full-page-content">
                <div className="max-content-width mb-m">
                    <GenericOverviewTable
                        columns={columns}
                        apiGetter={apiGetter}
                        emptyTableMessage={
                            filter
                                ? 'No collection types found with this text.'
                                : 'Sorry no collection types yet.'
                        }
                    ></GenericOverviewTable>
                </div>
            </div>
        </div>
    );
}

export default CollectionTypes;
