import React from 'react';
import { Typography } from 'antd';
import TopCollection from './TopCollection';

const { Title, Text } = Typography;

/**
 *
 * @param {Object} props
 * @param {string} props.title
 * @param {TopCollection[]} props.collections
 * @param {Guide} props.guide
 * @param {Source[]} props.filterBySources
 */
function TopCollectionsSet({ title, collections, filterBySources, guide }) {
    return (
        <div className="collections-section">
            <Title level={3}>{title}</Title>
            {!collections.length && (
                <Text type="secondary">No Collections yet</Text>
            )}
            {collections.map((collection) => (
                <TopCollection
                    key={collection.ps_category}
                    collection={collection}
                    filterBySources={filterBySources}
                    guide={guide}
                />
            ))}
        </div>
    );
}

export default TopCollectionsSet;
