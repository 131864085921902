import { get } from 'utils/http';
import { BASE_URL } from 'constants/env';

export const GUIDE_TYPES_SWR_KEY = '/cms/guide_types';

/**
 * Gets the possible guide types from the database
 *
 * @returns {Promise<GuideType[]>}
 */
export const getGuideTypes = () => {
    return get(`${BASE_URL}/cms/guide_types`).then((result = {}) => {
        return result.objects || [];
    });
};
