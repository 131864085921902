import React from 'react';
import { Spin, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSearchByName } from 'api/location-iq.api';
import { useDebounce } from '@react-hook/debounce';

/**
 *
 * @param {Object} props
 * @param {PSLocation} props.value
 * @param {(newLocation: PSLocationWithBB) => void} props.onChange
 * @param {string} [props.placeholder='']
 */
export default function LocationSelector({
    value = null,
    onChange,
    placeholder = '',
}) {
    const [searchQueryDebounced, setSearchQueryDebounced] = useDebounce(
        '',
        300,
    );
    const { locationsByName, isValidating, isLoading } = useSearchByName(
        searchQueryDebounced,
    );

    const getSelectValue = () => {
        // If we have results, and they contain the preslected location, we set its id so the Select shows correctly.
        // If we don't yet have results we will just show the name
        if (!value) {
            return null;
        }
        if (
            !locationsByName ||
            !locationsByName.find((item) => item.id === value.id)
        ) {
            return getNameToShowFromLocation(value);
        }
        return value.id;
    };

    return (
        <Select
            showSearch={true}
            placeholder={isLoading ? 'Loading...' : placeholder}
            notFoundContent={isLoading ? <Spin size="small" /> : null}
            filterOption={false}
            value={getSelectValue()}
            onSearch={(text) => setSearchQueryDebounced(text)}
            onChange={(stringValue) => {
                const fullSelectedValue = locationsByName.find(
                    (value) => value.id === stringValue,
                );
                onChange(fullSelectedValue);
            }}
            style={{ width: '100%' }}
            size="large"
            suffixIcon={<SearchOutlined />}
            loading={isValidating}
        >
            {(locationsByName || []).map((d) => (
                <Select.Option key={d.id} value={d.id}>
                    {getNameToShowFromLocation(d)}
                </Select.Option>
            ))}
        </Select>
    );
}

/**
 *
 * @param {PSLocation} location
 * @returns {string}
 */
function getNameToShowFromLocation(location) {
    return `${location.name} (${location.detail})`;
}
