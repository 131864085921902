import * as actionTypes from './action-types';

/**
 * @type {AuthState}
 */
const AUTH_INITIAL_STATE = {
    loading: false,
    initted: false,
    loggedInUser: null,
    errorLoading: null,
    loggingOut: false,
    errorLoggingOut: null,
};

/**
 * auth reducer
 *
 * @param {AuthState} state
 * @param {*} action
 * @returns {AuthState}
 */
export const auth = (state = AUTH_INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case actionTypes.START_LOADING:
            return {
                ...state,
                initted: false,
                loading: true,
                loggedInUser: null,
                errorLoading: null,
            };
        case actionTypes.ERROR_LOADING:
            return {
                ...state,
                initted: false,
                loading: false,
                loggedInUser: null,
                errorLoading: action.error,
            };
        case actionTypes.SUCCESS_LOADING:
            return {
                ...state,
                initted: true,
                loading: false,
                loggedInUser: action.payload,
                errorLoading: null,
            };
        case actionTypes.START_LOGGING_OUT:
            return {
                ...state,
                loggingOut: true,
                errorLoggingOut: null,
            };
        case actionTypes.SUCCESS_LOGGING_OUT:
            return {
                ...state,
                loggingOut: false,
                errorLoggingOut: null,
                loggedInUser: null,
            };
        case actionTypes.ERROR_LOGGING_OUT:
            return {
                ...state,
                loggingOut: false,
                errorLoggingOut: action.error,
            };
        default:
            return state;
    }
};
