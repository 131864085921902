import React from 'react';
import { isVideo } from 'constants/media-types.constants';
import MediaItemTooltipWrapper from '../../MediaItemTooltipWrapper';
import PolarstepsMediaItemPopover from './PolarstepsMediaItemPopover';
import MediaItemContent from '../MediaItemContent';
import CameraSvg from '../camera.svg';

/**
 *
 * @param {Object} props
 * @param {PSMedia} props.media
 * @param {Id} props.guideId
 * @param {(media: PSMedia) => void} props.onSelect
 * @param {boolean} props.isSelected
 * @param {boolean} [props.isLoading = false]
 * @param {boolean} [props.canBeFavorite=true]
 */
function PolarstepsMediaItem({
    media,
    guideId,
    onSelect,
    isSelected,
    isLoading = false,
    canBeFavorite = true,
}) {
    return (
        <MediaItemTooltipWrapper
            content={<PolarstepsMediaItemPopover media={media} />}
            overlayClassName="polarsteps-media-item-popover__antd-popover"
        >
            <MediaItemContent
                media={media}
                guideId={guideId}
                onSelect={() => onSelect(media)}
                isSelected={isSelected}
                mediaType="PSMedia"
                isLoading={isLoading}
                canBeFavorite={canBeFavorite}
                imgSrc={media.large_thumbnail_path}
            >
                {isVideo(media) && (
                    <div className="media-selector-image-list-item-video">
                        <img alt="" src={CameraSvg} />
                    </div>
                )}
            </MediaItemContent>
        </MediaItemTooltipWrapper>
    );
}

export default PolarstepsMediaItem;
