import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';

type ItemSetChanges<T> = {
    newItems: T[];
    deletedItems: T[];
    editedItems: T[];
};

type DataWithId = { id: Id };

export type ItemFieldChange = {
    id: Id;
    fieldName: string;
    newValue: unknown;
};

/**
 * Gets the changes between two sets of items
 */
export const getChangesBetweenItemSets = memoizeOne(
    getChangesBetweenItemSetsNotMemo,
);

/**
 * Gets the changes between two sets of items
 */
function getChangesBetweenItemSetsNotMemo<T extends DataWithId>(
    current: T[],
    original: T[],
): ItemSetChanges<T> {
    const newItems = current.filter((item) => !isItemContained(item, original));
    const deletedItems = original.filter(
        (item) => !isItemContained(item, current),
    );
    const editedItems = current.filter((item) =>
        hasItemChanged(item, original),
    );

    return { newItems, deletedItems, editedItems };
}

/**
 * Checks if an item is contained in an array of items (just checking its id)
 */
function isItemContained<T extends DataWithId>(item: T, items: T[]): boolean {
    return !!findOriginal(item, items);
}

/**
 * Checks if a item has changed compared to the original (also checks if there was an original one)
 */
function hasItemChanged<T extends DataWithId>(
    item: T,
    originals: T[],
): boolean {
    const originalItem = originals.find(({ id }) => item.id === id);
    if (!originalItem) {
        return false;
    }

    return !isEqual(item, originalItem);
}

/**
 * Gets the original item related (same id) to a item from a list
 */
export const findOriginal = <T extends DataWithId>(item: T, originals: T[]) => {
    return originals.find(({ id }) => item?.id === id);
};

/**
 * Applies a field patch to items, returning a copy of them with the modified item
 */
export function applyPatchToItem<T extends DataWithId>(
    items: T[],
    change: ItemFieldChange,
): T[] {
    const itemsToChange = items.find((item) => item.id === change.id);

    if (!itemsToChange) return items;

    const changedItem = {
        ...itemsToChange,
        [change.fieldName]: change.newValue,
    };

    return items.map((item) => {
        if (item.id === changedItem.id) {
            return changedItem;
        }
        return item;
    });
}

export function getItemsMappedById<T extends DataWithId>(
    itemsArray: T[] = [],
): Record<Id, T> {
    return itemsArray.reduce((accumulator, item) => {
        return {
            ...accumulator,
            [item.id]: item,
        };
    }, {});
}
