import React from 'react';
import ReactCountryFlag from 'react-country-flag';

/**
 * @param {Object} props
 * @param {string} props.countryCode
 */
function Flag({ countryCode }) {
    return (
        <ReactCountryFlag
            countryCode={countryCode}
            svg
            style={{
                width: '16px',
                height: '12px',
                borderRadius: '4px',
                overflow: 'hidden',
            }}
        />
    );
}

export default Flag;
