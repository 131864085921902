import React from 'react';
import ReactDOM from 'react-dom';
import { addGAPIMetaHeader } from 'utils/add-gapi-meta-header';
import { initSentry } from 'utils/sentry';
import './root-styles/styles';
import App from './App';
import './typings/type-defs.js';

initSentry();

addGAPIMetaHeader();

ReactDOM.render(<App />, document.getElementById('root'));

// @ts-ignore
window.cms_version = process.env.REACT_APP_VERSION; // eslint-disable-line no-undef

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
