import useSWR, { cache, mutate } from 'swr';
import { get, post, patch, postDelete } from 'utils/http';
import { BASE_URL } from 'constants/env';
import { deleteTip, addTip, patchTip } from './collection-spot-tips.api';
import {
    getChangedTips,
    orderCollectionSpots,
} from 'utils/collection-spot-utils';
import { getSourceId } from './sources.api';
import { getChildId } from 'utils/get-child-id';
import { refreshTopCollectionsForGuide } from './collections.api';

type CollectionSpotsResponse = {
    collection_spots: CollectionSpot[];
};

export const getSWRKeyForCollectionSpots = (collectionId?: Id) => {
    if (!collectionId) {
        return null;
    }
    return `/cms/collection/${collectionId}/collection_spots`; // Not the real API, but good as a key
};

export const getSWRKeyForGuideSpots = (guideId: Id) => {
    if (!guideId) {
        return null;
    }
    return `/cms/guide/${guideId}/spots`; // Not the real API, but good as a key
};

export const refreshCollectionSpots = (collectionId: Id, guideId: Id) => {
    mutateAllCollectionSpotsFromGuide(guideId);
    return mutate(getSWRKeyForCollectionSpots(collectionId));
};

/**
 * Hook to get the collections spots for a collection
 */
export const useCollectionSpots = (collectionId: Id) => {
    const {
        data,
        isValidating,
        error,
        mutate,
    } = useSWR(
        getSWRKeyForCollectionSpots(collectionId),
        () => getCollectionSpots(collectionId),
        { revalidateOnFocus: false },
    );
    return {
        collectionSpots: data,
        isValidating,
        error,
        refresh: mutate,
    };
};

/**
 * Hook to get the collections spots for a guide
 */
export const useGuideSpots = (guideId: Id | undefined) => {
    const { data, isValidating, error, mutate } = useSWR(
        guideId ? getSWRKeyForGuideSpots(guideId) : null,
        () => getGuideSpots(guideId),
        { revalidateOnFocus: false },
    );
    return {
        guideSpots: data,
        isValidating,
        error,
        refresh: mutate,
    };
};

/**
 * Gets all the collection spots associated with a collection
 */
async function getCollectionSpots(collectionId: Id): Promise<CollectionSpot[]> {
    const result = await get<CollectionSpotsResponse>(
        `${BASE_URL}/cms/collections/${collectionId}/spots`,
    );
    if (!result.collection_spots) {
        return result.collection_spots;
    }
    return result.collection_spots.map((collection_spot) => {
        return {
            ...collection_spot,
            quick_facts: collection_spot.quick_facts?.length
                ? collection_spot.quick_facts
                : [{ emoji: '', text: '' }],
        };
    });
}

/**
 * Gets all the collection spots associated with a guide
 */
async function getGuideSpots(guideId: Id | undefined) {
    if (!guideId) return [];

    const result = await get<CollectionSpotsResponse>(
        `${BASE_URL}/cms/guide/${guideId}/spots`,
    );
    return result.collection_spots;
}

/**
 * Adds a collection spot to the database.
 */
export const addCollectionSpot = async (
    guide: Guide,
    collection: Collection,
    spot: PSSpot_v15_Minimal,
    mainEditor: CMSUser,
) => {
    // Special case, if the guide is published we don't allow adding spots that are facebook spots
    if (
        guide.publish_status.name === 'published' &&
        spot.source === 'facebook'
    ) {
        return Promise.reject('Cannot add Facebook spots to a published guide');
    }
    const currentCollectionSpots = cache.get(
        getSWRKeyForCollectionSpots(collection.id),
    );
    const isSpotPublished = guide.publish_status.name !== 'published';
    const collectionSpot = prepareCollectionSpot(
        spot,
        collection,
        currentCollectionSpots,
        mainEditor,
        isSpotPublished,
    );
    const collectionSpotDB = await toCollectionSpotDB(collectionSpot);
    return post(`${BASE_URL}/cms/collection_spots`, collectionSpotDB);
};

/**
 * Gets a CollectionSpot object from the info in a spot collection and other collectionSpots in the collection
 */
function prepareCollectionSpot(
    spot: PSSpot_v15_Minimal,
    collection: Collection,
    currentCollectionSpots: CollectionSpot[],
    mainEditor: CMSUser,
    published: boolean,
): CollectionSpot {
    return {
        collection,
        collection_id: collection.id!,
        name: spot.name,
        local_name: null!,
        top_spot: false,
        order: (currentCollectionSpots || []).length,
        main_editor: mainEditor,
        main_editor_id: mainEditor.id,
        featured_photo: null!,
        custom_tip: undefined,
        spot,
        spot_id: spot.id,
        source_id: undefined!,
        tips: [],
        labels: [],
        published,
        collection_spot_media: [],
        ready_for_review: false,
    };
}

/**
 * Patches a collection spot in the database.
 */
export const patchCollectionSpot = async (
    collectionSpot: CollectionSpot,
    newMedia?: CMSMedia,
): Promise<CollectionSpot> => {
    const collectionSpotDB = await toCollectionSpotDB({
        ...collectionSpot,
        featured_photo: newMedia as CMSMedia,
    });
    return patch<CollectionSpot>(
        `${BASE_URL}/cms/collection_spots/${collectionSpot.id}`,
        collectionSpotDB,
    );
};

/**
 * Patches a collection spot in the DB, updating the tips that need to be updated
 */
export const patchCollectionSpotAndTips = async (
    collectionSpot: CollectionSpot,
    originalCollectionSpot: CollectionSpot,
    newFeaturedPhoto: CMSMedia,
) => {
    // First, update the tips
    const { newTips, deletedTips, editedTips } = getChangedTips(
        collectionSpot,
        originalCollectionSpot,
    );

    const willAddTips = newTips.map((newTip) => addTip(newTip));
    const willDeleteTips = deletedTips.map((deletedTip) =>
        deleteTip(deletedTip),
    );
    const willPatchTips = editedTips.map((editedTip) => patchTip(editedTip));

    await Promise.all([...willAddTips, ...willDeleteTips, ...willPatchTips]);

    // Then patch the collection spot itself
    const patchedCollectionSpot = await patchCollectionSpot(
        collectionSpot,
        newFeaturedPhoto,
    );

    const currentCollectionSpots: CollectionSpot[] = cache.get(
        getSWRKeyForCollectionSpots(collectionSpot.collection_id),
    );

    if (currentCollectionSpots) {
        // Refresh stale data, just replacing the changed spot in the collection
        await mutate(
            getSWRKeyForCollectionSpots(collectionSpot.collection_id),
            async () =>
                currentCollectionSpots.map((eachSpot) => {
                    if (patchedCollectionSpot.id !== eachSpot.id) {
                        return eachSpot;
                    }
                    return patchedCollectionSpot;
                }),
        );
        if (
            originalCollectionSpot.collection_id !=
            patchedCollectionSpot.collection_id
        ) {
            // Refresh the original collection as the spot moved to new collection
            await mutate(
                getSWRKeyForCollectionSpots(
                    originalCollectionSpot.collection_id,
                ),
            );
        }
    }

    // // We then trigger the real refresh
    // refreshCollectionSpots(collectionSpot.collection_id);

    if (patchedCollectionSpot.top_spot || originalCollectionSpot.top_spot) {
        refreshTopCollectionsForGuide(
            originalCollectionSpot.collection.guide_id,
        );
    }

    return patchedCollectionSpot;
};

/**
 * Removes a collection spot from the database. (will also remove all associated tips)
 */
export const removeCollectionSpot = async (
    collectionSpotToRemove: CollectionSpot,
    allCollectionSpots: CollectionSpot[],
) => {
    // Remove spot
    await postDelete(
        `${BASE_URL}/cms/collection_spots/${collectionSpotToRemove.id}`,
        {
            returnPlainResponse: true,
        },
    );

    // Update the positions of the other spots in the collection
    const remainingCollectionSpots = allCollectionSpots.filter(
        (spot) => spot.id !== collectionSpotToRemove.id,
    );
    const orderedCollectionSpots = orderCollectionSpots(
        remainingCollectionSpots,
    ).map((spot, index) => ({ ...spot, order: index }));

    // We find the ones that have changed order
    const changedCollectionSpots = orderedCollectionSpots.reduce(
        (acc, currentSpot) => {
            const originalSpot = allCollectionSpots.find(
                (spot) => spot.id === currentSpot.id,
            );
            if (originalSpot?.order === currentSpot.id) {
                return acc;
            }
            return [...acc, currentSpot];
        },
        [] as CollectionSpot[],
    );
    await Promise.all(
        changedCollectionSpots.map((spot) =>
            patchCollectionSpot(spot, undefined),
        ),
    );

    // Refresh stale data
    if (orderedCollectionSpots.length) {
        // We refresh collection spots, first using the data we infer will be correct
        await mutate(
            getSWRKeyForCollectionSpots(
                orderedCollectionSpots[0].collection_id,
            ),
            async () => orderedCollectionSpots,
        );
    }
    // We then trigger the real refresh
    refreshCollectionSpots(
        collectionSpotToRemove.collection_id,
        collectionSpotToRemove.collection.guide_id,
    );

    // Refresh top collections if needed
    if (collectionSpotToRemove.top_spot) {
        refreshTopCollectionsForGuide(
            collectionSpotToRemove.collection.guide_id,
        );
    }
};

async function toCollectionSpotDB(collectionSpot: CollectionSpot) {
    const sourceId = await getSourceId(collectionSpot.spot.source);
    const featured_photo_id = getChildId(collectionSpot.featured_photo);
    return {
        top_spot: collectionSpot.top_spot,
        collection_id: collectionSpot.collection_id,
        name: collectionSpot.name,
        local_name: collectionSpot.local_name || null,
        order: collectionSpot.order,
        top_spot_order: collectionSpot.top_spot_order,
        main_editor_id: collectionSpot.main_editor.id,
        featured_photo_id,
        custom_tip: collectionSpot.custom_tip || null,
        spot_id: collectionSpot.spot.id,
        source_id: sourceId,
        labels: collectionSpot.labels,
        published: collectionSpot.published,
        quick_facts: fixQuickFacts(collectionSpot.quick_facts),
        collection_spot_media: collectionSpot.collection_spot_media.map(
            (media) => ({ cms_media_id: media.cms_media_id }),
        ),
        editor_notes: collectionSpot.editor_notes,
        ready_for_review: collectionSpot.ready_for_review,
    };
}

export const getSWRKeyForAllCollectionSpotsFromGuide = (guideId: Id) => {
    if (!guideId) {
        return null;
    }
    return `${BASE_URL}/cms/guides/${guideId}/collection_spots`;
};

function fixQuickFacts(quickFacts?: CollectionSpotQuickFact[]) {
    if (!quickFacts) {
        return [];
    }
    const nonEmpty = quickFacts.filter(({ emoji, text }) => !!emoji && !!text);
    return nonEmpty;
}

export const getAllCollectionSpotsFromGuide = async (
    guideId: Id,
): Promise<CollectionSpot[]> => {
    const result = await get<{ spots: CollectionSpot[] }>(
        `${BASE_URL}/cms/guides/${guideId}/collection_spots`,
    );
    return result?.spots;
};

export function mutateAllCollectionSpotsFromGuide(guideId: Id) {
    mutate(getSWRKeyForAllCollectionSpotsFromGuide(guideId));
}

export const useAllCollectionSpotsFromGuide = (guideId: Id) => {
    const {
        data,
        isValidating,
        error,
        mutate,
    } = useSWR(
        getSWRKeyForAllCollectionSpotsFromGuide(guideId),
        () => getAllCollectionSpotsFromGuide(guideId),
        { revalidateOnFocus: false },
    );

    return {
        isLoading: !data && isValidating,
        spots: data,
        errorLoading: error,
        mutate,
    };
};
