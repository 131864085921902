// @ts-strict
import { BASE_URL } from 'constants/env';

const DEFAULT_OPTIONS: RequestInit = {
    credentials: 'include',
};

const VERSION_HEADERS = {
    'Polarsteps-Api-Version': 15,
};

interface FetchOptions extends RequestInit {
    returnPlainResponse?: boolean;
}

const doFetch = <T>(
    url: RequestInfo | URL,
    options: FetchOptions = {},
): Promise<T> => {
    const optionsToUse = {
        ...DEFAULT_OPTIONS,
        ...options,
    };
    optionsToUse.headers = {
        ...getVersionHeaders(url),
        ...(optionsToUse.headers || {}),
    };

    return fetch(url, optionsToUse).then((response) =>
        processFetchResponse<T>(response),
    );
};

const doFetchPlainResponse = (
    url: RequestInfo | URL,
    options: FetchOptions = {},
): Promise<Response> => {
    const optionsToUse = {
        ...DEFAULT_OPTIONS,
        ...options,
    };
    optionsToUse.headers = {
        ...getVersionHeaders(url),
        ...(optionsToUse.headers || {}),
    };

    return fetch(url, optionsToUse).then((response) =>
        processFetchPlainResponse(response),
    );
};

/**
 * Checks if Polarsteps version headers should be send for a specific URL
 */
export const shouldAddVersionHeaders = (url: RequestInfo | URL) => {
    if (!url) {
        return false;
    }
    const re = new RegExp('^(http|https)://', 'i');
    const isExternalRoute = re.test(url.toString());
    if (!isExternalRoute) {
        return true;
    }
    const allowedUrls = [BASE_URL];
    // eslint-disable-next-line no-restricted-syntax
    for (const allowedUrl of allowedUrls) {
        if (allowedUrl && url.toString().indexOf(allowedUrl) === 0) {
            return true;
        }
    }
    return false;
};

export const get = <T>(url: RequestInfo | URL, options: FetchOptions = {}) => {
    const optionsToUse: FetchOptions = {
        ...DEFAULT_OPTIONS,
        ...options,
        mode: 'cors',
    };
    optionsToUse.headers = {
        ...getVersionHeaders(url),
        ...(optionsToUse.headers || {}),
    };
    return doFetch<T>(url, {
        ...optionsToUse,
        method: 'GET',
    });
};

export const post = <T>(
    url: RequestInfo | URL,
    params = {},
    options: FetchOptions = {},
) => {
    const { headers: customHeaders, ...restOptions } = options;

    return doFetch<T>(url, {
        ...restOptions,
        headers: { 'Content-Type': 'application/json', ...customHeaders },
        method: 'POST',
        body: JSON.stringify(params),
    });
};

export const makeCancelablePost = <T>(
    url: RequestInfo | URL,
    params = {},
    options = {},
) => {
    let abortController: AbortController | null = new AbortController();
    const signal = abortController.signal;

    return {
        result: post<T>(url, params, { ...options, signal }),
        canceler: () => {
            if (abortController) {
                abortController.abort();
                abortController = null;
            }
        },
    };
};

export const patch = <T>(
    url: RequestInfo | URL,
    params = {},
    options: FetchOptions = {},
): Promise<T> => {
    const { headers: customHeaders, ...restOptions } = options;

    return doFetch<T>(url, {
        headers: { 'Content-Type': 'application/json', ...customHeaders },
        method: 'PATCH',
        body: JSON.stringify(params),
        ...restOptions,
    });
};

export const postDelete = (
    url: RequestInfo | URL,
    options: FetchOptions = {},
): Promise<Response> => {
    const { headers: customHeaders, ...restOptions } = options;

    return doFetchPlainResponse(url, {
        ...restOptions,
        headers: { 'Content-Type': 'application/json', ...customHeaders },
        method: 'DELETE',
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postLikeFormAndDontProcess = <T extends Record<string, any>>(
    url: RequestInfo | URL,
    data: T,
    options: FetchOptions = {},
) => {
    return fetch(url, {
        ...DEFAULT_OPTIONS,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: createFormParams<T>(data),
        ...options,
        method: 'POST',
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postLikeForm = <T extends Record<string, any>, R>(
    url: RequestInfo | URL,
    data: T,
    options = {},
) => {
    return postLikeFormAndDontProcess<T>(url, data, options).then((response) =>
        processFetchResponse<R>(response),
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createFormParams<T extends Record<string, any>>(data: T) {
    return Object.keys(data)
        .map((key) => {
            return (
                encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            );
        })
        .join('&');
}

function processFetchPlainResponse(response: Response): Promise<Response> {
    if (!response.ok) {
        return Promise.reject(response);
    }

    return Promise.resolve(response);
}

function processFetchResponse<T>(response: Response): Promise<T> {
    if (!response.ok) {
        return Promise.reject(response);
    }

    if (response.status === 204) {
        return Promise.resolve({} as T);
    }

    return response.json();
}

function getVersionHeaders(url: RequestInfo | URL) {
    if (shouldAddVersionHeaders(url)) {
        return VERSION_HEADERS;
    }
    return {};
}
