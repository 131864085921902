import {
    GUIDE_CUSTOM_LOCATION_MAX_LENGTH,
    GUIDE_GOOD_TO_KNOW_DESCRIPTION_MAX_LENGTH,
    GUIDE_HIGHLIGHT_DESCRIPTION_MAX_LENGTH,
    GUIDE_HIGHLIGHT_TITLE_MAX_LENGTH,
    GUIDE_NUMBER_OF_DAYS_DESCRIPTION_MAX_LENGTH,
    GUIDE_NUMBER_OF_DAYS_TITLE_MAX_LENGTH,
    GUIDE_TAGLINE_MAX_LENGTH,
} from 'constants/fields-limits';
import { every } from 'lodash';
import { getGoodToKnowItemsForGuide } from 'store/good-to-know-items/selectors';
import { getHighlightsForGuide } from 'store/highlight-items/selectors';
import { getNumberOfDaysItemsForGuide } from 'store/number-of-days-items/selectors';
import { getStoriesForGuide } from 'store/story-items/selectors';
import { isSameObject } from 'utils/compare';
import { areQuickFactsCorrect } from 'utils/guide-utils';
import { getChangesBetweenItemSets } from 'utils/sortable-item-utils';

/**
 * Gets the current guide being edited
 *
 * @param {RootState} state
 * @returns {Guide}
 */
export const getCurrentGuide = (state) => {
    return state.guideEditor.currentGuide;
};

/**
 * Checks if the editor is shown to add a guide (as opposed to editing it)
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const isAddingGuide = (state) => {
    return state.guideEditor.addingGuide;
};

/**
 * Checks if a new guide is being saved at the moment
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const isSavingNewGuide = (state) => {
    return state.guideEditor.savingNewGuide;
};

/**
 * Checks if a guide is being patched at the moment
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const isPatchingGuide = (state) => {
    return state.guideEditor.patchingGuide;
};

/**
 * Checks if a guide is correct enough to be saved (contains all the data it needs)
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const isGuideCorrect = (state) => {
    const guide = getCurrentGuide(state);

    return (
        !!guide &&
        !!guide.location &&
        !!guide.tagline &&
        guide.tagline.length <= GUIDE_TAGLINE_MAX_LENGTH &&
        guide.location_name_custom.length <= GUIDE_CUSTOM_LOCATION_MAX_LENGTH &&
        areQuickFactsCorrect(guide.quick_facts)
    );
};

/**
 * Gets the original guide being edited (without modifications)
 *
 * @param {RootState} state
 * @returns {Guide}
 */
export const getOriginalGuide = (state) => {
    if (!state.guideEditor.originalGuideId) {
        return null;
    }
    return state.guides.byId[state.guideEditor.originalGuideId] || null;
};

/**
 * Checks if a guide being edited has changed compared to the original (always true if we are adding a new guide)
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const hasGuideChanged = (state) => {
    if (isAddingGuide(state)) {
        return true;
    }
    const current = getCurrentGuide(state);
    const original = getOriginalGuide(state);

    if (!current || !original) {
        return true;
    }

    return (
        !isSameObject(current.location, original.location) ||
        !isSameObject(current.cover_image, original.cover_image) ||
        !isSameObject(current.guide_type, original.guide_type) ||
        !isSameObject(current.guide_ranking, original.guide_ranking) ||
        !isSameObject(current.main_editor, original.main_editor) ||
        current.main_editor_id !== original.main_editor_id ||
        !isSameObject(current.publish_status, original.publish_status) ||
        current.location_name_custom !== original.location_name_custom ||
        current.tagline !== original.tagline ||
        current.description !== original.description ||
        current.editor_notes !== original.editor_notes ||
        JSON.stringify(current.quick_facts) !==
            JSON.stringify(original.quick_facts) ||
        current.reviewed_at !== original.reviewed_at ||
        current.orientation_content_only !==
            original.orientation_content_only ||
        current.meta_description !== original.meta_description
    );
};

/**
 * Checks if a guide being edited has changed compared to the original (always true if we are adding a new guide)
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const haveGuideNumberOfDaysHeaderChanged = (state) => {
    if (isAddingGuide(state)) {
        return true;
    }
    const current = getCurrentGuide(state);
    const original = getOriginalGuide(state);

    if (!current || !original) {
        return true;
    }

    return current.itinerary_header !== original.itinerary_header;
};

/**
 * Checks if a guide being edited has changed the location to the original
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const hasGuideChangedLocation = (state) => {
    if (isAddingGuide(state)) {
        return false;
    }
    const current = getCurrentGuide(state);
    const original = getOriginalGuide(state);

    if (!current || !original) {
        return true;
    }

    return !isSameObject(current.location, original.location);
};

/**
 * Checks if the spot collection editor is being shown for ANY spot collection
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const isShowingSpotCollectionEditor = (state) => {
    return state.guideEditor.showingSpotCollectionEditor;
};

/**
 * Gets the current stories being edited for a guide
 *
 * @param {RootState} state
 * @returns {StoryItem[]}
 */
export const getCurrentStoriesBeingEdited = (state) => {
    return state.guideEditor.currentStories || [];
};

/**
 * Gets the current highlights being edited for a guide
 *
 * @param {RootState} state
 * @returns {HighlightItem[]}
 */
export const getCurrentHighlightsBeingEdited = (state) => {
    return state.guideEditor.currentHighlights || [];
};

/**
 * Gets the current good to know items being edited for a guide
 *
 * @param {RootState} state
 * @returns {GoodToKnowItem[]}
 */
export const getCurrentGoodToKnowItemsBeingEdited = (state) => {
    return state.guideEditor.currentGoodToKnowItems || [];
};

/**
 * Gets the current number of days items being edited for a guide
 *
 * @param {RootState} state
 * @returns {NumberOfDaysItem[]}
 */
export const getCurrentNumberOfDaysItemsBeingEdited = (state) => {
    return state.guideEditor.currentNumberOfDaysItems || [];
};

/**
 * Checks if the stories being edited have changed from the originals
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const haveStoriesChanged = (state) => {
    const currentGuide = getCurrentGuide(state);
    if (!currentGuide?.id) {
        return false;
    }
    const original = getStoriesForGuide(state, currentGuide.id);
    const current = getCurrentStoriesBeingEdited(state);
    if (original === null || current === null) {
        return false;
    }
    const changes = getChangesBetweenItemSets(current, original);

    return !!(
        changes.deletedItems?.length ||
        changes.editedItems?.length ||
        changes.newItems?.length
    );
};

/**
 * Checks if the highlights being edited have changed from the originals
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const haveHighlightsChanged = (state) => {
    const currentGuide = getCurrentGuide(state);
    if (!currentGuide?.id) {
        return false;
    }
    const original = getHighlightsForGuide(state, currentGuide.id);
    const current = getCurrentHighlightsBeingEdited(state);
    if (original === null || current === null) {
        return false;
    }
    const changes = getChangesBetweenItemSets(current, original);

    return !!(
        changes.deletedItems?.length ||
        changes.editedItems?.length ||
        changes.newItems?.length
    );
};

/**
 * Checks if the good to know items being edited have changed from the originals
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const haveGoodToKnowItemsChanged = (state) => {
    const currentGuide = getCurrentGuide(state);
    if (!currentGuide?.id) {
        return false;
    }
    const original = getGoodToKnowItemsForGuide(state, currentGuide.id);
    const current = getCurrentGoodToKnowItemsBeingEdited(state);
    if (original === null || current === null) {
        return false;
    }
    const changes = getChangesBetweenItemSets(current, original);

    return !!(
        changes.deletedItems?.length ||
        changes.editedItems?.length ||
        changes.newItems?.length
    );
};

/**
 * Checks if the number of days items being edited have changed from the originals
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const haveNumberOfDaysItemsChanged = (state) => {
    const currentGuide = getCurrentGuide(state);
    if (!currentGuide?.id) {
        return false;
    }
    const original = getNumberOfDaysItemsForGuide(state, currentGuide.id);
    const current = getCurrentNumberOfDaysItemsBeingEdited(state);
    if (original === null || current === null) {
        return false;
    }
    const changes = getChangesBetweenItemSets(current, original);

    return !!(
        changes.deletedItems?.length ||
        changes.editedItems?.length ||
        changes.newItems?.length
    );
};

/**
 * Checks if the stories being edited are filled with needed data
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const areStoriesComplete = (state) => {
    const currentStories = getCurrentStoriesBeingEdited(state);
    if (!currentStories) {
        return true;
    }
    return every(currentStories, isStoryComplete);
};

/**
 * Checks if the good to know items being edited have errors
 *
 * @param {RootState} state
 * @returns {{hasErrors: boolean,errors: FieldErrors}}
 */
export const doGoodToKnowItemsHaveErrors = (state) => {
    const currentGoodToKnowItems = getCurrentGoodToKnowItemsBeingEdited(state);

    const errors = currentGoodToKnowItems.reduce((acc, item) => {
        const itemErrors = doesGoodToKnowItemHaveErrors(item);

        if (Object.keys(itemErrors).length) {
            return { ...acc, [item.id]: itemErrors };
        }

        return acc;
    }, {});

    return { hasErrors: !!Object.keys(errors).length, errors };
};

/**
 * Checks if the highlights being edited have errors
 *
 * @param {RootState} state
 * @returns {{hasErrors: boolean,errors: FieldErrors}}
 */
export const doHighlightsHaveErrors = (state) => {
    const currentHighlights = getCurrentHighlightsBeingEdited(state);

    const errors = currentHighlights.reduce((acc, item) => {
        const itemErrors = doesHighlightHaveErrors(item, state);

        if (Object.keys(itemErrors).length) {
            return { ...acc, [item.id]: itemErrors };
        }

        return acc;
    }, {});

    return { hasErrors: !!Object.keys(errors).length, errors };
};

/**
 * Checks if the highlights being edited have errors
 *
 * @param {RootState} state
 * @returns {{hasErrors: boolean,errors: FieldErrors}}
 */
export const doNumberOfDaysHaveErrors = (state) => {
    const currentNumberOfDaysItems = getCurrentNumberOfDaysItemsBeingEdited(
        state,
    );

    const errors = currentNumberOfDaysItems.reduce((acc, item) => {
        const itemErrors = doesNumberOfDaysItemHaveError(item);

        if (Object.keys(itemErrors).length) {
            return { ...acc, [item.id]: itemErrors };
        }

        return acc;
    }, {});

    return { hasErrors: !!Object.keys(errors).length, errors };
};

/**
 * Checks if a story is complete. The first story does not need a tagline.
 *
 * @param {StoryItem} story
 * @param {number} index
 * @returns {boolean}
 */
function isStoryComplete(story, index) {
    if (index === 0) {
        return !!story.cms_media;
    }
    return !!story.cms_media && !!story.tagline;
}

/**
 * Checks if a good to know item has error.
 *
 * @param {GoodToKnowItem} goodToKnowItem
 * @returns {FieldError}
 */
function doesGoodToKnowItemHaveErrors(goodToKnowItem) {
    /** @type {FieldError} */
    const fieldError = {};

    if (!goodToKnowItem.title) fieldError['title'] = 'Title is required';
    if (!goodToKnowItem.color_code)
        fieldError['color_code'] = 'Color is required';
    if (!goodToKnowItem.description)
        fieldError['description'] = 'Description is required';
    if (
        (goodToKnowItem.description?.length ?? 0) >
        GUIDE_GOOD_TO_KNOW_DESCRIPTION_MAX_LENGTH
    )
        fieldError[
            'description'
        ] = `Description should be less than ${GUIDE_GOOD_TO_KNOW_DESCRIPTION_MAX_LENGTH} characters`;

    return fieldError;
}

/**
 * Checks if a highlight has error.
 *
 * @param {HighlightItem} highlight
 * @param {RootState} state

 * @returns {FieldError}
 */
function doesHighlightHaveErrors(highlight, state) {
    const currentGuide = getCurrentGuide(state);
    /** @type {FieldError} */
    const fieldError = {};

    if (!highlight.title) fieldError['title'] = 'Title is required';
    if ((highlight.title?.length ?? 0) > GUIDE_HIGHLIGHT_TITLE_MAX_LENGTH)
        fieldError['title'] = 'Title is required';
    if (!highlight.description)
        fieldError['description'] = 'Description is required';
    if (!currentGuide.orientation_content_only && !highlight.spot)
        fieldError['spot'] = 'Spot is required';
    if (
        (highlight.description?.length ?? 0) >
        GUIDE_HIGHLIGHT_DESCRIPTION_MAX_LENGTH
    )
        fieldError[
            'description'
        ] = `Description should be less than ${GUIDE_HIGHLIGHT_DESCRIPTION_MAX_LENGTH} characters`;

    return !!highlight && fieldError;
}

/**
 * Checks if a number of days item has error.
 *
 * @param {NumberOfDaysItem} numberOfDaysItem

 * @returns {FieldError}
 */
function doesNumberOfDaysItemHaveError(numberOfDaysItem) {
    /** @type {FieldError} */
    const fieldError = {};

    if (!numberOfDaysItem.title) fieldError['title'] = 'Title is required';
    if (
        (numberOfDaysItem.title?.length ?? 0) >
        GUIDE_NUMBER_OF_DAYS_TITLE_MAX_LENGTH
    )
        fieldError['title'] = 'Title is required';
    if (
        (numberOfDaysItem.description?.length ?? 0) >
        GUIDE_NUMBER_OF_DAYS_DESCRIPTION_MAX_LENGTH
    )
        fieldError[
            'description'
        ] = `Description should be less than ${GUIDE_NUMBER_OF_DAYS_DESCRIPTION_MAX_LENGTH} characters`;

    return !!numberOfDaysItem && fieldError;
}

/**
 * @param {RootState} state
 * @returns {boolean}
 */
export const isOrientationContentOnly = (state) => {
    return !!state.guideEditor.currentGuide?.orientation_content_only;
};
