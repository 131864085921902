import { BASE_URL } from 'constants/env';
import { get, patch, post, postDelete } from 'utils/http';
import { deleteOldCMSMediaIfChanged } from './cms-media.api';

type HighlightsResponse = {
    num_results: number;
    highlights: HighlightItem[];
};

const defaultOptions = {
    headers: {
        'Polarsteps-Api-Version': '53',
    },
};

/**
 * Gets all the highlight items associated with a guide
 */
export const getHighlightsForGuide = (
    guideId: Id | undefined,
): Promise<HighlightItem[]> => {
    return get<HighlightsResponse>(
        `${BASE_URL}/cms/guides/${guideId}/highlights`,
        defaultOptions,
    ).then((result) => {
        return result.highlights;
    });
};

/**
 * Adds a highlight item to the database
 */
export const addHighlight = async (
    highlight: HighlightItem,
): Promise<HighlightItem> => {
    return post(
        `${BASE_URL}/cms/guides/highlights`,
        toDBFormat(highlight),
        defaultOptions,
    );
};

/**
 * Patches a highlight in the database
 */
export const patchHighlight = async (
    newHighlight: HighlightItem,
    oldHighlight?: HighlightItem,
): Promise<HighlightItem> => {
    const patchedHighlightItem = await patch<HighlightItem>(
        `${BASE_URL}/cms/guides/highlights/${newHighlight.id}`,
        toDBFormat(newHighlight),
        defaultOptions,
    );

    deleteOldCMSMediaIfChanged(
        patchedHighlightItem.cms_media,
        oldHighlight?.cms_media ?? null,
    );

    return patchedHighlightItem;
};

/**
 * Removes a highlight item to the database
 */
export const deleteHighlight = async (highlight: HighlightItem) => {
    const deletionResult = await postDelete(
        `${BASE_URL}/cms/guides/highlights/${highlight.id}`,
        {
            returnPlainResponse: true,
            ...defaultOptions,
        },
    );
    deleteOldCMSMediaIfChanged(null, highlight.cms_media);
    return deletionResult;
};

/**
 * Converts a highlight item to the format needed for the DB
 */
function toDBFormat(highlight: HighlightItem): DBHighlightItem {
    return {
        guide_id: highlight.guide_id,
        cms_media_id: highlight.cms_media?.id || undefined,
        spot_id: highlight.spot?.id || undefined,
        title: highlight.title,
        description: highlight.description,
        order: highlight.order,
        editor_notes: highlight.editor_notes,
    };
}
