import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useSelector } from 'utils/use-selector';
import { Button, Typography } from 'antd';
import { CameraOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import ResponsiveModal from 'components/ResponsiveModal';
import { notifySpotCollectionEditorShown } from 'store/guide-editor/actions';
import { useLabelTypes } from 'utils/collection-spot-label-utils';
import { getMediaWithSize } from 'utils/media-utils';
import CollectionSpotEditor from './CollectionSpotEditor';
import CollectionSpotTip from './CollectionSpotTip';
import SpotCategory from './SpotCategory';
import { SpotLabel } from './SpotLabel';
import { getGuideById } from 'store/guides/selectors';
import './style.css';

const { Paragraph } = Typography;

/**
 *
 * @param {Object} props
 * @param {CollectionSpot} props.collectionSpot
 * @param {Collection} props.collection
 * @param {boolean} props.isDimmed
 */
function CollectionSpot({ collectionSpot, collection, isDimmed = false }) {
    const guide = useSelector((state) =>
        getGuideById(state, collection.guide_id),
    );
    const [isShowingEditPopup, showEditPopup] = useState(false);
    const [
        shouldShowImageSelectorFirst,
        setShouldShowImageSelectorFirst,
    ] = useState(false);
    const dispatch = useDispatch();
    const { labelTypes } = useLabelTypes();

    const notifyShowEditPopup = (shouldBeShown) => {
        showEditPopup(shouldBeShown);
        if (!shouldBeShown) {
            setShouldShowImageSelectorFirst(false); // We reset this var so next time the popup is open we choose if we want to show it or not
        }
        dispatch(notifySpotCollectionEditorShown(shouldBeShown));
    };

    const closestSizedMedia = getMediaWithSize(
        collectionSpot.featured_photo,
        390,
    );

    const labels = collectionSpot.labels
        .map(
            (label) =>
                labelTypes.find(
                    ({ id }) => id === label.collection_spot_label_type_id,
                )?.name,
        )
        .filter((x) => x);

    return (
        <div
            className={classNames('collection-spot', { 'is-dimmed': isDimmed })}
        >
            <div
                className="collection-spot-meta"
                onClick={() => notifyShowEditPopup(true)}
            >
                {collectionSpot.ready_for_review && (
                    <div className="collection-spot-review-banner">
                        Ready for review
                    </div>
                )}
                <div className="collection-spot-labels">
                    <div className="collection-spot-label">
                        <SpotCategory spot={collectionSpot.spot} />
                    </div>
                    {labels.map((label) => (
                        <div className="collection-spot-label" key={label}>
                            <SpotLabel name={label} />
                        </div>
                    ))}
                </div>
                <div className="collection-spot-controls">
                    <Button
                        size="small"
                        icon={<CameraOutlined />}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShouldShowImageSelectorFirst(true);
                            notifyShowEditPopup(true);
                        }}
                    />
                </div>
            </div>
            <div
                className="collection-spot-content"
                onClick={() => notifyShowEditPopup(true)}
            >
                <div className="collection-spot-image">
                    {closestSizedMedia && (
                        <img
                            className="collection-spot-image-img"
                            src={closestSizedMedia.path}
                            alt={collectionSpot.name}
                        />
                    )}
                    <div className="collection-spot-image-decorator" />
                </div>
                <div className="collection-spot-title">
                    <Paragraph
                        ellipsis={{ rows: 1, expandable: false }}
                        className="collection-spot-paragraph collection-spot-title-text"
                    >
                        {collectionSpot.name}
                    </Paragraph>
                </div>
                <CollectionSpotTip collectionSpot={collectionSpot} />
            </div>
            {!collectionSpot.published && (
                <div
                    className="collection-spot__unpublished-overlay"
                    onClick={() => notifyShowEditPopup(true)}
                >
                    <EyeInvisibleOutlined style={{ fontSize: 24 }} />
                    <div className="mt-s">Unpublished spot</div>
                </div>
            )}

            {!!collection && (
                <ResponsiveModal
                    className="collection-spot-editor__modal"
                    open={isShowingEditPopup}
                    footer={null}
                    width={1024}
                    onCancel={() => notifyShowEditPopup(false)}
                    maskClosable={true}
                    closable={false}
                >
                    <CollectionSpotEditor
                        visible={isShowingEditPopup}
                        shouldShowImageSelectorFirst={
                            shouldShowImageSelectorFirst
                        }
                        originalCollectionSpot={collectionSpot}
                        guide={guide}
                        onClose={() => notifyShowEditPopup(false)}
                    />
                </ResponsiveModal>
            )}
        </div>
    );
}

export default CollectionSpot;
