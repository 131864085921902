import React from 'react';
import GenericSelectorSWR from 'components/GenericSelectorSWR';
import {
    COLLECTION_TYPES_SWR_KEY,
    getAllCollectionTypes,
} from 'api/collection-types.api';

/**
 *
 * @param {Object} props
 * @param {Id} props.value
 * @param {(newVal?: Id) => void} props.onChange
 * @param {import('antd/lib/config-provider/SizeContext').SizeType} [props.size]
 * @param {boolean} [props.allowClear]
 * @param {string} [props.placeholder]
 */
function CollectionTypeSelector({
    value,
    onChange,
    size,
    allowClear = false,
    placeholder = 'Select',
}) {
    return (
        <GenericSelectorSWR
            value={value}
            onChange={onChange}
            swrKey={COLLECTION_TYPES_SWR_KEY}
            fetcher={getAllCollectionTypes}
            placeholder={placeholder}
            allowClear={allowClear}
            size={size}
        ></GenericSelectorSWR>
    );
}

export default CollectionTypeSelector;
