import { useCallback } from 'react';

export type ListItem = {
    id: Id;
    order: number;
};

export type ListData<T> = T & ListItem;

export const useSort = <T extends ListItem>(items: ListData<T>[]) => {
    const handleMoveUp = useCallback(
        (item: ListData<T>) => {
            const itemToMove = items.find(({ id }) => id === item.id);
            if (!itemToMove || itemToMove.order <= 0) {
                return null;
            }
            const prevItem = items.find(
                (story) => story.order === itemToMove.order - 1,
            );

            if (!prevItem) return null;

            return [
                {
                    id: itemToMove.id,
                    newOrder: itemToMove.order - 1,
                },
                {
                    id: prevItem?.id,
                    newOrder: itemToMove.order,
                },
            ];
        },
        [items],
    );

    const handleMoveDown = useCallback(
        (item: ListData<T>) => {
            const itemToMove = items.find(({ id }) => id === item.id);
            if (!itemToMove || itemToMove.order >= items.length - 1) {
                return null;
            }
            const nextItem = items.find(
                (story) => story.order === itemToMove.order + 1,
            );

            if (!nextItem) return null;

            return [
                {
                    id: itemToMove.id,
                    newOrder: itemToMove.order + 1,
                },
                {
                    id: nextItem?.id,
                    newOrder: itemToMove.order,
                },
            ];
        },
        [items],
    );

    return {
        onMoveUp: handleMoveUp,
        onMoveDown: handleMoveDown,
    };
};
