import { createApi } from 'unsplash-js';
import { get } from 'utils/http';
import { BASE_URL } from 'constants/env';

/**
 * @param {string} query
 * @param {MediaOrientationSelectorValue} [orientation = 'all']
 * @param {number} [page=1]
 * @returns {Promise<{ hasMore: boolean, currentPage: number, results: UnsplashPhotoFromSearch[]}>}
 */
export const search = async (query, orientation = 'all', page = 1) => {
    const resultsPerPage = 30;
    const api = await getApi();

    const result = await api.search.getPhotos({
        query,
        per_page: resultsPerPage,
        page,
        orientation: orientation !== 'all' ? orientation : undefined,
    });
    if (result.errors) {
        throw new Error(result.errors.join('; '));
    }

    return {
        results: result.response?.results || [],
        currentPage: page,
        hasMore: page < (result.response?.total_pages || 1),
    };
};

let api = null;
async function getApi() {
    if (!api) {
        const accessKey = await get(`${BASE_URL}/cms/unsplash/key`);
        api = createApi({
            accessKey,
        });
    }
    return api;
}
