import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import Flag from 'components/Flag';
import { languageToCountry } from 'utils/country-language-converter';
import './style.css';

const { Option } = Select;

class LanguageSelector extends React.Component {
    /**
     * @param {Object} props
     * @param {string[]} props.allowedLanguages
     * @param {string} [props.selectedLanguage]
     * @param {(x: string) => void} props.onSelectLanguage
     */
    props;

    state = {
        /** @type {{value: string, language: string, countryCode: string}[]} */
        flagsToShow: null,
    };

    async componentDidMount() {
        const countries = await Promise.all(
            this.props.allowedLanguages.map(languageToCountry),
        );
        const flagsToShow = countries.map((countryCode, index) => {
            const language = this.props.allowedLanguages[index];
            return {
                value: language,
                language,
                countryCode,
            };
        });
        this.setState({ flagsToShow });
    }

    render() {
        if (!this.state.flagsToShow) {
            return null;
        }
        return (
            <Select
                onChange={(value) => this.props.onSelectLanguage(value)}
                value={this.props.selectedLanguage}
                className="language-selector"
            >
                {this.state.flagsToShow.map((flag) => (
                    <Option key={flag.value} value={flag.value}>
                        <div className="language-selector__value">
                            <Flag countryCode={flag.countryCode} />
                        </div>
                    </Option>
                ))}
            </Select>
        );
    }
}

LanguageSelector.propTypes = {
    allowedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedLanguage: PropTypes.string,
    onSelectLanguage: PropTypes.func.isRequired,
};

export default LanguageSelector;
