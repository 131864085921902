import React, { useState } from 'react';
import { Typography, Button, notification, Card, Spin, Tag } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useInView } from 'react-intersection-observer';
import { getName } from 'utils/collection-utils';
import {
    addCollectionSpot as addCollectionSpotToDB,
    refreshCollectionSpots,
    useCollectionSpots,
} from 'api/collection-spots.api';
import CollectionEditor from 'components/GuideEditor/CollectionEditor';
import SpotSelector from 'components/SpotSelector';
import CollectionStatus from './CollectionStatus';
import CollectionSpots from './CollectionSpots.jsx';
import {
    refreshTopCollectionsForGuide,
    patchCollection as patchCollectionInDB,
    refreshCollectionsForGuide,
    archiveCollection as archiveCollectionInDB,
} from 'api/collections.api';
import { useCurrentUser } from 'utils/use-current-user';
import { parse } from 'utils/error-parser';
import './style.css';

const { Text } = Typography;

type CollectionProps = {
    collection: Collection;
    guide: Guide;
    filterBySources: Source[];
};

function Collection({ collection, guide, filterBySources }: CollectionProps) {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const currentUser = useCurrentUser();
    const { collectionSpots } = useCollectionSpots(collection.id as Id);
    const [isAddingCollectionSpot, setIsAddingCollectionSpot] = useState(false);
    const [isPatchingCollection, setIsPatchingCollection] = useState(false);
    const [isBeingEdited, setIsBeingEdited] = useState(false);
    const [isBeingRemoved, setIsBeingRemoved] = useState(false);
    /** @type {[Collection, React.Dispatch<Collection>]} */
    const [originalBeingEdited, setOriginalBeingEdited] = useState({
        ...collection,
    });

    const startEditingCollection = () => {
        setIsBeingEdited(true);
        setOriginalBeingEdited({ ...collection });
    };
    const stopEditingCollection = () => {
        setIsBeingEdited(false);
    };

    const patchCollection = async (patchedCollection: Collection) => {
        try {
            setIsPatchingCollection(true);
            await patchCollectionInDB(patchedCollection);
            notification.success({
                message: 'The collection was patched successfully',
            });
            await refreshCollectionsForGuide(patchedCollection.guide_id);
            stopEditingCollection();
        } catch (error) {
            notification.error({
                message: 'Error patching collection',
                description: parse(error),
            });
        } finally {
            setIsPatchingCollection(false);
        }
    };

    const addCollectionSpot = async (spot: PSSpot_v15_Minimal) => {
        try {
            setIsAddingCollectionSpot(true);
            await addCollectionSpotToDB(guide, collection, spot, currentUser);
            notification.success({
                message: 'Spot added to collection',
            });
        } catch (error) {
            notification.error({
                message: 'Error adding spot to collection',
                description: parse(error),
            });
        } finally {
            await refreshCollectionSpots(
                collection.id as Id,
                collection.guide_id,
            );
            await refreshTopCollectionsForGuide(collection.guide_id);
            setIsAddingCollectionSpot(false);
        }
    };

    const dispatchRemoveCollection = async () => {
        try {
            setIsBeingRemoved(true);
            await archiveCollectionInDB(collection.id as Id);
            refreshCollectionsForGuide(collection.guide_id);
            refreshTopCollectionsForGuide(collection.guide_id);
            notification.success({
                message: 'The collection was removed successfully',
            });
            stopEditingCollection();
        } catch (error) {
            notification.error({
                message: 'Error deleting collection',
                description: parse(error),
            });
        } finally {
            setIsBeingRemoved(false);
        }
    };

    return (
        <div className="collection" ref={ref}>
            <Card
                bodyStyle={{ padding: 0 }}
                headStyle={{ paddingRight: 0, paddingLeft: 16 }}
                title={
                    <div className="collection__title">
                        <CollectionStatus
                            collection={collection}
                            className="mr-s"
                        />
                        <div className="collection__header-title">
                            {getName(collection)}

                            {!!collectionSpots?.length && (
                                <div className="ml-s">
                                    <Tag>{collectionSpots?.length} Spots</Tag>
                                </div>
                            )}

                            {collection.description && (
                                <>
                                    <Text
                                        className="ml-xs"
                                        type="secondary"
                                        ellipsis={true}
                                        title={collection.description}
                                    >
                                        {collection.description}
                                    </Text>
                                </>
                            )}
                            <Button
                                type="link"
                                icon={<EditOutlined />}
                                size="small"
                                className="ml-xs mr-s"
                                onClick={() => startEditingCollection()}
                            />
                        </div>
                    </div>
                }
                extra={
                    <div className="collection__header-spot-selector">
                        <SpotSelector
                            value={null}
                            onSelect={(spot) => addCollectionSpot(spot)}
                            location={guide.location}
                            guide={guide}
                            placeholder="Add a new spot"
                            disabled={isAddingCollectionSpot}
                            customSearchIcon={
                                <PlusOutlined style={{ color: '#1890FF' }} />
                            }
                            allowAddPolarstepsSpot={true}
                        />
                    </div>
                }
            >
                <div className="collection__content">
                    {isAddingCollectionSpot && (
                        <div className="collection__content-loading">
                            <Spin />
                        </div>
                    )}
                    {inView && (
                        <CollectionSpots
                            collection={collection}
                            filterBySources={filterBySources}
                        />
                    )}
                </div>
            </Card>
            {collection && (
                <CollectionEditor
                    isVisible={isBeingEdited}
                    isEditing={true}
                    onSave={patchCollection}
                    isLoading={isPatchingCollection}
                    isBeingDeleted={isBeingRemoved}
                    onDiscard={() => stopEditingCollection()}
                    originalCollection={originalBeingEdited}
                    onRemove={() => dispatchRemoveCollection()}
                />
            )}

            {isBeingEdited && <div className="collection-editing"></div>}
        </div>
    );
}

export default Collection;
