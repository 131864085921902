import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'antd';
import { getCurrentCollectionType } from 'store/collection-type-editor/selectors';
import FirstLevelSpotCategoriesSelector from 'components/SpotCategoriesSelector/FirstLevel';
import SecondLevelSpotCategoriesSelector from 'components/SpotCategoriesSelector/SecondLevel';
import { patchCollectionTypeField } from 'store/collection-type-editor/actions';
import FormField from 'components/FormField';

function CollectionTypeSettings() {
    const dispatch = useDispatch();
    const collectionType = useSelector(getCurrentCollectionType);

    if (!collectionType) {
        return null;
    }

    return (
        <Card title="Settings">
            <FormField label="polarsteps_1st_level_id">
                <FirstLevelSpotCategoriesSelector
                    value={collectionType.ps_category}
                    onChange={(newFirstLevelCategory) => {
                        dispatch(
                            patchCollectionTypeField('ps_subcategory', null),
                        );
                        dispatch(
                            patchCollectionTypeField(
                                'ps_category',
                                newFirstLevelCategory,
                            ),
                        );
                    }}
                />
            </FormField>

            <FormField label="polarsteps_2nd_level_id">
                <SecondLevelSpotCategoriesSelector
                    key={collectionType.ps_category}
                    value={collectionType.ps_subcategory}
                    parentCategoryId={collectionType.ps_category}
                    onChange={(newSecondLevelCategory) => {
                        dispatch(
                            patchCollectionTypeField(
                                'ps_subcategory',
                                newSecondLevelCategory,
                            ),
                        );
                    }}
                />
            </FormField>
        </Card>
    );
}

export default CollectionTypeSettings;
