import {
    CheckCircleFilled,
    DislikeOutlined,
    DownCircleOutlined,
    EditOutlined,
    LikeOutlined,
    PlusOutlined,
    RightCircleOutlined,
} from '@ant-design/icons';
import {
    Alert,
    Avatar,
    Button,
    notification,
    Select,
    Spin,
    Switch,
    Table,
} from 'antd';
import { addTip } from 'api/collection-spot-tips.api';
import {
    addCollectionSpot as addCollectionSpotToDB,
    refreshCollectionSpots,
    useGuideSpots,
} from 'api/collection-spots.api';
import {
    refreshTopCollectionsForGuide,
    useCollections,
} from 'api/collections.api';
import { patchSpot } from 'api/spots.api';
import LanguageFlag from 'components/LanguageFlag';
import ResponsiveModal from 'components/ResponsiveModal';
import { BASE_URL } from 'constants/env';
import { chain, keyBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCurrentGuide } from 'store/guide-editor/selectors';
import { getCollectionSpotTipFromStepSpot } from 'utils/collection-spot-tip-utils';
import { getName as getCollectionName } from 'utils/collection-utils';
import { parse } from 'utils/error-parser';
import { useCurrentUser } from 'utils/use-current-user';
import { useSelector } from 'utils/use-selector';
import useAllPublicStepSpots from 'utils/use-all-public-step-spots';
import CollectionSpotEditor from './CollectionSpot/CollectionSpotEditor';
import SpotCategory from './CollectionSpot/SpotCategory';
import './GuideEditorTips.css';

const ToggleExpandTable = ({ expandable, columns, dataSource, ...rest }) => {
    const [expandedKeys, setExpandedKeys] = useState(
        expandable.defaultExpandedRowKeys,
    );

    const allAvailableKeys = dataSource.map((e) => e.key);
    const processedColumns = [...columns];
    processedColumns.unshift({
        title: allAvailableKeys.every((e) => expandedKeys.includes(e)) ? (
            <DownCircleOutlined onClick={() => collapseAll()} />
        ) : (
            <RightCircleOutlined onClick={() => expandAll()} />
        ),
        key: 'expand',
        render: (_, record) =>
            expandedKeys.includes(record.key) ? (
                <DownCircleOutlined
                    onClick={() => toggleExpandedKeys(record.key)}
                />
            ) : (
                <RightCircleOutlined
                    onClick={() => toggleExpandedKeys(record.key)}
                />
            ),
    });

    const toggleExpandedKeys = (key) => {
        setExpandedKeys((prev) => {
            const outArr = [...prev];
            if (outArr.includes(key)) {
                return outArr.filter((e) => e !== key);
            } else {
                outArr.push(key);
                return outArr;
            }
        });
    };

    const expandAll = () => {
        setExpandedKeys(allAvailableKeys);
    };

    const collapseAll = () => {
        setExpandedKeys([]);
    };

    return (
        <Table
            columns={processedColumns}
            dataSource={dataSource}
            expandable={{
                ...expandable,
                expandedRowKeys: expandedKeys,
                expandIconColumnIndex: -1,
            }}
            {...rest}
        />
    );
};

export default function GuideEditorTips() {
    const currentGuide = useSelector(getCurrentGuide);
    // @ts-ignore
    const { id: guideId } = useParams();
    const [showReviewed, setShowReviewed] = useState(false);

    const { collections } = useCollections(guideId);
    const {
        guideSpots,
        refresh: refreshGuideSpots,
        error: errorLoadingGuideSpots,
    } = useGuideSpots(guideId);

    const collectionSpots = useMemo(() => keyBy(guideSpots, 'spot_id'), [
        guideSpots,
    ]);

    const {
        publicStepSpots,
        stepSpots,
        refreshPublicStepSpots,
        error: errorLoadingPublicStepSpots,
    } = useAllPublicStepSpots(guideId, showReviewed);

    const addedTips = chain(collectionSpots)
        .values()
        .flatMap((spot) => spot.tips)
        .value();

    if (errorLoadingGuideSpots) {
        return (
            <div className="column-center">
                <Alert
                    message="Error loading spots for this guide"
                    description={parse(errorLoadingGuideSpots)}
                    showIcon
                    type="error"
                />
                <div className="mt-sm"></div>
                <Button size="large" onClick={() => refreshGuideSpots()}>
                    Try again
                </Button>
            </div>
        );
    }

    if (
        (!publicStepSpots && !errorLoadingPublicStepSpots) ||
        typeof guideSpots === 'undefined'
    ) {
        return <Spin />;
    }

    const onSpotUpdate = () => refreshGuideSpots();

    const spots = chain(publicStepSpots).map('spot').keyBy('id').value();

    const source = stepSpots.map(([spotId, steps]) => {
        const spot = spots[/** @type {string} */ (spotId)];

        return {
            key: spotId,
            Spot: (
                <div className="guide-editor-tips-table__spot-title">
                    <div className="guide-editor-tips-table__spot-name mr-sm">
                        {spot.name}
                    </div>
                    <SpotCategory spot={spot} />
                </div>
            ),
            Collection:
                Array.isArray(collections) && collections.length === 0 ? (
                    'No collection available'
                ) : collections ? (
                    <span className="guide-editor-tips-table__collection-column">
                        <AddToCollectionButton
                            collections={collections}
                            spot={spot}
                            guide={currentGuide}
                            collectionSpot={
                                collectionSpots &&
                                collectionSpots[/** @type {string} */ (spotId)]
                            }
                            onSuccess={() => onSpotUpdate()}
                            onDelete={() => onSpotUpdate()}
                        />
                    </span>
                ) : null,
            Review: (
                <span className="guide-editor-tips-table__collection-column">
                    <Button
                        className={
                            spot.is_reviewed
                                ? 'guide-editor-tips-spot-reviewed'
                                : ''
                        }
                        icon={
                            spot.is_reviewed ? (
                                <LikeOutlined />
                            ) : (
                                <DislikeOutlined />
                            )
                        }
                        onClick={() => {
                            patchSpot({
                                spot_id: /** @type {string} */ (spotId),
                                is_reviewed: !spot.is_reviewed,
                            }).then(() => {
                                refreshPublicStepSpots();
                            });
                        }}
                    >
                        {spot.is_reviewed ? 'Reviewed' : 'Mark as reviewed'}
                    </Button>
                </span>
            ),
            steps: /** @type {StepSpot[]} */ (steps).map((step) => ({
                key: step.id,
                Tip: (
                    <span className="ml-sm">
                        <LanguageFlag language={step.language} />
                        <span className="ml-sm">{step.tip}</span>
                    </span>
                ),
                Date: step.creation_time && (
                    <div className="guide-editor-tips__date">
                        {new Intl.DateTimeFormat('en-UK', {
                            dateStyle: 'medium',
                        }).format(new Date(step.creation_time))}
                    </div>
                ),
                User: step.user && (
                    <div className="guide-editor-tips__user">
                        <Avatar
                            size={24}
                            src={step.user.profile_image_thumb_path}
                        />
                        <a
                            href={`${BASE_URL}/${step.user.username}/${step.trip?.id}-${step.trip?.slug}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span className="ml-s guide-editor-tips__user-name">
                                {`${step.user.first_name} ${step.user.last_name}`}
                            </span>
                        </a>
                    </div>
                ),
                Collection: addedTips.find(
                    (tip) => tip.step_spot_id === step.id,
                ) ? (
                    <span style={{ fontSize: 14, color: '#52C41A' }}>
                        <CheckCircleFilled className="mr-xs" />
                        Tip added
                    </span>
                ) : (
                    <AddTipButton
                        collectionSpot={
                            collectionSpots[/** @type {string} */ (spotId)]
                        }
                        step={step}
                        onSuccess={() => onSpotUpdate()}
                    />
                ),
                Category: spot.category_new + spot.sub_category,
            })),
        };
    });

    const columns = [
        {
            title: 'Spot name',
            dataIndex: 'Spot',
            key: 'Spot',
            width: '60%',
        },

        {
            title: 'Collection',
            dataIndex: 'Collection',
            key: 'Collection',
            width: '15%',
        },
        {
            title: (
                <>
                    Show reviewed
                    <span className="ml-s">
                        <Switch
                            size="small"
                            checked={showReviewed}
                            onChange={() => {
                                setShowReviewed(!showReviewed);
                            }}
                        />
                    </span>
                </>
            ),
            dataIndex: 'Review',
            key: 'Review',
            width: '20%',
        },
    ];
    const expandedRowRender = ({ steps }) => {
        const columns = [
            {
                title: 'Tip',
                dataIndex: 'Tip',
                key: 'Tip',
                width: '40%',
            },
            {
                title: 'Date',
                dataIndex: 'Date',
                key: 'Date',
                width: '10%',
            },
            {
                title: 'User',
                dataIndex: 'User',
                key: 'User',
                width: '20%',
            },
            {
                title: 'Collection',
                dataIndex: 'Collection',
                key: 'Collection',
            },
        ];
        return (
            <Table
                className="guide-editor-tips-nested-table"
                indentSize={0}
                columns={columns}
                dataSource={steps}
                pagination={false}
            />
        );
    };

    return (
        <div className="guide-editor-tips-table">
            <ToggleExpandTable
                key={`${showReviewed}`}
                indentSize={0}
                columns={columns}
                dataSource={source}
                pagination={false}
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: Object.keys(spots),
                }}
            />
        </div>
    );
}

/**
 *
 * @param {Object} props
 * @param {Collection[]} props.collections
 * @param {Guide} props.guide
 * @param {PSSpot_v15_Minimal} props.spot
 * @param {() => Promise<any>} props.onSuccess
 * @param {() => void} props.onDelete
 * @param {CollectionSpot} props.collectionSpot
 */
function AddToCollectionButton({
    collections,
    spot,
    guide,
    onSuccess,
    onDelete,
    collectionSpot,
}) {
    const [isAdding, setIsAdding] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(null);

    const currentUser = useCurrentUser();
    const collectionId = collectionSpot?.collection_id;
    if (typeof collectionId !== 'undefined') {
        return (
            <>
                <div className="line">
                    <Button
                        type="primary"
                        size="small"
                        icon={<EditOutlined />}
                        onClick={() => {
                            setIsEditing(true);
                        }}
                    ></Button>
                    <div className="ml-s">Edit spot</div>
                </div>

                {isEditing && (
                    /* We unmount this component to make sure its
                       componentDidMount gets fired each time to prevent
                       displaying stale data */
                    <ResponsiveModal
                        className="collection-spot-editor__modal"
                        open={true}
                        footer={null}
                        width={1024}
                        onCancel={() => {
                            setIsEditing(false);
                        }}
                        maskClosable={true}
                        closable={false}
                    >
                        <CollectionSpotEditor
                            visible={true}
                            shouldShowImageSelectorFirst={false}
                            originalCollectionSpot={collectionSpot}
                            guide={guide}
                            onClose={() => {
                                setIsEditing(false);
                                onSuccess();
                            }}
                            onDelete={() => {
                                setIsEditing(false);
                                onDelete();
                            }}
                        />
                    </ResponsiveModal>
                )}
            </>
        );
    }
    if (isAdding) {
        const addCollectionSpot = async (collectionId) => {
            const collection = collections.find(
                (coll) => coll.id === collectionId,
            );

            if (!collection) {
                notification.error({
                    message: 'Error adding spot to collection',
                });
                return;
            }

            setIsLoading(true);
            try {
                await addCollectionSpotToDB(
                    guide,
                    collection,
                    spot,
                    currentUser,
                );
                refreshCollectionSpots(collection.id, collection.guide_id);
                refreshTopCollectionsForGuide(collection.guide_id);

                notification.success({
                    message: 'Spot added to collection',
                });
                onSuccess();
                setIsEditing(true);
            } catch (error) {
                notification.error({
                    message: 'Error adding spot to collection',
                    description: parse(error),
                });
            } finally {
                setIsLoading(false);
            }
        };

        return (
            <Select
                defaultOpen
                style={{ width: 120 }}
                size="small"
                onChange={(newId) => {
                    if (typeof newId === 'undefined') return;

                    addCollectionSpot(newId);
                }}
                onDropdownVisibleChange={setIsAdding}
                loading={isLoading}
                showSearch
                filterOption={(input, option) => {
                    /** @type {any} */
                    const value = option.props.children;

                    return (
                        value
                            .toLowerCase() // @ts-ignore
                            .indexOf(input.toLowerCase()) >= 0
                    );
                }}
            >
                {collections.map((collection) => (
                    <Select.Option
                        key={collection.id}
                        value={collection.id}
                        title={getCollectionName(collection)}
                    >
                        {getCollectionName(collection)}
                    </Select.Option>
                ))}
            </Select>
        );
    }

    if (isLoading) return <Spin size="small" />;

    return (
        <Button
            type="primary"
            icon={<PlusOutlined style={{ fontSize: 12 }} />}
            size="small"
            title="Edit"
            onClick={() => {
                setIsAdding(true);
            }}
        >
            Add to collection
        </Button>
    );
}

function AddTipButton({ collectionSpot, step, onSuccess }) {
    const [isAdding, setIsAdding] = useState(false);

    if (!collectionSpot) {
        return null;
    }

    if (isAdding) {
        return <Spin size="small" />;
    }

    return (
        <Button
            icon={<PlusOutlined style={{ fontSize: 12, color: '#1890FF' }} />}
            size="small"
            onClick={async () => {
                setIsAdding(true);
                const newCollectionSpotTip = await getCollectionSpotTipFromStepSpot(
                    step,
                    collectionSpot,
                );
                try {
                    await addTip(newCollectionSpotTip);
                    onSuccess();
                } catch (error) {
                    notification.error({
                        message: 'Error adding tip, please try again',
                        description: parse(error),
                    });
                    setIsAdding(false);
                }
            }}
        />
    );
}
