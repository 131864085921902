import { getCurrentCollectionType } from 'store/collection-type-editor/selectors';

/**
 * Checks if we are currently sending a collection type to the DB to be added
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const isAddingCollectionType = (state) => {
    return state.collectionTypes.addingCollectionType;
};

/**
 * Checks if a certain collection type is being patched at the moment
 *
 * @param {RootState} state
 * @param {Id} collectionTypeId
 * @returns {boolean}
 */
export const isPatchingCollectionType = (state, collectionTypeId) => {
    return !!state.collectionTypes.patchingById[collectionTypeId];
};

/**
 * Checks if the currently being edited collection type is being patched at the moment
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const isPatchingCurrentCollectionType = (state) => {
    const currentCollectionType = getCurrentCollectionType(state);
    if (!currentCollectionType) {
        return false;
    }
    return isPatchingCollectionType(state, currentCollectionType.id);
};

/**
 * Gets a collection type from the store (or null if store doesn't have it yet)
 *
 * @param {RootState} state
 * @param {Id} id
 * @returns {CollectionType}
 */
export const getById = (state, id) => {
    return state.collectionTypes.byId[id];
};

/**
 * Checks if a collection type is being loaded
 *
 * @param {RootState} state
 * @param {Id} id
 * @returns {boolean}
 */
export const checkLoadingById = (state, id) => {
    return state.collectionTypes.loadingById[id];
};

/**
 * Gets the error if any after trying to load a collectionType
 *
 * @param {RootState} state
 * @param {Id} id
 * @returns {string}
 */
export const getErrorById = (state, id) => {
    return state.collectionTypes.errorsById[id];
};
