import React from 'react';
import { Typography } from 'antd';
import Collection from './Collection';

const { Title, Text } = Typography;

/**
 *
 * @param {Object} props
 * @param {string} props.title
 * @param {Collection[]} props.collections
 * @param {Guide} props.guide
 * @param {Source[]} props.filterBySources
 */
function CollectionsSet({ title, collections, guide, filterBySources }) {
    return (
        <div className="collections-section">
            <Title level={3}>{title}</Title>
            {!collections.length && (
                <Text type="secondary">No Collections yet</Text>
            )}
            {collections.map((collection) => (
                <Collection
                    key={collection.id}
                    collection={collection}
                    guide={guide}
                    filterBySources={filterBySources}
                />
            ))}
        </div>
    );
}

export default CollectionsSet;
