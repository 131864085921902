import { notification } from 'antd';
import { cloneDeep } from 'lodash';
import {
    FAILED_PATCHING_GUIDE,
    FAILED_SAVING_NEW_GUIDE,
    INIT_EDITED_GUIDE,
    NOTIFY_SPOT_COLLECTION_EDITOR_SHOWN,
    PATCH_GUIDE_FIELD,
    SET_CURRENT_GUIDE_GOOD_TO_KNOW_ITEMS,
    SET_CURRENT_GUIDE_HIGHLIGHTS,
    SET_CURRENT_GUIDE_NUMBER_OF_DAYS_ITEMS,
    SET_CURRENT_GUIDE_STORIES,
    START_ADDING_GUIDE,
    START_PATCHING_GUIDE,
    START_SAVING_NEW_GUIDE,
    STOP_ADDING_GUIDE,
    STOP_EDITING_GUIDE,
    SUCCESS_PATCHING_GUIDE,
    SUCCESS_SAVING_NEW_GUIDE,
    UPDATE_CURRENT_GUIDE_FIELD,
} from './action-types';

import { deleteOldCMSMediaIfChanged } from 'api/cms-media.api';
import {
    addGuide,
    patchGuide as patchGuideInDB,
    patchGuideSingleField as patchGuideSingleFieldInDB,
} from 'api/guides.api';
import { addLocation, removeLocation } from 'api/location.api';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getCurrentUser } from 'store/auth/selectors';
import {
    loadGoodToKnowItemsForGuide,
    saveChangedGoodToKnowItems,
} from 'store/good-to-know-items/actions';
import { getGoodToKnowItemsForGuide } from 'store/good-to-know-items/selectors';
import { SUCCESS_LOADING_GUIDE, UPDATE_GUIDE } from 'store/guides/action-types';
import { loadGuide } from 'store/guides/actions';
import {
    loadHighlightsForGuide,
    saveChangedHighlights,
} from 'store/highlight-items/actions';
import { getHighlightsForGuide } from 'store/highlight-items/selectors';
import {
    loadNumberOfDaysItemsForGuide,
    saveChangedNumberOfDaysItems,
} from 'store/number-of-days-items/actions';
import { getNumberOfDaysItemsForGuide } from 'store/number-of-days-items/selectors';
import {
    loadStoriesForGuide,
    saveChangedStories,
} from 'store/story-items/actions';
import { getStoriesForGuide } from 'store/story-items/selectors';
import { isSameObject } from 'utils/compare';
import { parse, parseFetchError } from 'utils/error-parser';
import { getPolygonGeometryFromBBox } from 'utils/geojson-utils';
import { getCurrentGuide, getOriginalGuide, isGuideCorrect } from './selectors';

/**
 * Starts the edition of a new guide
 */
export const startAddingGuide = () => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const currentUser = getCurrentUser(state);

        dispatch({
            type: START_ADDING_GUIDE,
            author: currentUser,
        });
    };
};

/**
 * Stops the edition of a new guide
 */
export const stopAddingGuide = () => {
    return {
        type: STOP_ADDING_GUIDE,
    };
};

/**
 * Starts the edition of an existing guide
 */
export const startEditingGuide = (guideId: Id) => {
    return (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(loadGuide(guideId)).then((guide) => {
            dispatch({
                type: INIT_EDITED_GUIDE,
                guide,
            });
        });
    };
};

/**
 * Stops the edition of an existing guide
 */
export const stopEditingGuide = () => {
    return {
        type: STOP_EDITING_GUIDE,
    };
};

/**
 * Changes the value of a field in the currently edited guided
 */
export const patchGuideField = (fieldName: string, newValue: unknown) => {
    return {
        type: PATCH_GUIDE_FIELD,
        fieldName,
        newValue,
    };
};

/**
 * Changes the location in the currently edited guided, updating its viewport fields if there is BoundingBox info
 */
export const patchGuideLocation = (newLocation: PSLocationWithBB) => {
    return (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(patchGuideField('location', newLocation));
        if (newLocation.boundingbox) {
            const polygon = getPolygonGeometryFromBBox(newLocation.boundingbox);
            dispatch(patchGuideField('viewport_area', polygon));
            dispatch(patchGuideField('search_area', polygon));
        }
    };
};

/**
 * Saves a new guide (already should be in the state) in the DB
 */
export const saveNewGuide = (newCover: CMSMedia) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        if (!isGuideCorrect(state)) {
            return Promise.reject('Guide is not complete');
        }
        const currentUser = getCurrentUser(state);
        if (!currentUser) {
            return Promise.reject('Not logged in');
        }
        const guide = getCurrentGuide(state);
        dispatch({ type: START_SAVING_NEW_GUIDE });

        try {
            // We add the selected location and media if any
            const addedLocation = await addLocation(guide.location);
            await addGuide({
                ...guide,
                main_editor: guide.main_editor || currentUser,
                main_editor_id: guide.main_editor_id || currentUser.id,
                cover_image: newCover,
                location: addedLocation,
            });
            dispatch({ type: SUCCESS_SAVING_NEW_GUIDE });
        } catch (e) {
            const errorText = parse(e);
            dispatch({ type: FAILED_SAVING_NEW_GUIDE, error: errorText });
            notification.error({
                message: 'Error adding guide',
                description: errorText,
            });
            throw errorText;
        }
    };
};

/**
 * Patches the guide being edited
 */
export const patchGuide = (
    newCover: CMSMedia,
): ((dispatch: Dispatch, getState: () => RootState) => Promise<Guide>) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        if (!isGuideCorrect(state)) {
            return Promise.reject('Guide is not complete');
        }

        const guide = getCurrentGuide(state);
        const originalGuide = getOriginalGuide(state);

        dispatch({ type: START_PATCHING_GUIDE });

        try {
            const location = await addLocationIfChanged(
                guide.location,
                originalGuide.location,
            );
            const patchedGuide = await patchGuideInDB({
                ...guide,
                location,
                cover_image: newCover,
            });

            // Update stores accordingly
            dispatch({
                type: SUCCESS_LOADING_GUIDE,
                guideId: patchedGuide.id,
                payload: patchedGuide,
            });
            dispatch({ type: SUCCESS_PATCHING_GUIDE, patchedGuide });

            deleteOldLocationIfChanged(
                patchedGuide.location,
                originalGuide.location,
            );
            deleteOldCMSMediaIfChanged(
                patchedGuide.cover_image,
                originalGuide.cover_image,
            );
            return patchedGuide;
        } catch (error) {
            const errorText = await parseFetchError(error);
            dispatch({ type: FAILED_PATCHING_GUIDE, error: errorText });
            throw new Error(errorText);
        }
    };
};

/**
 * Patches only one field of the guide being edited
 */
export const patchGuideSingleField = (
    fieldName: keyof Guide,
    value: unknown,
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const originalGuide = getOriginalGuide(state);

        try {
            const patchedGuide = await patchGuideSingleFieldInDB(
                originalGuide,
                fieldName,
                value,
            );
            dispatch({ type: UPDATE_GUIDE, payload: patchedGuide });
            dispatch({
                type: UPDATE_CURRENT_GUIDE_FIELD,
                fieldName,
                value: patchedGuide[fieldName],
            });
            return patchedGuide;
        } catch (error) {
            const errorText = parse(error);
            return Promise.reject(errorText);
        }
    };
};

/**
 * Checks if two locations are the same, then saves the new one
 */
function addLocationIfChanged(
    newLocation: PSLocation,
    oldLocation: PSLocation,
): Promise<PSLocation> {
    if (isSameObject(newLocation, oldLocation)) {
        return Promise.resolve(newLocation);
    }
    return addLocation(newLocation);
}

/**
 * Checks if an old location has changed, and deletes it if so
 */
function deleteOldLocationIfChanged(
    newLocation: PSLocation,
    oldLocation: PSLocation,
): Promise<unknown> {
    if (!oldLocation || isSameObject(newLocation, oldLocation)) {
        return Promise.resolve();
    }
    return removeLocation(oldLocation);
}

/**
 * Notifies that the spot collection editor is shown or not
 */
export const notifySpotCollectionEditorShown = (isShown: boolean) => {
    return {
        type: NOTIFY_SPOT_COLLECTION_EDITOR_SHOWN,
        isShown,
    };
};

/**
 * Starts the edition of an existing guide
 */
export const initGuideStories = (guideId: Id) => {
    return async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        const originalStories =
            (await dispatch(loadStoriesForGuide(guideId))) ?? [];

        dispatch({
            type: SET_CURRENT_GUIDE_STORIES,
            currentStories: fixOrder(cloneDeep(originalStories)),
        });
    };
};

export const initGuideHighlights = (guideId: Id) => {
    return async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        const originalHighlights =
            (await dispatch(loadHighlightsForGuide(guideId))) ?? [];

        dispatch({
            type: SET_CURRENT_GUIDE_HIGHLIGHTS,
            currentHighlights: fixOrder(cloneDeep(originalHighlights)),
        });
    };
};

export const initGuideGoodToKnowItems = (guideId: Id) => {
    return async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        const originalItems =
            (await dispatch(loadGoodToKnowItemsForGuide(guideId))) ?? [];

        dispatch({
            type: SET_CURRENT_GUIDE_GOOD_TO_KNOW_ITEMS,
            currentGoodToKnowItems: fixOrder(cloneDeep(originalItems)),
        });
    };
};

export const initGuideNumberOfDaysItems = (guideId: Id) => {
    return async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        const originalItems =
            (await dispatch(loadNumberOfDaysItemsForGuide(guideId))) ?? [];

        dispatch({
            type: SET_CURRENT_GUIDE_NUMBER_OF_DAYS_ITEMS,
            currentNumberOfDaysItems: fixOrder(cloneDeep(originalItems)),
        });
    };
};

/**
 * Saves the current guide stories being edited
 *
 * If their cms_media has changed, it should be already uploaded and updated in this object,
 * only the reference to it will be updated in this action
 */
export const saveGuideStories = (guideId: Id, currentStories: StoryItem[]) => {
    return async (
        dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
        getState: () => RootState,
    ) => {
        const originalStories = getStoriesForGuide(getState(), guideId) ?? [];
        await dispatch(
            saveChangedStories(guideId, currentStories, originalStories),
        );
        await dispatch(initGuideStories(guideId));
        notification.success({
            message: 'The changes were saved successfully',
        });
    };
};

/**
 * Saves the current guide highlights being edited
 *
 * If their cms_media has changed, it should be already uploaded and updated in this object,
 * only the reference to it will be updated in this action
 */
export const saveGuideHighlights = (
    guideId: Id,
    currentHighlights: HighlightItem[],
) => {
    return async (
        dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
        getState: () => RootState,
    ) => {
        const originalHighlights = getHighlightsForGuide(getState(), guideId);
        await dispatch(
            saveChangedHighlights(
                guideId,
                currentHighlights,
                originalHighlights ?? [],
            ),
        );
        await dispatch(initGuideHighlights(guideId));
        notification.success({
            message: 'The changes were saved successfully',
        });
    };
};

/**
 * Saves the current guide good to know items being edited
 */
export const saveGoodToKnowItems = (
    guideId: Id,
    currentGoodToKnowItems: GoodToKnowItem[],
) => {
    return async (
        dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
        getState: () => RootState,
    ) => {
        const originalItems = getGoodToKnowItemsForGuide(getState(), guideId);
        await dispatch(
            saveChangedGoodToKnowItems(
                guideId,
                currentGoodToKnowItems,
                originalItems ?? [],
            ),
        );
        await dispatch(initGuideGoodToKnowItems(guideId));
        notification.success({
            message: 'The changes were saved successfully',
        });
    };
};

/**
 * Saves the current guide number of days items being edited
 */
export const saveNumberOfDaysItems = (
    guideId: Id,
    currentNumberOfDaysItems: NumberOfDaysItem[],
) => {
    return async (
        dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
        getState: () => RootState,
    ) => {
        const originalItems = getNumberOfDaysItemsForGuide(getState(), guideId);
        await dispatch(
            saveChangedNumberOfDaysItems(
                guideId,
                currentNumberOfDaysItems,
                originalItems ?? [],
            ),
        );
        await dispatch(initGuideNumberOfDaysItems(guideId));
        notification.success({
            message: 'The changes were saved successfully',
        });
    };
};

/**
 * Resets the stories being edited for a guide
 */
export const resetGuideStories = () => {
    return {
        type: SET_CURRENT_GUIDE_STORIES,
        currentStories: null,
    };
};

/**
 * Resets the highlights being edited for a guide
 */
export const resetGuideHighlights = () => {
    return {
        type: SET_CURRENT_GUIDE_HIGHLIGHTS,
        currentHighlights: null,
    };
};

/**
 * Resets the good to know items being edited for a guide
 */
export const resetGuideGoodToKnowItems = () => {
    return {
        type: SET_CURRENT_GUIDE_GOOD_TO_KNOW_ITEMS,
        currentGoodToKnowItems: null,
    };
};

/**
 * Resets the number of days items being edited for a guide
 */
export const resetGuideNumberOfDaysItems = () => {
    return {
        type: SET_CURRENT_GUIDE_NUMBER_OF_DAYS_ITEMS,
        currentNumberOfDaysItems: null,
    };
};

/**
 * Notifies the change in guide stories being edited
 */
export const notifyGuideStoriesChange = (stories: StoryItem[]) => {
    return {
        type: SET_CURRENT_GUIDE_STORIES,
        currentStories: fixOrder(stories),
    };
};

/**
 * Notifies the change in guide highlights being edited
 */
export const notifyGuideHighlightsChange = (highlights: HighlightItem[]) => {
    return {
        type: SET_CURRENT_GUIDE_HIGHLIGHTS,
        currentHighlights: fixOrder(highlights),
    };
};

/**
 * Notifies the change in guide good to know items being edited
 */
export const notifyGuideGoodToKnowItemsChange = (
    goodToKnowItems: GoodToKnowItem[],
) => {
    return {
        type: SET_CURRENT_GUIDE_GOOD_TO_KNOW_ITEMS,
        currentGoodToKnowItems: fixOrder(goodToKnowItems),
    };
};

/**
 * Notifies the change in guide number of days items being edited
 */
export const notifyGuideNumberOfDaysItemsChange = (
    numberOfDaysItems: NumberOfDaysItem[],
) => {
    return {
        type: SET_CURRENT_GUIDE_NUMBER_OF_DAYS_ITEMS,
        currentNumberOfDaysItems: fixOrder(numberOfDaysItems),
    };
};

function fixOrder<T extends { order: number }>(items: T[]): T[] {
    const orderedItems = items.slice().sort((a, b) => a.order - b.order);

    return orderedItems.map((item, index) => {
        return {
            ...item,
            order: index,
        };
    });
}
