import React, { useState } from 'react';
import { Typography, Button, Input, Card } from 'antd';
import {
    CloseCircleOutlined,
    DeleteOutlined,
    TranslationOutlined,
} from '@ant-design/icons';
import AvatarWithName from 'components/AvatarWithName';
import Flag from 'components/Flag';
import LanguageSelector from 'components/LanguageSelector';
import FormField from 'components/FormField';
import { TRANSLATION_TIP_MAX_LENGTH } from 'constants/fields-limits';

const { Text } = Typography;
const { TextArea } = Input;

const DEFAULT_ALLOWED_LANGUAGES = ['en', 'nl', 'fr', 'de'];

/**
 * @param {Object} props
 * @param {CollectionSpotTip} props.collectionSpotTip
 * @param {function} props.onUpdate
 * @param {function} props.onDelete
 */
function SelectedTip(props) {
    const [showTranslation, setShowTranslation] = useState(
        !!props.collectionSpotTip.translation,
    );
    const [allowedLanguages] = useState(
        getAllowedLanguages(props.collectionSpotTip.language),
    );

    const deleteTranslation = () => {
        setShowTranslation(false);
        props.onUpdate({
            ...props.collectionSpotTip,
            translation: '',
        });
    };

    const stepSpot = props.collectionSpotTip.step_spot;
    const user = stepSpot?.user;

    return (
        <Card
            className="custom-actions-card"
            title={
                stepSpot && (
                    <AvatarWithName
                        className="grow-full-flex"
                        src={user.profile_image_thumb_path}
                        name={`${user.first_name} ${user.last_name}`}
                    />
                )
            }
            extra={
                stepSpot && (
                    <LanguageSelector
                        allowedLanguages={allowedLanguages}
                        onSelectLanguage={(language) => {
                            props.onUpdate({
                                ...props.collectionSpotTip,
                                language,
                            });
                        }}
                        selectedLanguage={props.collectionSpotTip.language}
                    />
                )
            }
            actions={[
                <div
                    key={`selected-tip__${props.collectionSpotTip.id}`}
                    className="line-center ml-m mr-m"
                >
                    <Button
                        onClick={() => props.onDelete(props.collectionSpotTip)}
                        type="link"
                        icon={<CloseCircleOutlined />}
                        danger={true}
                        size="small"
                    >
                        Remove
                    </Button>
                    <div
                        className="grow-full-flex"
                        style={{ width: '100%' }}
                    ></div>
                    {stepSpot && (
                        <Button
                            onClick={() => setShowTranslation(true)}
                            type="link"
                            icon={<TranslationOutlined />}
                            size="small"
                        >
                            Add translation
                        </Button>
                    )}
                </div>,
            ]}
        >
            {!stepSpot && (
                <Text type="secondary">
                    This tip has been removed by the user
                </Text>
            )}
            {stepSpot && (
                <>
                    <div>{props.collectionSpotTip.step_spot.tip}</div>
                    {showTranslation && (
                        <div className="line-center mt-m">
                            <div className="selected-tip__translation-flag">
                                <Flag countryCode="GB" />
                            </div>

                            <FormField
                                length={
                                    (props.collectionSpotTip.translation || '')
                                        .length
                                }
                                maxLength={TRANSLATION_TIP_MAX_LENGTH}
                                className="grow-full-flex selected-tip__form-field"
                            >
                                <TextArea
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                    value={props.collectionSpotTip.translation}
                                    onChange={(e) =>
                                        props.onUpdate({
                                            ...props.collectionSpotTip,
                                            translation: e.target.value,
                                        })
                                    }
                                />
                            </FormField>

                            <div className="selected-tip__translation-delete">
                                <Button
                                    icon={
                                        <DeleteOutlined
                                            style={{ color: '#F5222D' }}
                                        />
                                    }
                                    onClick={deleteTranslation}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </Card>
    );
}

/**
 * Should return the list of all default allowed languages, plus the tip language if it is not one of them.
 *
 * @param {string} tipLanguage
 */
function getAllowedLanguages(tipLanguage) {
    if (
        !tipLanguage ||
        DEFAULT_ALLOWED_LANGUAGES.find(
            (language) => language.toLowerCase() === tipLanguage.toLowerCase(),
        )
    ) {
        return DEFAULT_ALLOWED_LANGUAGES;
    }
    return [...DEFAULT_ALLOWED_LANGUAGES, tipLanguage.toLowerCase()];
}

export default SelectedTip;
