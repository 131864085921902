import React from 'react';
import { Select, Typography, Alert, Button } from 'antd';
import { useGuideSpots } from 'api/collection-spots.api';
import { parse } from 'utils/error-parser';
import './style.css';

type SpotSelectorValue = {
    id: Id;
    name: string;
};

type InGuideSpotSelectorProps<T> = {
    value: T | null;
    guide: Guide;
    onSelect: (spot: PSSpot_v15_Minimal | null) => void;
    placeholder?: string;
    hasError?: boolean;
};

/**
 * This component will find spots within a guide and allow selecting them
 */
export default function InGuideSpotSelector<T extends SpotSelectorValue>({
    value,
    guide,
    onSelect,
    placeholder = 'Search spot in guide',
    hasError = false,
}: InGuideSpotSelectorProps<T>) {
    const { guideSpots = [], isValidating, error, refresh } = useGuideSpots(
        guide?.id,
    );

    const handleChange = (value: string | number) => {
        if (!value) {
            onSelect(null);
            return;
        }
        const tempSpot = guideSpots.find(
            (collectionSpot) => collectionSpot.spot.id === value,
        );
        onSelect(tempSpot?.spot ?? null);
    };

    const getSelectValue = () => {
        if (!value || !guideSpots) {
            return null;
        }
        if (
            guideSpots.find(
                (collectionSpot) => collectionSpot.spot.id === value.id,
            )
        ) {
            return value.id;
        }
        return value.name;
    };

    const shownValue = getSelectValue();

    if (error) {
        return (
            <div>
                <Alert
                    message="Error loading spots"
                    description={parse(error)}
                    type="error"
                    showIcon
                />
                <div className="full-page-error-button">
                    <Button type="primary" onClick={() => refresh()}>
                        Try loading again
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <Select
            className="in-guide-spot-selector__select"
            showSearch
            value={shownValue}
            allowClear={!!shownValue}
            placeholder={placeholder}
            size="large"
            loading={isValidating && !guideSpots}
            optionLabelProp="label"
            optionFilterProp="label"
            onChange={handleChange}
            status={hasError ? 'error' : undefined}
            filterOption={(input, option) => {
                return (
                    (option?.label || '')
                        // @ts-ignore
                        .toLowerCase()
                        .includes((input || '').toLowerCase())
                );
            }}
        >
            {guideSpots &&
                guideSpots.map((collectionSpot) =>
                    getOptionFromStepSpot(collectionSpot.spot),
                )}
        </Select>
    );
}

function getOptionFromStepSpot(spot: PSSpot_v15_Minimal): JSX.Element {
    return (
        <Select.Option
            key={spot.id}
            value={spot.id}
            // @ts-ignore
            label={spot.name}
        >
            <div className="spot-search__option line-center">
                <span className="grow-full-flex spot-search__option-text">
                    {spot.name} ({spot.category_label})
                </span>
            </div>
            <div>
                <Typography.Text type="secondary">
                    {spot.location?.locality}, {spot.location?.country}
                </Typography.Text>
            </div>
        </Select.Option>
    );
}
