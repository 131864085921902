import { get } from 'utils/http';
import { BASE_URL } from 'constants/env';
import {
    convertPSMediaToCMSMedia,
    convertSpotMediaToCMSMedia,
    isCoverImageSchema,
} from 'utils/media-utils';
import { useEffect, useState, useMemo } from 'react';
import { parse } from 'utils/error-parser';
import { isSpotSource, useSources } from './sources.api';
import { getDetails } from './spots.api';

/**
 * Find media result
 *
 * @typedef {object} SearchMediaResult
 * @property {boolean} hasMore If false, no more pages are available
 * @property {number} currentPage
 * @property {PSLocation} location Location used to search
 * @property {PSMedia[]} results
 */

/**
 * Retrieves the original media if it is possible and converts it to CMSMedia
 *
 * @param {CMSMedia | CoverImageSchema} media
 * @param {PSSpot_v15_Minimal} spot
 * @returns {{ originalMedia: CMSMedia, error: string, isLoading: boolean}}
 */
export const useOriginalMedia = (media, spot) => {
    const [originalMedia, setOriginalMedia] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { sources } = useSources();
    const [propMediaMemoized, setPropMediaMemoized] = useState(null);

    // We make sure to not run everything many times just because media object reference has changed
    useEffect(() => {
        setPropMediaMemoized((prevState) => {
            if (JSON.stringify(prevState) !== JSON.stringify(media)) {
                return media;
            }
            return prevState;
        });
    }, [media]);

    const mediaSource = useMemo(
        () =>
            (sources || []).find((source) =>
                //TODO - change it when the API starts returning source_id
                isCoverImageSchema(media)
                    ? source.name === media.source
                    : source.id === media.source_id,
            ),
        [sources, media],
    );

    useEffect(() => {
        if (!propMediaMemoized || !mediaSource) {
            return;
        }

        getOriginalMediaFromSource(propMediaMemoized, mediaSource, spot)
            .then((newOriginalMedia) => setOriginalMedia(newOriginalMedia))
            .catch((error) => setError(parse(error)))
            .finally(() => setIsLoading(false));
    }, [mediaSource, propMediaMemoized, spot]);

    return { originalMedia, error, isLoading };
};

/**
 *
 * @param {CMSMedia} media
 * @param {Source} mediaSource
 * @param {PSSpot_v15_Minimal} [spot] Related spot if any
 */
function getOriginalMediaFromSource(media, mediaSource, spot) {
    if (!media) {
        throw new Error('getOriginalMediaFromSource: no media');
    }
    if (!mediaSource) {
        throw new Error('getOriginalMediaFromSource: no mediaSource');
    }

    if (mediaSource.name === 'polarsteps' && media.media_id) {
        return get(
            `${BASE_URL}/cms/media/${media.media_id}?source_id=${media.source_id}`,
        ).then((data) => convertPSMediaToCMSMedia(data));
    }

    // We need to check if there is a spot because sometimes the media is coming from a spot that is not longer
    // assigned to that story
    if (spot && isSpotSource(mediaSource)) {
        return getDetails(spot.id).then((spotDetails) => {
            // TODO: spot detail photos are paginated now, so we should get all those photos
            const originalPhoto = (spotDetails.photos?.data || []).find(
                (photo) => photo.external_id + '' === media.external_id + '',
            );
            if (originalPhoto) {
                return convertSpotMediaToCMSMedia(spotDetails, originalPhoto);
            }

            return media;
        });
    }

    return Promise.resolve(media);
}

/**
 * Searches media in Polarsteps related to a location
 *
 * @param {PSLocation} location
 * @param {number[]} allowedMediaTypes List of media types we want to get
 * @param {number} [page=0] page we want to obtain
 * @returns {Promise<SearchMediaResult>}
 */
const PAGE_SIZE = 48;

export const searchFromLocation = (location, allowedMediaTypes, page = 0) => {
    const typePart = getMediaTypeParam(allowedMediaTypes);

    return get(
        `${BASE_URL}/cms/media/search?lat=${location.lat}&lon=${
            location.lon
        }&limit=${PAGE_SIZE}&offset=${PAGE_SIZE * page}${typePart}`,
    ).then((result = []) => {
        return {
            currentPage: page,
            hasMore: result.length >= PAGE_SIZE,
            location,
            results: result,
        };
    });
};

/**
 * @param {number[]} allowedMediaTypes List of media types we want to get
 */
function getMediaTypeParam(allowedMediaTypes) {
    return allowedMediaTypes
        .map((mediaTypeId) => `&type=${mediaTypeId}`)
        .join('');
}

/**
 * Gets the user from a Media Object
 *
 * @param {PSMedia} media
 * @returns {Promise<PSUser>}
 */
export const getUser = (media) => {
    return get(`${BASE_URL}/cms/trips/${media.step.trip_id}/user`);
};
