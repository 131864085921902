import { AutoComplete } from 'antd';
import emojiRegex from 'emoji-regex';
import React from 'react';
import { factOptions } from './FactOptions';

export default function FactDropdown({ value, updateFact }) {
    function onChange(option) {
        const regex = emojiRegex();
        const emoji = (option.target.value.match(regex) || [])[0];
        const textWithoutEmojis = option.target.value
            .replace(regex, '')
            .slice(1);
        if (emoji && textWithoutEmojis) {
            updateFact({ emoji: emoji, text: textWithoutEmojis });
        }
    }

    return (
        <AutoComplete
            style={{ flex: 1 }}
            options={factOptions}
            key={value}
            defaultValue={value}
            onBlur={onChange}
            className="collection-spot-editor__spot-content-text "
            placeholder="Enter a emoji and fact here."
            filterOption={(inputValue, option) => {
                return option.label
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
            }}
        />
    );
}
