import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { get } from 'utils/http';

/**
 * @param {PSSpot_v15_Photo} spotPhoto
 * @returns {PSSpot_v15_Photo}
 */
function addExternalIDIfNeeded(spotPhoto) {
    return {
        external_id: uuid(),
        ...spotPhoto,
    };
}

/**
 * @param {PSSpot_v15} spot
 */
export const useInfiniteSpotMedia = (spot) => {
    const [allMedia, setAllMedia] = useState([]);
    const [lastResult, setLastResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getNewPage = async () => {
        if (isLoading || !lastResult?.next) {
            return;
        }
        setIsLoading(true);
        /** @type {PSSpot_v15_Photos} */
        const nextResult = await get(lastResult?.next);
        setAllMedia((prev) => {
            return [...prev, ...nextResult.data.map(addExternalIDIfNeeded)];
        });
        setLastResult(nextResult);
        setIsLoading(false);
    };

    useEffect(() => {
        if (spot) {
            setLastResult(spot?.photos);
            setAllMedia((spot?.photos?.data || []).map(addExternalIDIfNeeded));
            getNewPage();
        }
    }, [spot]);

    const hasMoreResults = !!lastResult?.next;

    return {
        /** @type {PSSpot_v15_Photo[]} */
        allMedia,
        getNewPage,
        hasMoreResults,
        isLoading,
    };
};
