/**
 * Filter for flask restless queries
 *
 * @typedef {object} FRFilter
 * @property {string} name Name of the field to query
 * @property {string} op Opetarion to use for query
 * @property {string} [val] value to compare
 */

/**
 * Gets a string of the form "{"name":"blah","op":"==","val":"bleh"},{"name":"blah","op":"==","val":"bleh"}"
 * to use for restless queries, from a list of filters
 *
 * @param {FRFilter[]} filters
 * @returns {string}
 */
export const getString = (filters = []) => {
    return filters
        .filter((filter) => !!filter)
        .map(getStringFromSingle)
        .join(',');
};

/**
 * Gets a string of the form "{"name":"blah","op":"==","val":"bleh"}" to use for restless queries from a single filter
 *
 * @param {FRFilter} filter
 * @returns {string}
 */
export const getStringFromSingle = (filter) => {
    if (!filter) {
        return '';
    }
    const valPart = filter.val ? `,"val":"${filter.val}"` : '';
    return `{"name":"${filter.name}","op":"${filter.op}"${valPart}}`;
};
