import { BASE_URL } from 'constants/env';
import { get, patch, post, postDelete } from 'utils/http';

type GoodToKnowResponse = {
    good_to_know: GoodToKnowItem[];
};

const defaultOptions = {
    headers: {
        'Polarsteps-Api-Version': '53',
    },
};

/**
 * Gets all the good to know items associated with a guide
 */
export const getGoodToKnowItemsForGuide = (
    guideId: Id | undefined,
): Promise<GoodToKnowItem[]> => {
    return get<GoodToKnowResponse>(
        `${BASE_URL}/cms/guides/${guideId}/good-to-know`,
        defaultOptions,
    ).then((result) => {
        return result.good_to_know;
    });
};

/**
 * Adds a good to know item to the database
 */
export const addGoodToKnowItem = async (
    goodToKnow: GoodToKnowItem,
): Promise<GoodToKnowItem> => {
    return post(
        `${BASE_URL}/cms/guides/good-to-know`,
        toDBFormat(goodToKnow),
        defaultOptions,
    );
};

/**
 * Patches a good to know in the database
 */
export const patchGoodToKnowItem = async (
    newGoodToKnow: GoodToKnowItem,
): Promise<GoodToKnowItem> => {
    const patchedGoodToKnowItem = await patch<GoodToKnowItem>(
        `${BASE_URL}/cms/guides/good-to-know/${newGoodToKnow.id}`,
        toDBFormat(newGoodToKnow),
        defaultOptions,
    );

    return patchedGoodToKnowItem;
};

/**
 * Removes a good to know item to the database
 */
export const deleteGoodToKnowItem = async (goodToKnow: GoodToKnowItem) => {
    const deletionResult = await postDelete(
        `${BASE_URL}/cms/guides/good-to-know/${goodToKnow.id}`,
        {
            returnPlainResponse: true,
            ...defaultOptions,
        },
    );
    return deletionResult;
};

/**
 * Converts a good to know item to the format needed for the DB
 */
function toDBFormat(goodToKnow: GoodToKnowItem): DBGoodToKnowItem {
    return {
        guide_id: goodToKnow.guide_id,
        title: goodToKnow.title,
        description: goodToKnow.description,
        order: goodToKnow.order,
        editor_notes: goodToKnow.editor_notes,
        color_code: goodToKnow.color_code,
    };
}
