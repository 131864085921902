import { getUser } from 'api/media.api';
import useSWR from 'swr';

/**
 * Hook that gets the user for a PSMedia object
 *
 * @param {PSMedia} media
 */
export const useMediaUser = (media) => {
    const { data, error } = useSWR(media.id + '', () => getUser(media));
    return {
        user: data,
        error,
        isLoading: !data && !error,
    };
};
