import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAllSecondLevel } from 'api/spot-categories.api';
import { Select, Alert, Button } from 'antd';
import { parse } from 'utils/error-parser';

const { Option } = Select;

/**
 *
 * @param {Object} props
 * @param {(newVal: SpotCategory) => void} props.onChange
 * @param {string} props.value
 */
function AllSubcategoriesSelector({ onChange, value }) {
    const { error, isLoading, allCategories, reload } = useAllSecondLevel();

    useEffect(() => {
        if (error) {
            Sentry.captureException(new Error(error));
        }
    }, [error]);

    if (error) {
        return (
            <div className="line-center">
                <Alert
                    className="grow-full-flex"
                    message="There was an error loading"
                    description={parse(error)}
                    type="error"
                    showIcon
                />
                <div className="mr-sm"></div>
                <Button size="large" onClick={() => reload()}>
                    Try again
                </Button>
            </div>
        );
    }

    return (
        <>
            <Select
                size="large"
                loading={isLoading}
                showSearch
                style={{ width: '100%' }}
                placeholder="Search for a category"
                optionFilterProp="datalabel"
                onChange={(newVal) => {
                    const fullCategory = allCategories.find(
                        (category) => category.id === newVal,
                    );
                    onChange(fullCategory);
                }}
                value={value}
                filterOption={(input, option) =>
                    option.datalabel
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
            >
                {(allCategories || []).map((subCategory) => (
                    <Option
                        key={subCategory.id}
                        value={subCategory.id}
                        datalabel={subCategory.name}
                    >
                        {subCategory.name}
                    </Option>
                ))}
            </Select>
        </>
    );
}

export default AllSubcategoriesSelector;
