import { notification } from 'antd';
import {
    addHighlight,
    deleteHighlight,
    getHighlightsForGuide as getHighlightsForGuideFromDB,
    patchHighlight,
} from 'api/highlight-items.api';
import { Dispatch } from 'redux';
import { parse } from 'utils/error-parser';
import {
    findOriginal,
    getChangesBetweenItemSets,
} from 'utils/sortable-item-utils';
import {
    FAILED_LOADING_HIGHLIGHT_ITEMS_FOR_GUIDE,
    FAILED_SAVING_HIGHLIGHT_ITEMS,
    STARTED_LOADING_HIGHLIGHT_ITEMS_FOR_GUIDE,
    STARTED_SAVING_HIGHLIGHT_ITEMS,
    SUCCESS_LOADING_HIGHLIGHT_ITEMS_FOR_GUIDE,
    SUCCESS_SAVING_HIGHLIGHT_ITEMS,
} from './action-types';
import { getHighlightsForGuide } from './selectors';

export const loadHighlightsForGuide = (guideId: Id) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch({ type: STARTED_LOADING_HIGHLIGHT_ITEMS_FOR_GUIDE, guideId });
        try {
            const payload = await getHighlightsForGuideFromDB(guideId);
            dispatch({
                type: SUCCESS_LOADING_HIGHLIGHT_ITEMS_FOR_GUIDE,
                guideId,
                payload,
            });
            return getHighlightsForGuide(getState(), guideId);
        } catch (error) {
            const errorText = parse(error);
            dispatch({
                type: FAILED_LOADING_HIGHLIGHT_ITEMS_FOR_GUIDE,
                guideId,
                error: errorText,
            });
        }
    };
};

/**
 * Saves the highlights that have changed
 */
export const saveChangedHighlights = (
    guideId: Id,
    current: HighlightItem[],
    original: HighlightItem[],
) => {
    return async (dispatch: Dispatch) => {
        const {
            newItems,
            deletedItems,
            editedItems,
        } = getChangesBetweenItemSets(current, original);

        dispatch({ type: STARTED_SAVING_HIGHLIGHT_ITEMS });
        const highlightsToAdd = newItems.map((newHighlight) =>
            addHighlight(newHighlight),
        );
        const highlightsToDelete = deletedItems.map((deletedHighlight) =>
            deleteHighlight(deletedHighlight),
        );
        const highlightsToPatch = editedItems.map((editedHighlight) =>
            patchHighlight(
                editedHighlight,
                findOriginal(editedHighlight, original),
            ),
        );

        try {
            await Promise.all([
                ...highlightsToAdd,
                ...highlightsToDelete,
                ...highlightsToPatch,
            ]);
            await loadHighlightsForGuide(guideId);
            dispatch({ type: SUCCESS_SAVING_HIGHLIGHT_ITEMS });
        } catch (error) {
            const errorText = parse(error);
            dispatch({
                type: FAILED_SAVING_HIGHLIGHT_ITEMS,
                error: errorText,
            });
            notification.error({
                message: 'Error saving highlight items',
                description: errorText,
            });
            throw error;
        }
    };
};
