import {
    STARTED_LOADING_GUIDE,
    SUCCESS_LOADING_GUIDE,
    FAILED_LOADING_GUIDE,
} from './action-types';
import { getGuide } from 'api/guides.api';
import { parse } from 'utils/error-parser';

/**
 * Loads a guide from the DB
 *
 * @param {Id} guideId
 * @returns {(dispatch: any) => Promise<Guide>}
 */
export const loadGuide = (guideId) => {
    return (dispatch) => {
        dispatch({ type: STARTED_LOADING_GUIDE, guideId });
        return getGuide(guideId)
            .then((guide) => {
                dispatch({
                    type: SUCCESS_LOADING_GUIDE,
                    guideId,
                    payload: guide,
                });
                return guide;
            })
            .catch((error) => {
                const errorText = parse(error);
                dispatch({
                    type: FAILED_LOADING_GUIDE,
                    guideId,
                    error: errorText,
                });
                throw errorText;
            });
    };
};
