import React from 'react';
import { Checkbox, Input, Card, Select } from 'antd';
import AuthorSelector from 'components/AuthorSelector';
import FormField from 'components/FormField';
import { SpotLabelSelector } from 'components/SpotLabelSelector';
import {
    COLLECTION_SPOT_NAME_MAX_LENGTH,
    COLLECTION_SPOT_LOCAL_NAME_MAX_LENGTH,
} from 'constants/fields-limits';
import CollectionSpotEditorSource from './Source';
import { useCollections } from 'api/collections.api';

const { TextArea } = Input;

/**
 *
 * @param {Object} props
 * @param {CollectionSpot} props.collectionSpot
 * @param {(patch: Object.<string, any>) => void} props.onPatchField
 * @param {Guide} props.guide
 */
export default function CollectionSpotEditorDetails({
    collectionSpot,
    onPatchField,
    guide,
}) {
    const { collections } = useCollections(guide.id);

    return (
        <div className="collection-spot-editor__tab-content collection-spot-editor__details">
            <div className="collection-spot-editor__details-content">
                <Card title="Spot details">
                    <FormField
                        labelWidth={105}
                        label="Spot name"
                        length={collectionSpot.name.length}
                        maxLength={COLLECTION_SPOT_NAME_MAX_LENGTH}
                    >
                        <TextArea
                            className="form-field__textarea"
                            autoSize={{ minRows: 1, maxRows: 2 }}
                            value={collectionSpot.name}
                            onChange={(e) =>
                                onPatchField({
                                    name: e.target.value,
                                })
                            }
                            placeholder=""
                        />
                    </FormField>
                    <FormField
                        labelWidth={105}
                        label="Local name"
                        length={(collectionSpot.local_name || '').length}
                        maxLength={COLLECTION_SPOT_LOCAL_NAME_MAX_LENGTH}
                    >
                        <TextArea
                            className="form-field__textarea"
                            autoSize={{ minRows: 1, maxRows: 2 }}
                            value={collectionSpot.local_name}
                            onChange={(e) =>
                                onPatchField({
                                    local_name: e.target.value,
                                })
                            }
                            placeholder="Secondary name"
                        />
                    </FormField>
                    <FormField labelWidth={105} label="Labels">
                        <SpotLabelSelector
                            value={
                                collectionSpot.labels[0]
                                    ?.collection_spot_label_type_id
                            }
                            onChange={(id) => {
                                if (id) {
                                    // Reuse ids for the same labels to avoid
                                    // recreating them unnecessarily
                                    const existingId = collectionSpot.labels.find(
                                        (label) =>
                                            label.collection_spot_label_type_id ===
                                                id &&
                                            label.collection_spot_id ===
                                                collectionSpot.id,
                                    )?.id;

                                    onPatchField({
                                        labels: [
                                            {
                                                id: existingId,
                                                collection_spot_id:
                                                    collectionSpot.id,
                                                collection_spot_label_type_id: id,
                                            },
                                        ],
                                    });
                                } else {
                                    onPatchField({ labels: [] });
                                }
                            }}
                        />
                    </FormField>
                    <FormField labelWidth={105} label="Top spot?">
                        <Checkbox
                            checked={collectionSpot.top_spot}
                            onChange={(e) => {
                                onPatchField({
                                    top_spot: e.target.checked,
                                });
                            }}
                        >
                            Set as top spot
                        </Checkbox>
                    </FormField>
                    <FormField labelWidth={105} label="Curator">
                        <AuthorSelector
                            value={collectionSpot.main_editor?.id}
                            onChange={(newId, newValue) => {
                                onPatchField({
                                    main_editor: newValue,
                                });
                            }}
                        />
                    </FormField>
                    <FormField labelWidth={105} label="Collection">
                        <Select
                            showSearch
                            placeholder="Select a collection"
                            optionFilterProp="children"
                            defaultValue={collectionSpot.collection.id}
                            onChange={(value) => {
                                onPatchField({
                                    collection_id: value,
                                });
                            }}
                            filterOption={(input, option) =>
                                (option?.label.toString() ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            options={collections.map((col) => {
                                return {
                                    label: col.collection_type.name,
                                    value: col.id,
                                };
                            })}
                            dropdownMatchSelectWidth={false}
                            style={{ minWidth: '100%' }}
                        />
                    </FormField>
                </Card>
                <div className="mb-ml"></div>
                <Card title="Source">
                    <CollectionSpotEditorSource
                        collectionSpot={collectionSpot}
                        guide={guide}
                        onSourceUpdate={(newSpotSource) =>
                            onPatchField({
                                spot: newSpotSource,
                                spot_id: newSpotSource.id,
                            })
                        }
                    />
                </Card>
                <div className="mb-ml"></div>
                <Card title="Editor's notes">
                    <TextArea
                        className="form-field__textarea"
                        autoSize={{ minRows: 1, maxRows: 20 }}
                        value={collectionSpot.editor_notes?.detail || ''}
                        onChange={(e) => {
                            const prevNotes = collectionSpot.editor_notes || {};
                            onPatchField({
                                editor_notes: {
                                    ...prevNotes,
                                    detail: e.target.value,
                                },
                            });
                        }}
                        size="large"
                        placeholder="Share your thoughts"
                    />
                </Card>
            </div>
        </div>
    );
}
