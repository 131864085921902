import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Alert, Spin } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { EyeOutlined } from '@ant-design/icons';
import Loading from 'components/Loading';
import GuideEditor from 'components/GuideEditor';
import {
    startEditingGuide,
    stopEditingGuide,
} from 'store/guide-editor/actions';
import { useHistory, useParams } from 'react-router-dom';
import { getCurrentGuide } from 'store/guide-editor/selectors';
import { getName } from 'utils/guide-utils';
import './style.css';
import GuideSpotCount from 'components/GuideSpotCount';

function EditGuide() {
    const history = useHistory();
    // @ts-ignore
    const { id } = useParams();
    const dispatch = useDispatch();
    const currentGuide = useSelector(getCurrentGuide);
    // @ts-ignore
    const isLoading = useSelector((state) => state.guides.loadingById[id]);
    // @ts-ignore
    const errorLoading = useSelector((state) => state.guides.errorsById[id]);

    useEffect(() => {
        dispatch(startEditingGuide(id));

        return () => {
            dispatch(stopEditingGuide());
        };
    }, [dispatch, id]);

    return (
        <div className="full-page">
            <PageHeader
                title={
                    isLoading ? (
                        <Spin size="small" />
                    ) : (
                        <div className="line">
                            <div className="mr-s">
                                {getName(currentGuide) || 'Edit guide'}
                            </div>
                            <GuideSpotCount guide={currentGuide} />
                        </div>
                    )
                }
                extra={
                    currentGuide && (
                        <Button
                            href={`https://polarsteps.com/guides/${currentGuide.id}`}
                            target="_BLANK"
                            icon={<EyeOutlined />}
                        >
                            View guide
                        </Button>
                    )
                }
                ghost={false}
                onBack={() => history.push('/guides')}
            />
            {isLoading && (
                <div className="full-page-content">
                    <div className="full-page-content-center">
                        <Loading tip="Loading..." />
                    </div>
                </div>
            )}

            {errorLoading && (
                <div className="full-page-content">
                    <div className="full-page-content-center full-page-error">
                        <Alert
                            message="Error loading the guide"
                            description={errorLoading}
                            type="error"
                            showIcon
                        />
                        <div className="full-page-error-button">
                            <Button
                                type="primary"
                                onClick={() => dispatch(startEditingGuide(id))}
                            >
                                Try loading again
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {!isLoading && !errorLoading && <GuideEditor />}
        </div>
    );
}

export default EditGuide;
