import React, { useEffect, useState } from 'react';
import { Modal, notification } from 'antd';
import PolarstepsSpotEditor from 'components/PolarstepsSpotEditor';
import { isPolarstepsSpotValid } from 'utils/polarsteps-spot-utils';
import { addPolarstepsSpot } from 'api/polarsteps-spots.api';
import { parse } from 'utils/error-parser';

/**
 *
 * @param {Object} props
 * @param {boolean} props.isShown
 * @param {string} props.initialName
 * @param {PSLocation} props.location Location that gives context to this spot (i.e. city of guide where it will be located)
 * @param {(spot: PolarstepsSpot) => void} props.onAddPolarstepsSpot
 * @param {() => void} props.onClose
 */
function AddPolarstepsSpotModal({
    isShown,
    location,
    initialName,
    onClose,
    onAddPolarstepsSpot,
}) {
    /** @type {[PolarstepsSpot, React.Dispatch<PolarstepsSpot>]} */
    const [initialSpot, setInitialSpot] = useState(null);
    /** @type {[PolarstepsSpot, React.Dispatch<PolarstepsSpot>]} */
    const [currentSpot, setCurrentSpot] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (!isShown) {
            setInitialSpot(null);
            return;
        }
        setInitialSpot({
            ...getEmptySpot(),
            name: initialName,
        });
    }, [isShown]);

    const saveNewSpot = () => {
        setIsSaving(true);
        addPolarstepsSpot(currentSpot)
            .then((result) => {
                onAddPolarstepsSpot(result);
            })
            .catch((e) => {
                notification.error({
                    message: 'Error adding spot',
                    description: parse(e),
                });
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    return (
        <Modal
            width={1024}
            style={{
                top: '10px',
            }}
            title={
                <>
                    <span>New spot:</span> <span>{currentSpot?.name}</span>
                </>
            }
            open={isShown}
            okText="Create spot"
            onOk={saveNewSpot}
            okButtonProps={{
                disabled: !isPolarstepsSpotValid(currentSpot),
            }}
            confirmLoading={isSaving}
            onCancel={() => onClose()}
            bodyStyle={{
                backgroundColor: '#F0F2F5',
                maxHeight: '80vh',
                overflow: 'auto',
            }}
        >
            <div className="line-center">
                {initialSpot && (
                    <PolarstepsSpotEditor
                        guideLocation={location}
                        initialSpot={initialSpot}
                        onSpotChange={(newSpot) => setCurrentSpot(newSpot)}
                    />
                )}
            </div>
        </Modal>
    );
}

export default AddPolarstepsSpotModal;

/**
 * @returns {PolarstepsSpot}
 */
function getEmptySpot() {
    return {
        name: '',
        description: null,
        sub_category: null,
        category_label: null,
        lat: null,
        lon: null,
        locality: null,
        administrative_area: null,
        country: null,
        country_code: null,
        single_line: null,
        website: null,
        phone_number: null,
    };
}
