import { BASE_URL } from 'constants/env';
import { get, patch, post } from 'utils/http';

/**
 * Gets all the guides from the database
 *
 * @param {string} [searchTerm='']
 * @param {string} [sortColumn='id']
 * @param {string} [sortOrder='desc']
 * @param {number} [page=1]
 * @param {number} [resultsPerPage=20] If this is set to more than FlaskRestless allows, this may be ignored by the server
 * @param {Object} [options]
 * @param {boolean} [options.excludeArchived=true] If true we will not get collections marked as "archived"
 * @returns {Promise<{pagination: PaginationInfo, results: GuideFromSearch[]}>}
 */
export const getAllGuides = async (
    searchTerm = '',
    sortColumn = 'id',
    sortOrder = 'desc',
    page = 1,
    resultsPerPage = 20,
    options = {},
) => {
    const optionsWithDefaults = {
        excludeArchived: true,
        ...options,
    };
    const params = new URLSearchParams({
        q: searchTerm,
        offset: `${(page - 1) * resultsPerPage}`,
        limit: `${resultsPerPage}`,
        exclude_archived: `${optionsWithDefaults.excludeArchived}`,
        sort_column: sortColumn,
        sort_order: sortOrder,
    });

    const result = await get(
        `${BASE_URL}/cms/guides-search?${params.toString()}`,
    );
    return {
        pagination: {
            page,
            totalPages: Math.ceil(result.total / resultsPerPage),
            totalResults: result.total,
            resultsPerPage,
        },
        results: result.guides || [],
    };
};

/**
 * Adds a guide the database
 *
 * @param {Guide} guide
 * @returns {Promise<void>}
 */
export const addGuide = (guide) => {
    const dbGuide = transformToDBFormat(guide);
    return post(`${BASE_URL}/cms/guides`, dbGuide);
};

/**
 * Patches a guide in the database
 *
 * @param {Guide} guide
 * @returns {Promise<Guide>}
 */
export const patchGuide = (guide) => {
    const dbGuide = transformToDBFormat(guide);
    return patch(`${BASE_URL}/cms/guides/${guide.id}`, dbGuide).then(
        transformFromDBFormat,
    );
};

/**
 * Patches a guide's single field in the database
 *
 * @param {Guide} guide
 * @param {string} fieldName
 * @param {any} value
 * @returns {Promise<Guide>}
 */
export const patchGuideSingleField = (guide, fieldName, value) => {
    return patch(`${BASE_URL}/cms/guides/${guide.id}`, {
        [fieldName]: value,
    }).then(transformFromDBFormat);
};

/**
 * Converts a guide to the format needed for the DB
 *
 * @param {Guide} guide
 * @returns {DBGuide}
 */
function transformToDBFormat(guide) {
    // cover_image_id should be undefined when cover_image is undefined, to avoid changes
    const cover_image_id =
        typeof guide.cover_image === 'undefined'
            ? undefined
            : guide.cover_image?.id || null;

    return {
        publish_status_id: guide.publish_status.id,
        main_editor_id: guide.main_editor_id,
        location_name_custom: guide.location_name_custom,
        location_id: guide.location.id,
        guide_type_id: guide.guide_type.id,
        guide_ranking_id: guide.guide_ranking.id,
        tagline: guide.tagline,
        description: guide.description,
        editor_notes: guide.editor_notes,
        cover_image_id,
        viewport_area: JSON.stringify(guide.viewport_area),
        search_area: JSON.stringify(guide.search_area),
        quick_facts: fixQuickFacts(guide.quick_facts),
        reviewed_at: guide.reviewed_at,
        orientation_content_only: guide.orientation_content_only,
        meta_description: guide.meta_description,
    };
}

/**
 *
 * @param {QuickFact[]} quickFacts
 */
function fixQuickFacts(quickFacts) {
    if (!quickFacts) {
        return [];
    }
    const nonEmpty = quickFacts.filter(({ title, body }) => !!title && !!body);
    return nonEmpty;
}

/**
 * Gets a guide from the DB
 *
 * @param {Id} guideId +
 * @returns {Promise<Guide>}
 */
export const getGuide = (guideId) => {
    return get(`${BASE_URL}/cms/guides/${guideId}`).then(transformFromDBFormat);
};

/**
 * Converts a guide from DB format to our own
 *
 * @param {GuideFromDB} guide
 * @returns {Guide}
 */
function transformFromDBFormat(guide) {
    return {
        ...guide,
        viewport_area: JSON.parse(guide.viewport_area),
        search_area: JSON.parse(guide.search_area),
    };
}
