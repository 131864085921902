import React from 'react';
import { Alert, Button, Select, Spin } from 'antd';
import {
    labelName,
    refetchLabels,
    useLabelTypes,
} from 'utils/collection-spot-label-utils';

/**
 * @param {Object} props
 * @param {Id} props.value
 * @param {(newVal: Id) => void} props.onChange
 */
export function SpotLabelSelector({ value, onChange }) {
    let { labelTypes, error, isLoading } = useLabelTypes();

    if (error) {
        return (
            <div className="line-center">
                <Alert
                    className="grow-full-flex"
                    message="There was an error loading"
                    type="error"
                    showIcon
                />
                <div className="mr-sm"></div>
                <Button size="large" onClick={() => refetchLabels()}>
                    Try again
                </Button>
            </div>
        );
    }

    const selectedLabel = labelTypes.find((label) => label.id === value);

    return (
        <Select
            allowClear
            placeholder={isLoading ? 'Loading...' : 'None'}
            notFoundContent={isLoading ? <Spin size="small" /> : null}
            filterOption={false}
            value={selectedLabel?.id}
            // @ts-ignore
            onChange={
                /**
                 * @param {number} id
                 */
                (id) => {
                    onChange(id);
                }
            }
            style={{ width: '100%' }}
            size="large"
        >
            {labelTypes.map((label) => (
                <Select.Option key={label.id} value={label.id}>
                    {labelName(label?.name)}
                </Select.Option>
            ))}
        </Select>
    );
}
