export const IMAGE = 0;
export const VIDEO = 1;

/**
 * Checks if a media object is a image
 *
 * @param {AnyMedia} media
 * @returns {boolean}
 */
export const isImage = (media) => {
    return media.type === IMAGE || !media.type; // We check also for media without type, just to make sure old media is considered image too.
};

/**
 * Checks if a media object is a video
 *
 * @param {AnyMedia} media
 * @returns {boolean}
 */
export const isVideo = (media) => {
    return media.type === VIDEO;
};
