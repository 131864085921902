import { get } from 'utils/http';
import { BASE_URL } from 'constants/env';

/**
 * Searches step spots with tip related to a step spot
 *
 * @param {PSSpot_v15_Minimal} spot
 * @returns {Promise<StepSpot[]>}
 */
export const getStepSpotsWithTips = (spot) => {
    return get(`${BASE_URL}/cms/spots/${spot.id}/public_step_spots`);
};
