/**
 * Gets all the loaded number of days items associated with a guide
 */
export const getNumberOfDaysItemsForGuide = (
    state: RootState,
    guideId: Id | undefined,
): NumberOfDaysItem[] | null => {
    if (!guideId) return null;

    const allNumberOfDaysItemsHash = getNumberOfDaysItemsInfo(state, guideId)
        .byId;
    if (!allNumberOfDaysItemsHash) return null;

    const allNumberOfDaysItems = Object.values(allNumberOfDaysItemsHash);
    return allNumberOfDaysItems.sort((a, b) => {
        return (b.order || 0) - (a.order || 0);
    });
};

/**
 * Checks if number of days items associated with a guide are being loaded
 *
 * @param {RootState} state
 * @param {Id} guideId
 * @returns {boolean}
 */
export const isLoadingNumberOfDaysItemsForGuide = (
    state: RootState,
    guideId?: Id,
): boolean => {
    if (!guideId) return false;

    return getNumberOfDaysItemsInfo(state, guideId).loading;
};

/**
 * Gets error (if any) loading number of days items associated with a guide
 */
export const getErrorLoadingNumberOfDaysItemsForGuide = (
    state: RootState,
    guideId?: Id,
): string | null => {
    if (!guideId) return null;

    return getNumberOfDaysItemsInfo(state, guideId).error;
};

/**
 * Checks if we are saving number of days items items at the moment
 */
export const isSavingNumberOfDaysItems = (state: RootState): boolean => {
    return state.numberOfDaysItems.isSavingNumberOfDaysItems;
};

/**
 * Gets the state of number of days items associated to a guide. If non existing, will return a default value
 */
function getNumberOfDaysItemsInfo(
    state: RootState,
    guideId: Id,
): NumberOfDaysItemsPerGuideState {
    return (
        state.numberOfDaysItems.byGuideId[guideId] || {
            loading: false,
            error: null,
            byId: null,
        }
    );
}
