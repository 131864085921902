import { BASE_URL } from 'constants/env';
import { get, patch, post, postDelete } from 'utils/http';

type NumberOfDaysResponse = {
    itineraries: NumberOfDaysItem[];
};

const defaultOptions = {
    headers: {
        'Polarsteps-Api-Version': '53',
    },
};

/**
 * Gets all the number of days items associated with a guide
 */
export const getNumberOfDaysItemsForGuide = (
    guideId: Id | undefined,
): Promise<NumberOfDaysItem[]> => {
    return get<NumberOfDaysResponse>(
        `${BASE_URL}/cms/guides/${guideId}/itinerary`,
        defaultOptions,
    ).then((result) => {
        return result.itineraries;
    });
};

/**
 * Adds a number of days item to the database
 */
export const addNumberOfDaysItem = async (
    numberOfDays: NumberOfDaysItem,
): Promise<NumberOfDaysItem> => {
    return post(
        `${BASE_URL}/cms/guides/itinerary`,
        toDBFormat(numberOfDays),
        defaultOptions,
    );
};

/**
 * Patches a number of days in the database
 */
export const patchNumberOfDaysItem = async (
    newNumberOfDays: NumberOfDaysItem,
): Promise<NumberOfDaysItem> => {
    const patchedNumberOfDaysItem = await patch<NumberOfDaysItem>(
        `${BASE_URL}/cms/guides/itinerary/${newNumberOfDays.id}`,
        toDBFormat(newNumberOfDays),
        defaultOptions,
    );

    return patchedNumberOfDaysItem;
};

/**
 * Removes a number of days item to the database
 */
export const deleteNumberOfDaysItem = async (
    numberOfDays: NumberOfDaysItem,
) => {
    const deletionResult = await postDelete(
        `${BASE_URL}/cms/guides/itinerary/${numberOfDays.id}`,
        {
            returnPlainResponse: true,
            ...defaultOptions,
        },
    );

    numberOfDays.spots.forEach(async (spot) => {
        deleteNumberOfDaysSpot(spot);
    });

    return deletionResult;
};

/**
 * Adds a number of days spot to the database
 */
export const addNumberOfDaysSpot = async (
    numberOfDaysId: Id,
    spot: NumberOfDaysSpot,
): Promise<NumberOfDaysSpot> => {
    return post(
        `${BASE_URL}/cms/guides/itinerary/${numberOfDaysId}/spot`,
        spotToDBFormat(spot),
        defaultOptions,
    );
};

/**
 * Patches a number of days spot in the database
 */
export const patchNumberOfDaysSpot = async (
    spot: NumberOfDaysSpot,
): Promise<NumberOfDaysSpot> => {
    const patchedNumberOfDaysSpot = await patch<NumberOfDaysSpot>(
        `${BASE_URL}/cms/guides/itinerary/spot/${spot.id}`,
        spotToDBFormat(spot),
        defaultOptions,
    );

    return patchedNumberOfDaysSpot;
};

/**
 * Removes a number of days spot to the database
 */
export const deleteNumberOfDaysSpot = async (spot: NumberOfDaysSpot) => {
    const deletionResult = await postDelete(
        `${BASE_URL}/cms/guides/itinerary/spot/${spot.id}`,
        {
            returnPlainResponse: true,
            ...defaultOptions,
        },
    );
    return deletionResult;
};

/**
 * Converts a number of days item to the format needed for the DB
 */
function toDBFormat(numberOfDays: NumberOfDaysItem): DBNumberOfDaysItem {
    return {
        guide_id: numberOfDays.guide_id,
        title: numberOfDays.title,
        description: numberOfDays.description,
        order: numberOfDays.order,
        editor_notes: numberOfDays.editor_notes,
    };
}

/**
 * Converts a number of days item to the format needed for the DB
 */
function spotToDBFormat(spot: NumberOfDaysSpot): DBNumberOfDaysSpot {
    return {
        alt_spot_name: spot.alt_spot_name,
        description: spot.description,
        order: spot.order,
        internal_id: spot.internal_id,
    };
}
