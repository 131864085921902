import React, { useEffect, useState, useMemo } from 'react';
import { Card, Tag } from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Loading from 'components/Loading';
import CollectionSpot from '../CollectionSpot';
import {
    orderTopCollectionSpots,
    isSpotFromSources,
} from 'utils/collection-spot-utils.js';
import { refreshTopCollectionsForGuide } from 'api/collections.api';
import {
    patchCollectionSpot,
    refreshCollectionSpots,
} from 'api/collection-spots.api';
import uniq from 'lodash.uniq';
import './style.css';

const SortableItem = SortableElement(
    /**
     * @param {Object} props
     * @param {CollectionSpot} props.spot
     * @param {Source[]} props.filterBySources
     */
    ({ spot, filterBySources }) => (
        <CollectionSpot
            collectionSpot={spot}
            collection={spot.collection}
            isDimmed={!isSpotFromSources(spot, filterBySources)}
        />
    ),
);

const SortableList = SortableContainer(
    /**
     * @param {Object} props
     * @param {CollectionSpot[]} props.items
     * @param {Source[]} props.filterBySources
     * @param {boolean} props.isLoading
     */
    ({ items, filterBySources, isLoading }) => {
        return (
            <div className="collection__content-spots">
                {isLoading && (
                    <div className="collection__content-spots-loading">
                        <Loading />
                    </div>
                )}

                {items.map((spot, index) => (
                    // @ts-ignore
                    <SortableItem
                        key={`item-${spot.id}`}
                        index={index}
                        spot={spot}
                        filterBySources={filterBySources}
                    />
                ))}
            </div>
        );
    },
);

/**
 *
 * @param {Object} props
 * @param {TopCollection} props.collection
 * @param {Guide} props.guide
 * @param {Source[]} props.filterBySources
 */
function TopCollection({ collection, filterBySources, guide }) {
    const orderedSpots = useMemo(
        () => orderTopCollectionSpots(collection.spots),
        [collection.spots],
    );
    const [isLoading, setIsLoading] = useState(false);
    const [spotsToShow, setSpotsToShow] = useState(orderedSpots);

    useEffect(() => {
        setSpotsToShow(orderedSpots);
    }, [orderedSpots]);

    /**
     * @param {number} oldIndex
     * @param {number} newIndex
     */
    const reorderSpots = async (oldIndex, newIndex) => {
        /** @type {Object.<Id, CollectionSpot>} */
        const spotsHash = orderedSpots.reduce(
            (acc, spot) => ({ ...acc, [spot.id]: spot }),
            {},
        );
        /** @type {CollectionSpot[]} */
        const reorderedSpots = arrayMove(
            orderedSpots,
            oldIndex,
            newIndex,
        ).map((spot, index) => ({ ...spot, top_spot_order: index }));

        // Update right away to give fast feedback
        setSpotsToShow(reorderedSpots);

        const spotsToUpdate = reorderedSpots.filter(
            (spot) => spotsHash[spot.id].top_spot_order !== spot.top_spot_order,
        );
        setIsLoading(true);
        await Promise.all(
            spotsToUpdate.map((correctOrderSpot) =>
                patchCollectionSpot(correctOrderSpot, undefined),
            ),
        );
        if (spotsToUpdate.length) {
            await refreshTopCollectionsForGuide(guide.id);
        }
        const collectionsToRefresh = uniq(
            spotsToUpdate.map((spot) => spot.collection_id),
        );
        collectionsToRefresh.forEach((collectionId) =>
            refreshCollectionSpots(collectionId, guide.id),
        );
        setIsLoading(false);
    };

    return (
        <div className="collection top-collection">
            <Card
                bodyStyle={{ padding: 0 }}
                headStyle={{ paddingLeft: 16 }}
                title={
                    <div className="collection__title">
                        <div className="collection__header-title">
                            {topCollectionName(collection.name)}

                            {!!spotsToShow?.length && (
                                <div className="ml-s">
                                    <Tag>{spotsToShow?.length} Spots</Tag>
                                </div>
                            )}
                        </div>
                    </div>
                }
            >
                <div className="collection__content">
                    {/* 
                    // @ts-ignore */}
                    <SortableList
                        isLoading={isLoading}
                        items={spotsToShow}
                        filterBySources={filterBySources}
                        distance={10}
                        axis="xy"
                        helperClass="collection-spot__dragging"
                        onSortEnd={({ oldIndex, newIndex }) =>
                            reorderSpots(oldIndex, newIndex)
                        }
                    />
                </div>
            </Card>
        </div>
    );
}

function topCollectionName(type) {
    switch (type) {
        case 'EAT_DRINK':
            return 'Top Spots to Eat & Drink';
        case 'SEE_DO':
            return 'Top Things to See & Do';
        case 'STAY':
            return 'Top Places to Stay';
        case 'TRAVEL_TRANSPORTATION':
            return 'Travel & Transportation';
        default:
            return type;
    }
}

export default TopCollection;
