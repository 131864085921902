import memoize from 'lodash/memoize';
import { get } from 'utils/http';
import { getArchivedFromList } from 'utils/publish-status-utils.js';
import { BASE_URL } from 'constants/env';
import { withCache } from 'utils/fetch-cache';

export const PUBLISH_STATUS_SWR_KEY = '/cms/publish_status';

/**
 * Gets the possible publish status from the database
 *
 * @returns {Promise<PublishStatus[]>}
 */
export const getAllPublishStatus = () => {
    return withCache(`${BASE_URL}/cms/publish_status`, get).then(
        (result = {}) => result.objects || [],
    );
};

/**
 * Gets a filter to be sent to flask restless to avoid getting items with publish_status = archived
 *
 * @param {boolean} omitArchived
 * @returns {Promise<import('utils/flask-restless-filters').FRFilter>}
 */
export const getFilterForArchived = memoize((omitArchived) => {
    if (!omitArchived) {
        return Promise.resolve(null); // No filter needed
    }

    return getAllPublishStatus()
        .then(getArchivedFromList)
        .then((archivedStatus) => {
            return {
                name: 'publish_status_id',
                op: '!=',
                val: archivedStatus.id + '',
            };
        });
});
