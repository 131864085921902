import React from 'react';
import { Tag } from 'antd';
import { useAllCollectionSpotsFromGuide } from 'api/collection-spots.api';

/**
 * @param {Object} props
 * @param {Guide} props.guide
 */
function GuideSpotCount({ guide }) {
    const { spots: allSpots } = useAllCollectionSpotsFromGuide(guide?.id);

    if (typeof allSpots?.length !== 'number') {
        return null;
    }

    return (
        <div className="line-center">
            <Tag color="blue">{allSpots?.length} Spots</Tag>
        </div>
    );
}

export default GuideSpotCount;
