export const factOptions = [
    {
        label: 'Accessibility',
        options: [
            {
                label: '♿️ Wheelchair accessible',
                value: '♿️ Wheelchair accessible',
            },
            {
                label: '♿️ Partly wheelchair accessible',
                value: '♿️ Partly wheelchair accessible',
            },
            {
                label: '🦻 Assistive listening devices available',
                value: '🦻 Assistive listening devices available',
            },
            {
                label: '👋 Sign language-interpreted performances',
                value: '👋 Sign language-interpreted performances',
            },
            { label: '💬 Audio-described', value: '💬 Audio-described' },
            {
                label: '💬 Open-captioned performances',
                value: '💬 Open-captioned performances',
            },
            {
                label: '💬 Open-captioned screenings',
                value: '💬 Open-captioned screenings',
            },
            {
                label: 'ℹ️ Multiple accessibility features',
                value: 'ℹ️ Multiple accessibility features',
            },
            { label: 'ℹ️ No elevator', value: 'ℹ️ No elevator' },
        ],
    },
    {
        label: 'values',
        options: [
            {
                label: '💜 Black-owned business',
                value: '💜 Black-owned business',
            },
            {
                label: '💜 Woman-owned business',
                value: '💜 Woman-owned business',
            },
            {
                label: '💜 Indigenous-owned business',
                value: '💜 Indigenous-owned business',
            },
            { label: '❤️ Social enterprise', value: '❤️ Social enterprise' },
            {
                label: '❤️ Donates profits to local charities',
                value: '❤️ Donates profits to local charities',
            },
            {
                label: '❤️ Non-profit organization',
                value: '❤️ Non-profit organization',
            },
            {
                label: '❤️ Employs former inmates',
                value: '❤️ Employs former inmates',
            },
            {
                label: '💚 Sustainable business practices',
                value: '💚 Sustainable business practices  ',
            },
            {
                label: '💚 Plastic-free business',
                value: '💚 Plastic-free business',
            },
            { label: '💚 Solar-powered stay', value: '💚 Solar-powered stay' },
            {
                label: '🌎 UNESCO World Heritage Site',
                value: '🌎 UNESCO World Heritage Site',
            },
        ],
    },
    {
        label: 'See&Do',
        options: [
            { label: '💸 Free entry', value: '💸 Free entry' },
            { label: '💸 Free on xx', value: '💸 Free on xx' },
            {
                label: '💸 Free entry (donations encouraged)',
                value: '💸 Free entry (donations encouraged)',
            },
            {
                label: '💸 Free entry (ticket required for X)',
                value: '💸 Free entry (ticket required for X)',
            },
            {
                label: '🗓 Advance booking required',
                value: '🗓 Advance booking required',
            },
            { label: '🎟 Paid entry', value: '🎟 Paid entry' },
            {
                label: '💯 Discounted rates for online bookings',
                value: '💯 Discounted rates for online bookings',
            },
            { label: '🗓 Open X–Y', value: '🗓 Open X–Y' },
            { label: '🗓 Runs X–Y', value: '🗓 Runs X–Y' },
            { label: '🗓 Takes place in X', value: '🗓 Takes place in X' },
            { label: '🗓 Closed on X', value: '🗓 Closed on X' },
            {
                label: '🗓 Seasonal event (Nov-Dec)',
                value: '🗓 Seasonal event (Nov-Dec)',
            },
            { label: '🕓 Quietest before X', value: '🕓 Quietest before X' },
            { label: '🕓 Busiest at X', value: '🕓 Busiest at X' },
            { label: '🚫 No public access', value: '🚫 No public access ' },
            {
                label: '📝 Information displayed in X and Y',
                value: '📝 Information displayed in X and Y',
            },
            {
                label: '📱 Free guide available via museum app',
                value: '📱 Free guide available via museum app',
            },
            { label: '🚩 Free guided tours', value: '🚩 Free guided tours' },
            {
                label: '🚩 Guided tours available',
                value: '🚩 Guided tours available',
            },
            {
                label: '🎧 Free audio guides in X and Y',
                value: '🎧 Free audio guides in X and Y ',
            },
            {
                label: '🎧 Audio guides in X and Y',
                value: '🎧 Audio guides in X and Y ',
            },
            { label: '🔑 Lockers available', value: '🔑 Lockers available' },
            {
                label: '🔑 On-site changing facilities',
                value: '🔑 On-site changing facilities',
            },
            {
                label: '👶 Baby changing and breastfeeding facilities',
                value: '👶 Baby changing and breastfeeding facilities',
            },
            { label: '📷 No photography', value: '📷 No photography' },
            {
                label: '📷 No flash photography',
                value: '📷 No flash photography',
            },
            { label: '🪆 Handicrafts market', value: '🪆 Handicrafts market ' },
            { label: '🍎 Produce market', value: '🍎 Produce market' },
            { label: '🕰️ Flea market', value: ' 🕰️ Flea market' },
            { label: '🌷 Flower market', value: '🌷 Flower market' },
            {
                label: '🗓 Hosts regular events',
                value: '🗓 Hosts regular events',
            },
            {
                label: '🗓 Hosts regular activities',
                value: '🗓 Hosts regular activities',
            },
            {
                label: 'ℹ️ Head covering required',
                value: 'ℹ️ Head covering required',
            },
            { label: 'ℹ️ Remove shoes', value: 'ℹ️ Remove shoes' },
            {
                label: 'ℹ️ Modest dress required',
                value: 'ℹ️ Modest dress required',
            },
            { label: '🚹 Men only', value: '🚹 Men only' },
            { label: '🚺 Women only', value: '🚺 Women only ' },
            {
                label: '🏊 Lifeguard on duty (X–Y)',
                value: '🏊 Lifeguard on duty (X–Y)',
            },
            { label: '🍑 Nudist beach', value: '🍑 Nudist beach' },
            { label: '🐕 Dogs allowed', value: '🐕 Dogs allowed' },
            {
                label: '🐕 Dogs allowed on leash',
                value: '🐕 Dogs allowed on leash',
            },
            { label: '🐕 No dogs', value: '🐕 No dogs' },
            { label: '🚴 Cyclists allowed', value: '🚴 Cyclists allowed' },
            { label: '🚴 No cycling', value: '🚴 No cycling' },
            {
                label: '🥾 Easy Xkm/Xhr hiking trail',
                value: '🥾 Easy Xkm/Xhr hiking trail',
            },
            {
                label: '🥾 Moderate Xkm/Xhr hiking trail',
                value: '🥾 Moderate Xkm/Xhr hiking trail',
            },
            {
                label: '🥾 Advanced Xkm/Xhr hiking trail',
                value: '🥾 Advanced Xkm/Xhr hiking trail',
            },
            {
                label: '🥾 Multi-day hiking trail',
                value: '🥾 Multi-day hiking trail',
            },
            { label: '🥾 Xkm loop trail', value: '🥾 Xkm loop trail' },
            { label: '🥾 Xkm return trail', value: '🥾 Xkm return trail' },
            { label: '🏆 Great for X', value: '🏆 Great for X ' },
            { label: '📄 Paid park entry', value: '📄 Paid park entry' },
            { label: '📄 Permit required', value: '📄 Permit required' },
            {
                label: '📄 Permit required (pay upon entry)',
                value: '📄 Permit required (pay upon entry)',
            },
            {
                label: '📄 Permission required',
                value: '📄 Permission required',
            },
            {
                label: '🛻 4WD vehicle required',
                value: '🛻 4WD vehicle required ',
            },
            {
                label: '🛻 Off-road vehicle required',
                value: '🛻 Off-road vehicle required ',
            },
            {
                label: '☀️ Weather conditions permitting',
                value: '☀️ Weather conditions permitting',
            },
            {
                label: '👟 Walking tour (X hours)',
                value: '👟 Walking tour (X hours)',
            },
            { label: '🚌 Bus tour (X hours)', value: '🚌 Bus tour (X hours)' },
            {
                label: '🏍 Motorbike tour (X hours)',
                value: '🏍 Motorbike tour (X hours)',
            },
            {
                label: '🎨 Creative workshop (X hours)',
                value: '🎨 Creative workshop (X hours)',
            },
            {
                label: '🧑‍🍳 Cooking class (X hours)',
                value: '🧑‍🍳 Cooking class (X hours)',
            },
            {
                label: '💸 Free tour (tipping encouraged)',
                value: '💸 Free tour (tipping encouraged)',
            },
            { label: '⏱️ X–Y hour(s)', value: '⏱️ X–Y hour(s) ' },
            { label: '📣 Hosted in X', value: '📣 Hosted in X' },
            { label: '📣 Hosted in X and Y', value: '📣 Hosted in X and Y' },
            { label: '🛶 Equipment provided', value: '🛶 Equipment provided' },
            {
                label: '🛶 Bikes and helmets provided',
                value: '🛶 Bikes and helmets provided',
            },
            {
                label: '🛶 Equipment rental available',
                value: '🛶 Equipment rental available',
            },
            {
                label: '🏅 Suitable for beginners',
                value: '🏅 Suitable for beginners',
            },
            {
                label: '🏅 Moderate experience required',
                value: '🏅 Moderate experience required',
            },
            { label: '🏅 Experts only', value: '🏅 Experts only' },
            { label: '📍 Pick-up in X', value: '📍 Pick-up in X' },
            {
                label: '📍 Hotel pick-up in X available',
                value: '📍 Hotel pick-up in X available',
            },
            { label: '📍 Departs from X', value: '📍 Departs from X' },
            { label: '🍴 Includes lunch', value: '🍴 Includes lunch' },
            { label: '🌐 Passport required', value: '🌐 Passport required' },
            {
                label: '💵 Optional extras (admission to X)',
                value: '💵 Optional extras (admission to X)',
            },
            { label: '👋 Local guide', value: '👋 Local guide' },
            { label: '👋 Local host', value: '👋 Local host' },
            { label: '🎫 License required', value: '🎫 License required' },
            {
                label: '🎫 No license required',
                value: '🎫 No license required',
            },
        ],
    },
    {
        label: 'Eat&Drink',
        options: [
            { label: '💵 Cash only', value: '💵 Cash only' },
            { label: '🏧 ATM inside', value: '🏧 ATM inside' },
            { label: '💳 Card payments only', value: '💳 Card payments only' },
            { label: '💰 Cover charge', value: '💰 Cover charge' },
            {
                label: '💰 Occasional cover charges',
                value: '💰 Occasional cover charges',
            },
            { label: '🎟 Ticketed entry', value: '🎟 Ticketed entry' },
            {
                label: '🎵 Great for live music',
                value: '🎵 Great for live music',
            },
            { label: '🎤 Great for comedy', value: '🎤 Great for comedy' },
            { label: '🎵 Great for dancing', value: '🎵 Great for dancing' },
            {
                label: '🎵 Frequent live music',
                value: '🎵 Frequent live music',
            },
            { label: '🐕 Dog friendly', value: '🐕 Dog friendly' },
            { label: '🗓 Walk-ins only', value: '🗓 Walk-ins only' },
            {
                label: '🗓 Walk-ins only (expect to wait)',
                value: '🗓 Walk-ins only (expect to wait)',
            },
            {
                label: '🗓 Reservations recommended',
                value: '🗓 Reservations recommended',
            },
            { label: '🏆 Great for X', value: '🏆 Great for X' },
            { label: '🏆 Known for X', value: '🏆 Known for X' },
            { label: '🏆 Famous for X', value: '🏆 Famous for X ' },
            { label: '⏱ Expect a queue', value: '⏱ Expect a queue' },
            { label: '🎓 Student hangout', value: '🎓 Student hangout' },
            { label: '🏳️‍🌈 Queer inclusive', value: '🏳️‍🌈 Queer inclusive' },
            { label: '🏳️‍🌈 Gay bar', value: '🏳️‍🌈 Gay bar' },
            { label: '🏳️‍🌈 Lesbian bar', value: '🏳️‍🌈 Lesbian bar' },
            { label: '🏳️‍🌈 LGBTQ+ inclusive', value: '🏳️‍🌈 LGBTQ+ inclusive ' },
            {
                label: '🍕 Allows food delivery',
                value: '🍕 Allows food delivery',
            },
            { label: '🌯 BYO food', value: '🌯 BYO food' },
            { label: '🍷 BYOB (corkage fee)', value: '🍷 BYOB (corkage fee)' },
            {
                label: '🍷 Alcohol-free business (allows BYOB)',
                value: '🍷 Alcohol-free business (allows BYOB)',
            },
            { label: '🍴 Set lunch', value: '🍴 Set lunch' },
            {
                label: '🍴 Tasting menu available',
                value: '🍴 Tasting menu available',
            },
            { label: '💻 Laptop friendly', value: '💻 Laptop friendly' },
            { label: '💻 No laptops', value: '💻 No laptops' },
            { label: '📵 No phones', value: '📵 No phones' },
            {
                label: '☀️ Heated outdoor seating available',
                value: '☀️ Heated outdoor seating available',
            },
            {
                label: '☀️ Outdoor seating available',
                value: '☀️ Outdoor seating available',
            },
            {
                label: '☀️ Outdoor seating only',
                value: '☀️ Outdoor seating only',
            },
            { label: '👞 Dress code', value: '👞 Dress code' },
            { label: '📍 Local chain', value: '📍 Local chain' },
            { label: '📍 National chain', value: '📍 National chain' },
            { label: '🍷 Wine bar', value: '🍷 Wine bar' },
            { label: '🍺 Beer hall', value: '🍺 Beer hall' },
            { label: '🍸 Cocktail bar', value: '🍸 Cocktail bar' },
            { label: '🍸 Nightclub', value: '🍸 Nightclub' },
            { label: '🪑 Limited seating', value: '🪑 Limited seating' },
        ],
    },
    {
        label: 'Dietry',
        options: [
            {
                label: '🥦 Vegetarian restaurant',
                value: '🥦 Vegetarian restaurant',
            },
            {
                label: '🥦 Multiple vegetarian options',
                value: '🥦 Multiple vegetarian options ',
            },
            { label: '🌱 Vegan restaurant', value: '🌱 Vegan restaurant' },
            {
                label: '🌱 Multiple vegan options',
                value: '🌱 Multiple vegan options',
            },
            {
                label: '🥩 Limited vegetarian options',
                value: '🥩 Limited vegetarian options',
            },
            {
                label: '🍴 Multiple gluten-free options',
                value: '🍴 Multiple gluten-free options',
            },
            {
                label: '🍴 Caters to dietary restrictions',
                value: '🍴 Caters to dietary restrictions',
            },
            { label: '🔪 Kosher kitchen', value: '🔪 Kosher kitchen' },
            { label: '🔪 Halal kitchen', value: '🔪 Halal kitchen' },
            {
                label: '🔪 Gluten-free kitchen',
                value: '🔪 Gluten-free kitchen',
            },
            {
                label: '🌾 Offers plant-based milks',
                value: '🌾 Offers plant-based milks',
            },
        ],
    },
    {
        label: 'Stay',
        options: [
            {
                label: '🚘 Free street parking',
                value: '🚘 Free street parking',
            },
            {
                label: '🚘 Paid street parking',
                value: '🚘 Paid street parking',
            },
            {
                label: '🚘 Free on-site parking',
                value: '🚘 Free on-site parking',
            },
            {
                label: '🚘 Paid on-site parking',
                value: '🚘 Paid on-site parking',
            },
            {
                label: '🚖 Free airport shuttle',
                value: '🚖 Free airport shuttle',
            },
            {
                label: '🚖 Airport pick-up available',
                value: '🚖 Airport pick-up available',
            },
            {
                label: '🍴 Fully equipped kitchen',
                value: '🍴 Fully equipped kitchen',
            },
            {
                label: '🍴 Basic cooking facilities',
                value: '🍴 Basic cooking facilities',
            },
            { label: '🚿 Shared bathroom', value: '🚿 Shared bathroom' },
            { label: '🍴 Shared kitchen', value: '🍴 Shared kitchen' },
            { label: '🧦 Laundry facilities', value: '🧦 Laundry facilities' },
            {
                label: '🧦 Shared laundry facilities',
                value: '🧦 Shared laundry facilities',
            },
            {
                label: '🍳 Breakfast available',
                value: '🍳 Breakfast available',
            },
            { label: '🍳 Breakfast included', value: '🍳 Breakfast included ' },
            { label: '🐕 Pet friendly', value: '🐕 Pet friendly' },
            { label: '🐕 Dog friendly', value: '🐕 Dog friendly ' },
            {
                label: '🎈 Great for young families',
                value: '🎈 Great for young families',
            },
            { label: '🏳️‍🌈 Queer inclusive', value: '🏳️‍🌈 Queer inclusive' },
            { label: '🏳️‍🌈 Gay men’s resort', value: '🏳️‍🌈 Gay men’s resort' },
            {
                label: '🧼 Basic toiletries provided',
                value: '🧼 Basic toiletries provided',
            },
            { label: '📶 High-speed WiFi', value: '📶 High-speed WiFi' },
            { label: '📶 No WiFi', value: '📶 No WiFi' },
            { label: '🛎️ 24hr reception', value: '🛎️ 24hr reception' },
            { label: '🍸 On-site bar', value: '🍸 On-site bar' },
            { label: '🍴 On-site cafe', value: '🍴 On-site cafe' },
            {
                label: '💦 Outdoor swimming pool',
                value: '💦 Outdoor swimming pool',
            },
            {
                label: '💦 Indoor swimming pool',
                value: '💦 Indoor swimming pool',
            },
            { label: '💦 Hot tub', value: '💦 Hot tub' },
            { label: '💦 Sauna', value: '💦 Sauna' },
            { label: '🏋️ Fitness facilities', value: '🏋️ Fitness facilities' },
            {
                label: '🧖 Wellness facilities',
                value: '🧖 Wellness facilities ',
            },
            { label: '❄️ Air-conditioned', value: '❄️ Air-conditioned' },
            {
                label: '🚍 Accessible by public transport',
                value: '🚍 Accessible by public transport',
            },
            { label: '📵 No cell signal', value: '📵 No cell signal' },
            { label: '💸 Luxury choice', value: '💸 Luxury choice' },
            {
                label: '💸 Discounts for long-term stays',
                value: '💸 Discounts for long-term stays',
            },
            {
                label: '🚲 Free bikes available',
                value: '🚲 Free bikes available',
            },
            {
                label: '🚲 Rental bikes available',
                value: '🚲 Rental bikes available',
            },
        ],
    },
    {
        label: 'Camping',
        options: [
            { label: '⛺ Tent camping only', value: '⛺ Tent camping only' },
            {
                label: '⛺ Tent and RV camping',
                value: '⛺ Tent and RV camping ',
            },
            {
                label: '⛺ Tent and RV camping (cabins available)',
                value: '⛺ Tent and RV camping (cabins available)',
            },
            { label: '🚐 RV camping only', value: '🚐 RV camping only' },
            {
                label: '🔌 Powered pitches available',
                value: '🔌 Powered pitches available',
            },
            { label: '🛒 Camp store', value: '🛒 Camp store' },
            { label: '🚿 Free showers', value: '🚿 Free showers' },
            { label: '🚿 Paid showers', value: '🚿 Paid showers' },
            { label: '⛺️ Assigned pitches', value: '⛺️ Assigned pitches' },
            {
                label: '⛺️ Choose your own spot',
                value: '⛺️ Choose your own spot',
            },
            {
                label: '⛺️ Pre-pitched tents available',
                value: '⛺️ Pre-pitched tents available',
            },
            {
                label: '⛺️ Camping gear rental',
                value: '⛺️ Camping gear rental',
            },
            {
                label: '🏕️ Wild camping allowed',
                value: '🏕️ Wild camping allowed',
            },
            {
                label: '♨️ Free BBQ facilities',
                value: '♨️ Free BBQ facilities',
            },
            { label: '🪵 No Campfires', value: '🪵 No Campfires' },
            {
                label: '🪵 Campfires allowed (firewood available)',
                value: '🪵 Campfires allowed (firewood available)',
            },
            {
                label: '🪵 Campfires allowed (bring your own firewood)',
                value: '🪵 Campfires allowed (bring your own firewood)',
            },
            {
                label: '🪵 Campfires occasionally allowed',
                value: '🪵 Campfires occasionally allowed ',
            },
            { label: '🗓 Open seasonally', value: '🗓 Open seasonally' },
            { label: '🗓 Closed in winter', value: '🗓 Closed in winter' },
            { label: '📄 Permit required', value: '📄 Permit required' },
            {
                label: '📄 Permission required',
                value: '📄 Permission required',
            },
            { label: '⚡️ No power', value: '⚡️ No power' },
            { label: '⚡️ Off-grid', value: '⚡️ Off-grid' },
            {
                label: '🥖 Fresh bread available',
                value: '🥖 Fresh bread available',
            },
        ],
    },
    {
        label: 'Hostels',
        options: [
            {
                label: '💤 Single-sex dorms available',
                value: '💤 Single-sex dorms available',
            },
            {
                label: '💤 Women-only dorms available',
                value: '💤 Women-only dorms available',
            },
            {
                label: '💤 Private rooms available',
                value: '💤 Private rooms available',
            },
            {
                label: '💤 Private rooms and women-only dorms available',
                value: '💤 Private rooms and women-only dorms available',
            },
            {
                label: '🛏️ Bring your own bed linens',
                value: '🛏️ Bring your own bed linens',
            },
        ],
    },
    {
        label: 'Airbnbs',
        options: [
            { label: '🛌 Sleeps x', value: '🛌 Sleeps x' },
            {
                label: '🛌 Sleeps x (x bedrooms)',
                value: '🛌 Sleeps x (x bedrooms)',
            },
            { label: '🔐 Self check-in', value: '🔐 Self check-in' },
            { label: '🍴 Basic kitchenette', value: '🍴 Basic kitchenette' },
            { label: '🍴 Basic kitchen', value: '🍴 Basic kitchen' },
            {
                label: '🍴 Fully equipped kitchen',
                value: '🍴 Fully equipped kitchen',
            },
            { label: '🌳 Shared garden', value: '🌳 Shared garden' },
            { label: '☀️ Private terrace', value: '☀️ Private terrace' },
            {
                label: '🗓 X-night minimum stay',
                value: '🗓 X-night minimum stay',
            },
            { label: '🗓 X-week minimum stay', value: '🗓 X-week minimum stay' },
            {
                label: '💻 Dedicated workspace',
                value: '💻 Dedicated workspace',
            },
            { label: '🏡 Local host', value: '🏡 Local host' },
        ],
    },
    {
        label: 'Hotels',
        options: [
            {
                label: '🍴 Self-catering (basic kitchenette)',
                value: '🍴 Self-catering (basic kitchenette)',
            },
            { label: '👤 Adults only', value: '👤 Adults only' },
        ],
    },
];
