import React, { useState } from 'react';
import classNames from 'classnames';
import { Select, Spin, Typography } from 'antd';
import { SearchOutlined, WarningOutlined } from '@ant-design/icons';
import { useDebounceCallback } from '@react-hook/debounce';
import { search as searchSpots } from 'api/spots.api';
import { hasLocationInfo } from 'utils/ps-spot-utils';
import { parse } from 'utils/error-parser';
import './style.css';

const { Option } = Select;
const { Text } = Typography;

/**
 * This component will find spots. If you need them to be added on selection you can use SpotSelector, which wraps
 * this component and takes care of adding them automatically on selection
 *
 * @param {Object} props
 * @param {PSSpot_v15 | PSSpot_v15_Minimal} props.value
 * @param {string} [props.placeholder='Search spot']
 * @param {string} [props.selectingPlaceholder='Selecting spot...']
 * @param {PSLocation} props.location
 * @param {Guide} props.guide
 * @param {(tempSpot: PSSpot_v15_Minimal) => void} props.onSelect
 * @param {boolean} [props.disabled=false]
 * @param {boolean} [props.isAddingSpot=false]
 * @param {string?} [props.errorAdding=null]
 * @param {string} [props.className='']
 * @param {React.ReactNode} [props.customSearchIcon]
 * @param {boolean} [props.allowAddPolarstepsSpot=false] If true, there will be an option to add a Polarsteps custom Spot
 * @param {(newSpotName: string) => void} [props.onAddPolarstepsSpot=() => {}]
 */
function SpotSearch({
    className = '',
    value,
    placeholder = 'Search spot',
    selectingPlaceholder = 'Selecting spot...',
    location,
    guide,
    onSelect,
    disabled = false,
    isAddingSpot = false,
    errorAdding = null,
    customSearchIcon = null,
    allowAddPolarstepsSpot = false,
    onAddPolarstepsSpot = () => undefined,
}) {
    /** @type {[PSSpot_v15_Minimal[], import('react').Dispatch<PSSpot_v15_Minimal[]>]} */
    const [spots, setSpots] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [errorSearching, setErrorSearching] = useState(null);
    const [lastQuery, setLastQuery] = useState('');

    const POLARSTEPS_SPOT_ADD_VALUE = 'spot-search__add-polarsteps-spot-option';
    const getSelectValue = () => {
        if (!value) {
            return null;
        }
        if (spots.find((spot) => spot.id === value.id)) {
            return value.id;
        }
        return value.name;
    };

    // We need to add the add polarsteps spot content in different places because the way AntD works
    // If it was always added as an Option, the "empty content" part wouldn't be shown.
    const getAddPolarstepsSpotContent = (withResults) => {
        if (!allowAddPolarstepsSpot || !lastQuery.length) {
            return null;
        }
        const Tag = withResults ? Option : `div`; // Needs to be an Option when shown with more results
        return (
            <Tag
                className="spot-search__add-polarsteps-spot-option"
                value={POLARSTEPS_SPOT_ADD_VALUE}
                onClick={() => {
                    onAddPolarstepsSpot(lastQuery);
                }}
            >
                <div className="spot-search__add-polarsteps-spot">
                    Create spot &ldquo;{lastQuery}&rdquo;
                </div>
            </Tag>
        );
    };

    const getEmptyContent = () => {
        if (isSearching) {
            return (
                <div>
                    <div className="mb-xs">{getAddPolarstepsSpotContent()}</div>
                    <Spin size="small" />
                </div>
            );
        }
        if (isAddingSpot) {
            return <Spin size="small" />;
        }
        if (errorSearching || errorAdding) {
            return <Text type="danger">{errorSearching || errorAdding}</Text>;
        }
        if (!lastQuery) {
            return null;
        }
        return (
            <div>
                <div className="mb-xs">{getAddPolarstepsSpotContent()}</div>
                <Text type="secondary">No spots found</Text>
            </div>
        );
    };

    const handleChange = (value) => {
        if (!value) {
            onSelect(null);
            return;
        }
        if (value === POLARSTEPS_SPOT_ADD_VALUE) {
            onAddPolarstepsSpot(lastQuery);
            // onSelect(null);
            return;
        }
        const tempSpot = spots.find((spot) => spot.id === value);
        onSelect(tempSpot);
    };

    const doSearchV2 = useDebounceCallback(
        /**
         * @param {string} query
         * @param {Guide} guide
         * @param {PSLocation} location
         */
        (query, guide, location) => {
            searchSpots(query, guide, location)
                .then((result) => {
                    if (result.query !== query) {
                        return; // Result for a query that is not ours
                    }
                    setSpots(result.results.data);
                    setErrorSearching(null);
                })
                .catch((error) => {
                    setErrorSearching(parse(error));
                })
                .finally(() => {
                    setIsSearching(false);
                });
        },
        500,
        false,
    );

    const handleSearch = (newQuery) => {
        setLastQuery(newQuery);
        if (!newQuery) {
            setSpots([]);
            return;
        }
        setIsSearching(true);
        setErrorSearching(null);
        doSearchV2(newQuery, guide, location);
    };

    const shownValue = getSelectValue();

    return (
        <div className={classNames('spot-search', className)}>
            <Select
                className="spot-search__select"
                showSearch={true}
                value={shownValue}
                placeholder={isAddingSpot ? selectingPlaceholder : placeholder}
                defaultActiveFirstOption={false}
                filterOption={false}
                notFoundContent={getEmptyContent()}
                onSearch={handleSearch}
                onChange={handleChange}
                size="large"
                optionLabelProp="label"
                disabled={disabled}
                allowClear={!!shownValue}
                loading={isAddingSpot}
                suffixIcon={customSearchIcon || <SearchOutlined />}
            >
                {!isSearching &&
                    !!spots.length &&
                    getAddPolarstepsSpotContent(true)}

                {spots.map(getOptionFromStepSpot)}
            </Select>
        </div>
    );
}

export default SpotSearch;

/**
 *
 * @param {PSSpot_v15_Minimal} spot
 * @returns {*}
 */
function getOptionFromStepSpot(spot) {
    const noLocationSpot = !hasLocationInfo(spot);

    return (
        <Option
            key={spot.id}
            value={spot.id}
            // @ts-ignore
            label={spot.name}
        >
            <div className="spot-search__option line-center">
                {noLocationSpot && (
                    <WarningOutlined
                        className="spot-search__option-warning"
                        title="This spot has no location info"
                    />
                )}
                <span className="grow-full-flex spot-search__option-text">
                    {spot.name} ({spot.category_label})
                </span>
            </div>
            <div>
                <Text type="secondary">
                    {spot.location?.locality}, {spot.location?.country}
                </Text>
            </div>
        </Option>
    );
}
