import { useMemo } from 'react';
import useSWR from 'swr';
import { get } from 'utils/http';
import { useSharedCodebase } from './use-shared-codebase';

function useCategoryTree() {
    const {
        shared: sharedCodebase,
        error: errorLoadingLib,
    } = useSharedCodebase();
    const { data, error: errorLoadingJson } = useSWR(
        () => sharedCodebase.com.polarsteps.shared.core.CATEGORY_TREE_URL,
        (url) =>
            get(url, {
                credentials: 'omit',
            }),
        { revalidateOnFocus: false },
    );

    const error = errorLoadingLib || errorLoadingJson;

    return {
        sharedCodebase,
        tree: data,
        error,
        isLoading: !data && !error,
    };
}

/**
 *
 * @param {PSSpot_v15_Minimal | PolarstepsSpot} spot
 */
export const useCategoryInfo = (spot) => {
    const { sharedCodebase, tree, error, isLoading } = useCategoryTree();

    const categoryInfo = useMemo(() => {
        if (!sharedCodebase || !tree) {
            return null;
        }
        let core = sharedCodebase.polarstepsCore();
        const coreCategories = core.createCategoryTreeWithData(
            JSON.stringify(tree),
        );
        return coreCategories.findCategory(spot.sub_category);
    }, [sharedCodebase, tree, spot]);

    return { isLoading, error, categoryInfo };
};
