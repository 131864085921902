/**
 * Checks if two objects are the same (should be the same id if they exist)
 *
 * @param {*} a
 * @param {*} b
 * @returns {boolean}
 */
export const isSameObject = (a, b) => {
    if (a === b) {
        return true;
    }
    if ((!a && !!b) || (!!a && !b)) {
        return false;
    }
    return a?.id === b?.id;
};
