import React, { useMemo } from 'react';
import { useCategoryInfo } from 'utils/use-category-info';

/**
 * @param {Object} props
 * @param {PSSpot_v15_Minimal} props.spot
 */
function SpotCategory({ spot }) {
    const { categoryInfo } = useCategoryInfo(spot);
    const backgroundColor = useMemo(
        () => categoryInfo?.primaryColor() || '#8A99AD',
        [categoryInfo],
    );

    const icon = useMemo(() => categoryInfo?.smallIconSvg(), [categoryInfo]);

    const nameToShow = getNameForLabel(spot, categoryInfo);

    return (
        <div className="spot-label" title={nameToShow}>
            <div
                className="spot-label-bg"
                style={{
                    backgroundColor,
                }}
            ></div>
            <div className="spot-label-content">
                <div className="spot-label-icon">
                    {icon && <img src={icon} alt="" />}
                </div>

                <span>{nameToShow}</span>
            </div>
        </div>
    );
}

/**
 *
 * @param {PSSpot_v15_Minimal} spot
 * @param {any} category
 */
function getNameForLabel(spot, category) {
    if (spot.category_label) {
        return spot.category_label;
    }

    if (!category || category.isFallbackCategory()) {
        return null;
    }

    return category.name;
}

export default SpotCategory;
