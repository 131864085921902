import React from 'react';
import GenericSelectorSWR from 'components/GenericSelectorSWR';
import {
    PUBLISH_STATUS_SWR_KEY,
    getAllPublishStatus,
} from 'api/publish-status.api';

/**
 * @param {Object} props
 * @param {Id} props.value
 * @param {(newVal: Id) => void} props.onChange
 */
function PublishStatusSelector({ value, onChange }) {
    return (
        <GenericSelectorSWR
            value={value}
            onChange={onChange}
            swrKey={PUBLISH_STATUS_SWR_KEY}
            fetcher={getAllPublishStatus}
            placeholder="Select status"
        ></GenericSelectorSWR>
    );
}

export default PublishStatusSelector;
