import { Alert, Button } from 'antd';
import Loading from 'components/Loading';
import LoginWidget from 'components/LoginWidget';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authenticate, notifyGoogleSignOn } from 'store/auth/actions';
import {
    getErrorLoading,
    isInitted,
    isLoadingAuth,
    isLoggedIn,
} from 'store/auth/selectors';
import { useSelector } from 'utils/use-selector';

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
function AuthLayer({ children }) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(authenticate());
    }, [dispatch]);

    const loggedIn = useSelector(isLoggedIn);
    const loading = useSelector(isLoadingAuth);
    const initted = useSelector(isInitted);
    const errorLoading = useSelector(getErrorLoading);

    if (errorLoading) {
        return (
            <div className="full-page-content-center column-center">
                <Alert
                    message="Error authenticating"
                    description={errorLoading}
                    showIcon
                    type="error"
                />
                <div className="mt-sm"></div>
                <Button size="large" onClick={() => dispatch(authenticate())}>
                    Try again
                </Button>
            </div>
        );
    }

    if (!initted || loading) {
        return (
            <div className="full-page-content-center">
                <Loading />
            </div>
        );
    }

    if (!loggedIn) {
        return (
            <div className="full-page-content-center column-center">
                <LoginWidget
                    onSuccess={(user) => dispatch(notifyGoogleSignOn(user))}
                />
            </div>
        );
    }

    return <>{children}</>;
}

export default AuthLayer;
