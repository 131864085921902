/**
 * Gets an error and tries to parse it to a meaningful string
 *
 * @param {any} [error]
 * @returns {string}
 */
export const parse = (error = 'Unknown error') => {
    if (typeof error === 'string') {
        return error;
    }

    if (error.message) {
        return error.message;
    }

    if (error.statusText) {
        return error.statusText;
    }

    return JSON.stringify(error, undefined, 2);
};

/**
 * Gets an error and tries to parse it to a meaningful string, takes into account fetch Response errors
 *
 * @param {any} [error]
 * @returns {Promise<string>}
 */
export const parseFetchError = async (error = 'Unknown error') => {
    if (error instanceof Response) {
        const json = await error.json();
        if (json?.error) {
            return json?.error;
        }
        if (json) {
            return JSON.stringify(json, undefined, 2);
        }
    }
    return parse(error);
};
