import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Button, Popover, Typography, Select, Spin } from 'antd';
import { useAllSources } from 'api/sources.api';
import { FilterOutlined } from '@ant-design/icons';

const { Text } = Typography;

/**
 * @param {Object} props
 * @param {Source[]} props.sources
 * @param {(newSources: Source[]) => void} props.onChangeSources
 */
function SpotFilterSelector({ sources, onChangeSources }) {
    const { allSources, isLoading } = useAllSources();
    const allSourcesWithImportance = useMemo(() => {
        if (!allSources) {
            return null;
        }
        return orderSourceList(allSources);
    }, [allSources]);

    const selectedIds = useMemo(() => sources.map((source) => source.id), [
        sources,
    ]);

    return (
        <div className="guide-editor-collections__filter-button-container">
            <div className="guide-editor-collections__filter-button">
                <Popover
                    placement="bottomRight"
                    content={
                        <div>
                            <div className="mb-s">
                                <Text strong>Show spots from:</Text>
                            </div>

                            <Select
                                mode="multiple"
                                placeholder={
                                    isLoading ? 'Loading...' : 'All sources'
                                }
                                notFoundContent={
                                    isLoading ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                value={selectedIds}
                                onChange={(newIds) => {
                                    const fullSelectedValues = newIds.map(
                                        (newId) =>
                                            allSourcesWithImportance.find(
                                                (value) => value.id === newId,
                                            ),
                                    );
                                    onChangeSources(fullSelectedValues);
                                }}
                                style={{ minWidth: 256 }}
                                size="large"
                            >
                                {(allSourcesWithImportance || []).map(
                                    (source) => (
                                        <Select.Option
                                            key={source.id}
                                            value={source.id}
                                            className={classNames(
                                                'guide-editor-collections__option',
                                                {
                                                    'is-prio': source.isPrio,
                                                    'is-useless':
                                                        source.isUseless,
                                                },
                                            )}
                                        >
                                            {source.name}
                                        </Select.Option>
                                    ),
                                )}
                            </Select>
                        </div>
                    }
                    trigger="click"
                >
                    <Button icon={<FilterOutlined />}></Button>
                </Popover>
            </div>
        </div>
    );
}

export default SpotFilterSelector;

/**
 * @typedef SourceWithImportance
 * @property {Id} id
 * @property {string} name
 * @property {boolean} isPrio
 * @property {boolean} isUseless
 */

/**
 * Orders the sources according to their importance realted to spots, so editors see first the ones more likely to be used.
 *
 * @param {Source[]} sourceList
 * @returns {SourceWithImportance[]}
 */
function orderSourceList(sourceList) {
    const PRIO_SOURCES = [
        'facebook',
        'foursquare',
        'airbnb',
        'get_your_guide',
        'kayak',
        'viator',
    ];
    const USELESS_SOURCES = ['unsplash', 'shutterstock', 'polarsteps'];

    /** @type {SourceWithImportance[]} */
    const prioSources = PRIO_SOURCES.reduce((acc, prioSourceName) => {
        const sourceInList = sourceList.find(
            (source) => source.name === prioSourceName,
        );
        if (!sourceInList) {
            return acc;
        }
        return [...acc, { ...sourceInList, isPrio: true, isUseless: false }];
    }, []);

    /** @type {SourceWithImportance[]} */
    const uselessSources = USELESS_SOURCES.reduce((acc, prioSourceName) => {
        const sourceInList = sourceList.find(
            (source) => source.name === prioSourceName,
        );
        if (!sourceInList) {
            return acc;
        }
        return [...acc, { ...sourceInList, isPrio: false, isUseless: true }];
    }, []);

    /** @type {SourceWithImportance[]} */
    const normalSources = sourceList.reduce((acc, source) => {
        const isPrio = !!PRIO_SOURCES.find(
            (sourceName) => source.name === sourceName,
        );
        const isUseless = !!USELESS_SOURCES.find(
            (sourceName) => source.name === sourceName,
        );
        if (isPrio || isUseless) {
            return acc;
        }
        return [...acc, { ...source, isPrio: false, isUseless: false }];
    }, []);

    return [...prioSources, ...normalSources, ...uselessSources];
}
