import React from 'react';
import { Typography, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CustomTipCard from './CustomTipCard';
import SelectedTip from './SelectedTip';
import './SelectedTips.css';

const { Text } = Typography;

/**
 * @param {Object} props
 * @param {boolean} props.showCustomTip
 * @param {(x: boolean) => void} props.setShowCustomTip
 * @param {(patch: Object.<string, any>) => void} props.onPatchField
 * @param {CollectionSpot} props.collectionSpot
 */
function SelectedTips(props) {
    /**
     * @param {CollectionSpotTip} tipToDelete
     */
    const onDeleteTip = (tipToDelete) => {
        const newTips = props.collectionSpot.tips.filter(
            (tip) => tip.id !== tipToDelete.id,
        );
        props.onPatchField({
            tips: newTips,
        });
    };

    /**
     * @param {CollectionSpotTip} patchedTip
     */
    const onPatchTip = (patchedTip) => {
        const newTips = props.collectionSpot.tips.map((tip) => {
            if (tip.id === patchedTip.id) {
                return patchedTip;
            }
            return tip;
        });
        props.onPatchField({
            tips: newTips,
        });
    };

    return (
        <div className="collection-spot-editor__selected-tips">
            <div>
                <div className="mb-m line-center collection-spot-editor__selected-tips-title">
                    <Text strong>Tips</Text>
                    <div className="grow-full-flex" />
                    {!props.showCustomTip && (
                        <Button
                            style={{ fontSize: 12 }}
                            type="link"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                props.setShowCustomTip(true);
                            }}
                        >
                            Add custom tip
                        </Button>
                    )}
                </div>
                {(props.showCustomTip || props.collectionSpot.custom_tip) && (
                    <div className="mb-m">
                        <CustomTipCard
                            text={props.collectionSpot.custom_tip}
                            onChange={(newValue) =>
                                props.onPatchField({
                                    custom_tip: newValue,
                                })
                            }
                            onDelete={() => {
                                props.onPatchField({
                                    custom_tip: null,
                                });
                                props.setShowCustomTip(false);
                            }}
                        />
                    </div>
                )}
                {props.collectionSpot.tips.map((collectionSpotTip) => (
                    <div className="mb-m" key={collectionSpotTip.id}>
                        <SelectedTip
                            collectionSpotTip={collectionSpotTip}
                            onUpdate={onPatchTip}
                            onDelete={onDeleteTip}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SelectedTips;
