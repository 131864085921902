import React from 'react';
import { Select } from 'antd';
import { useFeatureFlag } from 'feature-flags';

export const MEDIA_SOURCES = {
    POLARSTEPS: 'POLARSTEPS',
    FAVORITES: 'FAVORITES',
    SPOT: 'SPOT', // Depending on the spot source we will use Airbnb, 4square, GetYourGuide, etc.
    UNSPLASH: 'UNSPLASH',
    SHUTTERSTOCK: 'SHUTTERSTOCK',
    UPLOADED_PHOTOS: 'UPLOADED_PHOTOS',
};

/**
 * Checks if a spot source provides photos.
 *
 * @param {string?} source
 */
export const isSpotSourceWithPhotos = (source) => {
    if (!source) {
        return false;
    }
    const SPOT_SOURCES_WITHOUT_PHOTOS = ['polarsteps_spots'];
    return !SPOT_SOURCES_WITHOUT_PHOTOS.includes(source);
};

/**
 *
 * @param {Object} props
 * @param {PSSpot_v15?} props.spot
 * @param {(value: string) => void} props.onChange
 * @param {string} props.value
 */
function MediaSelectorSourceSelector({ spot, onChange, value }) {
    const [ENABLE_UNSPLASH] = useFeatureFlag('ENABLE_UNSPLASH');
    const [ENABLE_SHUTTERSTOCK] = useFeatureFlag('ENABLE_SHUTTERSTOCK');

    return (
        <Select onChange={onChange} value={value} style={{ minWidth: 156 }}>
            <Select.Option
                key={MEDIA_SOURCES.POLARSTEPS}
                value={MEDIA_SOURCES.POLARSTEPS}
            >
                Polarsteps
            </Select.Option>
            {ENABLE_SHUTTERSTOCK && (
                <Select.Option
                    key={MEDIA_SOURCES.SHUTTERSTOCK}
                    value={MEDIA_SOURCES.SHUTTERSTOCK}
                >
                    Shutterstock
                </Select.Option>
            )}
            {ENABLE_UNSPLASH && (
                <Select.Option
                    key={MEDIA_SOURCES.UNSPLASH}
                    value={MEDIA_SOURCES.UNSPLASH}
                >
                    Unsplash
                </Select.Option>
            )}

            <Select.Option
                key={MEDIA_SOURCES.FAVORITES}
                value={MEDIA_SOURCES.FAVORITES}
            >
                Favorites
            </Select.Option>
            {isSpotSourceWithPhotos(spot?.source) && (
                <Select.Option
                    key={MEDIA_SOURCES.SPOT}
                    value={MEDIA_SOURCES.SPOT}
                >
                    {spot.source}
                </Select.Option>
            )}
            {spot && (
                <Select.Option
                    key={MEDIA_SOURCES.UPLOADED_PHOTOS}
                    value={MEDIA_SOURCES.UPLOADED_PHOTOS}
                >
                    Uploaded photos
                </Select.Option>
            )}
        </Select>
    );
}

export default MediaSelectorSourceSelector;
