import { BASE_URL } from 'constants/env';
import { sortBy, toPairs, groupBy } from 'lodash';
import { useMemo } from 'react';
import useSWR from 'swr';
import { parse } from 'utils/error-parser';
import { get } from 'utils/http';

const usePublicStepSpotsInGuide = (guideId: Id, showReviewed: boolean) => {
    const { data, error, isValidating, mutate } = useSWR<StepSpot[]>(
        guideId
            ? `${BASE_URL}/cms/guides/${guideId}/public_step_spots?show_reviewed=${showReviewed}`
            : null,
        get,
        { revalidateOnFocus: false },
    );

    return {
        publicStepSpots: data,
        error: error ? parse(error) : null,
        isLoading: !data && isValidating,
        refresh: mutate,
    };
};

const useAllPublicStepSpots = (guideId: Id, showReviewed: boolean) => {
    const {
        publicStepSpots,
        refresh: refreshPublicStepSpots,
        error,
    } = usePublicStepSpotsInGuide(guideId, showReviewed);

    const stepSpots = useMemo(() => {
        const grouped = toPairs(groupBy(publicStepSpots, 'spot_id'));
        const sortedInside = grouped.map(
            ([spotId, stepSpotsInGroup]) =>
                [
                    spotId,
                    sortBy(stepSpotsInGroup, ['creation_time', 'id']).reverse(),
                ] as [string, StepSpot[]],
        );
        return sortBy(sortedInside, ([, tips]) => -tips.length);
    }, [publicStepSpots]);

    return {
        publicStepSpots,
        stepSpots,
        refreshPublicStepSpots,
        error,
    };
};

export default useAllPublicStepSpots;
