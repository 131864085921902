import React, { useMemo } from 'react';
import {
    convertFavoriteToCMSMedia,
    getMediaTypeForFavorite,
    isFavoriteSelected,
} from 'utils/media-utils';
import { IMAGE } from 'constants/media-types.constants';

import { useMediaFavorites } from './useMediaFavorite';
import FavoriteMediaItem from './FavoriteMediaItem';
import { useState } from 'react';
import { useAllSources } from 'api/sources.api';

/**
 *
 * @param {Object} props
 * @param {CMSMedia?} props.selectedMedia
 * @param {Id} props.guideId
 * @param {(media: CMSMedia) => void} props.onSelect
 * @param {boolean} props.includeVideos
 * @param {boolean} props.isShown
 * @param {React.ReactNode} props.sourceSelector
 * @returns {JSX.Element}
 */
export default function FavoriteMedia({
    selectedMedia,
    guideId,
    onSelect,
    includeVideos,
    isShown,
    sourceSelector,
}) {
    /** @type {[Id, React.Dispatch<Id>]} */
    const [loadingFavoriteUuid, setLoadingFavoriteUuid] = useState(null);
    const { favorites } = useMediaFavorites(guideId);
    const { allSources } = useAllSources();
    const favsFilteredByMediaType = useMemo(() => {
        if (!favorites || !allSources) {
            return [];
        }
        if (includeVideos) {
            return favorites;
        }
        const polarstepsSource = allSources.find(
            (s) => s.name === 'polarsteps',
        );
        return favorites.filter(
            (fav) =>
                fav.source_id !== polarstepsSource.id ||
                // @ts-ignore
                fav.data.type === IMAGE,
        );
    }, [favorites, includeVideos, allSources]);
    /**
     * @param {FavoriteImage} favoriteMedia
     */
    const selectMedia = async (favoriteMedia) => {
        setLoadingFavoriteUuid(favoriteMedia.id);
        const convertedMedia = await convertFavoriteToCMSMedia(favoriteMedia);
        onSelect(convertedMedia);
        setLoadingFavoriteUuid(null);
    };

    if (!allSources) {
        return null;
    }

    return (
        <div style={{ display: isShown ? 'block' : 'none' }}>
            <div className="mt-m mb-m ml-sm">{sourceSelector}</div>
            {!favsFilteredByMediaType.length && (
                <div className="media-selector-no-results">
                    No favorites yet
                </div>
            )}

            <div className="media-selector-image-list-container">
                <div className="media-selector-image-list">
                    {favsFilteredByMediaType.map((item) => {
                        const favType = getMediaTypeForFavorite(
                            item,
                            allSources,
                        );
                        return (
                            <FavoriteMediaItem
                                key={item.id}
                                type={favType}
                                media={item}
                                guideId={guideId}
                                onSelect={() => selectMedia(item)}
                                isSelected={isFavoriteSelected(
                                    item,
                                    favType,
                                    selectedMedia,
                                )}
                                isLoading={loadingFavoriteUuid === item.id}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
