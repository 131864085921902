import { notification } from 'antd';
import {
    STARTED_LOADING_COLLECTION_TYPE,
    SUCCESS_LOADING_COLLECTION_TYPE,
    FAILED_LOADING_COLLECTION_TYPE,
    STARTED_ADDING_COLLECTION_TYPE,
    SUCCESS_ADDING_COLLECTION_TYPE,
    FAILED_ADDING_COLLECTION_TYPE,
    STARTED_PATCHING_COLLECTION_TYPE,
    SUCCESS_PATCHING_COLLECTION_TYPE,
    FAILED_PATCHING_COLLECTION_TYPE,
} from './action-types';
import {
    getCollectionType,
    addCollectionType as addCollectionTypeToDB,
    patchCollectionType as patchCollectionTypeInDB,
} from 'api/collection-types.api';
import { parse } from 'utils/error-parser';
import { getById } from './selectors';

/**
 * Loads a collection type from the DB
 *
 * @param {Id} collectionTypeId
 * @returns {object} Action to be dispatched
 */
export const loadCollectionType = (collectionTypeId) => {
    return (dispatch) => {
        dispatch({
            type: STARTED_LOADING_COLLECTION_TYPE,
            id: collectionTypeId,
        });
        return getCollectionType(collectionTypeId)
            .then((collectionType) => {
                dispatch({
                    type: SUCCESS_LOADING_COLLECTION_TYPE,
                    id: collectionTypeId,
                    payload: collectionType,
                });
                return collectionType;
            })
            .catch((error) => {
                dispatch({
                    type: FAILED_LOADING_COLLECTION_TYPE,
                    id: collectionTypeId,
                    error,
                });
            });
    };
};

/**
 * Gets the action to add a collection type to the DB
 *
 * @param {CollectionType} collectionType
 * @returns {any}
 */
export const addCollectionType = (collectionType) => {
    return (dispatch) => {
        dispatch({
            type: STARTED_ADDING_COLLECTION_TYPE,
            collectionType,
        });
        return addCollectionTypeToDB(collectionType)
            .then((addedCollectionType) => {
                dispatch({
                    type: SUCCESS_ADDING_COLLECTION_TYPE,
                    payload: addedCollectionType,
                });
                showSuccessAddingCollectionType();
            })
            .catch((error) => {
                const errorText = parse(error);

                dispatch({
                    type: FAILED_ADDING_COLLECTION_TYPE,
                    error: errorText,
                });
                showErrorAddingCollectionType(errorText);
            });
    };
};

function showSuccessAddingCollectionType() {
    notification.success({
        message: 'The collection type was added successfully',
    });
}

function showErrorAddingCollectionType(error) {
    notification.error({
        message: 'Error adding collection type',
        description: error,
    });
}

/**
 * Gets the action to patch a collection type to the DB
 *
 * @param {CollectionType} collectionType
 * @returns {any}
 */
export const patchCollectionType = (collectionType) => {
    return (dispatch) => {
        dispatch({
            type: STARTED_PATCHING_COLLECTION_TYPE,
            collectionType,
        });
        return patchCollectionTypeInDB(collectionType)
            .then((patchedCollectionType) => {
                dispatch({
                    type: SUCCESS_PATCHING_COLLECTION_TYPE,
                    payload: patchedCollectionType,
                });
                showSuccessPatchingCollectionType();
            })
            .catch((error) => {
                const errorText = parse(error);

                dispatch({
                    type: FAILED_PATCHING_COLLECTION_TYPE,
                    error: errorText,
                    collectionType,
                });
                showErrorPatchingCollectionType(errorText);
            });
    };
};

function showSuccessPatchingCollectionType() {
    notification.success({
        message: 'The collection type was patched successfully',
    });
}

function showErrorPatchingCollectionType(error) {
    notification.error({
        message: 'Error editing collection type',
        description: error,
    });
}

/**
 * Gets the action to archive a collection type
 *
 * @param {CollectionType} collectionType
 * @returns {any}
 */
export const archiveCollectionType = (collectionType) => {
    return (dispatch, getState) => {
        const originalCollectionType = getById(getState(), collectionType.id);
        const archivedCollectionType = {
            ...originalCollectionType,
            is_archived: true,
        };
        dispatch({
            type: STARTED_PATCHING_COLLECTION_TYPE,
            collectionType: archivedCollectionType,
        });
        return patchCollectionTypeInDB(archivedCollectionType)
            .then((patchedCollectionType) => {
                dispatch({
                    type: SUCCESS_PATCHING_COLLECTION_TYPE,
                    payload: patchedCollectionType,
                });
                showSuccessArchivingCollectionType();
            })
            .catch((error) => {
                const errorText = parse(error);

                dispatch({
                    type: FAILED_PATCHING_COLLECTION_TYPE,
                    error: errorText,
                    archivedCollectionType,
                });
                showErrorArchivingCollectionType(errorText);
            });
    };
};

function showSuccessArchivingCollectionType() {
    notification.success({
        message: 'The collection type was archived successfully',
    });
}

function showErrorArchivingCollectionType(error) {
    notification.error({
        message: 'Error archiving collection type',
        description: error,
    });
}
