import React from 'react';
import GenericSelectorSWR from 'components/GenericSelectorSWR';
import {
    getAllFirstLevel,
    SPOT_CATEGORY_FIRST_LEVEL_SWR_KEY,
} from 'api/spot-categories.api';

/**
 * @param {Object} props
 * @param {Id} props.value
 * @param {(newVal: Id) => void} props.onChange
 */
function FirstLevelSpotCategoriesSelector({ value, onChange }) {
    return (
        <GenericSelectorSWR
            value={value}
            onChange={onChange}
            swrKey={SPOT_CATEGORY_FIRST_LEVEL_SWR_KEY}
            fetcher={getAllFirstLevel}
            placeholder="Select first level category"
            useStringIds={true}
        ></GenericSelectorSWR>
    );
}

export default FirstLevelSpotCategoriesSelector;
