import { patch, post, postDelete } from 'utils/http';
import { BASE_URL } from 'constants/env';
import { parse } from 'utils/error-parser';
import { getBiggestMedia, getIdFromSize } from 'utils/media-utils';
import { isSameObject } from 'utils/compare';
import { IMAGE, isVideo } from 'constants/media-types.constants';
import { getAllSources, getSourceId } from './sources.api';
import { uploadImage, signImageToUpload } from 'utils/image/image-upload';
import { cropImage, resizeLocalImage } from 'utils/image/image-utils';

/**
 * Adds a new guide media
 *
 * @param {CMSMedia | null} media
 * @param {MediaSize[]} sizes
 * @param {boolean} [triggerLicensing=false] If true, this flag will let the BE know this image should be licensed
 * @returns {Promise<CMSMedia>}
 */
export const addCMSMedia = async (media, sizes, triggerLicensing = false) => {
    if (!media) {
        return null;
    }
    const options = await getDownloadOptionsFromMedia(media.source_id);
    const paths = await uploadMediaIfNeededAndGetPaths(media, sizes, options);

    const mediaToSave = {
        ...media,
        paths,
        $$cropInfo: undefined,
        id: undefined,
    };

    const shutterstockId = await getSourceId('shutterstock');
    if (media.source_id === shutterstockId) {
        return saveShutterstockCMSMedia(
            {
                ...mediaToSave,
                trigger_licensing: triggerLicensing || undefined,
            },
            media.$$cropInfo,
        );
    }

    return post(`${BASE_URL}/cms/cms_media`, mediaToSave).catch((error) => {
        const errorText = parse(error);
        return Promise.reject(errorText);
    });
};

/**
 * @param {CMSMedia} media
 * @returns {Promise<CMSMedia>}
 */
export const patchCMSMedia = async (media) => {
    try {
        const updatedMedia = await patch(
            `${BASE_URL}/cms/cms_media/${media.id}`,
            media,
        );
        return updatedMedia;
    } catch (error) {
        const errorText = parse(error);
        throw new Error(errorText);
    }
};

/**
 * Cretaes new media from a local file
 *
 * @param {HTMLImageElement} imageElement
 * @param {File} file
 * @param {MediaSize[]} sizes
 * @returns {Promise<CMSMedia>}
 */
export const createCMSMediaFromLocalFile = async (
    imageElement,
    file,
    sizes,
) => {
    const uploadableImages = await resizeLocalImage(imageElement, file, sizes);
    const signedImages = await Promise.all(
        uploadableImages.map(signImageToUpload),
    );

    const uploadedImages = await Promise.all(signedImages.map(uploadImage));
    const paths = await createPathsFromUploadedImages(uploadedImages);
    const polarstepsEditorsId = await getSourceId('polarsteps_editors');

    const mediaToSave = {
        source_id: polarstepsEditorsId,
        external_id: '',
        author_name: '',
        source_url: null,
        attribution_url: null,
        paths,
        type: IMAGE,
    };

    return post(`${BASE_URL}/cms/cms_media`, mediaToSave).catch((error) => {
        const errorText = parse(error);
        return Promise.reject(errorText);
    });
};

/**
 *
 * @param {CMSMedia} media
 * @param {CropInfo} cropInfo
 * @returns {Promise<CMSMedia>}
 */
function saveShutterstockCMSMedia(media, cropInfo) {
    const convertedCrop = {
        crop_x: cropInfo.x / cropInfo.originalWidth,
        crop_y: cropInfo.y / cropInfo.originalHeight,
        crop_width: cropInfo.width / cropInfo.originalWidth,
        crop_height: cropInfo.height / cropInfo.originalHeight,
    };
    const mediaToSave = {
        ...media,
        ...convertedCrop,
        is_preview: true,
    };
    return post(`${BASE_URL}/cms/shutterstock/media`, mediaToSave).catch(
        (error) => {
            const errorText = parse(error);
            return Promise.reject(errorText);
        },
    );
}

/**
 *
 * @param {Id} sourceId source for the media
 * @returns {Promise<DownloadMediaOptions>}
 */
export async function getDownloadOptionsFromMedia(sourceId) {
    const sourcePolarsteps = await getSourceId('polarsteps');
    const allSources = await getAllSources();
    const sourcesNeedingCORSCache = [
        'get_your_guide',
        'kayak',
        'hostelworld',
        'trip_advisor',
        'airbnb',
        'polarsteps_editors',
    ]
        .map(
            (sourceName) =>
                allSources.find(
                    (otherSource) => otherSource.name === sourceName,
                )?.id,
        )
        .filter((sourceId) => typeof sourceId !== 'undefined');

    return {
        bustCache: sourceId === sourcePolarsteps,
        useCORSCache: sourcesNeedingCORSCache.includes(sourceId),
    };
}

/**
 * Depending on the media type will upload images, and will return the new paths object to be stored
 *
 * @param {CMSMedia} media
 * @param {MediaSize[]} sizes
 * @param {DownloadMediaOptions} options
 * @returns {Promise<Object.<string, CMSMediaPathInfo>>}
 */
async function uploadMediaIfNeededAndGetPaths(media, sizes, options) {
    if (isVideo(media)) {
        return Promise.resolve(media.paths);
    }

    const unsplashSourceId = await getSourceId('unsplash');
    // For unsplash we don't need to upload, just create the correct URLs
    if (media.source_id === unsplashSourceId) {
        return getCroppedPathsFromUnsplash(media, sizes);
    }

    // Is image, crop and resize
    const biggestImage = getBiggestMedia(media);
    const croppedImages = await cropImage(
        biggestImage.path,
        media.$$cropInfo,
        sizes,
        options,
    );
    const signedImages = await Promise.all(
        croppedImages.map(signImageToUpload),
    );

    const uploadedImages = await Promise.all(signedImages.map(uploadImage));
    return createPathsFromUploadedImages(uploadedImages);
}

/**
 * @param {SignedUploadableImage[]} images
 * @returns {Object.<string, CMSMediaPathInfo>}
 */
function createPathsFromUploadedImages(images) {
    return images.reduce((acc, image) => {
        return {
            ...acc,
            [image.image.name]: {
                path: image.resourceUrl,
                width: image.image.width,
                height: image.image.height,
            },
        };
    }, {});
}

/**
 * @param {CMSMedia} media
 * @param {MediaSize[]} desiredSizes
 * @returns {Object.<string, CMSMediaPathInfo>}
 */
function getCroppedPathsFromUnsplash(media, desiredSizes) {
    const biggestOriginal = getBiggestMedia(media);
    // Unsplash uses imgix to provide images already cropped, we use focal point
    // to achieve a equivalent cropping method to the one we are using in other sources:
    // https://docs.imgix.com/apis/rendering/size/crop#focalpoint
    const crop = media.$$cropInfo;
    const focalPointX = (crop.x + crop.width / 2) / crop.originalWidth;
    const focalPointY = (crop.y + crop.height / 2) / crop.originalHeight;

    // The zoom should be enough to cover all the area we need,
    // we know that depending on the ratios of the original vs the crop
    const cropRatio = crop.width / crop.height;
    const originalRatio = crop.originalWidth / crop.originalHeight;
    const shouldImageFillHorizontally = cropRatio > originalRatio;
    const focalPointZoom = shouldImageFillHorizontally
        ? crop.originalWidth / crop.width
        : crop.originalHeight / crop.height;

    const parsedUrl = new URL(biggestOriginal.path);
    parsedUrl.searchParams.append('fp-x', focalPointX + '');
    parsedUrl.searchParams.append('fp-y', focalPointY + '');
    parsedUrl.searchParams.append('fp-z', focalPointZoom + '');
    parsedUrl.searchParams.append('crop', 'focalpoint');
    parsedUrl.searchParams.append('fit', 'crop');

    /** @type {CMSMediaPathInfo[]} */
    const pathInfos = desiredSizes.map((size) => {
        const url = new URL(parsedUrl.toString());

        url.searchParams.append('w', size.maxWidth + '');
        url.searchParams.append('h', size.maxHeight + '');

        return {
            height: size.maxHeight,
            width: size.maxWidth,
            path: url.toString(),
        };
    });
    return pathInfos.reduce((acc, pathInfo) => {
        const sizeId = getIdFromSize({
            width: pathInfo.width,
            height: pathInfo.height,
        });
        return {
            ...acc,
            [sizeId]: pathInfo,
        };
    }, {});
}

/**
 * Removes a cms media from the database
 *
 * @param {CMSMedia} media
 * @returns {Promise}
 */
export const removeCMSMedia = (media) => {
    return postDelete(`${BASE_URL}/cms/cms_media/${media.id}`, {
        returnPlainResponse: true,
    });
};

/**
 * Checks if an old media has changed, and deletes it if so
 *
 * @param {CMSMedia | null} newMedia
 * @param {CMSMedia | null} oldMedia
 * @returns {Promise<CMSMedia | null>}
 */
export const deleteOldCMSMediaIfChanged = (newMedia, oldMedia) => {
    if (!oldMedia || isSameObject(newMedia, oldMedia)) {
        return Promise.resolve(newMedia ?? null);
    }
    return removeCMSMedia(oldMedia);
};

/**
 * Checks if two medias are the same, then saves the new one
 *
 * @param {CMSMedia} newMedia
 * @param {CMSMedia} oldMedia
 * @param {MediaSize[]} sizes
 * @returns {Promise<CMSMedia>} The newly saved media, if needed, the original if not needed.
 */
export const addCMSMediaIfChanged = (newMedia, oldMedia, sizes) => {
    if (isSameObject(newMedia, oldMedia)) {
        return Promise.resolve(newMedia);
    }
    if (!newMedia) {
        return Promise.resolve(null);
    }
    return addCMSMedia(newMedia, sizes);
};
