import * as Tooltip from '@radix-ui/react-tooltip';
import classNames from 'classnames';

import { MEDIA_SELECTOR_SHOW_POPOVER_DELAY } from 'constants/ux';
import React from 'react';

import styles from './style.module.css';

type MediaItemPopoverWrapperProps = {
    content: JSX.Element;
    overlayClassName?: string;
    children: React.ReactNode;
    mouseEnterDelay?: number;
};

function MediaItemTooltipWrapper({
    content,
    overlayClassName = '',
    children,
    mouseEnterDelay = MEDIA_SELECTOR_SHOW_POPOVER_DELAY,
}: MediaItemPopoverWrapperProps) {
    return (
        <Tooltip.Provider delayDuration={mouseEnterDelay}>
            <Tooltip.Root>
                <Tooltip.Trigger className={styles.trigger}>
                    {children}
                </Tooltip.Trigger>

                <Tooltip.Content
                    className={classNames(styles.container, overlayClassName)}
                    side="right"
                >
                    {content}
                    <Tooltip.Arrow className={styles.arrow} />
                </Tooltip.Content>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
}

export default MediaItemTooltipWrapper;
