import React from 'react';
import { Input, Button, Card } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import polarstepsLogo from './polarsteps-logo.svg';
import AvatarWithName from 'components/AvatarWithName';
import Flag from 'components/Flag';
import FormField from 'components/FormField';
import { CUSTOM_TIP_MAX_LENGTH } from 'constants/fields-limits';

const { TextArea } = Input;

/**
 * @param {Object} props
 * @param {string} props.text
 * @param {() => void} [props.onDelete]
 * @param {(newText: string) => void} [props.onChange]
 */
function CustomTipCard({ text, onChange, onDelete }) {
    return (
        <Card
            className="custom-actions-card"
            title={<AvatarWithName src={polarstepsLogo} name="Polarsteps" />}
            extra={<Flag countryCode="GB" />}
            actions={[
                <div
                    className="line-center ml-m"
                    key="custom-tip-card__delete"
                    style={{ width: '100%' }}
                >
                    <Button
                        onClick={onDelete}
                        type="link"
                        icon={<CloseCircleOutlined />}
                        danger={true}
                        size="small"
                    >
                        Remove
                    </Button>
                    <div className="grow-full-flex"></div>
                </div>,
            ]}
        >
            <FormField length={text?.length} maxLength={CUSTOM_TIP_MAX_LENGTH}>
                <TextArea
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    value={text}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                    placeholder="Add a custom tip..."
                />
            </FormField>
        </Card>
    );
}

export default CustomTipCard;
