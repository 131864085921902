import React from 'react';
import { getContainedSize } from 'utils/image/image-size-utils';
import { MEDIA_SELECTOR_POPOVER_IMG_SIZE } from 'constants/ux';
import classNames from 'classnames';

import tooltipStyles from '../../MediaItemTooltipWrapper/style.module.css';
import './ShutterstockMediaItemPopover.css';

/**
 * @param {Object} props
 * @param {ShutterstockPhotoFromSearch} props.media
 */
function ShutterstockMediaItemPopover({ media }) {
    const assetToUse = media.assets['preview_1000'] || media.assets['preview'];
    const size = getContainedSize(assetToUse, MEDIA_SELECTOR_POPOVER_IMG_SIZE);

    return (
        <div className={tooltipStyles.root}>
            <img
                style={size}
                className={classNames(
                    tooltipStyles.image,
                    'shutterstock-media-item-popover__img',
                )}
                src={assetToUse.url}
                alt={media.description}
            />
            <div className={tooltipStyles.text}>{media.description}</div>
        </div>
    );
}

export default ShutterstockMediaItemPopover;
