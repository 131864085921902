import { get } from 'utils/http';
import { BASE_URL } from 'constants/env';
import { withCache } from 'utils/fetch-cache';

export const TIP_STATUS_SWR_KEY = '/cms/tip_status';

/**
 * Gets the possible tip status from the database
 *
 * @returns {Promise<TipStatus[]>}
 */
export const getAllTipStatus = () => {
    return withCache(`${BASE_URL}/cms/tip_status`, get).then(
        (result = {}) => result.objects || [],
    );
};
