import * as actionTypes from './action-types';

/**
 * @type {GuidesState}
 */
const GUIDES_INITIAL_STATE = {
    byId: {},
    loadingById: {},
    errorsById: {},
};

/**
 * guides reducer
 *
 * @param {GuidesState} state
 * @param {*} action
 * @returns {GuidesState}
 */
export const guides = (state = GUIDES_INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case actionTypes.STARTED_LOADING_GUIDE:
            return {
                ...state,
                loadingById: {
                    ...state.loadingById,
                    [action.guideId]: true,
                },
                errorsById: {
                    ...state.errorsById,
                    [action.guideId]: null,
                },
            };
        case actionTypes.FAILED_LOADING_GUIDE:
            return {
                ...state,
                loadingById: {
                    ...state.loadingById,
                    [action.guideId]: false,
                },
                errorsById: {
                    ...state.errorsById,
                    [action.guideId]: action.error,
                },
                byId: {
                    ...state.byId,
                    [action.guideId]: null,
                },
            };
        case actionTypes.SUCCESS_LOADING_GUIDE:
            return {
                ...state,
                loadingById: {
                    ...state.loadingById,
                    [action.guideId]: false,
                },
                errorsById: {
                    ...state.errorsById,
                    [action.guideId]: null,
                },
                byId: {
                    ...state.byId,
                    [action.guideId]: action.payload,
                },
            };
        case actionTypes.UPDATE_GUIDE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: action.payload,
                },
            };
        default:
            return state;
    }
};
