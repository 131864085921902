import { get, post, patch } from 'utils/http';
import { BASE_URL } from 'constants/env';
import { getString } from 'utils/flask-restless-filters';
import { getLocation } from './location.api';
import { getCollectionClass } from './collection-class.api';
import { getArchivedFromList } from 'utils/publish-status-utils';
import { mutateAllCollectionSpotsFromGuide } from './collection-spots.api';
import {
    getAllPublishStatus,
    getFilterForArchived,
} from './publish-status.api';
import useSWR, { mutate } from 'swr';

/**
 * Gets all the collections from the database
 *
 * @param {string} [searchTerm='']
 * @param {number} [page=1]
 * @param {number} [resultsPerPage=20] If this is set to more than FlaskRestless allows, this may be ignored by the server
 * @returns {Promise<{pagination: PaginationInfo, results: Collection[]}>}
 */
export const getAllCollections = async (
    searchTerm = '',
    page = 1,
    resultsPerPage = 20,
) => {
    const filters = [
        searchTerm && {
            or: [
                { name: 'name', op: 'ilike', val: `%${searchTerm}%` },
                {
                    name: 'guide',
                    op: 'has',
                    val: {
                        name: 'tagline',
                        op: 'ilike',
                        val: `%${searchTerm}%`,
                    },
                },
            ],
        },
    ].filter((x) => x);
    const result = await get(
        `${BASE_URL}/cms/collections?page=${page}&results_per_page=${resultsPerPage}&q={"filters":${JSON.stringify(
            filters,
        )}}`,
    );
    const augmentedCollections = await augmentListOfCollections(result.objects);
    return {
        pagination: {
            page: result.page,
            totalPages: result.total_pages,
            totalResults: result.num_results,
            resultsPerPage,
        },
        results: augmentedCollections,
    };
};

/**
 *
 * @typedef GetCollectionsOptions
 * @property {boolean} [omitArchived=true] If true we will not get collections marked as "archived"
 */

/**
 * Gets all the collections associated with a guide
 *
 * @param {Id} guideId
 * @param {GetCollectionsOptions} options
 * @returns {Promise<Collection[]>}
 */
const getCollectionsForGuide = (guideId, { omitArchived = true } = {}) => {
    return getFilterForArchived(omitArchived)
        .then((archivedFilter) => {
            const filters = getString([
                { name: 'guide_id', op: '==', val: guideId + '' },
                archivedFilter,
            ]);
            return get(
                `${BASE_URL}/cms/collections?q={"filters":[${filters}]}`,
            );
        })
        .then((result = {}) => augmentListOfCollections(result.objects));
};

/**
 * @param {Id} guideId
 */
export const getSWRKeyForCollections = (guideId) => {
    if (!guideId) {
        return null;
    }
    return `/cms/guides/${guideId}/collections`; // This is not the correct api, but works as a key
};

/**
 * Hook that gets the collections of a guide
 *
 * @param {Id} guideId
 */
export const useCollections = (guideId) => {
    const { data, isValidating, error } = useSWR(
        getSWRKeyForCollections(guideId),
        () => getCollectionsForGuide(guideId),
        { revalidateOnFocus: false },
    );
    return {
        collections: data,
        isLoading: isValidating,
        error,
    };
};

/**
 * @param {Id} guideId
 */
export const getSWRKeyForTopCollections = (guideId) => {
    if (!guideId) {
        return null;
    }
    return `/cms/guides/${guideId}/top_collections`;
};
/**
 * @param {Id} guideId
 */
export const refreshCollectionsForGuide = (guideId) => {
    mutateAllCollectionSpotsFromGuide(guideId);
    return mutate(getSWRKeyForCollections(guideId));
};

/**
 * Gets all the collections associated with a guide
 *
 * @param {Id} guideId
 * @returns {Promise<TopCollection[]>}
 */
const getTopCollectionsForGuide = async (guideId) => {
    const result = await get(
        `${BASE_URL}/cms/guide/${guideId}/top_collections`,
    );
    // We receive an object of the form:
    // {
    //      'FOOD_BEVERAGES': {
    //          [ < array with CollectionSpots > ]
    //      },
    //      ... more key-value pairs of different top collections
    // }
    const topCollections = Object.entries(result).map(
        ([ps_category, spots]) => {
            return {
                ps_category,
                name: ps_category,
                spots,
            };
        },
    );
    return topCollections;
};

/**
 * Hook that gets the top collections of a guide
 *
 * @param {Id} guideId
 */
export const useTopCollections = (guideId) => {
    const { data, isValidating, error } = useSWR(
        getSWRKeyForTopCollections(guideId),
        () => getTopCollectionsForGuide(guideId),
        { revalidateOnFocus: false },
    );
    return {
        topCollections: data,
        isLoading: isValidating,
        error,
    };
};

/**
 * @param {Id} guideId
 */
export const refreshTopCollectionsForGuide = (guideId) => {
    return mutate(getSWRKeyForTopCollections(guideId));
};

/**
 * Adds a collection to the database
 *
 * @param {Collection} collection
 * @returns {Promise<Collection>}
 */
export const addCollection = (collection) => {
    const dbCollection = toDBFormat(collection);
    return post(`${BASE_URL}/cms/collections`, dbCollection).then(
        augmentCollection,
    );
};

/**
 * Patches a collection in the database
 *
 * @param {Collection} collection
 * @returns {Promise<Collection>}
 */
export const patchCollection = (collection) => {
    const dbCollection = toDBFormat(collection);
    return patch(
        `${BASE_URL}/cms/collections/${collection.id}`,
        dbCollection,
    ).then(augmentCollection);
};

/**
 * Marks a collection as archived in the DB
 *
 * @param {Id} collectionId
 * @returns {Promise<Collection>}
 */
export const archiveCollection = (collectionId) => {
    return getAllPublishStatus()
        .then(getArchivedFromList)
        .then((archivedPublishStatus) => {
            return patch(`${BASE_URL}/cms/collections/${collectionId}`, {
                publish_status_id: archivedPublishStatus.id,
            });
        })
        .then(augmentCollection);
};

/**
 * Converts a collection to the format needed for the DB
 *
 * @param {Collection} collection
 * @returns {DBCollection}
 */
function toDBFormat(collection) {
    return {
        guide_id: collection.guide_id,
        name: collection.name,
        description: collection.description,
        collection_type_id: collection.collection_type.id,
        main_editor_id: collection.main_editor.id,
        publish_status_id: collection.publish_status.id,
        editor_notes: collection.editor_notes
    };
}

/**
 * @param {Collection[]} shallowCollections
 * @returns {Promise<Collection[]>}
 */
function augmentListOfCollections(shallowCollections = []) {
    return Promise.all(shallowCollections.map(augmentCollection));
}

/**
 * Gets the data needed to make a collection with all the data.
 * Right now we augment the location inside of a guide to be able to get its name,
 * and the collection type to be able to get the collection class
 *
 * @param {Collection} collection
 * @returns {Promise<Collection>}
 */
function augmentCollection(collection) {
    return Promise.all([
        getLocation(collection.guide.location_id),
        getCollectionClass(collection.collection_type.collection_class_id),
    ]).then(([location, collection_class]) => {
        return {
            ...collection,
            guide: {
                ...collection.guide,
                location,
            },
            collection_type: {
                ...collection.collection_type,
                collection_class,
            },
        };
    });
}
