import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'utils/use-selector';
import { Button, Alert } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { ContainerOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { loadCollectionType } from 'store/collection-types/actions';
import Loading from 'components/Loading';
import { Link } from 'react-router-dom';
import CollectionTypeEditor from 'components/CollectionTypeEditor';
import { startEditingCollectionType } from 'store/collection-type-editor/actions';
import {
    isCollectionTypeCorrect,
    getCurrentCollectionType,
} from 'store/collection-type-editor/selectors';
import { isPatchingCurrentCollectionType } from 'store/collection-types/selectors';
import { patchCollectionType } from 'store/collection-types/actions';
import {
    checkLoadingById,
    getErrorById,
} from 'store/collection-types/selectors';
import { routes } from 'routes';
import ArchiveCollectionTypeModal from './ArchiveCollectionTypeModal';
import './style.css';

function EditCollectionType() {
    // @ts-ignore
    const { id } = useParams();
    const idToUse = parseInt(id, 10);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadCollectionType(idToUse)).then((loadedCollectionType) => {
            dispatch(startEditingCollectionType(loadedCollectionType));
        });
    }, [idToUse, dispatch]);

    const isLoading = useSelector((state) => checkLoadingById(state, idToUse));
    const errorLoading = useSelector((state) => getErrorById(state, idToUse));
    const collectionTypeComplete = useSelector(isCollectionTypeCorrect);
    const currentCollectionType = useSelector(getCurrentCollectionType);
    const patchingCollectionType = useSelector(isPatchingCurrentCollectionType);
    const [shouldShowArchiveModal, setShouldShowArchiveModal] = useState(false);
    const history = useHistory();

    const onPatch = () => {
        if (!isCollectionTypeCorrect) {
            return;
        }
        dispatch(patchCollectionType(currentCollectionType)).then(() => {
            history.push(routes['guides-collection-types'].route);
        });
    };

    return (
        <div className="full-page">
            <PageHeader
                title="Edit collection type"
                ghost={false}
                onBack={() => history.push('/guides/collection-types')}
            />
            {isLoading && (
                <div className="full-page-content">
                    <div className="full-page-content-center">
                        <Loading tip="Loading..." />
                    </div>
                </div>
            )}
            {errorLoading && (
                <div className="full-page-content">
                    <div className="full-page-content-center full-page-error">
                        <Alert
                            message="Error loading the collection type"
                            description={errorLoading}
                            type="error"
                            showIcon
                        />
                        <div className="full-page-error-button">
                            <Button
                                type="primary"
                                onClick={() =>
                                    dispatch(loadCollectionType(idToUse))
                                }
                            >
                                Try loading again
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {currentCollectionType && <CollectionTypeEditor />}

            <div className="full-page-footer">
                <div className="line-center">
                    <Button
                        type="link"
                        size="small"
                        danger={true}
                        icon={<ContainerOutlined />}
                        onClick={() => {
                            setShouldShowArchiveModal(true);
                        }}
                    >
                        Archive collection type
                    </Button>
                </div>

                <div className="grow-full-flex"></div>
                <Link to="/guides/collection-types">
                    <Button size="large">Cancel</Button>
                </Link>
                <Button
                    size="large"
                    type="primary"
                    disabled={!collectionTypeComplete}
                    onClick={onPatch}
                    loading={patchingCollectionType}
                >
                    Save changes
                </Button>
            </div>
            <ArchiveCollectionTypeModal
                show={shouldShowArchiveModal}
                onHide={() => setShouldShowArchiveModal(false)}
            />
        </div>
    );
}

export default EditCollectionType;
