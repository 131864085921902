import React, { useEffect, useState, useCallback } from 'react';
import { Input, Spin, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useDebounce } from '@react-hook/debounce';
import InfiniteScroll from 'react-infinite-scroller';
import UnsplashMediaItem from './UnsplashMediaItem';
import {
    convertUnsplashMediaToCMSMedia,
    isUnsplashMediaSelected,
} from 'utils/media-utils';
import { useInfiniteScrollGet } from 'utils/use-infinite-scroll-get';
import { search } from 'api/unsplash.api';
import MediaOrientationSelector from '../MediaOrientationSelector';
import { parse } from 'utils/error-parser';

/**
 *
 * @param {Object} props
 * @param {CMSMedia?} props.selectedMedia
 * @param {Id} props.guideId
 * @param {(media: CMSMedia) => void} props.onSelect
 * @param {boolean} props.isShown
 * @param {React.ReactNode} props.sourceSelector
 * @param {PSSpot_v15} props.spot
 * @param {PSLocation} props.location
 * @returns {JSX.Element}
 */
function UnsplashMedia({
    selectedMedia,
    guideId,
    onSelect,
    isShown,
    sourceSelector,
    spot,
    location,
}) {
    /** @type {[MediaOrientationSelectorValue, React.Dispatch<React.SetStateAction<MediaOrientationSelectorValue>>]} */
    const [orientation, setOrientation] = useState('all');

    const selectMedia = async (media) => {
        const convertedMedia = await convertUnsplashMediaToCMSMedia(media);
        onSelect(convertedMedia);
    };
    const [filter, setFilter] = useState(spot?.name || location.name || '');
    const [filterDebounced, setFilterDebounced] = useDebounce(
        spot?.name || location.name || '',
        300,
    );

    // isActive will be true once the component isShown for the first time, so we only start querying when that happens
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        if (isShown) {
            setIsActive(true);
        }
    }, [isShown]);

    const apiGetter = useCallback(
        (page) => search(filterDebounced, orientation, page),
        [filterDebounced, orientation],
    );

    const {
        isLoading,
        getNewPage,
        hasMoreResults,
        results,
        errorLoadingMessage,
    } = useInfiniteScrollGet(apiGetter);

    useEffect(() => {
        notification.destroy();
        if (errorLoadingMessage) {
            notification.error({
                message: 'Error searching for images',
                description: parse(errorLoadingMessage),
            });
        }
    }, [errorLoadingMessage]);

    return (
        <div style={{ display: isShown ? 'block' : 'none' }}>
            <div className="media-selector__tools">
                {sourceSelector}
                <div className="grow-full-flex ml-sm">
                    <Input
                        className="media-selector-modal__searchbox"
                        placeholder="Search"
                        value={filter}
                        onChange={(e) => {
                            setFilter(e.target.value);
                            setFilterDebounced(e.target.value);
                        }}
                        prefix={
                            <SearchOutlined
                                style={{ color: 'rgba(0,0,0,.25)' }}
                            />
                        }
                    />
                </div>
                <div className="ml-sm">
                    <MediaOrientationSelector
                        value={orientation}
                        onChange={setOrientation}
                    />
                </div>
            </div>

            {isActive && (
                <div className="media-selector-image-list-container">
                    {/* All photos */}
                    <InfiniteScroll
                        key={`${filterDebounced}__${orientation}`}
                        className="media-selector-image-list"
                        pageStart={0}
                        loadMore={getNewPage}
                        threshold={400}
                        hasMore={!isLoading && hasMoreResults}
                        useWindow={false}
                    >
                        {results.map((photo) => (
                            <UnsplashMediaItem
                                key={photo.id}
                                media={photo}
                                guideId={guideId}
                                onSelect={() => selectMedia(photo)}
                                isSelected={isUnsplashMediaSelected(
                                    photo,
                                    selectedMedia,
                                )}
                            />
                        ))}
                    </InfiniteScroll>

                    {/* Loading */}
                    {isLoading && (
                        <div className="media-selector-image-list-loading">
                            <Spin />
                        </div>
                    )}
                    {!results.length && !isLoading && !!filter && (
                        <div className="media-selector-no-results">
                            No results
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default UnsplashMedia;
