import React from 'react';
import { Typography, Avatar } from 'antd';
import { getTip } from 'utils/collection-spot-utils';
import { getPossessiveForName } from 'utils/possessive';
import './CollectionSpotTip.css';

const { Paragraph } = Typography;

/**
 *
 * @param {Object} props
 * @param {CollectionSpot} props.collectionSpot
 */
function CollectionSpotTip({ collectionSpot }) {
    const tip = getTip(collectionSpot);

    return (
        <div className="collection-spot-tip">
            {tip && (
                <Paragraph ellipsis={{ rows: 4, expandable: false }}>
                    {tip.user && (
                        <div className="collection-spot-tip__user">
                            <Avatar
                                src={tip.user.avatarUrl}
                                size={16}
                                style={{ marginRight: 4 }}
                            />
                            {getPossessiveForName(tip.user.firstName)} tip:
                        </div>
                    )}
                    {tip.tip}
                </Paragraph>
            )}
            {!tip && <div className="collection-spot-tip__empty">No tip</div>}
        </div>
    );
}

export default CollectionSpotTip;
