import React from 'react';
import { Modal } from 'antd';
import { useWindowHeight } from '@react-hook/window-size';

/**
 * Wrapper around Modal that takes care to change the position and size of it depending on layout size
 *
 * @param {object} props
 * @param {number} [props.heightThreshold=1200] Any viewport height equal and lower than this will get a full height modal
 * @param {number} [props.padding=20] Padding around the modal (outside)
 * @param {React.ReactNode} [props.children]
 * @param {React.ReactNode} [props.footer]
 * @param {number} [props.width]
 * @param {string} [props.className='']
 * @param {string} [props.title]
 * @param {boolean} [props.open=false]
 * @param {()=> void} [props.onOk]
 * @param {()=> void} [props.onCancel]
 * @param {React.StyleHTMLAttributes} [props.bodyStyle=false]
 * @param {boolean} [props.maskClosable=true]
 * @param {boolean} [props.closable]
 */
function ResponsiveModal(props) {
    const windowHeight = useWindowHeight(); // Already debounced
    const style =
        windowHeight <= props.heightThreshold
            ? {
                  top: props.padding,
                  height: `calc(100vh - ${2 * props.padding}px)`,
              }
            : {};

    return (
        <Modal style={style} {...props}>
            {props.children}
        </Modal>
    );
}

ResponsiveModal.defaultProps = {
    heightThreshold: 1200,
    padding: 20,
    children: <></>,
    footer: undefined,
    width: undefined,
    className: '',
    title: undefined,
    open: false,
    onOk: undefined,
    onCancel: undefined,
    bodyStyle: {},
    maskClosable: true,
};

export default ResponsiveModal;
