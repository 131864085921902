/**
 * Gets a location from a Spot
 *
 * @param {PSSpot_v15_Minimal} spot
 * @returns {PSLocation}
 */
export const getLocationFromSpot = (spot) => {
    return {
        name: spot.name,
        lat: spot.location.lat,
        lon: spot.location.lon,
        detail: spot.location.locality,
        fullDetail: spot.location.locality,
        countryCode: null,
    };
};

/**
 * Checks if a spot has lat lon associated with it
 *
 * @param {PSSpot_v15_Minimal} spot
 */
export const hasLocationInfo = (spot) => {
    return (
        typeof spot.location?.lat === 'number' &&
        typeof spot.location?.lon === 'number'
    );
};

/**
 * Gets the photo out of a list of paths that has at least minWidth width, or as a fallback the biggest one available
 *
 * @param {PSSpot_v15_Photo} photo
 * @param {number} minWidth
 */
export const getPhotoWithClosestSize = (photo, minWidth) => {
    if (!photo || !photo.paths) {
        return null;
    }

    const sizeIds = Object.entries(photo.paths).map(([key, pathInfo]) => ({
        key,
        pathInfo,
    }));

    const orderedSizes = sizeIds.sort((a, b) =>
        a.pathInfo.width > b.pathInfo.width ? 1 : -1,
    );
    // Now orderedSizedIds are all the keys with info, ordered from less to max width
    for (const sizeId of orderedSizes) {
        if (sizeId.pathInfo.width >= minWidth) {
            return sizeId.pathInfo;
        }
    }
    // If nothing found, just return the biggest one
    if (orderedSizes.length) {
        return orderedSizes[orderedSizes.length - 1].pathInfo;
    }
    return null;
};
