/**
 * @param {PolarstepsSpot} spot
 */
export const isPolarstepsSpotValid = (spot) => {
    if (!spot) {
        return false;
    }
    return (
        !!spot.name &&
        !!spot.sub_category &&
        typeof spot.lat === 'number' &&
        typeof spot.lon === 'number'
    );
};

/**
 * Transforms a Spot with source = "polarsteps-spot" to a generic spot usable within Polarsteps.
 *
 * @param {PolarstepsSpot} spot
 * @returns {PSSpot_v15_Minimal}
 */
export const toGenericSpot = (spot) => {
    return {
        id: spot.internal_id,
        source: 'polarsteps_spots',
        name: spot.name,
        category_label: spot.category_label,
        sub_category: spot.sub_category,
        category_new: spot.sub_category,
        location: {
            lat: spot.lat,
            lon: spot.lon,
            locality: spot.locality,
            administrative_area: spot.administrative_area,
            country: spot.country,
            country_code: spot.country_code,
            single_line: spot.single_line,
            source: null,
        },
        is_reviewed: false,
    };
};
