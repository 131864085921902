import { GOOGLE_CLIENT_ID } from 'constants/env';

/**
 * Adds a meta header ot the html to indicate google api client id. We don't do it directly in the
 * index.html file because we want to change i tdepending on the ENV, and its values are centralized
 * in the constants/env.js file.
 */
export const addGAPIMetaHeader = () => {
    const meta = document.createElement('meta');
    meta.setAttribute('google-signin-client_id', GOOGLE_CLIENT_ID);
    document.getElementsByTagName('head')[0].appendChild(meta);
};
