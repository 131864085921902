import { Card, Typography } from 'antd';
import { GOOGLE_CLIENT_URL } from 'constants/auth';
import React, { useEffect, useRef } from 'react';
import { useCallback } from 'react';
// @ts-ignore
import { ReactComponent as Logo } from '../Sidebar/polarsteps-logo.svg';
import './style.css';

const { Text } = Typography;

const useScript = (url, onload) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.onload = onload;

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, [url, onload]);
};

/**
 * @param {Object} props
 * @param {(credentialResponse: google.accounts.id.CredentialResponse) => void} props.onSuccess
 */
const LoginWidget = ({ onSuccess = () => undefined }) => {
    const googleSignInButton = useRef(null);

    const initScript = useCallback(() => {
        window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: onSuccess,
        });
        window.google.accounts.id.renderButton(googleSignInButton.current, {
            type: 'standard',
            theme: 'outline',
            size: 'large',
            text: 'signin_with',
            shape: 'pill',
        });
    }, [window?.google?.accounts]);

    useScript(GOOGLE_CLIENT_URL, initScript);

    return (
        <Card className="login-widget__card">
            <div className="login-widget__title">
                <Logo className="login-widget__logo" width={220} height={60} />
                <Text className="login-widget__subtitle" strong>
                    Meraki CMS
                </Text>
            </div>
            <div className="login-widget__body">
                <div ref={googleSignInButton}></div>
            </div>
        </Card>
    );
};

export default LoginWidget;
