import React from 'react';
import GenericSelectorSWR from 'components/GenericSelectorSWR';
import {
    GUIDE_RANKINGS_SWR_KEY,
    getGuideRankings,
} from 'api/guide-ranking.api';

/**
 * @param {Object} props
 * @param {Id} props.value
 * @param {(newVal: Id) => void} props.onChange
 */
function GuideRankingSelector({ value, onChange }) {
    return (
        <GenericSelectorSWR
            value={value}
            onChange={onChange}
            swrKey={GUIDE_RANKINGS_SWR_KEY}
            fetcher={getGuideRankings}
            placeholder="Select guide ranking"
            textGetter={(item) => `${item.level}. ${item.name}`}
        ></GenericSelectorSWR>
    );
}

export default GuideRankingSelector;
