export const STARTED_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE =
    'STARTED_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE';
export const FAILED_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE =
    'FAILED_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE';
export const SUCCESS_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE =
    'SUCCESS_LOADING_NUMBER_OF_DAYS_ITEMS_FOR_GUIDE';
export const STARTED_SAVING_NUMBER_OF_DAYS_ITEMS =
    'STARTED_SAVING_NUMBER_OF_DAYS_ITEMS';
export const FAILED_SAVING_NUMBER_OF_DAYS_ITEMS =
    'FAILED_SAVING_NUMBER_OF_DAYS_ITEMS';
export const SUCCESS_SAVING_NUMBER_OF_DAYS_ITEMS =
    'SUCCESS_SAVING_NUMBER_OF_DAYS_ITEMS';
export const STARTED_SAVING_NUMBER_OF_DAYS_SPOTS =
    'STARTED_SAVING_NUMBER_OF_DAYS_SPOTS';
export const FAILED_SAVING_NUMBER_OF_DAYS_SPOTS =
    'FAILED_SAVING_NUMBER_OF_DAYS_ITEMS';
export const SUCCESS_SAVING_NUMBER_OF_DAYS_SPOTS =
    'SUCCESS_SAVING_NUMBER_OF_DAYS_SPOTS';
