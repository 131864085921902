import React, { useState } from 'react';

import { buyStockImagesForGuide } from 'api/shutterstock.api';
import { Modal, Typography } from 'antd';
import { CloseCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { parse } from 'utils/error-parser';
const { Text } = Typography;

/**
 *
 * @param {Object} props
 * @param {Guide} props.guide
 * @param {number} props.photosToPurchase
 * @param {() => void} props.onPurchased
 * @param {() => void} props.onCancel
 */
function GuidePurchaseWarningModal({
    guide,
    photosToPurchase,
    onPurchased,
    onCancel,
}) {
    const [isPurchasing, setIsPurchasing] = useState(false);
    /** @type {[string, React.Dispatch<string>]} */
    const [error, setError] = useState(null);

    const purchase = async () => {
        setIsPurchasing(true);
        try {
            await buyStockImagesForGuide(guide);
            setError(null);
            setIsPurchasing(false);
            onPurchased();
        } catch (e) {
            setError(parse(e));
            setIsPurchasing(false);
        }
    };
    const okText = getOkText(isPurchasing, !!error);
    const title = getTitle(photosToPurchase, !!error);
    const text = getText(photosToPurchase, error);

    return (
        <Modal
            open={true}
            okText={okText}
            onOk={() => purchase()}
            okButtonProps={{ loading: isPurchasing, disabled: isPurchasing }}
            onCancel={onCancel}
            closable={false}
            maskClosable={false}
        >
            <div className="line">
                {getIcon(!!error)}
                <div className="grow-full-flex ml-m">
                    <Text strong>{title}</Text>
                    <div className="mt-m">{text}</div>
                </div>
            </div>
        </Modal>
    );
}

function getIcon(error = false) {
    if (error) {
        return (
            <CloseCircleOutlined
                type="close-circle"
                style={{ color: '#FF4D4F', fontSize: 24 }}
            />
        );
    }
    return <WarningOutlined style={{ color: '#FAAD14', fontSize: 24 }} />;
}

function getOkText(isPurchasing = false, error = false) {
    if (isPurchasing) {
        return 'Purchasing photos';
    }
    if (error) {
        return 'Retry purchase & publish';
    }

    return 'Purchase & publish';
}

function getTitle(photosToPurchase = 0, error = false) {
    if (error) {
        return `Error purchasing ${photosToPurchase} stock photo(s)`;
    }
    return `Purchasing ${photosToPurchase} stock photo(s)`;
}

function getText(photosToPurchase = 0, error = '') {
    if (error) {
        return `Something went wrong. Error: “${error}”`;
    }
    return `By publishing you’ll purchase ${photosToPurchase} stock photo(s). Are you sure?`;
}

export default GuidePurchaseWarningModal;
