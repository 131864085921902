import { get, post, patch } from 'utils/http';
import { BASE_URL } from 'constants/env';
import { mutate } from 'swr';

export const COLLECTION_TYPES_SWR_KEY = '/cms/collection_types';

/**
 * @typedef GetAllCollectionTypesWithPaginationResult
 * @property {PaginationInfo} pagination
 * @property {CollectionType[]} results
 */

/**
 * Gets all the collection types from the database, with pagination
 *
 * @param {string} [searchTerm='']
 * @param {number} [page=1]
 * @param {number} [resultsPerPage=20] If this is set to more than FlaskRestless allows, this may be ignored by the server
 * @returns {Promise<GetAllCollectionTypesWithPaginationResult>}
 */
export const getAllCollectionTypesWithPagination = async (
    searchTerm = '',
    page = 1,
    resultsPerPage = 20,
) => {
    const filters = [
        searchTerm && {
            or: [
                { name: 'name', op: 'ilike', val: `%${searchTerm}%` },

                {
                    name: 'collection_class',
                    op: 'has',
                    val: {
                        name: 'name',
                        op: 'ilike',
                        val: `%${searchTerm}%`,
                    },
                },
            ],
        },
    ].filter((x) => x);
    const result = await get(
        `${BASE_URL}/cms/collection_types?page=${page}&results_per_page=${resultsPerPage}&q={"filters":${JSON.stringify(
            filters,
        )}}`,
    );
    return {
        pagination: {
            page: result.page,
            totalPages: result.total_pages,
            totalResults: result.num_results,
            resultsPerPage,
        },
        results: result.objects || [],
    };
};

/**
 * Gets all the collection types from the database
 *
 * @returns {Promise<CollectionType[]>}
 */
export const getAllCollectionTypes = async () => {
    const generator = allCollectionTypesGetterGenerator();
    let allValues = [];
    for await (const partialValues of generator) {
        allValues = [...allValues, ...partialValues];
    }
    return allValues;
};

async function* allCollectionTypesGetterGenerator(resultsPerPage = 20) {
    let currentPage = 1;
    /** @type {GetAllCollectionTypesWithPaginationResult} */
    let lastResult = null;
    while (
        !lastResult ||
        lastResult.pagination.page < lastResult.pagination.totalPages
    ) {
        lastResult = await getAllCollectionTypesWithPagination(
            '',
            currentPage,
            resultsPerPage,
        );
        currentPage += 1;
        yield lastResult.results;
    }
}

/**
 * Gets a particular collection type from the database
 *
 * @param {Id} collectionTypeId
 * @returns {Promise<CollectionType>}
 */
export const getCollectionType = (collectionTypeId) => {
    return get(`${BASE_URL}/cms/collection_types/${collectionTypeId}`);
};

/**
 * Adds a collection type to the database
 *
 * @param {CollectionType} newCollectionType
 * @returns {Promise<CollectionType>}
 */
export const addCollectionType = async (newCollectionType) => {
    const dbCollectionType = transformToDBFormat(newCollectionType);
    const addedCollectionType = await post(
        `${BASE_URL}/cms/collection_types`,
        dbCollectionType,
    );
    mutate(COLLECTION_TYPES_SWR_KEY);
    return addedCollectionType;
};

/**
 *
 * @param {CollectionType} collectionType
 * @returns {CollectionTypeToDB}
 */
function transformToDBFormat(collectionType) {
    return {
        id: collectionType.id || undefined,
        name: collectionType.name,
        collection_class_id: collectionType.collection_class.id,
        ps_category: collectionType.ps_category,
        ps_subcategory: collectionType.ps_subcategory,
        is_archived: collectionType.is_archived,
    };
}

/**
 * Patches a collection type in the database
 *
 * @param {CollectionType} collectionType
 * @returns {Promise<CollectionType>}
 */
export const patchCollectionType = async (collectionType) => {
    const dbCollectionType = transformToDBFormat(collectionType);
    const patchedCollectionType = await patch(
        `${BASE_URL}/cms/collection_types/${collectionType.id}`,
        dbCollectionType,
    );
    mutate(COLLECTION_TYPES_SWR_KEY);
    return patchedCollectionType;
};
