import { AnyAction } from 'redux';
import * as actionTypes from './action-types';
import { getItemsMappedById } from 'utils/sortable-item-utils';

const GOOD_TO_KNOW_ITEMS_INITIAL_STATE: GoodToKnowItemsState = {
    byGuideId: {},
    isSavingGoodToKnowItems: false,
};

export const goodToKnowItems = (
    state = GOOD_TO_KNOW_ITEMS_INITIAL_STATE,
    action: AnyAction,
): GoodToKnowItemsState => {
    switch (action.type) {
        case actionTypes.STARTED_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE:
            return {
                ...state,
                byGuideId: {
                    ...state.byGuideId,
                    [action.guideId]: {
                        loading: true,
                        error: null,
                        byId: null,
                    },
                },
            };
        case actionTypes.FAILED_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE:
            return {
                ...state,
                byGuideId: {
                    ...state.byGuideId,
                    [action.guideId]: {
                        loading: false,
                        error: action.error,
                        byId: null,
                    },
                },
            };

        case actionTypes.SUCCESS_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE:
            return {
                ...state,
                byGuideId: {
                    ...state.byGuideId,
                    [action.guideId]: {
                        loading: false,
                        error: null,
                        byId: getItemsMappedById(action.payload),
                    },
                },
            };

        case actionTypes.STARTED_SAVING_GOOD_TO_KNOW_ITEMS:
            return {
                ...state,
                isSavingGoodToKnowItems: true,
            };
        case actionTypes.SUCCESS_SAVING_GOOD_TO_KNOW_ITEMS:
        case actionTypes.FAILED_SAVING_GOOD_TO_KNOW_ITEMS:
            return {
                ...state,
                isSavingGoodToKnowItems: false,
            };

        default:
            return state;
    }
};
