import React from 'react';
import SpotMediaItemPopover from './SpotMediaItemPopover';
import MediaItemContent from '../MediaItemContent';
import MediaItemTooltipWrapper from '../../MediaItemTooltipWrapper';
import { getPhotoWithClosestSize } from 'utils/ps-spot-utils';

/**
 *
 * @param {Object} props
 * @param {PSSpot_v15_Photo} props.media
 * @param {PSSpot_v15_Minimal} props.spot
 * @param {Id} props.guideId
 * @param {(media: PSSpot_v15_Photo) => void} [props.onSelect]
 * @param {boolean} [props.isSelected = false]
 * @param {boolean} [props.isLoading=false]
 * @param {boolean} [props.canBeFavorite=true]
 * @param {React.ReactNode} [props.extraContent]
 * @param {number} [props.popoverMouseEnterDelay] seconds
 */
function SpotMediaItem({
    media,
    spot,
    guideId,
    onSelect = () => undefined,
    isSelected = false,
    isLoading = false,
    canBeFavorite = true,
    extraContent,
    popoverMouseEnterDelay,
}) {
    const photo = getPhotoWithClosestSize(media, 300);

    return (
        <MediaItemTooltipWrapper
            mouseEnterDelay={popoverMouseEnterDelay}
            content={<SpotMediaItemPopover media={media} />}
        >
            <MediaItemContent
                media={media}
                guideId={guideId}
                onSelect={() => onSelect(media)}
                isSelected={isSelected}
                mediaType="Spot"
                isLoading={isLoading}
                canBeFavorite={canBeFavorite}
                imgSrc={photo.path}
                spot={spot}
                extraContent={extraContent}
            />
        </MediaItemTooltipWrapper>
    );
}

export default SpotMediaItem;
