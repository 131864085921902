/**
 * Gets the name of a collection, if a custom one is not set, will check the collection type's name
 *
 * @param {Collection} collection
 * @returns {string}
 */
export const getName = (collection) => {
    return collection.name || collection.collection_type.name;
};

/**
 * Checks if a collection is core
 *
 * @param {Collection} collection
 * @returns {boolean}
 */
export const isCollectionCore = (collection) => {
    return (
        collection.collection_type.collection_class.name.toLowerCase() ===
        'core'
    );
};

/**
 * Checks if a collection is theme
 *
 * @param {Collection} collection
 * @returns {boolean}
 */
export const isCollectionTheme = (collection) => {
    return (
        collection.collection_type?.collection_class?.name.toLowerCase() ===
        'themed'
    );
};
