/**
 * Gets all the loaded good to know items associated with a guide
 */
export const getGoodToKnowItemsForGuide = (
    state: RootState,
    guideId: Id | undefined,
): GoodToKnowItem[] | null => {
    if (!guideId) return null;

    const allGoodToKnowItemsHash = getGoodToKnowItemsInfo(state, guideId).byId;
    if (!allGoodToKnowItemsHash) return null;

    const allGoodToKnowItems = Object.values(allGoodToKnowItemsHash);
    return allGoodToKnowItems.sort((a, b) => {
        return (b.order || 0) - (a.order || 0);
    });
};

/**
 * Checks if good to know items associated with a guide are being loaded
 *
 * @param {RootState} state
 * @param {Id} guideId
 * @returns {boolean}
 */
export const isLoadingGoodToKnowItemsForGuide = (
    state: RootState,
    guideId?: Id,
): boolean => {
    if (!guideId) return false;

    return getGoodToKnowItemsInfo(state, guideId).loading;
};

/**
 * Gets error (if any) loading good to know items associated with a guide
 */
export const getErrorLoadingGoodToKnowItemsForGuide = (
    state: RootState,
    guideId?: Id,
): string | null => {
    if (!guideId) return null;

    return getGoodToKnowItemsInfo(state, guideId).error;
};

/**
 * Checks if we are saving good to know items items at the moment
 */
export const isSavingGoodToKnowItems = (state: RootState): boolean => {
    return state.goodToKnowItems.isSavingGoodToKnowItems;
};

/**
 * Gets the state of good to know items associated to a guide. If non existing, will return a default value
 */
function getGoodToKnowItemsInfo(
    state: RootState,
    guideId: Id,
): GoodToKnowItemsPerGuideState {
    return (
        state.goodToKnowItems.byGuideId[guideId] || {
            loading: false,
            error: null,
            byId: null,
        }
    );
}
