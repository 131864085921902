import React from 'react';
import GenericSelectorSWR from 'components/GenericSelectorSWR';
import {
    COLLECTION_CLASSES_SWR_KEY,
    getCollectionClasses,
} from 'api/collection-class.api';

/**
 *
 * @param {Object} props
 * @param {Id} props.value
 * @param {(newVal: Id) => void} props.onChange
 */
function CollectionClassSelector({ value, onChange }) {
    return (
        <GenericSelectorSWR
            value={value}
            onChange={onChange}
            swrKey={COLLECTION_CLASSES_SWR_KEY}
            fetcher={getCollectionClasses}
            placeholder="Select type"
        ></GenericSelectorSWR>
    );
}

export default CollectionClassSelector;
