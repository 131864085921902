import { notification } from 'antd';
import {
    addGoodToKnowItem,
    deleteGoodToKnowItem,
    getGoodToKnowItemsForGuide as getGoodToKnowItemsForGuideFromDB,
    patchGoodToKnowItem,
} from 'api/good-to-know-items.api';
import { Dispatch } from 'redux';
import { parse } from 'utils/error-parser';
import { getChangesBetweenItemSets } from 'utils/sortable-item-utils';
import {
    FAILED_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE,
    FAILED_SAVING_GOOD_TO_KNOW_ITEMS,
    STARTED_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE,
    STARTED_SAVING_GOOD_TO_KNOW_ITEMS,
    SUCCESS_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE,
    SUCCESS_SAVING_GOOD_TO_KNOW_ITEMS,
} from './action-types';
import { getGoodToKnowItemsForGuide } from './selectors';

export const loadGoodToKnowItemsForGuide = (guideId: Id) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch({
            type: STARTED_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE,
            guideId,
        });
        try {
            const payload = await getGoodToKnowItemsForGuideFromDB(guideId);
            dispatch({
                type: SUCCESS_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE,
                guideId,
                payload,
            });
            return getGoodToKnowItemsForGuide(getState(), guideId);
        } catch (error) {
            const errorText = parse(error);
            dispatch({
                type: FAILED_LOADING_GOOD_TO_KNOW_ITEMS_FOR_GUIDE,
                guideId,
                error: errorText,
            });
        }
    };
};

/**
 * Saves the good to know items that have changed
 */
export const saveChangedGoodToKnowItems = (
    guideId: Id,
    current: GoodToKnowItem[],
    original: GoodToKnowItem[],
) => {
    return async (dispatch: Dispatch) => {
        const {
            newItems,
            deletedItems,
            editedItems,
        } = getChangesBetweenItemSets(current, original);

        dispatch({ type: STARTED_SAVING_GOOD_TO_KNOW_ITEMS });
        const itemsToAdd = newItems.map((newItem) =>
            addGoodToKnowItem(newItem),
        );
        const itemsToDelete = deletedItems.map((deletedItem) =>
            deleteGoodToKnowItem(deletedItem),
        );
        const itemsToPatch = editedItems.map((editedItem) =>
            patchGoodToKnowItem(editedItem),
        );

        try {
            await Promise.all([
                ...itemsToAdd,
                ...itemsToDelete,
                ...itemsToPatch,
            ]);
            await loadGoodToKnowItemsForGuide(guideId);
            dispatch({ type: SUCCESS_SAVING_GOOD_TO_KNOW_ITEMS });
        } catch (error) {
            const errorText = parse(error);
            dispatch({
                type: FAILED_SAVING_GOOD_TO_KNOW_ITEMS,
                error: errorText,
            });
            notification.error({
                message: 'Error saving good to know items',
                description: errorText,
            });
            throw error;
        }
    };
};
