import { Alert, Button } from 'antd';
import React from 'react';
import './style.css';

/**
 *
 * @param {Object} props
 * @param {string} props.error
 * @param {string} [props.stacktrace]
 */
export default function ErrorBoundaryMessage({ error, stacktrace }) {
    return (
        <>
            <Button
                onClick={() => {
                    navigator.clipboard.writeText(error);
                }}
                className="ml-l mt-l mb-l"
                type="primary"
            >
                Copy error info
            </Button>

            <Button
                onClick={() => {
                    window.location.reload();
                }}
                className="ml-l mt-l mb-l"
            >
                Reload page
            </Button>
            <div className="error-boundary">
                <Alert
                    message={error}
                    description={
                        <>
                            {stacktrace && (
                                <pre className="error-boundary__description">
                                    {stacktrace}
                                </pre>
                            )}
                        </>
                    }
                    showIcon
                    type="error"
                />
            </div>
        </>
    );
}
