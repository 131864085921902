import { useEffect, useCallback } from 'react';
import { hasUnsavedChanges } from 'store/misc-selectors';
import { useSelector } from 'utils/use-selector';

function BeforeUnloadWarning() {
    const shouldShowUnloadWarning = useSelector(hasUnsavedChanges);

    const showModalIfNeeded = useCallback(
        (e) => {
            if (shouldShowUnloadWarning) {
                // Cancel the event
                e.preventDefault();
                // Chrome requires returnValue to be set
                e.returnValue = '';
            } else {
                delete e['returnValue'];
            }
        },
        [shouldShowUnloadWarning],
    );

    useEffect(() => {
        window.addEventListener('beforeunload', showModalIfNeeded);
        return () => {
            window.removeEventListener('beforeunload', showModalIfNeeded);
        };
    }, [showModalIfNeeded]);

    return null;
}

export default BeforeUnloadWarning;
