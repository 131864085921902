import { Alert, Button, Input, Typography, notification } from 'antd';
import FormField from 'components/FormField';
import InGuideSpotSelector from 'components/InGuideSpotSelector';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    initGuideNumberOfDaysItems,
    notifyGuideNumberOfDaysItemsChange,
    patchGuideField,
    patchGuideSingleField,
    resetGuideNumberOfDaysItems,
    saveNumberOfDaysItems,
} from 'store/guide-editor/actions';
import {
    doNumberOfDaysHaveErrors,
    getCurrentGuide,
    getCurrentNumberOfDaysItemsBeingEdited,
    haveGuideNumberOfDaysHeaderChanged,
    haveNumberOfDaysItemsChanged,
} from 'store/guide-editor/selectors';
import {
    getErrorLoadingNumberOfDaysItemsForGuide,
    isLoadingNumberOfDaysItemsForGuide,
    isSavingNumberOfDaysItems,
} from 'store/number-of-days-items/selectors';
import { ItemFieldChange, applyPatchToItem } from 'utils/sortable-item-utils';
import { v4 as uuid } from 'uuid';
import { SortableList } from '../SortableList';
import { useSort } from '../SortableList/use-sort';
import styles from './style.module.css';
import {
    addNumberOfDaysSpot,
    changeNumberOfDaysSpot,
    deleteNumberOfDaysSpot,
} from 'store/number-of-days-items/actions';
import {
    GUIDE_NUMBER_OF_DAYS_DESCRIPTION_MAX_LENGTH,
    GUIDE_NUMBER_OF_DAYS_TITLE_MAX_LENGTH,
} from 'constants/fields-limits';

type GuideEditorNumberOfDaysProps = {
    onClickDiscard: () => void;
};

type GuideEditorNumberOfDaysItemProps = {
    index: number;
    numberOfDaysItem: NumberOfDaysItem;
    guide: Guide;
    onChangeField: (change: ItemFieldChange) => void;
    errors?: FieldError;
    isSubmitted?: boolean;
};

type GuideEditorNumberOfDaysHeaderProps = {
    guide: Guide;
};

type GuideEditorNumberOfDaysSpotProps = {
    numberOfDaysId: Id;
    numberOfDaysSpot: NumberOfDaysSpot;
    guide: Guide;
};

export const GuideEditorNumberOfDays = ({
    onClickDiscard,
}: GuideEditorNumberOfDaysProps) => {
    const dispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const guide = useSelector(getCurrentGuide);
    const guideId = useMemo(() => guide?.id, [guide]);
    const currentNumberOfDaysItems = useSelector(
        getCurrentNumberOfDaysItemsBeingEdited,
    );

    const isLoading = useSelector((state: RootState) =>
        isLoadingNumberOfDaysItemsForGuide(state, guideId),
    );
    const errorLoading = useSelector((state: RootState) =>
        getErrorLoadingNumberOfDaysItemsForGuide(state, guideId),
    );
    const isSaving = useSelector(isSavingNumberOfDaysItems);
    const hasChanged = useSelector(haveNumberOfDaysItemsChanged);
    const { hasErrors, errors } = useSelector(doNumberOfDaysHaveErrors);
    const hasNumberOfDaysHeaderChanged = useSelector(
        haveGuideNumberOfDaysHeaderChanged,
    );

    /**
     * Patches many number of days item fields at the same time and dispatches them to the store
     */
    const patchNumberOfDaysItemFields = (changes: ItemFieldChange[]) => {
        let items = currentNumberOfDaysItems;
        for (const change of changes) {
            items = applyPatchToItem(items, change);
        }

        dispatch(notifyGuideNumberOfDaysItemsChange(items));
    };

    const patchNumberOfDaysItemField = (changes: ItemFieldChange) => {
        patchNumberOfDaysItemFields([changes]);
    };

    const dispatchSavedNumberOfDaysItems = async () => {
        if (!guideId) return;
        dispatch(saveNumberOfDaysItems(guideId, currentNumberOfDaysItems));
    };

    const dispatchSaveNumberOfDaysHeader = async () => {
        if (!guideId) return;
        dispatch(
            patchGuideSingleField('itinerary_header', guide.itinerary_header),
        );
    };

    const save = async () => {
        setIsSubmitted(true);
        if (hasNumberOfDaysHeaderChanged) dispatchSaveNumberOfDaysHeader();
        if (!hasErrors) {
            dispatchSavedNumberOfDaysItems();
        } else {
            notification.error({
                message: 'Form validation failed',
                description: 'Please check the fields marked in red',
            });
        }
    };

    useEffect(() => {
        if (guideId) {
            dispatch(initGuideNumberOfDaysItems(guideId));
        }
        return () => {
            dispatch(resetGuideNumberOfDaysItems());
        };
    }, [guideId, dispatch]);

    const { onMoveUp, onMoveDown } = useSort(currentNumberOfDaysItems);

    const addNumberOfDaysItem = () => {
        if (!guideId) return;
        const newItem = getEmptyNumberOfDays(
            guideId,
            currentNumberOfDaysItems.length,
        );
        const newList = currentNumberOfDaysItems.concat([newItem]);
        dispatch(notifyGuideNumberOfDaysItemsChange(newList));
    };

    const deleteNumberOfDaysItem = ({ id }: NumberOfDaysItem) => {
        const newItems = currentNumberOfDaysItems.filter(
            (item) => item.id !== id,
        );
        dispatch(notifyGuideNumberOfDaysItemsChange(newItems));
    };

    const moveNumberOfDaysItemUp = (item: NumberOfDaysItem) => {
        const sortedItems = onMoveUp(item);

        if (sortedItems) {
            patchNumberOfDaysItemFields(
                sortedItems.map((item) => ({
                    id: item.id,
                    fieldName: 'order',
                    newValue: item.newOrder,
                })),
            );
        }
    };

    const moveNumberOfDaysItemDown = (item: NumberOfDaysItem) => {
        const sortedItems = onMoveDown(item);

        if (sortedItems) {
            patchNumberOfDaysItemFields(
                sortedItems.map((item) => ({
                    id: item.id,
                    fieldName: 'order',
                    newValue: item.newOrder,
                })),
            );
        }
    };

    if (!guide) {
        return null;
    }

    return (
        <>
            {errorLoading && (
                <div className="full-page-tabs-error">
                    <Alert
                        className="grow-full-flex"
                        message="There was an error loading number of days items"
                        description={errorLoading}
                        type="error"
                        showIcon
                    />
                </div>
            )}
            <SortableList
                title="Number of days in..."
                header={<GuideEditorNumberOfDaysHeader guide={guide} />}
                data={currentNumberOfDaysItems}
                onAdd={addNumberOfDaysItem}
                onDelete={deleteNumberOfDaysItem}
                onMoveDown={moveNumberOfDaysItemDown}
                onMoveUp={moveNumberOfDaysItemUp}
                isLoading={isLoading || isSaving}
            >
                {({ data, index }) =>
                    !data ? (
                        <SortableList.EmptyState
                            message="No number of days items yet"
                            action={
                                <SortableList.AddButton
                                    onClick={addNumberOfDaysItem}
                                    disabled={isLoading}
                                >
                                    Add item
                                </SortableList.AddButton>
                            }
                        />
                    ) : (
                        <GuideEditorNumberOfDaysItem
                            key={data.id}
                            index={index}
                            numberOfDaysItem={data}
                            guide={guide}
                            onChangeField={patchNumberOfDaysItemField}
                            isSubmitted={isSubmitted}
                            errors={errors[data.id]}
                        />
                    )
                }
            </SortableList>

            <div className="full-page-tabs-footer">
                <div className="max-content-width">
                    <div className="grow-full-flex"></div>

                    <Button size="large" onClick={onClickDiscard}>
                        Cancel
                    </Button>

                    <Button
                        size="large"
                        type="primary"
                        loading={isSaving}
                        onClick={save}
                        disabled={!hasChanged && !hasNumberOfDaysHeaderChanged}
                    >
                        Save changes
                    </Button>
                </div>
            </div>
        </>
    );
};

function GuideEditorNumberOfDaysItem({
    index,
    numberOfDaysItem,
    guide,
    onChangeField,
    errors = {},
    isSubmitted = false,
}: GuideEditorNumberOfDaysItemProps) {
    const dispatch = useDispatch();
    const { onMoveUp, onMoveDown } = useSort(numberOfDaysItem.spots);

    const handleChangeField = useCallback(
        <T,>(fieldName: string, newValue: T) => {
            onChangeField({
                id: numberOfDaysItem.id,
                fieldName: fieldName,
                newValue: newValue,
            });
        },
        [numberOfDaysItem.id, onChangeField],
    );

    const addSpot = () => {
        const newItem = getEmptySpot(numberOfDaysItem.spots.length);
        dispatch(addNumberOfDaysSpot(numberOfDaysItem.id, newItem));
    };

    const deleteSpot = ({ id }: NumberOfDaysSpot) => {
        dispatch(deleteNumberOfDaysSpot(numberOfDaysItem.id, id));
    };

    const moveSpotUp = (item: NumberOfDaysSpot) => {
        const sortedItems = onMoveUp(item);

        if (sortedItems) {
            dispatch(
                changeNumberOfDaysSpot(
                    numberOfDaysItem.id,
                    sortedItems.map((item) => ({
                        id: item.id,
                        fieldName: 'order',
                        newValue: item.newOrder,
                    })),
                ),
            );
        }
    };

    const moveSpotDown = (item: NumberOfDaysSpot) => {
        const sortedItems = onMoveDown(item);

        if (sortedItems) {
            dispatch(
                changeNumberOfDaysSpot(
                    numberOfDaysItem.id,
                    sortedItems.map((item) => ({
                        id: item.id,
                        fieldName: 'order',
                        newValue: item.newOrder,
                    })),
                ),
            );
        }
    };

    return (
        <div className={styles.item}>
            <Typography.Title level={5}>{`Item ${index + 1}`}</Typography.Title>
            <div className={styles.itemContent}>
                <div className={styles.form}>
                    <FormField
                        label="Title"
                        length={numberOfDaysItem.title.length}
                        maxLength={GUIDE_NUMBER_OF_DAYS_TITLE_MAX_LENGTH}
                        labelWidth={100}
                        className={styles.noMargin}
                        error={isSubmitted ? errors.title : undefined}
                    >
                        <Input
                            size="large"
                            value={numberOfDaysItem.title}
                            status={isSubmitted && errors.title ? 'error' : ''}
                            onChange={(e) =>
                                handleChangeField('title', e.target.value)
                            }
                        />
                    </FormField>

                    <FormField
                        label="Description"
                        length={numberOfDaysItem.description.length}
                        maxLength={GUIDE_NUMBER_OF_DAYS_DESCRIPTION_MAX_LENGTH}
                        labelWidth={100}
                        className={styles.noMargin}
                        error={isSubmitted ? errors.description : undefined}
                    >
                        <Input.TextArea
                            size="large"
                            value={numberOfDaysItem.description}
                            autoSize={{
                                minRows: 1,
                                maxRows: 4,
                            }}
                            status={
                                isSubmitted && errors.description ? 'error' : ''
                            }
                            onChange={(e) =>
                                handleChangeField('description', e.target.value)
                            }
                        />
                    </FormField>
                    <Typography.Title level={4} className={styles.spotsTitle}>
                        Spots
                    </Typography.Title>
                    <SortableList
                        data={numberOfDaysItem.spots}
                        onAdd={addSpot}
                        onDelete={deleteSpot}
                        onMoveDown={moveSpotDown}
                        onMoveUp={moveSpotUp}
                        secondary
                    >
                        {({ data }) =>
                            !data ? (
                                <div className={styles.spotEmptyState}>
                                    <SortableList.AddButton
                                        onClick={addSpot}
                                        type="default"
                                    >
                                        Add new item
                                    </SortableList.AddButton>
                                </div>
                            ) : (
                                <GuideEditorNumberOfDaysSpot
                                    numberOfDaysId={numberOfDaysItem.id}
                                    numberOfDaysSpot={data}
                                    guide={guide}
                                />
                            )
                        }
                    </SortableList>

                    <FormField
                        label="Notes"
                        labelWidth={100}
                        className={styles.noMargin}
                    >
                        <Input.TextArea
                            size="large"
                            autoSize={{
                                minRows: 1,
                                maxRows: 12,
                            }}
                            value={numberOfDaysItem.editor_notes}
                            onChange={(e) =>
                                handleChangeField(
                                    'editor_notes',
                                    e.target.value,
                                )
                            }
                            placeholder="Add your thoughts..."
                        />
                    </FormField>
                </div>
            </div>
        </div>
    );
}

function GuideEditorNumberOfDaysHeader({
    guide,
}: GuideEditorNumberOfDaysHeaderProps) {
    const dispatch = useDispatch();

    const handleChange = useCallback(
        (newValue: string) => {
            dispatch(patchGuideField('itinerary_header', newValue));
        },
        [dispatch],
    );

    useEffect(() => {
        if (!guide.itinerary_header)
            handleChange(
                `Visiting ${
                    guide.location_name_custom || guide.location.name
                } for...`,
            );
    }, [
        guide.itinerary_header,
        guide.location.name,
        guide.location_name_custom,
        handleChange,
    ]);

    return (
        <div className={styles.header}>
            <Typography.Title level={5}>Section header</Typography.Title>
            <div className={styles.headerField}>
                <FormField
                    length={guide.itinerary_header?.length}
                    labelWidth={100}
                    className={styles.noMargin}
                >
                    <Input
                        size="large"
                        value={guide.itinerary_header}
                        onChange={(e) => handleChange(e.target.value)}
                    />
                </FormField>
                <Typography.Text className={styles.headerHelpText}>
                    Edit the default header title in case it needs grammatical
                    changes.
                </Typography.Text>
            </div>
        </div>
    );
}

function GuideEditorNumberOfDaysSpot({
    numberOfDaysId,
    numberOfDaysSpot,
    guide,
}: GuideEditorNumberOfDaysSpotProps) {
    const dispatch = useDispatch();

    const handleChangeMultipleFields = useCallback(
        (changes: ItemFieldChange[]) => {
            dispatch(changeNumberOfDaysSpot(numberOfDaysId, changes));
        },
        [numberOfDaysId, dispatch],
    );

    const handleChangeField = useCallback(
        <T,>(fieldName: string, newValue: T) => {
            handleChangeMultipleFields([
                {
                    id: numberOfDaysSpot.id,
                    fieldName: fieldName,
                    newValue: newValue,
                },
            ]);
        },
        [handleChangeMultipleFields, numberOfDaysSpot.id],
    );

    const handleChangeSpot = useCallback(
        (spot: PSSpot_v15_Minimal | null) => {
            if (!spot) return;

            handleChangeMultipleFields([
                {
                    id: numberOfDaysSpot.id,
                    fieldName: 'internal_id',
                    newValue: spot.id,
                },
                {
                    id: numberOfDaysSpot.id,
                    fieldName: 'name',
                    newValue: spot.name,
                },
                {
                    id: numberOfDaysSpot.id,
                    fieldName: 'category_label',
                    newValue: spot.category_label,
                },
            ]);
        },
        [handleChangeMultipleFields, numberOfDaysSpot.id],
    );

    return (
        <div className={styles.form}>
            <FormField
                label="Spot"
                labelWidth={100}
                className={styles.noMargin}
            >
                <InGuideSpotSelector
                    value={numberOfDaysSpot}
                    guide={guide}
                    onSelect={handleChangeSpot}
                />
            </FormField>
            <FormField
                label="Alt spot name"
                length={numberOfDaysSpot.alt_spot_name?.length}
                labelWidth={100}
                className={styles.noMargin}
            >
                <Input
                    size="large"
                    value={numberOfDaysSpot.alt_spot_name}
                    placeholder="Optional"
                    onChange={(e) =>
                        handleChangeField('alt_spot_name', e.target.value)
                    }
                />
            </FormField>
            <FormField
                label="Description"
                length={numberOfDaysSpot.description?.length}
                labelWidth={100}
                maxLength={50}
                className={styles.noMargin}
            >
                <Input.TextArea
                    size="large"
                    autoSize={{
                        minRows: 1,
                        maxRows: 12,
                    }}
                    value={numberOfDaysSpot.description}
                    onChange={(e) =>
                        handleChangeField('description', e.target.value)
                    }
                />
            </FormField>
        </div>
    );
}

function getEmptyNumberOfDays(guideId: Id, order: number): NumberOfDaysItem {
    return {
        id: uuid(),
        guide_id: guideId,
        title: ``,
        description: '',
        editor_notes: '',
        order,
        spots: [],
    };
}

function getEmptySpot(order: number): NumberOfDaysSpot {
    return {
        id: uuid(),
        internal_id: undefined,
        order: order,
        alt_spot_name: '',
        description: '',
        name: '',
    };
}
