import React, { useState } from 'react';
import { Modal, Typography } from 'antd';
import { CloseCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { parse } from 'utils/error-parser';
import { addCMSMedia } from 'api/cms-media.api';
import { ButtonProps } from 'antd/lib/button';

const { Text } = Typography;

type PurchaseStockPhotoWarningModalProps = {
    onAdd: (cmsMedia: CMSMedia[]) => void;
    onCancel: () => void;
    photosToPurchase: CMSMedia[];
    sizesToUpload: MediaSize[];
};

type StockPhotoWarningModalProps = {
    onConfirm: () => void;
    onCancel: () => void;
    numberOfPhotos: number;
};

type StockPhotoModalProps = {
    title: string;
    text: string;
    okText: string;
    icon: JSX.Element;
    onOk: () => void;
    onCancel: () => void;
    okButtonProps?: ButtonProps | undefined;
};

export function PurchaseStockPhotoWarningModal({
    onAdd,
    onCancel,
    photosToPurchase,
    sizesToUpload,
}: PurchaseStockPhotoWarningModalProps) {
    const [isAdding, setIsAdding] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const purchase = async () => {
        setIsAdding(true);
        try {
            const allPhotos = await Promise.all(
                photosToPurchase.map((media) =>
                    addCMSMedia(media, sizesToUpload, true),
                ),
            );
            setError(null);
            setIsAdding(false);
            onAdd(allPhotos);
        } catch (e) {
            setError(parse(e));
            setIsAdding(false);
        }
    };
    const okText = getOkText(isAdding, !!error);
    const title = getTitle(photosToPurchase.length, !!error);
    const text = getText(photosToPurchase.length, error);

    return (
        <StockPhotoModal
            title={title}
            text={text}
            okText={okText}
            icon={getIcon(!!error)}
            onOk={purchase}
            onCancel={onCancel}
            okButtonProps={{ loading: isAdding, disabled: isAdding }}
        />
    );
}

export function StockPhotoWarningModal({
    onConfirm,
    onCancel,
    numberOfPhotos,
}: StockPhotoWarningModalProps) {
    return (
        <StockPhotoModal
            title={`Purchasing ${numberOfPhotos} stock photo(s)`}
            text={`By saving you’ll purchase ${numberOfPhotos} stock photo(s). Are you sure?`}
            okText="Purchase & save"
            icon={
                <WarningOutlined style={{ color: '#FAAD14', fontSize: 24 }} />
            }
            onOk={onConfirm}
            onCancel={onCancel}
        />
    );
}

function StockPhotoModal({
    text,
    title,
    okText,
    icon,
    onOk,
    onCancel,
    okButtonProps,
}: StockPhotoModalProps) {
    return (
        <Modal
            open={true}
            okText={okText}
            onOk={onOk}
            okButtonProps={okButtonProps}
            onCancel={onCancel}
            closable={false}
            maskClosable={false}
        >
            <div className="line">
                {icon}
                <div className="grow-full-flex ml-m">
                    <Text strong>{title}</Text>
                    <div className="mt-m">{text}</div>
                </div>
            </div>
        </Modal>
    );
}

function getIcon(error = false) {
    if (error) {
        return (
            <CloseCircleOutlined
                type="close-circle"
                style={{ color: '#FF4D4F', fontSize: 24 }}
            />
        );
    }
    return <WarningOutlined style={{ color: '#FAAD14', fontSize: 24 }} />;
}
function getOkText(isPurchasing = false, error = false) {
    if (isPurchasing) {
        return 'Purchasing photos';
    }
    if (error) {
        return 'Retry purchase & save';
    }

    return 'Purchase & save';
}

function getTitle(photosToPurchase = 0, error = false) {
    if (error) {
        return `Error purchasing ${photosToPurchase} stock photo(s)`;
    }
    return `Purchasing ${photosToPurchase} stock photo(s)`;
}

function getText(photosToPurchase = 0, error: string | null) {
    if (error) {
        return `Something went wrong. Error: “${error ?? ''}”`;
    }
    return `By saving you’ll purchase ${photosToPurchase} stock photo(s). Are you sure?`;
}
