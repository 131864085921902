/**
 * Gets all the loaded highlights associated with a guide
 */
export const getHighlightsForGuide = (
    state: RootState,
    guideId: Id | undefined,
): HighlightItem[] | null => {
    if (!guideId) return null;

    const allHighlightsHash = getGuideHighlightsInfo(state, guideId).byId;
    if (!allHighlightsHash) return null;

    const allHighlights = Object.values(allHighlightsHash);
    return allHighlights.sort((a, b) => {
        return (b.order || 0) - (a.order || 0);
    });
};

/**
 * Checks if highlights associated with a guide are being loaded
 *
 * @param {RootState} state
 * @param {Id} guideId
 * @returns {boolean}
 */
export const isLoadingHighlightsForGuide = (
    state: RootState,
    guideId?: Id,
): boolean => {
    if (!guideId) return false;

    return getGuideHighlightsInfo(state, guideId).loading;
};

/**
 * Gets error (if any) loading highlights associated with a guide
 */
export const getErrorLoadingHighlightsForGuide = (
    state: RootState,
    guideId?: Id,
): string | null => {
    if (!guideId) return null;

    return getGuideHighlightsInfo(state, guideId).error;
};

/**
 * Checks if we are saving highlight items at the moment
 */
export const isSavingHighlightItems = (state: RootState): boolean => {
    return state.highlightItems.isSavingHighlightItems;
};

/**
 * Gets the state of highlights associated to a guide. If non existing, will return a default value
 */
function getGuideHighlightsInfo(
    state: RootState,
    guideId: Id,
): HighlightItemsPerGuideState {
    return (
        state.highlightItems.byGuideId[guideId] || {
            loading: false,
            error: null,
            byId: null,
        }
    );
}
