import React from 'react';

import { Spin, Select, Alert, Button } from 'antd';
import useSWR, { mutate } from 'swr';

/**
 * Component that creates a selector that will get its values with useSWR
 *
 * @param {Object} props
 * @param {Id} props.value
 * @param {(newValId?: Id, newValFull?: any) => void} props.onChange
 * @param {string} props.swrKey to be passed to SWR
 * @param {(any) => Promise<any[]>} props.fetcher to be passed to SWR
 * @param {import('swr').SWRConfiguration} [props.swrOptions] to be passed to SWR
 * @param {string} [props.placeholder='Please select']
 * @param {boolean} [props.useStringIds=false] If True we will assume ids are strings, not numbers
 * @param {(item: any) => string} [props.textGetter] If we want to display a different text in the select options,
 * function that takes an item and returns the text to display. It will just show the value 'name' prop by default
 * @param {boolean} [props.allowClear] If true, will show a clear button
 * @param {import('antd/lib/config-provider/SizeContext').SizeType} [props.size]
 */
function GenericSelectorSWR({
    value,
    onChange,
    swrKey,
    fetcher,
    swrOptions = { revalidateOnFocus: false },
    placeholder = 'Please select',
    useStringIds = false,
    textGetter = (item) => item.name,
    allowClear,
    size = 'large',
}) {
    const { data: allValues, error, isValidating: isLoading } = useSWR(
        swrKey,
        fetcher,
        swrOptions,
    );

    const selectedValueLabel = getSelectedValueLabel(
        value,
        allValues,
        useStringIds,
        textGetter,
    );

    return (
        <>
            {!error && (
                <Select
                    placeholder={isLoading ? 'Loading...' : placeholder}
                    notFoundContent={isLoading ? <Spin size="small" /> : null}
                    filterOption={false}
                    value={selectedValueLabel}
                    onChange={(stringValue) => {
                        if (!stringValue) {
                            onChange(undefined);
                            return;
                        }

                        const intValue = useStringIds
                            ? stringValue
                            : parseInt(stringValue, 10);
                        // @ts-ignore
                        const fullSelectedValue = allValues.find(
                            (value) => value.id === intValue,
                        );
                        onChange(intValue, fullSelectedValue);
                    }}
                    style={{ width: '100%' }}
                    size={size}
                    allowClear={allowClear}
                >
                    {(allValues || [])
                        // @ts-ignore
                        .map((d) => (
                            <Select.Option key={d.id} value={d.id}>
                                {textGetter(d)}
                            </Select.Option>
                        ))}
                </Select>
            )}
            {error && (
                <div className="line-center">
                    <Alert
                        className="grow-full-flex"
                        message="There was an error loading"
                        type="error"
                        showIcon
                    />
                    <div className="mr-sm"></div>
                    <Button size="large" onClick={() => mutate(swrKey)}>
                        Try again
                    </Button>
                </div>
            )}
        </>
    );
}

function getSelectedValueLabel(value, allValues, useStringIds, textGetter) {
    if (!allValues) {
        return undefined;
    }
    if (!useStringIds && typeof value !== 'number') {
        return undefined;
    }

    if (useStringIds && typeof value !== 'string') {
        return undefined;
    }
    const fullFalue = allValues.find((eachValue) => eachValue.id === value);
    if (fullFalue) {
        return textGetter(fullFalue);
    }
    return value.toString();
}

export default GenericSelectorSWR;
