import { useState, useEffect, useRef } from 'react';
import CountryLanguage from 'country-language';

const LANG_TO_COUNTRY_OVERRIDES = {
    en: 'gb',
    es: 'es',
    fr: 'fr',
    nl: 'nl',
    de: 'de',
};

/**
 * Gets the country identifying a language
 *
 * @param {string} language
 * @returns {Promise<string>}
 */
export const languageToCountry = (language) => {
    if (LANG_TO_COUNTRY_OVERRIDES[language]) {
        return Promise.resolve(LANG_TO_COUNTRY_OVERRIDES[language]);
    }
    return new Promise((resolve, reject) => {
        CountryLanguage.getLanguageCountries(language, (error, countries) => {
            if (error || !countries?.length) {
                reject(error);
                return;
            }
            resolve(countries[0].code_2);
        });
    });
};

/**
 * Hook to get the country code related to a language
 *
 * @param {string} language
 * @returns {{countryCode: string, isLoading: boolean}} The country code, or null if we can't get it, or didn't get it yet
 */
export const useCountryCodeFromLanguage = (language) => {
    const [countryCode, setCountryCode] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!language) {
            return;
        }
        setIsLoading(true);
        languageToCountry(language).then((relatedCountryCode) => {
            if (isMounted.current) {
                setCountryCode(relatedCountryCode || null);
                setIsLoading(false);
            }
        });
    }, [language]);

    return { countryCode, isLoading };
};
