import React from 'react';
import { Radio } from 'antd';

/**
 *
 * @param {Object} props
 * @param {MediaOrientationSelectorValue} [props.value="all"]
 * @param {(newValue: MediaOrientationSelectorValue) => void} [props.onChange = () => undefined]
 * @param {boolean} [props.disabled = false]
 */
function MediaOrientationSelector({
    value = 'all',
    onChange = () => undefined,
    disabled = false,
}) {
    return (
        <Radio.Group
            buttonStyle="outline"
            value={value}
            onChange={(e) => {
                onChange(e.target.value);
            }}
        >
            <Radio.Button key={'all'} value={'all'} disabled={disabled}>
                All
            </Radio.Button>
            <Radio.Button
                key={'portrait'}
                value={'portrait'}
                disabled={disabled}
            >
                Portrait
            </Radio.Button>
            <Radio.Button
                key={'squarish'}
                value={'squarish'}
                disabled={disabled}
            >
                Squarish
            </Radio.Button>
            <Radio.Button
                key={'landscape'}
                value={'landscape'}
                disabled={disabled}
            >
                Landscape
            </Radio.Button>
        </Radio.Group>
    );
}

export default MediaOrientationSelector;
