import React, { useState } from 'react';
import { Input, Button, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import FormField from 'components/FormField';
import CollectionTypeSelector from 'components/CollectionTypeSelector';
import PublishStatusSelector from 'components/PublishStatusSelector';
import AuthorSelector from 'components/AuthorSelector';
import { isCollectionTheme } from 'utils/collection-utils';
import './style.css';

const { TextArea } = Input;

/**
 *
 * @param {Object} props
 * @param {boolean} [props.isVisible=false]
 * @param {boolean} props.isEditing
 * @param {boolean} [props.isLoading=false]
 * @param {boolean} [props.isBeingDeleted=false]
 * @param {(collectionId: Id) => void} [props.onRemove=() => {}]
 * @param {(collection: Collection) => void} props.onSave
 * @param {() => void} props.onDiscard
 * @param {Collection} props.originalCollection
 */
function CollectionEditor({
    isVisible = false,
    isEditing,
    onSave,
    onDiscard,
    originalCollection,
    isLoading = false,
    isBeingDeleted = false,
    onRemove = () => undefined,
}) {
    /** @type {[Collection, (c: Collection) => any]} */
    const [collection, updateCollection] = useState(originalCollection);

    /** @type {[boolean, any]} */
    const [isShowingDeletePopup, showDeletePopup] = useState(false);

    const isThemedCollection = isCollectionTheme(collection);

    return (
        <Modal
            open={isVisible}
            title={isEditing ? 'Edit collection' : 'Add new collection'}
            footer={
                <div className="line-center">
                    {isEditing && (
                        <Button
                            type="link"
                            danger={true}
                            icon={<DeleteOutlined />}
                            disabled={isLoading}
                            onClick={() => showDeletePopup(true)}
                            className="collection-editor-delete"
                        >
                            Delete collection
                        </Button>
                    )}
                    <div className="grow-full-flex"></div>
                    <Button
                        onClick={onDiscard}
                        disabled={isLoading}
                        className="mr-xs"
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        loading={isLoading}
                        onClick={() => onSave(collection)}
                        disabled={!isCollectionComplete(collection)}
                    >
                        {isEditing ? 'Save changes' : 'Add collection'}
                    </Button>
                </div>
            }
            closable={false}
            width={658}
        >
            <div className="collection-editor">
                <FormField label="Type">
                    <CollectionTypeSelector
                        value={collection.collection_type?.id}
                        onChange={(newTypeId, newType) => {
                            updateCollection({
                                ...collection,
                                collection_type: newType,
                            });
                        }}
                    ></CollectionTypeSelector>
                </FormField>
                <FormField
                    label="Custom title"
                    maxLength={30}
                    length={collection.name?.length}
                >
                    <Input
                        maxLength={30}
                        value={collection.name}
                        onChange={(e) => {
                            updateCollection({
                                ...collection,
                                name: e.target.value,
                            });
                        }}
                        size="large"
                        placeholder="Optional"
                    />
                </FormField>
                {isThemedCollection && (
                    <FormField
                        label="Description"
                        length={collection.description?.length}
                        maxLength={125}
                    >
                        <TextArea
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            value={collection.description}
                            onChange={(e) => {
                                updateCollection({
                                    ...collection,
                                    description: e.target.value,
                                });
                            }}
                            maxLength={125}
                            placeholder="Optional"
                        />
                    </FormField>
                )}
                <FormField label="Publish Status">
                    <PublishStatusSelector
                        value={collection.publish_status?.id}
                        onChange={(newId, newValue) => {
                            updateCollection({
                                ...collection,
                                publish_status: newValue,
                            });
                        }}
                    />
                </FormField>
                <FormField label="Main curator">
                    <AuthorSelector
                        value={collection.main_editor?.id}
                        onChange={(newId, newValue) => {
                            updateCollection({
                                ...collection,
                                main_editor: newValue,
                            });
                        }}
                    />
                </FormField>
                <FormField label="Notes">
                    <TextArea
                        className="form-field__textarea"
                        autoSize={{ minRows: 1, maxRows: 12 }}
                        value={collection.editor_notes}
                        onChange={(e) => {
                            updateCollection({
                                ...collection,
                                editor_notes: e.target.value,
                            });
                        }}
                        placeholder="Add your thoughts..."
                    />
                </FormField>
            </div>
            <Modal
                title="Delete collection"
                open={isShowingDeletePopup}
                onOk={() => onRemove(collection.id)}
                confirmLoading={isBeingDeleted}
                onCancel={() => showDeletePopup(false)}
                okText="Delete"
                okButtonProps={{
                    danger: true,
                }}
                cancelText="Cancel"
            >
                <p>
                    Are you sure you want to delete this collection? All data
                    will be lost.
                </p>
            </Modal>
        </Modal>
    );
}

export default CollectionEditor;

/**
 * Checks if a collection is complete to be added to the DB
 *
 * @param {Collection} collection
 * @returns {boolean}
 */
function isCollectionComplete(collection) {
    return (
        !!collection.collection_type &&
        !!collection.publish_status &&
        !!collection.main_editor
    );
}
