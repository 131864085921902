import { get } from 'utils/http';
import { BASE_URL } from 'constants/env';
import { withCache } from 'utils/fetch-cache';

export const COLLECTION_CLASSES_SWR_KEY = '/cms/collection_class';

/**
 * Gets the possible collection classes from the database
 *
 * @returns {Promise<CollectionClass[]>}
 */
export const getCollectionClasses = () => {
    return get(`${BASE_URL}/cms/collection_class`).then((result = {}) => {
        return result.objects || [];
    });
};

/**
 * Gets a collection class from the database
 *
 * @param {Id} collectionClassId
 * @returns {Promise<CollectionClass>}
 */
export const getCollectionClass = (collectionClassId) => {
    return withCache(
        `${BASE_URL}/cms/collection_class/${collectionClassId}`,
        get,
    );
};
