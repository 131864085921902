import React from 'react';
import { Spin } from 'antd';

/**
 *
 * @param {Object} props
 * @param {string} [props.tip]
 */
function Loading({ tip }) {
    return <Spin tip={tip} />;
}

export default Loading;
