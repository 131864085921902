export const STARTED_LOADING_COLLECTION_TYPE =
    'STARTED_LOADING_COLLECTION_TYPE';
export const SUCCESS_LOADING_COLLECTION_TYPE =
    'SUCCESS_LOADING_COLLECTION_TYPE';
export const FAILED_LOADING_COLLECTION_TYPE = 'FAILED_LOADING_COLLECTION_TYPE';
export const STARTED_ADDING_COLLECTION_TYPE = 'STARTED_ADDING_COLLECTION_TYPE';
export const SUCCESS_ADDING_COLLECTION_TYPE = 'SUCCESS_ADDING_COLLECTION_TYPE';
export const FAILED_ADDING_COLLECTION_TYPE = 'FAILED_ADDING_COLLECTION_TYPE';
export const STARTED_PATCHING_COLLECTION_TYPE =
    'STARTED_PATCHING_COLLECTION_TYPE';
export const SUCCESS_PATCHING_COLLECTION_TYPE =
    'SUCCESS_PATCHING_COLLECTION_TYPE';
export const FAILED_PATCHING_COLLECTION_TYPE =
    'FAILED_PATCHING_COLLECTION_TYPE';
