/**
 * @param {GeoJSON.BBox} bbox
 * @returns {GeoJSON.Polygon}
 */
export const getPolygonGeometryFromBBox = (bbox) => {
    /**
     * https://tools.ietf.org/html/rfc7946#section-3.1.6
     *
     */
    const [minlon, minlat, maxlon, maxlat] = bbox;

    return {
        type: 'Polygon',
        coordinates: [
            [
                [minlon, minlat],
                [maxlon, minlat],
                [maxlon, maxlat],
                [minlon, maxlat],
                [minlon, minlat], // The first and last positions are equivalent, and they MUST contain
                // identical values; their representation SHOULD also be identical.
            ],
        ],
    };
};

const initialBounds = {
    minLon: Number.POSITIVE_INFINITY,
    minLat: Number.POSITIVE_INFINITY,
    maxLon: Number.NEGATIVE_INFINITY,
    maxLat: Number.NEGATIVE_INFINITY,
};

/**
 * Gets bounds that include a polygon
 *
 * @param {GeoJSON.Polygon} polygon
 * @returns {[[number, number], [number, number]]} [[minlon, minlat], [maxlon, maxlat]]
 */
export const getBoundsFromPolygon = (polygon) => {
    if (!polygon?.coordinates[0]) {
        return null;
    }

    const { minLon, minLat, maxLon, maxLat } = polygon.coordinates[0].reduce(
        ({ minLon, minLat, maxLon, maxLat }, [lon, lat]) => {
            return {
                minLon: Math.min(minLon, lon),
                minLat: Math.min(minLat, lat),
                maxLon: Math.max(maxLon, lon),
                maxLat: Math.max(maxLat, lat),
            };
        },
        initialBounds,
    );
    return [
        [minLon, minLat],
        [maxLon, maxLat],
    ];
};

/**
 * Gets bounds that include multiple polygons
 *
 * @param {GeoJSON.Polygon[]} polygons
 * @returns {[[number, number], [number, number]]} [[minlon, minlat], [maxlon, maxlat]]
 */
export const getBoundsFromPolygons = (polygons) => {
    return polygons.reduce(
        (acc, polygon) => {
            if (!polygon?.coordinates) return acc;

            const [[accMinLon, accMinLat], [accMaxLon, accMaxLat]] = acc;
            const [[minLon, minLat], [maxLon, maxLat]] = getBoundsFromPolygon(
                polygon,
            );

            return [
                [Math.min(accMinLon, minLon), Math.min(accMinLat, minLat)],
                [Math.max(accMaxLon, maxLon), Math.max(accMaxLat, maxLat)],
            ];
        },
        [
            [initialBounds.minLon, initialBounds.minLat],
            [initialBounds.maxLon, initialBounds.maxLat],
        ],
    );
};
