import { get } from 'utils/http';
import { BASE_URL } from 'constants/env';

export const AUTHORS_SWR_KEY = '/cms/users/authors';

/**
 * Gets the list of users that are authors
 *
 * @returns {Promise<CMSUser[]>}
 */
export const getAuthors = () => {
    return get(`${BASE_URL}/cms/users/authors`).then((result = {}) => {
        return result || [];
    });
};
