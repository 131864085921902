import React, { useMemo } from 'react';
import useSWR from 'swr';
import { Typography } from 'antd';
import { getStepSpotsWithTips } from 'api/step-spots.api';
import { parse } from 'utils/error-parser';
import Loading from 'components/Loading';
import SelectableTip from './SelectableTip';
import './AllTips.css';
import { sortBy } from 'lodash';

const { Text } = Typography;

/**
 *
 * @param {Object} props
 * @param {CollectionSpot} props.collectionSpot
 * @param {(stepSpot: StepSpot) => any} props.onSelectStepSpot
 * @param {CollectionSpotTip[]} props.selectedTips
 */
export default function AllTips({
    collectionSpot,
    onSelectStepSpot,
    selectedTips,
}) {
    const { stepSpots, isLoading, error } = useStepSpotsWithTips(
        collectionSpot?.spot,
    );

    const orderedSpots = useMemo(() => {
        if (!stepSpots) {
            return stepSpots;
        }
        return sortBy(stepSpots, ['creation_time']).reverse();
    }, [stepSpots]);
    /**
     * @param {StepSpot} stepSpot
     * @returns {boolean}
     */
    const isStepSpotSelected = (stepSpot) => {
        for (const tip of selectedTips) {
            if (tip.step_spot?.id === stepSpot.id) {
                return true;
            }
        }
        return false;
    };

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <Text type="danger">Error loading public tips: {error}</Text>;
    }
    if (!stepSpots?.length) {
        return (
            <div className="column-center spot-editor-all-tips-empty">
                <Text strong>Public user tips</Text>
                <Text type="secondary">No public user tips for this spot</Text>
            </div>
        );
    }
    return (
        <div className="spot-editor-all-tips">
            <div className="spot-editor-all-tips-header">
                <Text strong>{stepSpots.length} public tips available</Text>
            </div>
            {orderedSpots.map((stepSpot) => (
                <SelectableTip
                    key={stepSpot.id}
                    isSelected={isStepSpotSelected(stepSpot)}
                    onSelect={onSelectStepSpot}
                    stepSpot={stepSpot}
                />
            ))}
        </div>
    );
}

/**
 * @param {PSSpot_v15_Minimal?} spot
 */
function useStepSpotsWithTips(spot) {
    const { data, error, isValidating } = useSWR(
        spot ? `/cms/spots/${spot.id}/public_step_spots` : null,
        () => getStepSpotsWithTips(spot),
    );
    return {
        stepSpots: data,
        error: error ? parse(error) : null,
        isLoading: isValidating && !data,
    };
}
