import React from 'react';

import { Tabs } from 'components/Tabs';
import CollectionTypeGeneral from './CollectionTypeGeneral';
import CollectionTypeSettings from './CollectionTypeSettings';

function CollectionTypeEditor() {
    return (
        <Tabs
            defaultActiveKey="general"
            type="full-page"
            tabs={[
                {
                    name: 'General',
                    key: 'general',
                    content: <CollectionTypeGeneral />,
                },
                {
                    name: 'Settings',
                    key: 'settings',
                    content: <CollectionTypeSettings />,
                },
            ]}
        />
    );
}

export default CollectionTypeEditor;
