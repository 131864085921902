import { PageHeader } from '@ant-design/pro-layout';
import { Button } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CollectionTypeEditor from 'components/CollectionTypeEditor';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { startAddingCollectionType } from 'store/collection-type-editor/actions';
import {
    getCurrentCollectionType,
    isCollectionTypeCorrect,
} from 'store/collection-type-editor/selectors';
import { addCollectionType } from 'store/collection-types/actions';
import { isAddingCollectionType } from 'store/collection-types/selectors';
import './style.css';

function AddCollectionType() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startAddingCollectionType());
    }, [dispatch]);

    const collectionTypeComplete = useSelector(isCollectionTypeCorrect);
    const currentCollectionType = useSelector(getCurrentCollectionType);
    const addingCollectionType = useSelector(isAddingCollectionType);
    const history = useHistory();

    const onAdd = () => {
        if (!isCollectionTypeCorrect) {
            return;
        }
        dispatch(addCollectionType(currentCollectionType)).then(() => {
            history.push(routes['guides-collection-types'].route);
        });
    };

    return (
        <div className="full-page">
            <PageHeader
                title="Add collection type"
                ghost={false}
                onBack={() => history.push('/guides/collection-types')}
            />
            <CollectionTypeEditor />
            <div className="full-page-footer">
                <div className="grow-full-flex"></div>
                <Link to="/guides/collection-types">
                    <Button size="large">Cancel</Button>
                </Link>
                <Button
                    size="large"
                    type="primary"
                    disabled={!collectionTypeComplete}
                    onClick={onAdd}
                    loading={addingCollectionType}
                >
                    Save changes
                </Button>
            </div>
        </div>
    );
}

export default AddCollectionType;
