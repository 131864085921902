import loadImage from 'blueimp-load-image-npm';
import { v4 as uuid } from 'uuid';
import { CORS_ANYWHERE_PROXY } from 'constants/env';
import { getIdFromSize } from 'utils/media-utils';

/**
 * @param {string} url
 * @param {DownloadMediaOptions} options
 * @returns {Promise<HTMLImageElement>}
 */
export const loadUrlInImage = (url, options = {}) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.onload = () => {
            resolve(image);
        };

        image.onerror = () => {
            reject('Error loading image from URL');
        };

        // Busting cache. When image comes from cache all Request Headers that avoid CORS problems are removed, so we really need to load it again.
        const urlToUse = options.bustCache ? `${url}?${uuid()}` : url;
        const urlPrefix = options.useCORSCache ? CORS_ANYWHERE_PROXY : '';
        image.src = `${urlPrefix}${urlToUse}`;
    });
};

function dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

/**
 * Crops an image (from a url) to different sizes
 *
 * @param {string} imageUrl
 * @param {CropInfo} cropInfo
 * @param {MediaSize[]} sizes
 * @param {DownloadMediaOptions} options
 * @returns {Promise<UploadableImage[]>}
 */
export async function cropImage(imageUrl, cropInfo, sizes, options) {
    const { image, blob } = await loadIntoBlob(imageUrl, options);

    const bigImageSize = {
        height: image.height,
        width: image.width,
    };
    const cropOptions = getCropOptions(cropInfo, bigImageSize, sizes);
    return Promise.all(
        cropOptions.map((options) => cropIndividualImage(blob, options)),
    );
}

/**
 * Resizes a local image to different sizes
 *
 * @param {HTMLImageElement} element
 * @param {File} imageFile
 * @param {MediaSize[]} sizes
 * @returns {Promise<UploadableImage[]>}
 */
export async function resizeLocalImage(element, imageFile, sizes) {
    const originalImage = {
        type: imageFile.type,
        height: element.naturalHeight,
        width: element.naturalWidth,
        name: getIdFromSize({
            height: element.naturalHeight,
            width: element.naturalWidth,
        }),
        blob: imageFile,
    };
    const resizedImages = await Promise.all(
        sizes.map((size) =>
            cropIndividualImage(imageFile, {
                sourceWidth: element.naturalWidth,
                sourceHeight: element.naturalHeight,
                top: 0,
                left: 0,
                maxWidth: size.maxWidth,
                maxHeight: size.maxHeight,
                quality: size.quality,
                crop: false,
                canvas: true,
            }),
        ),
    );
    const allImages = [originalImage, ...resizedImages];
    const nonRepeatedImages = allImages.reduce(
        (acc, image) => {
            if (acc.find((addedImage) => addedImage.name === image.name)) {
                // Do not add images with a name already present, they are just the same size
                return acc;
            }
            return [...acc, image];
        },
        /** @type {UploadableImage[]} */ [],
    );
    return nonRepeatedImages;
}

/**
 * @param {string} imageUrl
 * @param {DownloadMediaOptions} options
 * @returns {Promise<{blob: Blob, image: HTMLImageElement}>}
 */
async function loadIntoBlob(imageUrl, options) {
    const image = await loadUrlInImage(imageUrl, options);
    const canvas = drawImgToCanvas(image);
    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve({ blob, image });
        });
    });
}

/**
 * @param {HTMLImageElement} image
 * @param {{width: number, height: number}} [desiredSize] If present the canvas will be created with that size, if the image
 * is bigger than that, data will be lost. If not specified, the size of the image will be used to determine the size of the canvas.
 */
export function drawImgToCanvas(
    image,
    desiredSize = { width: null, height: null },
) {
    const canvas = document.createElement('canvas');
    canvas.width = desiredSize?.width || image.width;
    canvas.height = desiredSize?.height || image.height;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0);
    return canvas;
}

/**
 * @param {Blob} blob
 * @param {CropOptions} options
 * @returns {Promise<UploadableImage>}
 */
function cropIndividualImage(blob, options) {
    return new Promise((resolve, reject) => {
        loadImage(
            blob,
            (resultImage) => {
                if (!resultImage || resultImage.type === 'error') {
                    reject(new Error('Error loading image'));
                } else {
                    resolve(canvasToUploadableImage(resultImage, options));
                }
            },
            options,
        );
    });
}

/**
 * @param {HTMLCanvasElement} canvas
 * @param {CropOptions} options
 * @returns {UploadableImage}
 */
function canvasToUploadableImage(canvas, options) {
    const type = 'image/jpeg';
    const dataURI = canvas.toDataURL(type, options.quality);
    const blob = dataURItoBlob(dataURI);
    return {
        type: blob.type,
        name: getIdFromSize({
            width: canvas.width,
            height: canvas.height,
        }),
        blob,
        width: canvas.width,
        height: canvas.height,
    };
}

/**
 * Options to be passed to loadImage from https://github.com/blueimp/JavaScript-Load-Image
 *
 * @typedef {object} CropOptions
 * @property {number} sourceWidth The width of the sub-rectangle of the source image to draw into the destination canvas.
 * @property {number} sourceHeight The height of the sub-rectangle of the source image to draw into the destination canvas.
 * @property {number} top The top margin of the sub-rectangle of the source image.
 * @property {number} left The left margin of the sub-rectangle of the source image.
 * @property {number} maxWidth Defines the maximum width of the img/canvas element.
 * @property {number} maxHeight Defines the maximum height of the img/canvas element.
 * @property {number} quality Quality to be used when converting to jpeg
 * @property {boolean} crop Crops the image to the maxWidth/maxHeight constraints if set to true.
 * @property {boolean} [canvas] If true it will force the rendering system to do the rendering with canvas
 */

/**
 *
 * @param {CropInfo} cropInfo
 * @param {{width: number, height: number}} originalSize
 * @param {MediaSize[]} maxSizes
 * @returns {CropOptions[]}
 */
function getCropOptions(cropInfo, originalSize, maxSizes) {
    const percentageWidth = cropInfo.width / cropInfo.originalWidth;
    const percentageHeight = cropInfo.height / cropInfo.originalHeight;
    const percentageLeft = cropInfo.x / cropInfo.originalWidth;
    const percentageTop = cropInfo.y / cropInfo.originalHeight;

    const sharedOptions = {
        sourceWidth: Math.round(originalSize.width * percentageWidth),
        sourceHeight: Math.round(originalSize.height * percentageHeight),
        left: Math.round(originalSize.width * percentageLeft),
        top: Math.round(originalSize.height * percentageTop),
        crop: true,
        canvas: true,
    };

    return maxSizes.map((eachMaxSize) => {
        return {
            ...sharedOptions,
            ...eachMaxSize,
        };
    });
}
