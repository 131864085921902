import React, { useCallback, useState, useMemo } from 'react';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import { Spin, Radio } from 'antd';
import { searchFromLocation } from 'api/media.api';
import { convertPSMediaToCMSMedia, isPSMediaSelected } from 'utils/media-utils';
import { useInfiniteScrollGet } from 'utils/use-infinite-scroll-get';
import { VIDEO, IMAGE } from 'constants/media-types.constants';
import PolarstepsMediaItem from './PolarstepsMediaItem';
import MediaOrientationSelector from '../MediaOrientationSelector';

/**
 *
 * @param {Object} props
 * @param {CMSMedia?} props.selectedMedia
 * @param {Id} props.guideId
 * @param {PSLocation?} [props.location]
 * @param {(media: CMSMedia) => void} props.onSelect
 * @param {boolean} props.includeVideos
 * @param {boolean} props.isShown
 * @param {React.ReactNode} props.sourceSelector
 * @returns {JSX.Element}
 */
function MediaSelectorModalPolarsteps({
    selectedMedia,
    guideId,
    location,
    onSelect,
    includeVideos,
    isShown,
    sourceSelector,
}) {
    const [mediaType, setMediaType] = useState(
        includeVideos ? 'all' : 'photos',
    );
    const allowedMediaTypes = useMemo(() => {
        switch (mediaType) {
            case 'photos':
                return [IMAGE];
            case 'videos':
                return [VIDEO];
            default:
                return [];
        }
    }, [mediaType]);

    const apiGetter = useCallback(
        (page) => searchFromLocation(location, allowedMediaTypes, page),
        [location, allowedMediaTypes],
    );

    const {
        isLoading,
        getNewPage,
        hasMoreResults,
        results,
    } = useInfiniteScrollGet(apiGetter);

    const selectMedia = async (media) => {
        const convertedMedia = await convertPSMediaToCMSMedia(media);
        onSelect(convertedMedia);
    };
    const mediaTypeFilterOptions = [
        { label: 'Show all', value: 'all', disabled: !includeVideos },
        { label: 'Photos', value: 'photos' },
        { label: 'Videos', value: 'videos', disabled: !includeVideos },
    ];

    return (
        <div style={{ display: isShown ? 'block' : 'none' }}>
            <div className="media-selector__tools">
                {sourceSelector}
                <div className="line-center grow-full-flex">
                    <Radio.Group
                        buttonStyle="outline"
                        value={mediaType}
                        onChange={(e) => {
                            setMediaType(e.target.value);
                        }}
                    >
                        {mediaTypeFilterOptions.map(
                            ({ value, label, disabled }) => (
                                <Radio.Button
                                    key={value}
                                    value={value}
                                    disabled={disabled}
                                >
                                    {label}
                                </Radio.Button>
                            ),
                        )}
                    </Radio.Group>
                </div>
                <div className="ml-sm">
                    <MediaOrientationSelector disabled={true} />
                </div>
            </div>

            {!location && (
                <div className="media-selector-no-results">
                    Please select a location to see media related to it
                </div>
            )}

            {!isLoading && !hasMoreResults && !results.length && (
                <div className="media-selector-no-results">No media found</div>
            )}

            {location && (
                <div
                    className={classNames(
                        'media-selector-image-list-container',
                    )}
                >
                    {/* All photos */}
                    <InfiniteScroll
                        key={`${location?.id}__${allowedMediaTypes.join('_')}`}
                        className="media-selector-image-list"
                        pageStart={-1}
                        loadMore={getNewPage}
                        threshold={400}
                        hasMore={!isLoading && hasMoreResults}
                        useWindow={false}
                    >
                        {results.map((item) => (
                            <PolarstepsMediaItem
                                key={item.id}
                                media={item}
                                guideId={guideId}
                                canBeFavorite={!!guideId}
                                onSelect={() => selectMedia(item)}
                                isSelected={isPSMediaSelected(
                                    item,
                                    selectedMedia,
                                )}
                            />
                        ))}
                    </InfiniteScroll>

                    {/* Loading */}
                    {isLoading && (
                        <div className="media-selector-image-list-loading">
                            <Spin />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default MediaSelectorModalPolarsteps;
