import { useMemo } from 'react';
import useSWR from 'swr';
import { v4 as uuid } from 'uuid';
import { useSharedCodebase } from 'utils/use-shared-codebase';
import { LOCATION_IQ_KEY } from 'constants/env';
import { get } from 'utils/http';
import {
    location1toLocation2,
    sharedCodebaseLocationToPSLocationWithBB,
} from 'utils/location-utils';

const RESULT_LIMIT = 20;

/**
 * @param {string} query
 */
export function useSearchByName(query) {
    const { shared, error: errorLoadingSharedCodebase } = useSharedCodebase();
    const geocoder = useMemo(
        () => shared?.polarstepsCore().createGeocoder(LOCATION_IQ_KEY),
        [shared],
    );

    const { data, isValidating, error } = useSWR(
        getKeyShare(query, !!shared),
        async () => {
            const params = shared.com.polarsteps.shared.service.AutocompleteParameters.forStepSearchWithViewBox(
                query,
                null,
            );
            const req = geocoder.autocompleteRequest(params);
            const searchParams = new URLSearchParams();
            req.parameters.forEach((param) =>
                searchParams.set(param.key, param.value),
            );
            const url = new URL(`${req.url}?${searchParams.toString()}`);
            const result = await get(url.toString(), {
                credentials: 'omit',
            });
            const parsed = geocoder.parseAutocompleteResult(
                JSON.stringify(result),
                params,
            );
            return parsed.map((parsedResult) =>
                sharedCodebaseLocationToPSLocationWithBB(parsedResult, uuid()),
            );
        },
        { revalidateOnFocus: false },
    );

    return {
        locationsByName: data,
        isLoading: isValidating && !data && !error,
        isValidating,
        error: error || errorLoadingSharedCodebase,
    };
}

/**
 *
 * @param {string?} query
 * @param {boolean} isSharedCodebaseLoaded
 */
function getKeyShare(query, isSharedCodebaseLoaded) {
    if (!isSharedCodebaseLoaded || !query || query.length < 3) {
        return null;
    }
    return `locationiqSearchByName/${query}`;
}

/**
 * @param {any} rawData
 * @returns {PSLocationWithBB}
 */
function processSearchResult(rawData) {
    if (!rawData || !rawData.address) {
        return null;
    }

    const country = rawData.address.country;
    const countryCode = (rawData.address.country_code || '').toUpperCase();

    return {
        id: rawData.place_id,
        name: rawData.display_place,
        lat: parseFloat(rawData.lat),
        lon: parseFloat(rawData.lon),
        detail: country,
        fullDetail: country,
        countryCode,
        boundingbox: getGeoJSONBBox(rawData.boundingbox),
    };
}

/**
 * Gets a correct GeoJSON BBox from LocationIQs BBox )order and type of data is different
 *
 * @param {[string, string, string, string]} locationIQBBox [minlat, maxlat, minlon, maxlon]
 * @returns {GeoJSON.BBox} [minlon, minlat, maxlon, maxlat]
 */
function getGeoJSONBBox(locationIQBBox) {
    if (!locationIQBBox) {
        return null;
    }
    const intBox = locationIQBBox.map((str) => parseFloat(str));
    const [minlat, maxlat, minlon, maxlon] = intBox;
    return [minlon, minlat, maxlon, maxlat];
}

/**
 * Searches locations in locationIQ api by name, focusing on spots and returning only one result
 *
 * @param {string} query
 * @param {[[number, number], [number, number]]} [preferredBounds]
 * @returns {Promise<PSLocation2>}
 */
async function searchSpotByName(query, preferredBounds) {
    if (query.length < 3) {
        return Promise.resolve(null);
    }
    const url = `https://api.locationiq.com/v1/autocomplete.php?accept-language=en&normalizecity=1&limit=${RESULT_LIMIT}&bounded=0&key=${LOCATION_IQ_KEY}&q=${query}&dedupe=1${getBoundsParams(
        preferredBounds,
    )}`;
    const result = await get(url, {
        credentials: 'omit',
    });
    if (!result?.length) {
        return null;
    }
    const processedSearch = location1toLocation2(
        processSearchResult(result[0]),
    );
    return {
        ...processedSearch,
        single_line: result[0].display_name,
    };
}

/**
 *
 * @param {number} lat
 * @param {number} lon
 * @returns {Promise<PSLocation2>}
 */
async function searchSpotByLatLon(lat, lon) {
    const url = `https://eu1.locationiq.com/v1/reverse.php?key=${LOCATION_IQ_KEY}&lat=${lat}&lon=${lon}&format=json`;
    try {
        const result = await get(url, {
            credentials: 'omit',
        });
        if (!result) {
            throw new Error('searchSpotByLatLon: no result');
        }
        const processedSearch = location1toLocation2(
            processSearchResult(result),
        );
        return {
            ...processedSearch,
            lat,
            lon,
            single_line: result.display_name,
        };
    } catch (e) {
        return {
            lat,
            lon,
            source: null,
            locality: null,
            administrative_area: null,
            country: null,
            country_code: null,
            single_line: 'Unknown',
        };
    }
}

/**
 * Searches locations in locationIQ api by name, focusing on spots and returning only one result
 *
 * @param {string} query
 * @param {[[number, number], [number, number]]} [preferredBounds]
 * @returns {Promise<PSLocation2>}
 */
export const searchSpot = async (query, preferredBounds) => {
    console.log('searchSpot', query);
    if (!query) {
        return Promise.resolve(null);
    }

    const isLatLon = query.match(
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
    );
    if (isLatLon) {
        const [lat, lon] = query
            .replace(' ', '')
            .split(',')
            .map((string) => parseFloat(string));
        return searchSpotByLatLon(lat, lon);
    }
    return searchSpotByName(query, preferredBounds);
};

/**
 *
 * @param {[[number, number], [number, number]]} bounds
 */
function getBoundsParams(bounds) {
    if (!bounds) {
        return '';
    }
    const max_lon = Math.max(bounds[0][0], bounds[1][0]);
    const min_lon = Math.min(bounds[0][0], bounds[1][0]);
    const max_lat = Math.max(bounds[0][1], bounds[1][1]);
    const min_lat = Math.min(bounds[0][1], bounds[1][1]);
    return `&viewbox=${max_lon},${max_lat},${min_lon},${min_lat}`;
}
