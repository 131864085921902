import { BASE_URL } from 'constants/env';
import { get, post, postDelete } from 'utils/http';

/**
 * @param {Id} guideId
 * @returns {Promise<FavoriteImage[]>}
 */
export const getFavoriteImagesForGuide = (guideId) => {
    return get(`${BASE_URL}/cms/guides/${guideId}/favorite_images`).then(
        (result = {}) => {
            return result.favorites || [];
        },
    );
};

/**
 *
 * @param {Id} guide_id
 * @param {Object} favData
 * @param {Id} favData.creator_id
 * @param {Id} favData.source_id
 * @param {Id} favData.external_id
 * @param {FavoriteImageData} favData.data
 */
export const addFavoriteImage = (
    guide_id,
    { creator_id, source_id, external_id, data },
) => {
    return post(`${BASE_URL}/cms/guides/${guide_id}/favorite_images`, {
        guide_id,
        creator_id,
        source_id,
        external_id,
        data,
    });
};

/**
 * @param {Id} guide_id
 * @param {Id} id
 */
export const removeFavoriteImage = (guide_id, id) => {
    return postDelete(
        `${BASE_URL}/cms/guides/${guide_id}/favorite_images/${id}`,
        {
            returnPlainResponse: true,
        },
    );
};
