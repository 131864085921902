import {
    START_ADDING_COLLECTION_TYPE,
    START_EDITING_COLLECTION_TYPE,
    PATCH_COLLECTION_TYPE_FIELD,
} from './action-types';

/**
 * Starts the edition of a new collection type
 *
 * @returns {object} The action to be dispatched
 */
export const startAddingCollectionType = () => {
    return {
        type: START_ADDING_COLLECTION_TYPE,
    };
};

/**
 * Starts the edition of a collection type
 *
 * @param {CollectionType} collectionType
 * @returns {object} The action to be dispatched
 */
export const startEditingCollectionType = (collectionType) => {
    return {
        type: START_EDITING_COLLECTION_TYPE,
        collectionType,
    };
};

/**
 * Changes the value of a field in the currently edited collection type
 *
 * @param {string} fieldName
 * @param {*} newValue
 * @returns {object} The action to be dispatched
 */
export const patchCollectionTypeField = (fieldName, newValue) => {
    return {
        type: PATCH_COLLECTION_TYPE_FIELD,
        fieldName,
        newValue,
    };
};
