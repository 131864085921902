import useSWR from 'swr';

export const loadSharedCodebase = () => {
    return import('@polarsteps/polarsteps-shared-core').then((shared) => {
        shared.polarstepsCore().setLogLevel(0);
        return shared;
    });
};

export const useSharedCodebase = () => {
    const { data, isValidating, error } = useSWR(
        'shared-codebase',
        loadSharedCodebase,
        { revalidateOnFocus: false },
    );
    return {
        shared: data,
        domain: data?.com.polarsteps.shared.domain,
        util: data?.com.polarsteps.shared.util,
        service: data?.com.polarsteps.shared.service,
        isValidating,
        error,
    };
};
