/**
 * Returns the id of a child object. If the child object is udnefined, undefined will be returned instead.
 * If the child object is null, then null.
 *
 * @param {{id?: Id}} child
 */
export const getChildId = (child) => {
    if (typeof child === 'undefined') {
        return undefined;
    }
    if (child === null) {
        return null;
    }
    return child.id || null;
};
