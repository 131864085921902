import React from 'react';
import classNames from 'classnames';

/**
 *
 * @param {Object} props
 * @param {Collection} props.collection
 * @param {string} props.className
 */
function CollectionStatus({ collection, className = '' }) {
    return (
        <div
            className={classNames(
                'collection-status',
                `collection-status-${collection.publish_status.name}`,
                className,
            )}
        />
    );
}

export default CollectionStatus;
