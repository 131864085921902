import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
    Button,
    Card,
    Input,
    Modal,
    notification,
    Spin,
    Typography,
    Tooltip,
} from 'antd';
import {
    DeleteOutlined,
    UploadOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { SPOT_DETAIL_MEDIA } from 'constants/media-sizes.constants';
import { CloseCircleOutlined } from '@ant-design/icons';
import { createCMSMediaFromLocalFile, patchCMSMedia } from 'api/cms-media.api';
import {
    getMediaWithSize,
    hasMediaCorrectAttribution,
} from 'utils/media-utils';
import FormField from 'components/FormField';
import './custom-photos.css';

const { Text } = Typography;

/**
 * @typedef UploadingFileWithMetadata
 * @property {File} file
 * @property {string} id
 * @property {boolean} error
 * @property {boolean} completed
 * @property {CMSMedia?} relatedCMSMedia
 */

/**
 *
 * @param {Object} props
 * @param {CollectionSpot} props.collectionSpot
 * @param {(newPhotos: CollectionSpotMediaRelation[]) => void} props.onChange
 */
export default function CustomPhotos({ collectionSpot, onChange }) {
    const [isUpdatingCMSMedia, setIsUpdatingCMSMedia] = useState(false);
    /** @type {[CollectionSpotMediaRelation, React.Dispatch<CollectionSpotMediaRelation>]} */
    const [editedMedia, setEditedMedia] = useState(null);
    const [isChoosingFiles, setIsChoosingFiles] = useState(false);
    /** @type {UploadingFileWithMetadata[]} */
    const initialPBUState = null;
    const [photosBeingUploaded, setPhotosBeingUploaded] = useState(
        initialPBUState,
    );
    const arePhotosLeftUploading = useMemo(() => {
        return !!photosBeingUploaded?.length;
    }, [photosBeingUploaded]);

    const updateAddedSpotPhotos = useCallback(
        /**
         * @param {CMSMedia[]} newPhotos
         */
        (newPhotos) => {
            onChange([
                ...collectionSpot.collection_spot_media,
                ...newPhotos.map((cmsMedia) => ({
                    cms_media: cmsMedia,
                    cms_media_id: cmsMedia.id,
                    position: undefined,
                    id: undefined,
                })),
            ]);
        },
        [collectionSpot],
    );
    // Once all the photos are done, add them to the spot
    useEffect(() => {
        if (!photosBeingUploaded?.length) {
            return;
        }
        const missingPhotos = photosBeingUploaded.filter(
            (photo) => !photo.completed && !photo.error,
        );
        if (missingPhotos.length) {
            return;
        }
        const relatedMedia = photosBeingUploaded
            .filter((photo) => photo.completed && photo.relatedCMSMedia)
            .map((photo) => photo.relatedCMSMedia);
        if (relatedMedia.length) {
            updateAddedSpotPhotos(relatedMedia);
        }
        setPhotosBeingUploaded([]);
    }, [photosBeingUploaded, updateAddedSpotPhotos]);

    const onUploadClick = async () => {
        // @ts-ignore
        if (!window.showOpenFilePicker) {
            Modal.error({
                title: 'Unsupported browser',
                content: 'Please use the latest Chrome for this functionality',
            });
            return;
        }
        setIsChoosingFiles(true);
        try {
            // @ts-ignore
            const fileHandles = await window.showOpenFilePicker({
                multiple: true,
                types: [
                    {
                        description: 'Images',
                        accept: {
                            'image/*': ['.jpeg', '.jpg'],
                        },
                    },
                ],
                excludeAcceptAllOption: true,
            });
            /** @type {File[]} */
            const files = await Promise.all(
                fileHandles.map((handle) => handle.getFile()),
            );
            const filesWithMetadata = files.map((file) => ({
                file,
                id: uuid(),
                error: false,
                completed: false,
                relatedCMSMedia: null,
            }));
            setPhotosBeingUploaded(filesWithMetadata);
        } catch (e) {
            if (e.code !== e.ABORT_ERR) {
                // ABORT_ERR is thrown when user cancels
                notification.error({ message: 'Error uploading images' });
            }
        } finally {
            setIsChoosingFiles(false);
        }
    };

    /**
     * @param {string} photoId
     * @param {Error} error
     */
    const onErrorUploadingPhoto = (photoId, error) => {
        setPhotosBeingUploaded((old) =>
            old.map((photo) => {
                if (photo.id !== photoId) {
                    return photo;
                }
                return {
                    ...photo,
                    error: true,
                };
            }),
        );
        notification.error({ message: error.message });
    };

    /**
     * @param {string} photoId
     * @param {CMSMedia} relatedCMSMedia
     */
    const onNewMedia = (photoId, relatedCMSMedia) => {
        setPhotosBeingUploaded((old) =>
            old.map((photo) => {
                if (photo.id !== photoId) {
                    return photo;
                }
                return {
                    ...photo,
                    completed: true,
                    relatedCMSMedia,
                };
            }),
        );
    };

    /**
     *
     * @param {CollectionSpotMediaRelation} mediaItem
     */
    const deleteMediaItem = async (mediaItem) => {
        try {
            await new Promise((resolve, reject) =>
                Modal.confirm({
                    title: 'Delete photo',
                    content:
                        'Are you sure you want to delete this photo from the spot?',
                    onOk: (close) => {
                        resolve();
                        close();
                    },
                    onCancel: (close) => {
                        reject();
                        close();
                    },
                    okText: 'Delete',
                    okButtonProps: {
                        danger: true,
                    },
                    cancelText: 'Cancel',
                }),
            );
        } catch (e) {
            return;
        }
        onChange(
            collectionSpot.collection_spot_media.filter(
                (eachMedia) =>
                    eachMedia.cms_media_id !== mediaItem.cms_media_id,
            ),
        );
    };

    /**
     *
     * @param {CMSMedia} updatedCMSMedia
     */
    const updateCMSMedia = async (updatedCMSMedia) => {
        setIsUpdatingCMSMedia(true);
        try {
            const patchedMedia = await patchCMSMedia(updatedCMSMedia);
            onChange(
                collectionSpot.collection_spot_media.map((media) => {
                    if (media.cms_media_id === patchedMedia.id) {
                        return {
                            ...media,
                            cms_media: patchedMedia,
                        };
                    }
                    return media;
                }),
            );
        } catch (error) {
            notification.error({
                message: error?.message || 'Error updating attribution',
            });
        } finally {
            setIsUpdatingCMSMedia(false);
            setEditedMedia(null);
        }
    };

    return (
        <Card
            title={
                <div className="line-center">
                    <div className="grow-full-flex">Custom photos</div>
                    <Button
                        onClick={onUploadClick}
                        type="primary"
                        danger
                        icon={<UploadOutlined />}
                        loading={isChoosingFiles || arePhotosLeftUploading}
                        disabled={isChoosingFiles || arePhotosLeftUploading}
                    >
                        Upload photos
                    </Button>
                </div>
            }
            className="collection-spot-editor__photos-custom"
        >
            <Modal
                open={arePhotosLeftUploading}
                title="Uploading photos..."
                width={800 + 48 + 3 * 8} // Desired width + antd padding + grid gap * 3 gaps
                maskClosable={false}
                closable={false}
                footer={null}
            >
                <div className="cse-photos__uploading-list">
                    {photosBeingUploaded?.map((photo) => (
                        <UploadingPhoto
                            key={photo.id}
                            file={photo.file}
                            hasError={photo.error}
                            isCompleted={photo.completed}
                            onError={(error) =>
                                onErrorUploadingPhoto(photo.id, error)
                            }
                            onSuccess={(newMedia) =>
                                onNewMedia(photo.id, newMedia)
                            }
                        />
                    ))}
                </div>
            </Modal>
            <CMSMediaAttributionEditor
                media={editedMedia}
                onMediaChanged={(newMedia) => updateCMSMedia(newMedia)}
                onCancelEditing={() => setEditedMedia(null)}
                isLoading={isUpdatingCMSMedia}
            />

            {!collectionSpot.collection_spot_media.length && (
                <div className="line-center">
                    <Text type="secondary">No custom photos (yet)</Text>
                </div>
            )}
            <div className="cse-photos__media-list">
                {collectionSpot.collection_spot_media.map((media) => (
                    <div
                        key={media.cms_media_id}
                        className="cse-photos__media-item"
                        onClick={() => setEditedMedia(media)}
                    >
                        <img
                            className="cse-photos__media-item-img"
                            src={getMediaWithSize(media.cms_media, 200).path}
                        />
                        {!hasMediaCorrectAttribution(media.cms_media) && (
                            <Tooltip title="This photo is missing attribution">
                                <div className="cse-photos__media-item-warning">
                                    <WarningOutlined
                                        style={{
                                            color: 'var(--polar-red)',
                                            fontSize: 24,
                                        }}
                                    />
                                </div>
                            </Tooltip>
                        )}
                        <div className="cse-photos__media-item-delete">
                            <Button
                                icon={<DeleteOutlined />}
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    deleteMediaItem(media);
                                }}
                                danger={true}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </Card>
    );
}

/**
 * @param {Object} props
 * @param {File} props.file
 * @param {boolean} props.hasError
 * @param {boolean} props.isCompleted
 * @param {(error: Error) => void} props.onError
 * @param {(newCmsMedia: CMSMedia) => void} props.onSuccess
 */
function UploadingPhoto({ file, hasError, isCompleted, onError, onSuccess }) {
    const src = useMemo(() => URL.createObjectURL(file), [file]);

    /**
     *
     * @param {HTMLImageElement} img
     */
    const onImageReady = async (img) => {
        try {
            const newMedia = await createCMSMediaFromLocalFile(
                img,
                file,
                SPOT_DETAIL_MEDIA,
            );
            onSuccess(newMedia);
        } catch (e) {
            onError(
                new Error(`Error creating CMS media from local file: ${e}`),
            );
        }
    };

    return (
        <div
            className={classNames('uploading-photo__container', {
                'has-error': hasError,
                'is-completed': isCompleted,
            })}
        >
            <img
                className="uploading-photo"
                src={src}
                // @ts-ignore
                onLoad={(ev) => onImageReady(ev.target)}
                onError={() =>
                    onError(new Error(`Error loading image ${file.name}`))
                }
            />
            {!isCompleted && !hasError && (
                <div className="uploading-photo__loading">
                    <Spin />
                </div>
            )}
            {hasError && (
                <div className="uploading-photo__loading">
                    <CloseCircleOutlined
                        style={{ color: 'red', fontSize: 80 }}
                    />
                </div>
            )}
        </div>
    );
}

/**
 *
 * @param {Object} props
 * @param {CollectionSpotMediaRelation} props.media
 * @param {(newMedia: CMSMedia) => void} props.onMediaChanged
 * @param {() => void} props.onCancelEditing
 * @param {boolean} props.isLoading
 */
function CMSMediaAttributionEditor({
    media,
    onMediaChanged,
    onCancelEditing,
    isLoading,
}) {
    const [authorName, setAuthorName] = useState('');
    useEffect(() => {
        setAuthorName(media?.cms_media.author_name || '');
    }, [media?.cms_media, setAuthorName]);

    const [attributionUrl, setAttributionUrl] = useState('');
    useEffect(() => {
        setAttributionUrl(media?.cms_media.attribution_url || '');
    }, [media?.cms_media, setAttributionUrl]);

    return (
        <Modal
            title="Update photo attribution"
            open={!!media}
            onCancel={() => onCancelEditing()}
            onOk={() => {
                onMediaChanged({
                    ...media.cms_media,
                    attribution_url: attributionUrl,
                    author_name: authorName,
                });
            }}
            okText="Save changes"
            okButtonProps={{ loading: isLoading }}
        >
            <FormField label="Author name (*)">
                <Input
                    value={authorName}
                    onChange={(e) => setAuthorName(e.target.value)}
                    size="large"
                    placeholder="Author of the photo"
                />
            </FormField>
            <FormField label="Attribution url">
                <Input
                    value={attributionUrl}
                    onChange={(e) => setAttributionUrl(e.target.value)}
                    size="large"
                    placeholder="Optional"
                />
            </FormField>
        </Modal>
    );
}
