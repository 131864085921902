import React from 'react';
import classNames from 'classnames';
import { Avatar } from 'antd';
import './style.css';

/**
 *
 * @param {Object} props
 * @param {string} props.src
 * @param {string} props.name
 * @param {string} [props.className='']
 */
function AvatarWithName({ src, name, className = '' }) {
    return (
        <div className={classNames('avatar-with-name', className)}>
            <Avatar size={32} src={src} />
            <div className="avatar-with-name__name">{name}</div>
        </div>
    );
}

export default AvatarWithName;
