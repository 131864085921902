import React, { useEffect } from 'react';
import { Spin } from 'antd';
import ReactCrop from 'react-image-crop';
import { getMediaWithSize } from 'utils/media-utils';
import { useOriginalMedia } from 'api/media.api';

import 'react-image-crop/dist/ReactCrop.css';

/**
 * @param {Object} props
 * @param {(error : any) => void} props.onError
 * @param {CMSMedia} props.selectedMedia
 * @param {(cropInfo: {media: CMSMedia, crop: any}) => void} props.onCropChange
 * @param {any} props.crop
 * @param {number} props.ratio
 * @param {PSSpot_v15_Minimal?} [props.spot] Optional, if it is provided, when opening a spot media we will try to find the original one in this spot
 */
function MediaCropperModal({
    onError,
    selectedMedia,
    onCropChange,
    ratio,
    crop,
    spot,
}) {
    const { originalMedia, error } = useOriginalMedia(selectedMedia, spot);

    useEffect(() => {
        if (!originalMedia && error) {
            onError(error);
        }
    }, [originalMedia, error, onError]);

    if (!originalMedia)
        return (
            <div className="media-selector-image-list-loading">
                <Spin />
            </div>
        );

    const closestSizedMedia = getMediaWithSize(originalMedia, 1000);

    return (
        <div className="media-cropper-modal">
            <ReactCrop
                src={closestSizedMedia.path}
                crop={crop}
                imageStyle={{ maxHeight: '70vh' }}
                onChange={(newCrop) => {
                    onCropChange({
                        media: originalMedia,
                        crop: {
                            ...crop,
                            ...newCrop,
                        },
                    });
                }}
                onImageLoaded={(image) => {
                    onCropChange({
                        media: originalMedia,
                        crop: {
                            x: 0,
                            y: 0,
                            width: image.width,
                            aspect: ratio,
                            originalWidth: image.width,
                            originalHeight: image.height,
                        },
                    });
                    return false; // Must return false for this to work
                }}
            />
        </div>
    );
}

export default MediaCropperModal;
