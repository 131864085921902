import { get } from 'utils/http';
import { BASE_URL } from 'constants/env';

export const GUIDE_RANKINGS_SWR_KEY = '/cms/guide_rankings';

/**
 * Gets the possible guide ranking values from the database
 *
 * @returns {Promise<GuideType[]>}
 */
export const getGuideRankings = () => {
    // we want the ranking to be sorted by level in meraki (default would be id)
    return get(
        `${BASE_URL}/cms/guide_rankings?q={"order_by":[{"field":"level","direction":"desc"}]}`,
    ).then((result = {}) => {
        return result.objects || [];
    });
};
