import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from '@react-hook/debounce';
import { getAllCollections } from 'api/collections.api';
import GenericOverviewTable from 'components/GenericOverviewTable';
import { Input } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { getName as getCollectionName } from 'utils/collection-utils.js';
import { getName as getGuideName } from 'utils/guide-utils.js';
import './style.css';

const { Search } = Input;

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name > b.name,
        // eslint-disable-next-line react/display-name
        render: (text, record) => (
            <Link to={`/guides/guide/${record.guide_id}`}>
                {getCollectionName(record)}
            </Link>
        ),
    },
    {
        title: 'Type',
        dataIndex: 'collection_type.collection_class_id',
        key: 'collection_type.collection_class_id',
        sorter: (a, b) => a.type > b.type,
        // eslint-disable-next-line react/display-name
        render: (text, record) => record.collection_type.collection_class.name,
    },
    {
        title: 'Guide',
        dataIndex: 'guide_id',
        key: 'guide_id',
        // eslint-disable-next-line react/display-name
        render: (text, record) => (
            <Link to={`/guides/guide/${record.guide_id}`}>
                {getGuideName(record.guide)}
            </Link>
        ),
        sorter: (a, b) => a.name > b.name,
    },
];

function CollectionsOverview() {
    const [filter, setFilter] = useDebounce('', 300);
    const apiGetter = useCallback(
        (...args) => getAllCollections(filter, ...args),
        [filter],
    );

    return (
        <div className="full-page">
            <PageHeader title="Collections" ghost={false}>
                <Search
                    className="full-page-header-tools-filter"
                    placeholder="Search for collections"
                    size="large"
                    onChange={(e) => setFilter(e.target.value)}
                />
            </PageHeader>

            <div className="full-page-content">
                <div className="max-content-width mb-m">
                    <GenericOverviewTable
                        columns={columns}
                        apiGetter={apiGetter}
                        emptyTableMessage={
                            filter
                                ? 'No collections found with this text.'
                                : 'Sorry no collections yet.'
                        }
                    ></GenericOverviewTable>
                </div>
            </div>
        </div>
    );
}

export default CollectionsOverview;
