import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '@ant-design/pro-layout';
import GuideEditor from 'components/GuideEditor';
import { startAddingGuide, stopAddingGuide } from 'store/guide-editor/actions';
import './style.css';
import { useHistory } from 'react-router';

function AddGuide() {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(startAddingGuide());

        return () => {
            dispatch(stopAddingGuide());
        };
    }, [dispatch]);

    return (
        <div className="full-page">
            <PageHeader
                title="Add new guide"
                ghost={false}
                onBack={() => history.push('/guides')}
            />
            <GuideEditor />
        </div>
    );
}

export default AddGuide;
