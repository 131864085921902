import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isPatchingCurrentCollectionType } from 'store/collection-types/selectors';
import { archiveCollectionType } from 'store/collection-types/actions';
import { getCurrentCollectionType } from 'store/collection-type-editor/selectors';
import { routes } from 'routes';

/**
 *
 * @param {Object} props
 * @param {boolean} props.show
 * @param {() => void} props.onHide
 */
function ArchiveCollectionTypeModal({ show, onHide }) {
    const currentCollectionType = useSelector(getCurrentCollectionType);
    const patchingCollectionType = useSelector(isPatchingCurrentCollectionType);
    const dispatch = useDispatch();
    const history = useHistory();

    const onArchive = () => {
        dispatch(archiveCollectionType(currentCollectionType)).then(() => {
            history.push(routes['guides-collection-types'].route);
        });
    };

    return (
        <Modal
            open={show}
            title="Archive collection type"
            onOk={onArchive}
            onCancel={onHide}
            footer={[
                <Button key="back" onClick={onHide}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    danger={true}
                    loading={patchingCollectionType}
                    onClick={onArchive}
                >
                    Archive collection type
                </Button>,
            ]}
        >
            <p>Are you sure you want to archive this collection type? </p>
        </Modal>
    );
}

export default ArchiveCollectionTypeModal;
