import React from 'react';
import GenericSelectorSWR from 'components/GenericSelectorSWR';
import { GUIDE_TYPES_SWR_KEY, getGuideTypes } from 'api/guide-types.api';

/**
 * @param {Object} props
 * @param {Id} props.value
 * @param {(newVal: Id) => void} props.onChange
 */
function GuideTypeSelector({ value, onChange }) {
    return (
        <GenericSelectorSWR
            value={value}
            onChange={onChange}
            swrKey={GUIDE_TYPES_SWR_KEY}
            fetcher={getGuideTypes}
            placeholder="Select guide type"
        ></GenericSelectorSWR>
    );
}

export default GuideTypeSelector;
