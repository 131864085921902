import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Input } from 'antd';
import CollectionClassSelector from 'components/CollectionClassSelector';
import { getCurrentCollectionType } from 'store/collection-type-editor/selectors';
import { patchCollectionTypeField } from 'store/collection-type-editor/actions';
import FormField from 'components/FormField';

function CollectionTypeGeneral() {
    const dispatch = useDispatch();
    const collectionType = useSelector(getCurrentCollectionType);

    if (!collectionType) {
        return null;
    }

    return (
        <Card title="General">
            <FormField
                label="Name"
                length={collectionType.name.length}
                maxLength={30}
            >
                <Input
                    value={collectionType.name}
                    onChange={(e) =>
                        dispatch(
                            patchCollectionTypeField('name', e.target.value),
                        )
                    }
                    size="large"
                    maxLength={30}
                    placeholder="Name for the collection type"
                />
            </FormField>

            <FormField label="Type">
                <CollectionClassSelector
                    value={collectionType.collection_class.id}
                    onChange={(newTypeId, newType) => {
                        dispatch(
                            patchCollectionTypeField(
                                'collection_class',
                                newType,
                            ),
                        );
                    }}
                ></CollectionClassSelector>
            </FormField>
        </Card>
    );
}

export default CollectionTypeGeneral;
