/** @type {Map.<string, Promise<any> >} */
const promises = new Map();

/**
 * Returns a promise for a particular key to be fetched, that will be only triggered the first time, the rest it will be taken from a cache.
 *
 * @param {string} key Key that identifies the request in the cache
 * @param {(query: string) => Promise<any>} fetcher Will receive key as the first param, and should return a promise
 */
export function withCache(key, fetcher) {
    if (!promises.get(key)) {
        const promise = fetcher(key);
        promises.set(key, promise);
    }
    return promises.get(key);
}
