import { Alert, Button, notification } from 'antd';
import { addCMSMedia } from 'api/cms-media.api';
import StoryItemEditor from 'components/GuideEditor/StoryItemEditor';
import { StockPhotoWarningModal } from 'components/StockPhotoWarning';
import { STORY_COVER } from 'constants/media-sizes.constants';
import { times } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    initGuideStories,
    notifyGuideStoriesChange,
    resetGuideStories,
    saveGuideStories,
} from 'store/guide-editor/actions';
import {
    areStoriesComplete as areStoriesCompleteSelector,
    getCurrentGuide,
    getCurrentStoriesBeingEdited,
    haveStoriesChanged as haveStoriesChangedSelector,
} from 'store/guide-editor/selectors';
import {
    getErrorLoadingStoriesForGuide,
    getStoriesForGuide,
    isLoadingStoriesForGuide,
    isSavingStoryItems as isSavingStoryItemsSelector,
} from 'store/story-items/selectors';
import { isSameExternalMedia } from 'utils/media-utils';
import { applyPatchToItem } from 'utils/sortable-item-utils';
import { getNonBoughtMediaFromList } from 'utils/stock-images-utils';
import { useSelector } from 'utils/use-selector';
import { v4 as uuid } from 'uuid';
import { SortableList } from './SortableList';
import { useSort } from './SortableList/use-sort';

/**
 *
 * @param {Object} props
 * @param {() => void} props.onClickDiscard
 */

function GuideEditorStory({ onClickDiscard }) {
    const dispatch = useDispatch();
    const currentGuide = useSelector(getCurrentGuide);
    const guideId = useMemo(() => currentGuide?.id, [currentGuide]);
    useEffect(() => {
        if (guideId) {
            dispatch(initGuideStories(guideId));
        }
        return () => {
            dispatch(resetGuideStories());
        };
    }, [guideId, dispatch]);

    const currentStories = useSelector(getCurrentStoriesBeingEdited);
    const oldStories = useSelector((state) =>
        getStoriesForGuide(state, guideId),
    );
    const isLoading = useSelector((state) =>
        isLoadingStoriesForGuide(state, guideId),
    );
    const errorLoading = useSelector((state) =>
        getErrorLoadingStoriesForGuide(state, guideId),
    );
    const isSavingStoryItems = useSelector(isSavingStoryItemsSelector);
    const haveStoriesChanged = useSelector(haveStoriesChangedSelector);
    const areStoriesComplete = useSelector(areStoriesCompleteSelector);
    const [
        isAddingStockPhotoWarningShown,
        setIsAddingStockPhotoWarningShown,
    ] = useState(false);
    /** @type {[CMSMedia[], React.Dispatch<CMSMedia[]>]} */
    const [photosToPurchase, setPhotosToPurchase] = useState(null);
    const [isSavingMedia, setIsSavingMedia] = useState(false);
    /** @type {[number, React.Dispatch<number>]}*/
    const [storyToScrollTo, setStoryToScrollTo] = useState(null); // We will increment this number when adding a story, and when that change is detected a scroll will be done to the last story
    useEffect(() => {
        if (storyToScrollTo) {
            const allStories = document.body.querySelectorAll(
                '.js-story-item-editor',
            );
            if (!allStories) {
                return;
            }
            allStories[allStories.length - 1]?.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [storyToScrollTo]);

    const { onMoveUp, onMoveDown } = useSort(currentStories);

    const addStory = () => {
        const newStory = getEmptyStory(currentGuide, currentStories);
        const newStories = currentStories.concat([newStory]);
        dispatch(notifyGuideStoriesChange(newStories));
        setStoryToScrollTo(1 + (storyToScrollTo || 0));
    };

    const preloadDefaultStories = () => {
        let newStories = [];
        times(6, () => {
            newStories.push(getEmptyStory(currentGuide, currentStories));
        });
        dispatch(notifyGuideStoriesChange(currentStories.concat(newStories)));
        setStoryToScrollTo(1 + (storyToScrollTo || 0));
    };

    /**
     * Patches many story fields at the same time and dispatches them to the store
     *
     * @param {import('utils/sortable-item-utils').ItemFieldChange[]} changes
     */
    const patchStoriesFields = (changes) => {
        let stories = currentStories;
        for (const change of changes) {
            stories = applyPatchToItem(stories, change);
        }
        dispatch(notifyGuideStoriesChange(stories));
    };

    /**
     * Patches a single story field and dispatches it to the store
     *
     * @param {import('utils/sortable-item-utils').ItemFieldChange} change
     */
    const patchStoryField = (change) => {
        patchStoriesFields([change]);
    };

    /**
     * @param {StoryItem} story
     */
    const moveStoryUp = (story) => {
        const sortedItems = onMoveUp(story);

        if (sortedItems) {
            patchStoriesFields(
                sortedItems.map((item) => ({
                    id: item.id,
                    fieldName: 'order',
                    newValue: item.newOrder,
                })),
            );
        }
    };

    /**
    
     * @param {StoryItem} story
     */
    const moveStoryDown = (story) => {
        const sortedItems = onMoveDown(story);

        if (sortedItems) {
            patchStoriesFields(
                sortedItems.map((item) => ({
                    id: item.id,
                    fieldName: 'order',
                    newValue: item.newOrder,
                })),
            );
        }
    };

    /**
    
     * @param {StoryItem} story
     */
    const removeStory = ({ id: storyId }) => {
        const newStories = currentStories.filter(
            (story) => story.id !== storyId,
        );
        dispatch(notifyGuideStoriesChange(newStories));
    };

    /**
     * Checks if the guide is published, and the editor is adding stories with stock photos that will be bought.
     *
     * @returns {Promise<{shouldShow: boolean, stories: StoryItem[]}>} When it returns true, the warning should be show, and a list of stories with media that needs licensing will be returned
     */
    const shouldShowWarningAddingStockPhotos = async () => {
        if (currentGuide.publish_status.name !== 'published') {
            return { shouldShow: false, stories: null };
        }

        const storiesWithChangedMedia = currentStories.filter((story) => {
            if (!story.cms_media) {
                return false;
            }
            const oldStory = oldStories.find(
                (oldStory) => oldStory.id === story.id,
            );
            if (!oldStory) {
                return true;
            }
            return !isSameExternalMedia(story.cms_media, oldStory.cms_media);
        });
        const allNonBoughtStockImages = await getNonBoughtMediaFromList(
            storiesWithChangedMedia.map((story) => story.cms_media),
        );
        const storiesWithNonBoughtStockImages = storiesWithChangedMedia.filter(
            (story) => {
                return !!allNonBoughtStockImages.find((image) =>
                    isSameExternalMedia(image, story.cms_media),
                );
            },
        );
        return {
            shouldShow: !!allNonBoughtStockImages.length,
            stories: storiesWithNonBoughtStockImages,
        };
    };

    const dispatchSavedStories = async () => {
        if (!guideId) return;

        try {
            setIsSavingMedia(true);
            for (const story of currentStories) {
                const oldStory = oldStories?.find(
                    (oldStory) => oldStory.id === story.id,
                );
                if (
                    !isSameExternalMedia(
                        oldStory?.cms_media ?? null,
                        story.cms_media,
                    )
                ) {
                    const addedMedia = await addCMSMedia(
                        story.cms_media,
                        STORY_COVER,
                        !!story.cms_media &&
                            photosToPurchase?.includes(story.cms_media),
                    );
                    story.cms_media = addedMedia;
                }
            }
            dispatch(saveGuideStories(guideId, currentStories));
        } catch (error) {
            notification.error({
                message: 'Something went wrong. Please, try again later',
            });
        } finally {
            setIsSavingMedia(false);
        }
    };

    const save = async () => {
        const {
            shouldShow,
            stories,
        } = await shouldShowWarningAddingStockPhotos();
        if (shouldShow) {
            setPhotosToPurchase(stories.map((story) => story.cms_media));
            setIsAddingStockPhotoWarningShown(true);
            return;
        }
        dispatchSavedStories();
    };

    if (!currentGuide) {
        return null;
    }

    return (
        <>
            {errorLoading && (
                <div className="full-page-tabs-error">
                    <Alert
                        className="grow-full-flex"
                        message="There was an error loading story items"
                        description={errorLoading}
                        type="error"
                        showIcon
                    />
                </div>
            )}
            <SortableList
                title="Stories"
                data={currentStories}
                onAdd={addStory}
                onDelete={removeStory}
                onMoveDown={moveStoryDown}
                onMoveUp={moveStoryUp}
                isLoading={isLoading || isSavingStoryItems}
                disableFirstItem
            >
                {({ data, index }) =>
                    !data ? (
                        <SortableList.EmptyState
                            message="No stories yet"
                            action={
                                <SortableList.AddButton
                                    onClick={preloadDefaultStories}
                                    disabled={isLoading}
                                >
                                    Preload six stories
                                </SortableList.AddButton>
                            }
                        />
                    ) : (
                        <StoryItemEditor
                            story={data}
                            index={index}
                            guide={currentGuide}
                            onFieldChanged={(storyId, fieldName, newValue) =>
                                patchStoryField({
                                    id: storyId,
                                    fieldName,
                                    newValue,
                                })
                            }
                        />
                    )
                }
            </SortableList>

            {isAddingStockPhotoWarningShown && (
                <StockPhotoWarningModal
                    numberOfPhotos={photosToPurchase.length}
                    onConfirm={() => {
                        setIsAddingStockPhotoWarningShown(false);
                        dispatchSavedStories();
                    }}
                    onCancel={() => {
                        setIsAddingStockPhotoWarningShown(false);
                    }}
                />
            )}
            <div className="full-page-tabs-footer">
                <div className="max-content-width">
                    <div className="grow-full-flex"></div>

                    <Button size="large" onClick={onClickDiscard}>
                        Cancel
                    </Button>

                    <Button
                        size="large"
                        type="primary"
                        loading={isSavingStoryItems || isSavingMedia}
                        onClick={() => save()}
                        disabled={!haveStoriesChanged || !areStoriesComplete}
                    >
                        Save changes
                    </Button>
                </div>
            </div>
        </>
    );
}

/**
 *
 * @param {Guide} guide
 * @param {StoryItem[]} otherStories
 * @returns {StoryItem}
 */
function getEmptyStory(guide, otherStories) {
    return {
        id: uuid(),
        guide_id: guide.id,
        spot: null,
        cms_media: null,
        tagline: '',
        order: otherStories.length,
        editor_notes: '',
    };
}

export default GuideEditorStory;
