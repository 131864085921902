import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { getCurrentUser } from 'store/auth/selectors';
import { logout } from 'store/auth/actions';
import { Button } from 'antd';

function SidebarUser() {
    const dispatch = useDispatch();
    const user = useSelector(getCurrentUser);

    return (
        <div className="sidebar-user">
            <Avatar
                size={48}
                icon={<UserOutlined />}
                src={user.google_profile_image}
            />
            <div className="sidebar-user__text">
                <div>
                    {user.first_name} {user.last_name}
                </div>
                <Button
                    type="link"
                    onClick={() => dispatch(logout())}
                    style={{ padding: 0, height: 24, fontSize: 12 }}
                >
                    Logout
                </Button>
            </div>
        </div>
    );
}

export default SidebarUser;
