import { Spin, Typography } from 'antd';
import { isVideo as checkIsVideo } from 'constants/media-types.constants';
import { MEDIA_SELECTOR_POPOVER_IMG_SIZE } from 'constants/ux';
import React from 'react';
import { getContainedSize } from 'utils/image/image-size-utils';
import { useMediaUser } from 'utils/use-media-user';

import tooltipStyles from '../../MediaItemTooltipWrapper/style.module.css';
import './PolarstepsMediaItemPopover.css';

const { Text } = Typography;

/**
 * @param {Object} props
 * @param {PSMedia} props.media
 */
function PolarstepsMediaItemPopover({ media }) {
    const { user, isLoading } = useMediaUser(media);
    const size = getImagePreviewSize(media);
    const isVideo = checkIsVideo(media);
    const containedSize = getContainedSize(
        size,
        MEDIA_SELECTOR_POPOVER_IMG_SIZE,
    );

    return (
        <div className={tooltipStyles.root}>
            {isVideo && (
                <video
                    className={tooltipStyles.image}
                    controls
                    style={size}
                    autoPlay={true}
                    muted={true}
                    src={media.cdn_path}
                />
            )}
            {!isVideo && (
                <img
                    className={tooltipStyles.image}
                    style={containedSize}
                    src={media.large_thumbnail_path}
                    alt={`ID: ${media.id}`}
                />
            )}

            <div className="line-center">
                {isLoading && <Spin size="small" />}
            </div>

            <div className={tooltipStyles.text}>
                {user && (
                    <div>
                        Media by{' '}
                        <a
                            href={`https://polarsteps.com/${user.username}`}
                            target="_BLANK"
                            rel="noopener noreferrer"
                        >
                            {user.first_name} {user.last_name}
                        </a>
                    </div>
                )}
                <div className="mt-s">
                    <Text type="danger">No full-res available</Text>
                </div>
            </div>
        </div>
    );
}

/**
 *
 * @param {PSMedia} media
 */
function getImagePreviewSize(media) {
    const MAX_SIZE = 500;

    const originalWidth = media.full_res_width || 600;
    const originalHeight = media.full_res_height || 800;

    const ratio = originalWidth / originalHeight;
    if (originalWidth > originalHeight) {
        return {
            height: MAX_SIZE / ratio,
            width: MAX_SIZE,
        };
    } else {
        return {
            height: MAX_SIZE,
            width: MAX_SIZE * ratio,
        };
    }
}

export default PolarstepsMediaItemPopover;
