import React, { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Button, notification, Alert } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'utils/use-selector';
import { getCurrentGuide } from 'store/guide-editor/selectors';
import Loading from 'components/Loading';
import CollectionEditor from './CollectionEditor';
import { isCollectionCore, isCollectionTheme } from 'utils/collection-utils';
import {
    refreshTopCollectionsForGuide,
    refreshCollectionsForGuide,
    useTopCollections,
    useCollections,
    addCollection,
} from 'api/collections.api';
import CollectionsSet from './CollectionsSet';
import TopCollectionsSet from './TopCollectionsSet';
import SpotFilterSelector from './SpotFilterSelector';
import { useCurrentUser } from 'utils/use-current-user';
import { getAllPublishStatus } from 'api/publish-status.api';
import { parse } from 'utils/error-parser';
import './style.css';

function GuideEditorCollections() {
    const currentGuide = useSelector(getCurrentGuide);
    const guideId = useMemo(() => currentGuide?.id, [currentGuide]);
    const [isSavingNewCollection, setIsSavingNewCollection] = useState(false);
    const [isStartingToAddCollection, setIsStartingToAddCollection] = useState(
        false,
    );
    const [isAddingCollection, setIsAddingCollection] = useState(false);
    /** @type {[Collection, React.Dispatch<Collection>]} */
    const [defaultNewCollection, setDefaultNewCollection] = useState(null);

    /** @type {[Source[], React.Dispatch<Source[]>]} */
    const [filterBySources, setFilterBySources] = useState(null);
    const {
        collections,
        isLoading: isValidatingCollections,
        error: errorLoadingCollections,
    } = useCollections(guideId);

    const {
        topCollections,
        isLoading: isValidatingTopCollections,
        error: errorLoadingTopCollections,
    } = useTopCollections(guideId);

    const coreCollections = useMemo(
        () => (collections || []).filter(isCollectionCore),
        [collections],
    );
    const themeCollections = useMemo(
        () => (collections || []).filter(isCollectionTheme),
        [collections],
    );

    const isLoadingCollections = !collections && isValidatingCollections;
    const isLoadingTopCollections =
        !topCollections && isValidatingTopCollections;

    const currentUser = useCurrentUser();

    const saveNewCollection = async (newCollection) => {
        try {
            setIsSavingNewCollection(true);
            await addCollection(newCollection);
            refreshCollectionsForGuide(currentGuide.id);
            notification.success({
                message: 'The collection was added successfully',
            });
            setDefaultNewCollection(null);
            setIsAddingCollection(false);
        } catch (error) {
            notification.error({
                message: 'Error adding collection',
                description: parse(error),
            });
        } finally {
            setIsSavingNewCollection(false);
        }
    };

    const startAddingCollection = async () => {
        if (!currentUser) {
            return;
        }
        setIsStartingToAddCollection(true);
        const defNewCollection = await getDefaultNewCollection(
            currentUser,
            currentGuide,
        );
        setIsStartingToAddCollection(false);
        setDefaultNewCollection(defNewCollection);
        setIsAddingCollection(true);
    };

    return (
        <>
            <div className="add-collection-button-container">
                <Button
                    type="primary"
                    disabled={!currentUser}
                    icon={<PlusOutlined />}
                    loading={isStartingToAddCollection}
                    onClick={() => startAddingCollection()}
                >
                    Add collection
                </Button>
            </div>
            {defaultNewCollection && (
                <CollectionEditor
                    isVisible={isAddingCollection}
                    isEditing={false}
                    isLoading={isSavingNewCollection}
                    onSave={saveNewCollection}
                    onDiscard={() => setIsAddingCollection(false)}
                    originalCollection={defaultNewCollection}
                />
            )}

            {!isLoadingCollections && (
                <>
                    <SpotFilterSelector
                        sources={filterBySources || []}
                        onChangeSources={(sources) =>
                            setFilterBySources(sources)
                        }
                    />

                    <CollectionsSet
                        collections={coreCollections}
                        title="Core collections"
                        guide={currentGuide}
                        filterBySources={filterBySources}
                    />

                    <CollectionsSet
                        collections={themeCollections}
                        title="Theme collections"
                        guide={currentGuide}
                        filterBySources={filterBySources}
                    />
                </>
            )}

            {!isLoadingCollections &&
                !isLoadingTopCollections &&
                !errorLoadingTopCollections &&
                !!topCollections && (
                    <TopCollectionsSet
                        collections={topCollections}
                        title="Top collections"
                        filterBySources={filterBySources}
                        guide={currentGuide}
                    />
                )}

            {isLoadingCollections && (
                <div className="full-page-tabs-loading">
                    <Loading />
                </div>
            )}

            {errorLoadingCollections && (
                <div className="full-page-tabs-error">
                    <Alert
                        className="grow-full-flex mb-l"
                        message="There was an error loading collections"
                        description={errorLoadingCollections.toString()}
                        type="error"
                        showIcon
                        action={
                            <Button
                                size="large"
                                onClick={() => {
                                    refreshCollectionsForGuide(guideId);
                                }}
                            >
                                Try again
                            </Button>
                        }
                    />
                </div>
            )}
            {errorLoadingTopCollections && (
                <div className="full-page-tabs-error">
                    <Alert
                        className="grow-full-flex mb-l"
                        message="There was an error loading top collections"
                        description={errorLoadingTopCollections.toString()}
                        type="error"
                        showIcon
                        action={
                            <Button
                                size="large"
                                onClick={() => {
                                    refreshTopCollectionsForGuide(guideId);
                                }}
                            >
                                Try again
                            </Button>
                        }
                    />
                </div>
            )}
        </>
    );
}

export default GuideEditorCollections;

/**
 *
 * @param {CMSUser} currentUser
 * @param {Guide} guide
 * @returns {Promise<Collection>}
 */
async function getDefaultNewCollection(currentUser, guide) {
    const allPublishStatus = await getAllPublishStatus();
    const publishedPublishStatus = allPublishStatus.find(
        (status) => status.name.toLowerCase() === 'published',
    );
    return {
        id: uuid(),
        guide_id: guide.id,
        name: '',
        description: '',
        collection_type: null,
        collection_type_id: null,
        main_editor: currentUser,
        guide: {
            ...guide,
            search_area: guide.search_area
                ? JSON.stringify(guide.search_area)
                : null,
            viewport_area: guide.viewport_area
                ? JSON.stringify(guide.viewport_area)
                : null,
        },
        publish_status: publishedPublishStatus,
        publish_status_id: publishedPublishStatus.id,
        editor_notes: '',
    };
}
