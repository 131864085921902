import useSWR from 'swr';
import { BASE_URL } from 'constants/env';
import { get, patch, post } from 'utils/http';
import { parse } from 'utils/error-parser';

/**
 * @param {Id} spotId
 * @returns {Promise<PolarstepsSpot>}
 */
function getPolarstepsSpot(spotId) {
    return get(`${BASE_URL}/cms/polarspots/${spotId}`);
}

/**
 * @param {Id} spotId
 */
export const usePolarstepsSpot = (spotId) => {
    const key = spotId ? `polarsteps-spot/${spotId}` : null;
    const { data, error, isValidating, mutate } = useSWR(
        key,
        () => getPolarstepsSpot(spotId),
        {
            revalidateOnFocus: false,
        },
    );

    return {
        error: error ? parse(error) : null,
        isLoading: !data && isValidating,
        spot: data,
        mutate,
    };
};

/**
 *
 * @param {PolarstepsSpot} spot
 * @returns {Promise<PolarstepsSpot>}
 */
export const addPolarstepsSpot = (spot) => {
    return post(`${BASE_URL}/cms/polarspots`, spot);
};

/**
 *
 * @param {PolarstepsSpot} spot
 * @returns {Promise<PolarstepsSpot>}
 */
export const patchPolarstepsSpot = (spot) => {
    return patch(`${BASE_URL}/cms/polarspots/${spot.id}`, spot);
};
