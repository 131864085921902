/**
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const isLoadingAuth = (state) => {
    return state.auth.loading;
};

/**
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const isInitted = (state) => {
    return state.auth.initted;
};

/**
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const isLoggedIn = (state) => !!state.auth.loggedInUser;

/**
 *
 * @param {RootState} state
 * @returns {string}
 */
export const getErrorLoading = (state) => state.auth.errorLoading;

/**
 *
 * @param {RootState} state
 * @returns {CMSUser}
 */
export const getCurrentUser = (state) => state.auth.loggedInUser;
