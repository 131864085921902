import useSWR, { mutate } from 'swr';
import { get, patch } from 'utils/http';
import { BASE_URL } from 'constants/env';
import { useMemo } from 'react';
import { parse } from 'utils/error-parser';

/**
 *
 * @param {PSSpot_v15_Minimal} spot
 * @returns {Promise<PSSpot_v15_Photo[]>}
 */
function getSpotCustomGallery(spot) {
    return get(`${BASE_URL}/cms/spots/api/${spot.id}/custom_gallery`)
        .then((result) => {
            return result || [];
        })
        .catch((e) => {
            if (e.status === 404) {
                return [];
            }
            return Promise.reject(parse(e));
        });
}

/**
 *
 * @param {PSSpot_v15_Minimal} spot
 */
function getSpotCustomGallerySWRKey(spot) {
    return spot ? `/cms/spots/api/${spot.id}/custom_gallery` : null;
}

/**
 *
 * @param {PSSpot_v15_Minimal} spot
 */
export const useSpotCustomGallery = (spot) => {
    const key = getSpotCustomGallerySWRKey(spot);
    const { data: spotGalleryList, isValidating, mutate, error } = useSWR(
        key,
        () => getSpotCustomGallery(spot),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        },
    );
    /** @type {{[x: string]: PSSpot_v15_Photo;}} */
    const spotGalleryHash = useMemo(() => {
        if (!spotGalleryList) {
            return {};
        }
        return spotGalleryList.reduce((acc, spotPhoto) => {
            return {
                ...acc,
                [spotPhoto.external_id]: spotPhoto,
            };
        }, {});
    }, [spotGalleryList]);

    return {
        isLoading: isValidating && !spotGalleryList,
        mutate,
        spotGalleryList,
        spotGalleryHash,
        isValidating,
        error,
    };
};

/**
 *
 * @param {PSSpot_v15_Minimal} spot
 * @param {PSSpot_v15_Photo[]} updatedPhotos
 */
export const updateGallery = async (spot, updatedPhotos) => {
    const photosAfterUpdate = await patch(
        `${BASE_URL}/cms/spots/api/${spot.id}/custom_gallery`,
        {
            photos: updatedPhotos,
        },
    );
    mutate(getSpotCustomGallerySWRKey(spot));
    return photosAfterUpdate;
};
