import React from 'react';
import classNames from 'classnames';
import mandatoryIcon from './asterisk.svg';
import './style.css';

/**
 *
 * @param {Object} props
 * @param {string} [props.label=null]
 * @param {React.ReactNode} [props.children=null]
 * @param {number} [props.length=null]
 * @param {number} [props.maxLength=null]
 * @param {string} [props.className = '']
 * @param {boolean} [props.mandatory = false]
 */
function FormField2({
    label = null,
    children = null,
    length = null,
    maxLength = null,
    className = '',
    mandatory = false,
}) {
    const showMaxLengthInfo =
        typeof length === 'number' && typeof maxLength === 'number';

    return (
        <div
            className={classNames('form-field-2', className, {
                'form-field-2-with-max-value': !!maxLength,
            })}
        >
            {label && (
                <div className="form-field-2__label">
                    {mandatory && (
                        <div className="form-field-2__label-icon">
                            <img src={mandatoryIcon} alt="Mandatory" />
                        </div>
                    )}
                    {label}
                    {!mandatory && (
                        <span className="form-field-2__label-optional">
                            &nbsp;(optional)
                        </span>
                    )}
                </div>
            )}

            <div className="form-field-2__value">{children}</div>
            {showMaxLengthInfo && (
                <div
                    className={classNames('form-field-2__max-length', {
                        'form-field-2__max-length-error': length > maxLength,
                    })}
                >
                    {length} / {maxLength}
                </div>
            )}
        </div>
    );
}

export default FormField2;
