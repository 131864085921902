/**
 * Finds the "archived" status from a list of possible publish_status
 *
 * @param {PublishStatus[]} allStatus
 * @returns {PublishStatus}
 */
export const getArchivedFromList = (allStatus) => {
    return allStatus.find(
        (publishStatus) => publishStatus.name.toLowerCase() === 'archived',
    );
};
