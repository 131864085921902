import { BASE_URL } from 'constants/env';
import useSWR from 'swr';
import { parse } from 'utils/error-parser';
import { get, patch, post } from 'utils/http';
import { loadSharedCodebase } from 'utils/use-shared-codebase';

/**
 * Search spot result
 *
 * @typedef {object} SearchSpotsResult
 * @property {string} query query used to search
 * @property {{ data: PSSpot_v15_Minimal[]}} results
 */

/**
 * Searchs spots in Polarsteps
 *
 * @param {string} query
 * @param {Guide} guide
 * @param {PSLocation} location
 * @returns {Promise<SearchSpotsResult>}
 */
export const search = (query, guide, location) => {
    return get(
        `${BASE_URL}/cms/guides/${guide.id}/spots/search?q=${query}&center=${location.lat},${location.lon}`,
    ).then((result = {}) => {
        return {
            query,
            results: result || [],
        };
    });
};

/**
 * Adds a spot
 *
 * @param {PSSpot_v15_Minimal} spot
 * @returns {Promise<PSSpot_v15>}
 */
export const add = async (spot) => {
    const sharedCodebase = await loadSharedCodebase();
    const { CATEGORY_TREE_URL } = sharedCodebase.com.polarsteps.shared.core;
    let core = sharedCodebase.polarstepsCore();
    const tree = await get(CATEGORY_TREE_URL, {
        credentials: 'omit',
    });
    const coreCategories = core.createCategoryTreeWithData(
        JSON.stringify(tree),
    );
    const category = coreCategories.findCategory(spot.sub_category);

    // In order to support older versions of the app, we send legacy fields
    // TODO: this should be handled by the backend at some point
    // Since a fallback category doesn't have a parent category, we just send whatever is in that spot for those cases.
    const categoryString =
        category.firstLevelCategory()?.id || spot.category_new;
    return post(`${BASE_URL}/cms/spots`, {
        ...spot,
        category: categoryString,
    });
};
/**
 * Get details of spot
 *
 * @param {Id} spotId The internal_id for a PSSpot
 * @returns {Promise<PSSpot_v15>}
 */
export const getDetails = async (spotId) => {
    return get(`${BASE_URL}/cms/spots/api/detail/${spotId}`).catch((error) => {
        throw parse(error);
    });
};

/**
 * @param {Id} spotId The internal_id for a PSSpot
 */
function getSWRKeyForSpotDetails(spotId) {
    if (!spotId) {
        return null;
    }
    return `${BASE_URL}/cms/spots/api/detail/${spotId}`;
}

/**
 * Hook to get a PSSpot2 from an old spot (it will be used in the future to get the full spot from a minimal one)
 *
 * @param {PSSpot_v15_Minimal} spot
 */
export const useSpotDetails = (spot) => {
    const {
        data,
        isValidating,
        error,
        mutate,
    } = useSWR(getSWRKeyForSpotDetails(spot?.id), () => getDetails(spot.id));

    return {
        isLoading: !data && isValidating,
        fullSpot: data,
        errorLoading: error,
        mutate,
    };
};

/**
 * Migrates the source of a spot to new data
 *
 * @param {Id} spotId The id of the (old) spot we want to migrate
 * @param {PSSpot_v15_Minimal} newSpot The new data for the spot
 * @param {SpotMigrationReason} reason Why we migrate. It can be a fix, so the spot will just be replaced, or there is a new source for it,
 * in which case we should add the source to a list of sources.
 * Right now there is no one-to-many relationship, so we will just store the reason to allow recovering the duplicate spots in the future.
 * @returns {Promise<PSSpot_v15>}
 */
export const migrateSpotSource = (spotId, newSpot, reason) => {
    return post(`${BASE_URL}/cms/spot_source_migration/spot/${spotId}`, {
        ...newSpot,
        reason,
    });
};

/**
 * Patches a spot in the database
 *
 * @param {PatchSpot} spot
 */
export const patchSpot = (spot) => {
    return patch(
        `${BASE_URL}/cms/spots/${spot.spot_id}`,
        { is_reviewed: spot.is_reviewed },
        {
            headers: {
                'Polarsteps-Api-Version': '52',
                'Content-Type': 'application/json',
            },
        },
    );
};
