import { get } from 'utils/http';
import { BASE_URL } from 'constants/env';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

let allSourcesCache = null;

/**
 * Gets all sources
 *
 * @returns {Promise<Source[]>}
 */
export const getAllSources = async () => {
    if (allSourcesCache) {
        return Promise.resolve(allSourcesCache);
    }
    let allSources = [];
    const generator = sourcesPageGenerator();
    for await (const page of generator) {
        allSources = [...allSources, ...page];
    }
    allSourcesCache = allSources;
    return allSources;
};

async function* sourcesPageGenerator() {
    const resultsPerPage = 50;
    let currentPage = 1;
    let lastResult = null;
    while (!lastResult || lastResult.page < lastResult.total_pages) {
        lastResult = await get(
            `${BASE_URL}/cms/sources?page=${currentPage}&results_per_page=${resultsPerPage}`,
        );
        currentPage += 1;
        yield lastResult.objects;
    }
}

/**
 * Gets the source Id for a source name
 *
 * @param {string} sourceName
 * @returns {Promise<Id>}
 */
export const getSourceId = async (sourceName) => {
    const sources = await getAllSources();
    const relatedSource = sources.find((source) => source.name === sourceName);
    return relatedSource?.id;
};

/**
 * Hook to get all sources
 *
 * @returns {{isLoading: boolean, sources: Source[], error: string}}
 */
export const useSources = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [sources, setSources] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        getAllSources()
            .then((result) => setSources(result))
            .catch((error) => setError(error))
            .finally(() => setIsLoading(false));
    }, []);

    return { isLoading, sources, error };
};

/**
 * Checks if a source is related to third party spots, like Airbnb or 4square, as opposed to other types of sources like photos.
 *
 * @param {Source} source
 */
export const isSpotSource = (source) => {
    // We assume all spources are spot sources, except these ones
    const nonSpotSourceNames = [
        'polarsteps',
        'facebook',
        'location_iq',
        'unsplash',
        'shutterstock',
    ];
    return !nonSpotSourceNames.includes(source.name);
};

/**
 * Hook to get all sources from the database
 */
export const useAllSources = () => {
    const { data, error } = useSWR('sources', getAllSources, {
        revalidateOnFocus: false,
    });

    return {
        isLoading: !data && !error,
        allSources: data,
    };
};
