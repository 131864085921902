/**
 * @typedef ImageSize
 * @property {number} width
 * @property {number} height
 */

/**
 * Gets the size for an image that will fit the constraints in maxSize, while maintaining the ratio of originalSize
 *
 * @param {ImageSize} originalSize
 * @param {ImageSize} maxSize
 * @returns {ImageSize}
 */
export const getContainedSize = (originalSize, maxSize) => {
    if (!originalSize) {
        return null;
    }
    const heightFitted = fitToHeight(maxSize, originalSize);
    if (
        heightFitted.height <= maxSize.height &&
        heightFitted.width <= maxSize.width
    ) {
        return heightFitted;
    }
    return fitToWidth(maxSize, originalSize);
};

/**
 * @param {ImageSize} targetSize
 * @param {ImageSize} originalSize
 * @returns {ImageSize}
 */
function fitToWidth(targetSize, originalSize) {
    const resizeFactor = targetSize.width / originalSize.width;
    return {
        height: originalSize.height * resizeFactor,
        width: targetSize.width,
    };
}

/**
 * @param {ImageSize} targetSize
 * @param {ImageSize} originalSize
 * @returns {ImageSize}
 */
function fitToHeight(targetSize, originalSize) {
    const resizeFactor = targetSize.height / originalSize.height;
    return {
        height: targetSize.height,
        width: originalSize.width * resizeFactor,
    };
}
